import { validStatusCode } from '../constants';
import { SubjectAccountContacts, SubjectEnum } from '../types/subject/subject';
import { fetchSurveys } from './api/forms';
import { fetchOrderTemplates } from './api/orderTemplates';

export const throttle = (callback: any, limit = 250) => {
  let waiting = false;
  return (...args: any[]) => {
    if (!waiting) {
      callback.apply(this, args);
      waiting = true;
      setTimeout(function () {
        waiting = false;
      }, limit);
    }
  };
};

export const debounce = (func: any) => {
  let timer: null | ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 250);
  };
};

export const withErrorText = (t: any, text?: string) => {
  if (text == '' || text == 'null' || text == null) {
    return t('errors.nA');
  }
  return text;
};

export const isValidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const subjectLinkType = (gid: string) =>
  gid.includes(SubjectEnum.Person)
    ? 'people'
    : gid.includes(SubjectEnum.Household)
    ? 'households'
    : 'organizations';

export const subjectLink = (gid: string) => {
  const [type, id] = gid.split('/').splice(-2);
  return `${subjectLinkType(type)}/${id}`;
};

export const classNames = (...classes: Array<string>) => {
  return classes.filter(Boolean).join(' ');
};

export const reverseSubjectType = (type: SubjectEnum) => {
  return type === SubjectEnum.Person ? SubjectEnum.Organization : SubjectEnum.Person;
};
export const lowerReverseSubjectType = (type: SubjectEnum) => {
  return type === SubjectEnum.Person ? SubjectEnum.organization : SubjectEnum.person;
};

export const getParamString = (params: any) =>
  Object.keys(params)
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');

export const slugify = (str: string) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export const shorten = (str: string, maxLen: number, separator = ' ') => {
  if (!str) return '';
  if (str.length <= maxLen) return str;
  return str.substring(0, str.lastIndexOf(separator, maxLen)) + '...';
};

export const moreThanHundredsMessages = (messages: number): string =>
  messages > 99 ? '99+' : messages.toString();

export const isValidStatus = (status: number) => validStatusCode.includes(status);

export const convertNumberToString = (num: number): string => {
  return num.toString();
};

export const selectedAccountContactsHandler = (contact: number): string | number => {
  return contact === 0 ? contact.toString() : contact;
};

export const filterBySubject = (
  array: SubjectAccountContacts[],
  subjectClassType: SubjectEnum,
  subjectType: SubjectEnum,
): SubjectAccountContacts[] => {
  return array.filter(item => {
    if (subjectClassType === SubjectEnum.Person) {
      return subjectType === SubjectEnum.Organization
        ? item.type === SubjectEnum.Organization
        : item.type === SubjectEnum.Household;
    } else {
      return item.type === SubjectEnum.Person;
    }
  });
};

export const arrayWithNoDuplicates = (array: any[], object: any, key: string): any[] => {
  const concatenatedArray = array.concat([object]); // merge two arrays
  const newMap = new Map();
  for (const item of concatenatedArray) {
    newMap.set(item[key], item);
  }
  return [...newMap.values()];
};

export const getInitialsFromName = (name: string) => {
  return name
    .split(' ')
    .slice(0, 3)
    .map(namePart => namePart.charAt(0))
    .join('')
    .toUpperCase();
};

export const getOrderTemplates = (id: number) =>
  fetchOrderTemplates(id).then(response =>
    response.map(form => ({
      value: form.id,
      label: form.name,
    })),
  );

export const getParticipationForms = (id: number) =>
  fetchSurveys(id, 'participation').then(response =>
    response.map(form => ({
      value: form.id,
      label: form.name,
    })),
  );

export const getActivityForms = (id: number, type: string) =>
  fetchSurveys(id, 'survey', type).then(response =>
    response.map(form => ({
      value: form.id,
      label: form.name,
    })),
  );

export const gidToClassType = (gid: string) => {
  if (gid === null || gid === undefined || gid === '') {
    return undefined;
  }

  let subjectType;
  if (gid?.includes('Organization')) {
    subjectType = 'Organization';
  } else if (gid?.includes('Household')) {
    subjectType = 'Household';
  } else {
    subjectType = 'Person';
  }

  return subjectType;
};

export const gidToId = (gid: string) => {
  if (gid === null || gid === undefined || gid === '') {
    return undefined;
  }
  const id = gid.substring(gid.lastIndexOf('/') + 1);

  return id;
};
