import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

const OAUTH_ENDPOINT = import.meta.env.VITE_OAUTH_ENDPOINT;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = JSON.parse(localStorage.getItem('@toucan_token') || '""');
  if (token && config.headers) config.headers['Authorization'] = `Bearer ${token.access_token}`;

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  if (error.response) {
    // Access Token was expired
    if (error.response.status === 401) {
      const storedToken = JSON.parse(localStorage.getItem('@toucan_token') || '""');

      try {
        const rs = await axios.post(`${OAUTH_ENDPOINT}/token`, {
          grant_type: 'refresh_token',
          refresh_token: storedToken.refresh_token,
          client_id: import.meta.env.VITE_TOUCAN_CLIENT_ID,
          client_secret: import.meta.env.VITE_TOUCAN_CLIENT_SECRET,
        });
        localStorage.setItem('@toucan_token', JSON.stringify(rs.data));

        if (error.config.headers)
          error.config.headers['Authorization'] = `Bearer ${rs.data.access_token}`;

        return axios.request({ ...error.config });
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
  }
  return error;
};

export const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
