// Utils
import { useTranslation } from 'react-i18next';

// Types & Interfaces
type SearchOrCreateButtonProps = {
  toggleCreate?: Function;
  toggleSearch?: Function;
  searchFunction?: any;
  createFunction?: any;
  searchShown?: boolean;
};

const SearchOrCreateButton = ({
  toggleCreate,
  toggleSearch,
  searchFunction,
  createFunction,
  searchShown,
}: SearchOrCreateButtonProps) => {
  const { t } = useTranslation('dashboard');

  return (
    <div className="w-full gap-1 flex flex-row">
      <a
        onClick={() => {
          toggleCreate && toggleCreate(false);
          toggleSearch && toggleSearch(true);
          searchFunction && searchFunction();
        }}
        className={`rounded-lg flex justify-center items-center gap-1 h3 z-20 w-full h-[56px] ${
          searchShown
            ? 'bg-white text-primaryColor border border-primaryColor'
            : 'bg-primaryColor text-white  '
        }`}>
        {t('subjects.householdWizard.addCollaboration.actions.search')}
      </a>
      <a
        onClick={() => {
          toggleCreate && toggleCreate(true);
          toggleSearch && toggleSearch(false);
          createFunction && createFunction();
        }}
        className={`rounded-lg flex justify-center items-center gap-1 h3 z-20 w-full h-[56px] ${
          searchShown
            ? 'bg-primaryColor text-white '
            : 'bg-white text-primaryColor border border-primaryColor'
        }`}>
        {t('subjects.householdWizard.addCollaboration.actions.create')}
      </a>
    </div>
  );
};

export default SearchOrCreateButton;
