// Utils
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Combobox } from '@headlessui/react';
import { debounce } from '../../utils/utils';
import { classNames } from '../../utils/utils';
import '../../style/index.scss';

// Components
import Highlighted from '../Highlighted/Highlighted';

// Contexts
import { AccountContext } from '../../contexts/accountContext';

// Types
import { Option } from '../../types/input/select';
import { SubjectEnum } from '@/types';

// Icons & Images
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as AddIcon } from '@/assets/icons/add-circle.svg';

type FetchCallbackType = (accountId: number, ...args: Array<any>) => Promise<{ data: any }>;

type MultipleQueryCallbackType = Array<{
  name: string;
  callback: FetchCallbackType;
  type?: SubjectEnum;
}>;

const ResultOption = ({
  option,
  query,
  groupBy,
}: {
  option: Option;
  query: string;
  groupBy?: string;
}) =>
  groupBy ? (
    <React.Fragment>
      <Combobox.Option
        className="text-gray-500 cursor-default select-none relative py-2 pl-3 pr-9"
        value={option}
        disabled>
        <div className="flex items-center">
          <span className="font-bold block truncate">{option.name}</span>
        </div>
      </Combobox.Option>
      {option[groupBy].map((subItem: any) => (
        <Combobox.Option
          key={`${subItem.id}.${subItem.name}`}
          className={({ active }) =>
            classNames(
              active ? 'text-white bg-primaryColor' : 'text-gray-900',
              'cursor-default select-none relative py-2 pl-3 pr-9',
            )
          }
          value={subItem}>
          <div className="flex items-center">
            <span className="font-normal ml-3 block truncate">
              <Highlighted text={subItem.name} highlight={query} />
            </span>
          </div>
        </Combobox.Option>
      ))}
    </React.Fragment>
  ) : (
    <Combobox.Option
      key={`${option.id}.${option.name}`}
      className={({ active }) =>
        classNames(
          active ? 'text-white bg-primaryColor' : 'text-gray-900',
          'cursor-default select-none relative py-2 pl-3 pr-9',
        )
      }
      value={option}>
      <div className="flex items-center">
        <span className="font-normal ml-3 block truncate">
          <Highlighted text={option.name} highlight={query} />
        </span>
      </div>
    </Combobox.Option>
  );

const EmptyResultOption = ({ option }: { option: Option }) => (
  <Combobox.Option value={option} disabled>
    <div className="space-x-1 px-4 py-2 bg-white">
      <span className="font-medium text-gray-900">{option.name}</span>
    </div>
  </Combobox.Option>
);

const Autocomplete = ({
  setSelected,
  selected,
  fetchCallback,
  placeholder,
  local,
  groupBy,
  additionnalParam,
  clear,
  autoClear,
  autoFocus = true,
  specifiedAccountId,
  isMultiItems = true,
  displayValue = true,
  isAddOnSearchBar,
  openSubjectModalHandler,
  index,
  pushValues,
  blackPlaceholder = false,
}: {
  setSelected?: React.Dispatch<React.SetStateAction<any | undefined>>;
  selected?: Option;
  fetchCallback: FetchCallbackType | MultipleQueryCallbackType;
  placeholder?: string;
  local?: boolean;
  groupBy?: string;
  additionnalParam?: string | number;
  clear?: boolean;
  autoClear?: boolean;
  autoFocus?: boolean;
  specifiedAccountId?: number;
  isMultiItems?: boolean;
  displayValue?: boolean;
  isAddOnSearchBar?: boolean;
  setIsAddOnSearchBar?: any;
  openSubjectModalHandler?: any;
  index?: any;
  pushValues?: any;
  blackPlaceholder?: boolean;
}) => {
  const { t } = useTranslation('dashboard');

  const accountContext = React.useContext(AccountContext);
  const {
    selected: { id: selectedAccountId },
  } = accountContext.state;

  const accountId = specifiedAccountId || selectedAccountId;

  const emptyOption = {
    id: 0,
    name: t('placeHolders.noResults'),
  };

  const isMulti = Array.isArray(fetchCallback);

  const [searchText, setSearchText] = React.useState<string>('');
  const [options, setOptions] = React.useState<any>();
  const [item, setItem] = React.useState<Option>();

  const fetchQuery = async (query: string) => {
    if (isMulti) {
      Promise.all(fetchCallback.map(fc => fc.callback(accountId, query)))
        .then(function (data) {
          const result = data.map((val, i) => ({
            name: fetchCallback[i].name,
            options: val,
          }));
          setOptions(result);
        })
        .catch(error => console.log('network error', error));
    } else {
      fetchCallback(accountId, query)
        .then(function (data) {
          setOptions(data.data ?? data);
        })
        .catch(error => console.log('network error', error));
    }
  };

  const optimizedFn = React.useCallback(debounce(fetchQuery), []);
  const handleAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    e.target.value.length >= 3 && optimizedFn(e.target.value);
  };

  const localSearch = (e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value);
  const filteredResults =
    searchText === ''
      ? options
      : options?.filter(
          (option: Option) =>
            option.name.toLowerCase().includes(searchText.toLowerCase()) ||
            (groupBy &&
              option[groupBy].some((subItem: Option) =>
                subItem.name.toLowerCase().includes(searchText.toLowerCase()),
              )),
        );

  React.useEffect(() => {
    if (local) {
      if (isMulti) {
        Promise.all(
          fetchCallback.map(fc =>
            fc.callback(accountId, ...(additionnalParam ? [additionnalParam] : []), 1, 50),
          ),
        )
          .then(function (data) {
            const result = data.map((val, i) => ({
              name: fetchCallback[i].name,
              options: val,
            }));
            setOptions(result);
          })
          .catch(error => console.log('network error', error));
      } else {
        fetchCallback(accountId, ...(additionnalParam ? [additionnalParam] : []), 1, 50)
          .then(function (data) {
            setOptions(data.data);
          })
          .catch(error => console.log('network error', error));
      }
    }
  }, [accountId, local, additionnalParam]);

  const handleResult = (item: Option) => {
    setItem(item);
    setSelected(item);
    setSearchText(item.name);
    if (autoClear) {
      setSearchText('');
      setItem(undefined);
      setOptions(undefined);
    }
  };

  React.useEffect(() => {
    setItem(selected);
    if (selected === undefined) setSearchText('');
  }, [selected]);

  const results = local ? filteredResults : options;

  const resetQuery = () => {
    setSearchText('');
    setItem(undefined);
    setSelected(undefined);
  };

  return (
    <div>
      <Combobox
        as="div"
        className="relative mx-auto rounded-lg max-w-x1 bg-white shadow-2x1 ring-1 ring-black/5 "
        value={item}
        onChange={handleResult}>
        {({ open }) => (
          <>
            <div className="relative">
              <Combobox.Input
                className={`w-full px-4 py-3 focus:outline-none focus:ring-0 focus:text-primaryColor focus:border-primaryColor border border-strokeColor placeholder-${
                  isMultiItems ? 'grey-400' : 'bodyTextColor'
                } 
                ${blackPlaceholder ? 'custom-combo-box' : ''}
                text-bodyTextColor ${open && options?.length ? 'rounded-t-lg' : 'rounded-lg'}`}
                placeholder={placeholder || t('placeHolders.query')}
                onChange={local ? localSearch : handleAddress}
                displayValue={displayValue ? (content: Option) => content?.name : null}
                autoFocus={autoFocus}
                autoComplete="off"
              />
              {!open && !!options?.length && (
                <div className="absolute top-0 w-full">
                  <Combobox.Button className="w-full h-12">&nbsp;</Combobox.Button>
                </div>
              )}
              {clear && (
                <button
                  type="button"
                  onClick={resetQuery}
                  className="absolute right-0 p-2 top-1/2 -translate-y-1/2">
                  <FontAwesomeIcon icon={faTimes} size="sm" />
                </button>
              )}
            </div>
            <Combobox.Options className="max-h-96 w-full overflow-y-auto absolute bg-white z-[1001] divide-y divide-gray-300 border border-gray-300 border-t-0 rounded-b-lg">
              {isMulti
                ? results?.map((category: { name: string; options: Array<Option> }) => (
                    <React.Fragment key={category.name}>
                      <Combobox.Option value={category} disabled>
                        <div className="space-x-1 px-4 py-2 bg-white">
                          <span className="font-bold text-xl text-gray-900">{category.name}</span>
                        </div>
                      </Combobox.Option>
                      {category.options.map((option: Option) => (
                        <ResultOption
                          key={option.id || option.name}
                          option={option}
                          query={searchText}
                          groupBy={groupBy}
                        />
                      ))}
                      {!category.options.length && searchText.length > 0 && (
                        <>
                          <EmptyResultOption option={emptyOption} />
                          {isAddOnSearchBar && (
                            <button
                              type="button"
                              className={`w-full flex px-5 py-3.5 text-lg border mt-4 text-primaryColor bg-white border-primaryColor`}
                              onClick={() => pushValues('none')}>
                              <span className="mr-auto text-left flex flex-row">
                                <AddIcon className={'stroke-orange-500 mt-0.5 mr-3'} />{' '}
                                {t('person.buttons.create')}
                              </span>
                            </button>
                          )}
                        </>
                      )}
                    </React.Fragment>
                  ))
                : results?.map((option: Option) => (
                    <ResultOption
                      key={option.id || option.name}
                      option={option}
                      query={searchText}
                      groupBy={groupBy}
                    />
                  ))}
              {!results?.length && searchText.length > 0 && (
                <EmptyResultOption option={emptyOption} />
              )}
            </Combobox.Options>
          </>
        )}
      </Combobox>
    </div>
  );
};

export default Autocomplete;
