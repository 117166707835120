import React from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Combobox, Dialog } from '@headlessui/react';

import { debounce } from '../../utils/utils';
import { AccountContext } from '../../contexts/accountContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faBuilding,
  faTimes,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import { Option } from '../../types/input/select';
import Highlighted from '../Highlighted/Highlighted';
import { SubjectEnum, SubjectType } from '../../types/subject/subject';
import FullScreenSkeleton from '../Skeletons/FullScreenSkeleton';

import { SubjectContext } from '@/contexts/subjectContext';
import { LogisticModeType, ModeType, SubjectActionTypes } from '@/types';

import { ReactComponent as SearchIcon } from '@/assets/icons/search-normal.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';
import { ReactComponent as QRCodeIcon } from '@/assets/icons/scan.svg';
import { useModeConfig } from '@/contexts/configContext';

type FetchCallbackType = (account_id: number, query: string) => Promise<{ data: any }>;

type MultipleFetchCallbackType = Array<{ name: string; callback: FetchCallbackType; type?: LogisticModeType | SubjectEnum; prepared?: boolean }>;

const FullScreenSearch = ({
  fetchCallback,
  callback,
  placeholder,
  inputClassName,
  theme,
}: {
  fetchCallback: FetchCallbackType | MultipleFetchCallbackType;
  callback?: (...params: Array<any>) => void;
  placeholder?: string;
  inputClassName?: string;
  theme?: 'fullwidth' | 'icon' | undefined;
}) => {
  const { t } = useTranslation('dashboard');
  const mode = useModeConfig();
  const navigate = useNavigate();
  const accountContext = React.useContext(AccountContext);
  const subjectContext = React.useContext(SubjectContext);
  const {
    selected: { id: accountId },
  } = accountContext.state;
  const {
    state: { searchHistory },
    dispatch,
  } = subjectContext;

  const emptyOption = {
    id: 0,
    name: t('placeHolders.noResults'),
  };

  const isMulti = Array.isArray(fetchCallback);

  const [searchText, setSearchText] = React.useState<string>('');
  const [options, setOptions] = React.useState<any>();
  const [item, setItem] = React.useState<Option>();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const fetchQuery = async (query: string) => {
    setIsLoading(true);
    if (isMulti) {
      Promise.all(fetchCallback.map(fc => fc.callback(accountId, query)))
        .then(function (data) {
          const result = data.map((val, i) => ({
            name: fetchCallback[i].name,
            type: fetchCallback[i].type,
            prepared: fetchCallback[i].prepared,
            options: val,
          }));
          setOptions(result);
        })
        .catch(error => console.log('network error', error))
        .finally(() => setIsLoading(false));
    } else {
      fetchCallback(accountId, query)
        .then(function (data) {
          setOptions(data);
        })
        .catch(error => console.log('network error', error))
        .finally(() => setIsLoading(false));
    }
  };

  const optimizedFn = React.useCallback(debounce(fetchQuery), [accountId]);

  const handleSubject = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchText(query);
    query.length >= 3 && optimizedFn(query);
  };

  const handleResult = (value: Option) => {
    if (mode === ModeType.collaboration && searchHistory.findIndex((subject: Option) => subject.id === value.id) === -1) {
      dispatch({ type: SubjectActionTypes.SetSearchHistory, payload: value as SubjectType });
    }
    callback && callback(value);
    setIsOpen(false);
    resetQuery();
  };

  React.useEffect(() => {
    if (isOpen) setOptions(undefined);
  }, [isOpen]);

  const resetQuery = () => {
    setSearchText('');
    setItem(undefined);
  };

  return (
    <div className="flex">
      {!theme || theme === 'icon' ? (
        <button
          onClick={e => {
            e.preventDefault();
            setIsOpen(true);
          }}>
          <SearchIcon className="stroke-primaryColor" />
        </button>
      ) : (
        <div className="flex gap-3.5 w-full relative">
          <div className="absolute left-0 p-2 top-1/2 -translate-y-1/2">
            <SearchIcon className="stroke-primaryColor" />
          </div>
          <input
            className={`${
              inputClassName ||
              'w-full px-10 py-2.5 focus:outline-none focus:ring-0 focus:text-primaryColor focus:border-primaryColor border border-strokeColor rounded-lg placeholder-gray-400 text-bodyTextColor'
            }${item?.name ? '' : ' text-gray-400'}`}
            placeholder={'' || placeholder || t('placeHolders.query')}
            defaultValue={item?.name}
            onClick={e => {
              e.preventDefault();
              setIsOpen(true);
            }}
          />
          <Link to="/dashboard/scan" className="absolute right-0 p-2 top-1/2 -translate-y-1/2">
            <QRCodeIcon className="stroke-primaryColor" />
          </Link>
        </div>
      )}
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-[52]">
        <Dialog.Panel className="fixed inset-0 bg-white">
          <div className="flex justify-between">
            <div className="text-xl font-bold p-2 pl-3.5">{t('placeHolders.search')}</div>
            <button type="button" onClick={() => setIsOpen(false)} className="p-2">
              <CloseIcon className="stroke-primaryColor" />
            </button>
          </div>
          <Combobox
            as="div"
            className="relative mx-auto w-full bg-white flex flex-col"
            value={item}
            onChange={handleResult}>
            {({ open }) => (
              <>
                <div className="relative mx-3.5">
                  <Combobox.Input
                    className="w-full px-3 py-2.5 focus:outline-none focus:ring-0 focus:text-primaryColor focus:border-primaryColor border border-strokeColor rounded-lg placeholder-gray-400 text-bodyTextColor"
                    placeholder={placeholder || t('placeHolders.query')}
                    onChange={handleSubject}
                    displayValue={(content: Option) => mode === ModeType.collaboration ? content?.name : content?.ref}
                    autoFocus
                  />
                  <button
                    type="button"
                    onClick={resetQuery}
                    className="absolute right-0 p-2 top-1/2 -translate-y-1/2">
                    <CloseIcon className="stroke-primaryColor" />
                  </button>
                </div>
                <Combobox.Options
                  static
                  className="h-[calc(100vh-84px)] text-sm overflow-y-auto bg-white z-[1001] border border-gray-300 border-t-0 rounded-b-lg">
                  {mode === ModeType.collaboration && searchText.length < 3 && 
                    searchHistory
                      .filter(history => history?.account?.id === accountId)
                      ?.map((history: SubjectType) => {
                        return (
                          <Combobox.Option key={history.id} value={history}>
                            <div className="space-x-1 px-4 py-2 bg-white flex justify-between items-center">
                              <div>
                                <FontAwesomeIcon
                                  icon={history.dob !== undefined ? faUser : faBuilding}
                                  size="sm"
                                  color="text-gray-400"
                                />
                                <span className="ml-2 font-medium text-gray-900">
                                  <Highlighted text={history.name} highlight={searchText} />
                                </span>
                              </div>
                              <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                size="sm"
                                color="text-gray-500"
                              />
                            </div>
                          </Combobox.Option>
                        );
                      })}
                  {isMulti &&
                    !isLoading &&
                    options?.map((category: { name: string; options: Array<Option>; type: LogisticModeType; prepared: boolean; }) => (
                      <React.Fragment key={category.name}>
                        <Combobox.Option value={category} disabled>
                          <div className="space-x-1 px-4 py-2 bg-white border-b border-gray-300">
                            <span className="font-bold text-base text-gray-900">
                              {category.name}
                            </span>
                          </div>
                        </Combobox.Option>
                        {category.options?.map((option: Option) => (
                          <Combobox.Option key={option.id} value={option}>
                            {({ active }) => (
                              <div
                                className={`space-x-1 px-4 py-2 ${
                                  active ? 'bg-primaryColor' : 'bg-white'
                                }`}
                                onClick={mode === ModeType.collaboration ? undefined : () => navigate(`/dashboard/orders/${option.id}`, {
                                  state: { direction: category.type, prepared: category.prepared },
                                })}
                              >
                                {mode === ModeType.collaboration ? (
                                  <span
                                    className={`font-medium ${
                                      active ? 'text-white' : 'text-gray-900'
                                    }`}>
                                    <Highlighted text={option.name} highlight={searchText} />
                                  </span>
                                ): (
                                  <>          
                                    <Highlighted text={option.ref} highlight={searchText} />
                                    {option.source_ref && <span>{`(${option.source_ref})`}</span>}
                                    <span>{` - ${t('parcels.properties.origin')}: ${option?.pickup?.subject?.name} - ${t('parcels.properties.recipient')}: ${option?.delivery?.subject?.name}`}</span>
                                  </>
                                )}
                              </div>
                            )}
                          </Combobox.Option>
                        ))}
                        {!category.options.length && (
                          <Combobox.Option value={emptyOption} disabled>
                            <div className="space-x-1 px-4 py-2 bg-white">
                              <span className="font-medium text-gray-900">
                                <Highlighted text={emptyOption.name} highlight={searchText} />
                              </span>
                            </div>
                          </Combobox.Option>
                        )}
                      </React.Fragment>
                    ))}
                  {!isMulti &&
                    !isLoading &&
                    options?.map((option: Option) => (
                      <Combobox.Option key={option.id} value={option}>
                        {({ active }) => (
                          <div
                            className={`space-x-1 px-4 py-2 ${
                              active ? 'bg-primaryColor' : 'bg-white'
                            }`}>
                            <span
                              className={`font-medium ${active ? 'text-white' : 'text-gray-900'}`}>
                              {mode === ModeType.collaboration ? option.name : option.ref }
                            </span>
                          </div>
                        )}
                      </Combobox.Option>
                    ))}
                  {isLoading && <FullScreenSkeleton />}
                </Combobox.Options>
              </>
            )}
          </Combobox>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
};

export default FullScreenSearch;
