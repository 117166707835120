import React from 'react';

import {
  ParcelContextState as ParcelState,
  ParcelPayloadAction,
  ParcelActionTypes,
  ParcelType,
  NewParcelType,
} from '../types';

import { getParcelData, storeParcelData } from '../utils/data';

const initialState: ParcelState = {
  newParcel: undefined as unknown as NewParcelType,
  parcels: [] as ParcelType[],
  activeParcel: null,
};

const ParcelContext = React.createContext<{
  state: ParcelState;
  dispatch: React.Dispatch<ParcelPayloadAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

const reducer = (state: ParcelState, action: ParcelPayloadAction) => {
  switch (action.type) {
    case ParcelActionTypes.AddParcels: {
      return {
        ...state,
        parcels: [
          ...state.parcels,
          ...action.payload.filter(p => !state.parcels.find(parcel => parcel.id === p.id)),
        ],
      };
    }
    case ParcelActionTypes.SetParcel: {
      return {
        ...state,
        activeParcel: action.payload,
      };
    }
    case ParcelActionTypes.ResetNewParcel: {
      return {
        ...state,
        newParcel: {} as NewParcelType,
      };
    }
    case ParcelActionTypes.UpdateNewParcel: {
      return {
        ...state,
        newParcel: {
          ...state.newParcel,
          ...action.payload,
        },
      };
    }
    case ParcelActionTypes.Hydrate:
      return action.payload;
    case ParcelActionTypes.Clear:
      return initialState;
    default: {
      return { ...state };
    }
  }
};

const ParcelProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    async function rehydrate() {
      const storedState = await getParcelData();
      if (storedState) {
        dispatch({ type: ParcelActionTypes.Hydrate, payload: storedState });
      }
    }

    rehydrate();
  }, []);

  React.useEffect(() => {
    storeParcelData(state);
  }, [state]);

  return <ParcelContext.Provider value={{ state, dispatch }}>{children}</ParcelContext.Provider>;
};

export { ParcelContext, ParcelProvider };
