import { ModeType, TransportModeType } from '../config/config';
import { ConfigState } from '../state/config';

export enum ConfigActionTypes {
  Hydrate = 'HYDRATE',
  Clear = 'CLEAR',
  SetMode = 'SET_MODE',
  SetTransportMode = 'SET_TRANSPORT_MODE',
}

export type ConfigPayloadAction =
  | {
      type: ConfigActionTypes.Hydrate;
      payload: ConfigState;
    }
  | {
      type: ConfigActionTypes.Clear;
    }
  | {
      type: ConfigActionTypes.SetMode;
      payload: ModeType;
    }
  | {
      type: ConfigActionTypes.SetTransportMode;
      payload: TransportModeType;
    };
