import React from 'react';

import { AccountContext } from '../../contexts/accountContext';
import { useModeConfig, useThemeConfig } from '../../contexts/configContext';
import { ModeType, ThemeType } from '../../types/config/config';

import { classNames } from '../../utils/utils';
import SwitchLogisticMode from '../Dashboard/SwitchLogisticMode';
import { Link } from 'react-router-dom';

import ToucanWhiteIcon from '../../assets/images/toucan-icon-blanc.png';
import ColinorWhiteIcon from '../../assets/images/colinor-icon-blanc.svg';

const CustomerHeader = ({
  dashboard,
  title,
  canHaveTitle,
  canHaveLogo,
}: {
  dashboard?: boolean;
  title?: string;
  canHaveTitle?: boolean;
  canHaveLogo?: boolean;
}) => {
  const accountContext = React.useContext(AccountContext);
  const mode = useModeConfig();
  const theme = useThemeConfig();
  const logoSrc = theme === ThemeType.toucan ? ToucanWhiteIcon : ColinorWhiteIcon;

  return (
    <div className="bg-primaryColor">
      <div
        className={`p-5 flex flex-col items-center ${
          dashboard ? 'pt-[10px] mb-[40px]' : 'pt-[50px]'
        }`}>
        {canHaveLogo && (
          <Link to="/" title="accueil" className="mb-5">
            <img src={logoSrc} alt="logo" className="h-[35px]" />
          </Link>
        )}
        <div className="flex flex-col items-center align-middle">
          {dashboard ? (
            <div
              className={classNames(
                'bg-secondaryColor w-full flex flex-col gap-1.5 items-center justify-center text-white translate-y-16 mx-10',
                mode === ModeType.logistic ? 'pt-3.5' : 'py-3.5',
              )}>
              <p className="font-semibold truncate max-w-[90%] text-center">
                {accountContext.state?.selected?.name}
              </p>
              {mode === ModeType.logistic && <SwitchLogisticMode />}
            </div>
          ) : (
            <React.Fragment>
              {canHaveTitle && (
                <div className="flex flex-col items-center mt-5">
                  <h1 className="text-4xl text-center text-white mb-5">{title}</h1>
                </div>
              )}
              <div className="flex flex-col items-center">
                <div className="rounded-full bg-white w-[40px] h-[2px]"></div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerHeader;
