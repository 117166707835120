import React from 'react';

import { AccountState, AccountPayloadAction, AccountActionTypes, AccountType, ModeType } from '../types';

import { getAccountData, storeAccountData } from '../utils/data';

const initialState: AccountState = {
  accounts: [] as AccountType[],
  selected: {} as AccountType,
  mode: '' as ModeType,
};

const accountColors = [
  'indigo',
  'pink',
  'slate',
  'blue',
  'emerald',
  'yellow',
  'red',
  'green',
  'purple',
  'orange',
];

const AccountContext = React.createContext<{
  state: AccountState;
  dispatch: React.Dispatch<AccountPayloadAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

const reducer = (state: AccountState, action: AccountPayloadAction) => {
  switch (action.type) {
    case AccountActionTypes.SetAccount: {
      return {
        ...state,
        selected: action.payload,
        mode: action.payload.mode
      };
    }
    case AccountActionTypes.SetAccounts: {
      return {
        ...state,
        accounts: action.payload.map((account: AccountType, index: number) => ({
          ...account,
          color: accountColors[index % 10],
        })),
      };
    }
    case AccountActionTypes.SetTransportView: {
      return {
        ...state,
        selected: {
          ...state.selected,
          transportView: action.payload,
        },
      };
    }
    case AccountActionTypes.SetLogisticView: {
      return {
        ...state,
        selected: {
          ...state.selected,
          logisticView: action.payload,
        },
      };
    }
    case AccountActionTypes.Hydrate:
      return action.payload;
    case AccountActionTypes.Clear:
      return initialState;
    default: {
      return { ...state };
    }
  }
};

const AccountProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    async function rehydrate() {
      const storedState = await getAccountData();
      if (storedState) {
        dispatch({ type: AccountActionTypes.Hydrate, payload: storedState });
      }
    }

    rehydrate();
  }, []);

  React.useEffect(() => {
    storeAccountData(state);
  }, [state]);

  return <AccountContext.Provider value={{ state, dispatch }}>{children}</AccountContext.Provider>;
};

export { AccountContext, AccountProvider };
