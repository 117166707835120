export enum ThemeType {
  toucan = 'toucan',
  colinor = 'colinor',
}

export enum ModeType {
  collaboration = 'collaboration',
  transport = 'transport',
  logistic = 'logistic',
}

export enum TransportModeType {
  transport = 'transport',
  manager = 'manager',
  parcel = 'parcel',
}

export enum LogisticModeType {
  incoming = 'incoming',
  preparing = 'preparing',
  outgoing = 'outgoing',
}

export enum NodeEnvsType {
  development = 'development',
  staging = 'staging',
  production = 'production',
}
