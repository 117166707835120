// Utils
import React, { useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { classNames } from '@/utils/utils';
import { components, GroupBase, OptionsOrGroups } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import '../style/index.scss';

// Type
import { FormFieldsType } from '@/types';

// Icons & Images
import { ReactComponent as ChevronDownIcon } from '@/assets/icons/arrow-down.svg';
import IconError from '@/assets/icons/error.svg';

// Components
import CustomOption from './CustomOption';

const Select = ({
  nextStepAttempted = true,
  isOverflow,
  values,
  ...props
}: FormFieldsType & {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  loadOptions: (inputValue: string, callback?: any) => Promise<any>;
  defaultOptions?: boolean | OptionsOrGroups<any, GroupBase<any>>;
  isClearable?: boolean;
  hideDropdownIndicator?: boolean;
  noOptionsMessage?: () => string;
  hideNoOptionsMessage?: boolean;
  minLength?: number;
  bulletList?: boolean;
  deepDataObject?: boolean;
  getOptionValue?: (option: any) => string;
  getOptionLabel?: (option: any) => string;
  redirect?: string;
  redirectOptions?: any;
  saveChanges?: () => void;
  formatGroupLabel?: (group: GroupBase<any>) => JSX.Element;
  cacheOptions?: boolean;
  afterSelect?: (option: any) => void;
  readonly?: boolean;
  isMulti?: boolean;
  nextStepAttempted?: boolean;
  values?: any;
  isOverflow?: boolean;
}) => {
  const [field, meta] = useField(props);
  const [isNoResult, setIsNoResult] = useState<boolean>();
  const { t } = useTranslation('dashboard');

  const customNoOptionsMessage = ({ inputValue }: { inputValue: string }) => {
    if (typeof props.noOptionsMessage === 'function') {
      return props.noOptionsMessage();
    }

    return !props.minLength
      ? props.noOptionsMessage
      : inputValue.length >= props.minLength
      ? props.noOptionsMessage
      : t('placeHolders.searchLength', { length: props.minLength });
  };

  const loadOptions = (inputValue: string, callback: any) =>
    inputValue.length >= (props.minLength || 3) || !!props.defaultOptions
      ? props.loadOptions(inputValue, callback)
      : callback([]);

  const debouncedCallback = React.useCallback(
    debounce((inputText, callback) => {
      loadOptions(inputText, callback)?.then((options: any) => callback(options));
    }, 250),
    [],
  );

  const asyncParent = useRef<HTMLDivElement | null>(null);

  const keyDownHandler = e => {
    if (e.key === 'Enter') e.preventDefault();
    setTimeout(() => {
      if (asyncParent.current.querySelector('.customOption')) {
        setIsNoResult(false);
      } else {
        setIsNoResult(true);
      }
    }, 1000);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (asyncParent.current && !asyncParent.current.contains(event.target)) {
        setIsNoResult(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [asyncParent]);

  return (
    <div
      ref={asyncParent}
      className={classNames(
        'flex flex-col items-start text-sm',
        props.containerClasses ? props.containerClasses : '',
        isOverflow ? 'async-options' : '',
      )}>
      <AsyncSelect
        onKeyDown={keyDownHandler}
        classNamePrefix="select-content async-select"
        {...field}
        {...props}
        defaultInputValue={values}
        cacheOptions={props.cacheOptions ? props.cacheOptions : true}
        loadOptions={debouncedCallback}
        className="w-full select-component placeholder-gray-400"
        value={props.deepDataObject ? field.value : field.value?.value}
        onChange={option => {
          props.setFieldValue(field.name, props.deepDataObject ? option : option?.value);
          props.afterSelect && props.afterSelect(option);
        }}
        noOptionsMessage={props.minLength ? customNoOptionsMessage : props.noOptionsMessage}
        components={{
          Option: optionProps => <CustomOption optionProps={optionProps} otherProps={props} />,
          ...(props.hideDropdownIndicator ? { DropdownIndicator: () => null } : {}),
          ...(props.hideNoOptionsMessage ? { NoOptionsMessage: () => null } : {}),
          IndicatorSeparator: () => null,
          DownChevron: () => <ChevronDownIcon />,
          Menu: props =>
            props.options && props.options?.length > 0 && <components.Menu {...props} />,
        }}
        styles={{
          menu: provided => ({ ...provided, zIndex: 9999 }),
          option: baseStyles => ({
            ...baseStyles,
            borderBottomWidth: 1,
            padding: 12,
          }),
          multiValue: (styles, { data }) => {
            return {
              ...styles,
              padding: '0px 0px 0px 8px',
              borderRadius: 19,
            };
          },

          multiValueLabel: (styles, { data }) => ({
            ...styles,
            ...(data.color && { color: data.color }),
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            ...(data.color && { color: data.color }),
            padding: 6,
            ':hover': {
              ...(data.color && { backgroundColor: data.color, color: '#fff' }),
              borderRadius: 19,
            },
          }),
        }}
      />
      {isNoResult && <div className="mt-1">{t('placeHolders.noResults')}</div>}
      {meta.error ? (
        typeof meta.error === 'string' ? (
          <>
            {nextStepAttempted && (
              <div className="text-error ml-1 flex gap-1 items-center mt-1">
                <img src={IconError} />
                {meta.error}
              </div>
            )}
          </>
        ) : (
          Object.values(meta.error).map((error: any, i) => (
            <div key={i} className="text-error ml-1 flex gap-1 items-center mt-1">
              <img src={IconError} />
              {error}
            </div>
          ))
        )
      ) : null}
    </div>
  );
};
export default Select;
