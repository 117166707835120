import { AxiosResponse } from 'axios';
import api from './api';
import { parse_link_header } from '../hashUtils';
import { getParamString } from '../utils';
import { objectToFormdata } from '../..//utils/form';

export const fetchOrganizationsByName = async (account_id: number, query: string) => {
  const rawResponse = await api.get(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/organizations.json ${
      query && `?q[name_or_import_id_cont]=${query}`
    }`,
  );
  return rawResponse.data;
};

export const fetchOrganizations = async ({
  account_id,
  page = 1,
  limit = 5,
  query,
  category,
}: {
  account_id: number;
  page?: number;
  limit?: number;
  query?: string;
  category?: string;
}) => {
  const params = {
    ...(query ? { 'q[name_or_import_id_cont]': query } : {}),
    ...(category ? { 'q[category_id_eq]': category } : {}),
  };
  const paramString = getParamString(params);

  const rawResponse = await api
    .get(
      `${
        import.meta.env.VITE_API
      }/${account_id}/api/v1/organizations.json?page=${page}&per_page=${limit}${
        paramString.length ? '&' : ''
      }${paramString}`,
    )
    .then(response => {
      const properties = {
        links: parse_link_header(response.headers['link']),
        total: parseInt(response.headers['x-total'] || '0'),
        page: parseInt(response.headers['x-page'] || '1') || page,
        per_page: parseInt(response.headers['x-per-page'] || '0'),
      };
      return { properties: properties, data: response.data };
    });
  return rawResponse;
};

export const fetchCachedOrganizations = async (account_id: number, query: string) => {
  const rawResponse = caches
    .match(
      `${
        import.meta.env.VITE_API
      }/${account_id}/api/v1/organizations.json?q[name_or_import_id_cont]=${query}`,
    )
    .then(response => {
      if (!response) throw Error('No data');
      return response.json().then(data => data);
    });
  return rawResponse;
};

export const fetchOrganization = async (account_id: number, id: string) => {
  if (!id) throw Error('No id');
  const rawResponse = await api.get(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/organizations/${id}.json`,
  );
  return rawResponse.data;
};

export const createOrganization = async (
  account_id: number,
  body: object,
): Promise<AxiosResponse<any, any>> => {
  const newBody = objectToFormdata(body);
  if (!account_id) throw Error('No account id');
  const rawResponse = await api.post(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/organizations.json`,
    newBody,
  );
  return rawResponse;
};

export const editOrganization = async (
  account_id: number,
  organizationId: number,
  body: object,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  const rawResponse = await api.put(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/organizations/${organizationId}.json`,
    body,
  );
  return rawResponse;
};

export const deleteOrganization = async (
  account_id: number,
  organizationId: number,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  if (!organizationId) throw Error('No organization id');
  const rawResponse = await api.delete(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/organizations/${organizationId}.json`,
  );
  return rawResponse;
};

export const fetchOrganizationCategories = async (account_id: number) => {
  if (!account_id) throw Error('No account id');
  const rawResponse = await api.get(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/organization_categories.json`,
  );
  return rawResponse;
};
