// Utils
import { useTranslation } from 'react-i18next';
import Property from '../Property/Property';
import Select from 'react-select';
import React from 'react';
import DatePicker from 'react-datepicker';
import { TIME_INTERVAL } from '@/constants/time';
import i18n from '@/localization/index';
import en from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import { useField, useFormikContext } from 'formik';

// Types
import { EventRecurringPlanType, SelectOptionType } from '@/types';

// Types & Interfaces
type RecurringPlanSelectProps = {
  setFieldValue?: any;
  repetitionError?: boolean;
  setRepetitionError?: any;
  isFormik?: boolean;
  infos?: any;
  setInfos?: any;
  selectRecurringPlan?: any;
};

// Icons & Images
import IconError from '@/assets/icons/error.svg';

const locale = {
  fr: fr,
  en: en,
};

const RecurringPlanSelect = ({
  setFieldValue,
  repetitionError,
  setRepetitionError,
  isFormik,
  infos,
  setInfos,
  selectRecurringPlan,
}: RecurringPlanSelectProps) => {
  const { t } = useTranslation('dashboard');
  if (isFormik) {
    var { values } = useFormikContext();
  }

  const [isShowingRecurringEndAt, setIsShowingRecurringEndAt] = React.useState<boolean>(false);

  const recurringPlan: SelectOptionType[] = [
    {
      value: t('events.properties.recurring_plan.not_recurring.value'),
      label: t('events.properties.recurring_plan.not_recurring.label'),
    },
    {
      value: t('events.properties.recurring_plan.every_day.value'),
      label: t('events.properties.recurring_plan.every_day.label'),
    },
    {
      value: t('events.properties.recurring_plan.every_weekday.value'),
      label: t('events.properties.recurring_plan.every_weekday.label'),
    },
    {
      value: t('events.properties.recurring_plan.every_week.value'),
      label: t('events.properties.recurring_plan.every_week.label'),
    },
    {
      value: t('events.properties.recurring_plan.every_biweek.value'),
      label: t('events.properties.recurring_plan.every_biweek.label'),
    },
    {
      value: t('events.properties.recurring_plan.every_month.value'),
      label: t('events.properties.recurring_plan.every_month.label'),
    },
    {
      value: t('events.properties.recurring_plan.every_year.value'),
      label: t('events.properties.recurring_plan.every_year.label'),
    },
  ];

  const defaultRecurringPlan: SelectOptionType = {
    value: t('events.properties.recurring_plan.not_recurring.value'),
    label: t('events.properties.recurring_plan.not_recurring.label'),
  };

  const existingRecurringPlan: SelectOptionType = {
    value: t(`events.properties.recurring_plan.${infos?.recurring_plan}.value`),
    label: t(`events.properties.recurring_plan.${infos?.recurring_plan}.label`),
  };

  React.useEffect(() => {
    if (infos?.recurring_plan != 'not_recurring' && infos?.recurring_plan != null) {
      setIsShowingRecurringEndAt(true);
    }
  }, [infos]);

  return (
    <>
      <Property
        haveGap
        title={t('events.properties.repetition')}
        content={
          <Select
            options={recurringPlan}
            classNamePrefix="select-content"
            name="recurring_plan"
            className="w-full select-component placeholder-gray-400 "
            onChange={option => {
              if (option?.value === EventRecurringPlanType.not_recurring) {
                setIsShowingRecurringEndAt(false);
              } else {
                setIsShowingRecurringEndAt(true);
              }
              if (isFormik) {
                setFieldValue('recurring_plan', option);
              } else {
                selectRecurringPlan(option?.value);
              }
            }}
            placeholder={defaultRecurringPlan.label}
            defaultValue={infos?.recurring_plan ? existingRecurringPlan : recurringPlan[0]}
          />
        }
      />
      {isShowingRecurringEndAt && (
        <div className="flex flex-col">
          {values?.end_at && isFormik ? (
            <Property
              haveGap
              title={t('events.properties.endOfRepetition')}
              content={
                <div className="flex">
                  <DatePicker
                    selected={values?.recurring_end_at}
                    minDate={values?.end_at}
                    name="recurring_end_at"
                    onChange={(date: Date) => {
                      setFieldValue('recurring_end_at', date);
                      setRepetitionError(false);
                    }}
                    timeIntervals={TIME_INTERVAL}
                    dateFormat={t('date.dateOnlyFormat')}
                    locale={locale[i18n.language as 'fr' | 'en']}
                    className=" w-full px-4 py-3 focus:outline-none focus:ring-0 focus:text-primaryColor focus:border-primaryColor border border-strokeColor rounded-lg placeholder-gray-400 text-bodyTextColor"
                    onFocus={e => (e.target.readOnly = true)}
                  />
                </div>
              }
            />
          ) : (
            <Property
              haveGap
              title={t('events.properties.endOfRepetition')}
              content={
                <div className="flex">
                  <DatePicker
                    selected={infos?.recurring_end_at}
                    minDate={infos?.end_at}
                    name="recurring_end_at"
                    onChange={(date: Date) => setInfos({ ...infos, recurring_end_at: date })}
                    timeIntervals={TIME_INTERVAL}
                    dateFormat={t('date.dateOnlyFormat')}
                    locale={locale[i18n.language as 'fr' | 'en']}
                    className=" w-full px-4 py-3 focus:outline-none focus:ring-0 focus:text-primaryColor focus:border-primaryColor border border-strokeColor rounded-lg placeholder-gray-400 text-bodyTextColor"
                    onFocus={e => (e.target.readOnly = true)}
                  />
                </div>
              }
            />
          )}
          {repetitionError && (
            <div className="text-error ml-1 flex gap-1 items-center mt-1">
              <img src={IconError} />
              {t('toast.missingEventDate')}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RecurringPlanSelect;
