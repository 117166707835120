// Utils
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from '@/utils/utils';

// Icon & Images
import { faTruckFast, faAddressBook, faBagShopping } from '@fortawesome/pro-thin-svg-icons';

// Contexts
import { ConfigContext, useModeConfig } from '@/contexts/configContext';
import { UserContext } from '@/contexts/userContext';

// Types
import { AuthorizedModules, ConfigActionTypes, ModeType } from '@/types';

const icons = {
  transport: (current: boolean) => (
    <FontAwesomeIcon
      icon={faTruckFast}
      size="xl"
      style={{
        color: current ? '#EE651D' : '#101928',
        fontSize: '24px',
      }}
    />
  ),
  collaboration: (current: boolean) => (
    <FontAwesomeIcon
      icon={faAddressBook}
      size="xl"
      style={{
        color: current ? '#EE651D' : '#101928',
        fontSize: '24px',
      }}
    />
  ),
  logistic: (current: boolean) => (
    <FontAwesomeIcon
      icon={faBagShopping}
      size="xl"
      style={{
        color: current ? '#EE651D' : '#101928',
        fontSize: '24px',
      }}
    />
  ),
};

const BottomNavbar = () => {
  const { t } = useTranslation('dashboard');
  const userContext = React.useContext(UserContext);
  const { user } = userContext.state;
  const navigate = useNavigate();
  const { dispatch } = React.useContext(ConfigContext);
  const configMode = useModeConfig();

  const selectMode = (mode: ModeType) => {
    dispatch({
      type: ConfigActionTypes.SetMode,
      payload: mode,
    });
    navigate('/dashboard');
  };

  const modes = t(`dashboard.modes`, { returnObjects: true }) as { [name: string]: ModeType };

  const keyNavigation = {
    transport: AuthorizedModules.transports,
    collaboration: AuthorizedModules.crm,
    logistic: AuthorizedModules.procurement,
  };

  return (
    <nav className="fixed rounded-t-3xl shadow-[0_-10px_15px_-3px_rgba(0,0,0,0.1)] bottom-0 left-0 right-0 z-10 flex items-center  h-16 px-5 md:px-28 s:px-5 bg-white border-t border-gray-200 z-20">
      {Object.keys(modes)
        .filter(mode =>
          user.current_account?.authorized_modules.includes(keyNavigation[mode as ModeType]),
        )
        .map(mode => (
          <button
            key={mode}
            type="button"
            className="flex flex-col items-center justify-center mx-auto"
            onClick={() => selectMode(mode as ModeType)}>
            {icons[mode as ModeType](configMode === (mode as ModeType))}
            <span
              className={classNames(
                'text-xs',
                configMode === (mode as ModeType) ? 'text-primaryColor' : 'text-gray-900',
              )}>
              {modes[mode]}
            </span>
          </button>
        ))}
    </nav>
  );
};

export default BottomNavbar;
