// Utils
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';

type CheckboxDatabaseEmailProps = {
  databaseConsent?: boolean;
  emailCommunication?: boolean;
};

const CheckboxDatabaseEmail = ({ emailCommunication = true }: CheckboxDatabaseEmailProps) => {
  const { t } = useTranslation('dashboard');

  return (
    <div className="w-full flex flex-col gap-2">
      {emailCommunication && (
        <div>
          <div className=" flex flex-col gap-3">
            <label className="flex flex-row text-gray-600">
              <div className="h-5 w-5 mr-2">
                <Field type="checkbox" className="mr-2 h-5 w-5" name="accepts_marketing" />
              </div>
              <div> {t('subjects.properties.edit.checkbox.consentEmail')}</div>
            </label>
            <label className="flex flex-row text-gray-600">
              <div className="h-5 w-5 mr-2">
                <Field type="checkbox" className="mr-2 h-5 w-5" name="data_consent" />
              </div>
              <div>{t('subjects.addPersonForm.databaseConsent')}</div>
            </label>
          </div>
        </div>
      )}
    </div>
  );
};
export default CheckboxDatabaseEmail;
