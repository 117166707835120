import { Link } from 'react-router-dom';
import { components } from 'react-select';

import { classNames } from '@/utils/utils';

import { ReactComponent as AddCircleIcon } from '@/assets/icons/add-circle.svg';

type CustomOptionProps = {
  optionProps: any;
  otherProps: any;
};

const CustomOption = ({ optionProps, otherProps }: CustomOptionProps) => {
  const { data, innerRef, innerProps, isSelected } = optionProps;

  return (
    <div className="customOption">
      {data?.addNew ? (
        data.link ? (
          <Link to={data.link} onClick={otherProps.saveChanges} state={data.state}>
            <div
              ref={innerRef}
              {...innerProps}
              className="flex px-2 py-3 items-center gap-2 text-bodyTextColor">
              <AddCircleIcon className="stroke-bodyTextColor" /> {data.label}
            </div>
          </Link>
        ) : (
          <button
            type="button"
            onMouseDown={e => {
              e.stopPropagation();
              data.onclick();
            }}>
            <div
              ref={innerRef}
              {...innerProps}
              className="flex px-2 py-3 items-center gap-2 text-bodyTextColor">
              <AddCircleIcon className="stroke-bodyTextColor" /> {data.label}
            </div>
          </button>
        )
      ) : otherProps.bulletList ? (
        <div ref={innerRef} {...innerProps} className="flex px-2.5 py-3 items-center gap-2">
          <div
            className={classNames(
              'rounded-full w-5 h-5 border flex items-center justify-center',
              isSelected ? 'border-primaryColor' : 'border-strokeColor',
            )}>
            {isSelected && <div className="rounded-full w-3 h-3 bg-primaryColor"></div>}
          </div>
          {data.label}
        </div>
      ) : (
        <components.Option {...optionProps} />
      )}
    </div>
  );
};

export default CustomOption;
