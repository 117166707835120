import axios from 'axios';
import { setupInterceptorsTo } from './interceptors';

export const getToken = async (code: string) => {
  const OAUTH_ENDPOINT = import.meta.env.VITE_OAUTH_ENDPOINT;
  const rawResponse = await axios.post(`${OAUTH_ENDPOINT}/token`, {
    grant_type: 'authorization_code',
    code,
    redirect_uri: window.location.origin,
    client_id: import.meta.env.VITE_TOUCAN_CLIENT_ID,
    client_secret: import.meta.env.VITE_TOUCAN_CLIENT_SECRET,
  });

  const response = rawResponse.data;

  localStorage.setItem('@toucan_token', JSON.stringify(response));

  return response;
};

const api = setupInterceptorsTo(
  axios.create({
    baseURL: import.meta.env.VITE_API,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }),
);

export default api;
