import { useTranslation } from 'react-i18next';
import { useField, Field } from 'formik';
// Types
import { FormFieldsType } from '@/types';

const GenderSelect = ({ ...props }: FormFieldsType) => {
  const { t } = useTranslation('dashboard');
  const [field] = useField(props);

  return (
    <Field
      className="w-full focus:border-orange-400 select-component placeholder-gray-400 border border-gray-400 rounded-md h-12 pl-3"
      as="select"
      {...field}
      {...props}>
      <option value="unknown">{t('subjects.addPersonForm.gender.select')}</option>
      <option value="man">{t('subjects.addPersonForm.gender.man')}</option>
      <option value="woman">{t('subjects.addPersonForm.gender.woman')}</option>
      <option value="other">{t('subjects.addPersonForm.gender.other')}</option>
      <option value="confidential">{t('subjects.addPersonForm.gender.confidential')}</option>
    </Field>
  );
};
export default GenderSelect;
