// Utils
import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

// Styles
import '../../src/style/index.scss';
import 'react-datepicker/dist/react-datepicker.css';

// Contexts
import { AccountContext } from '../contexts/accountContext';
import { PersonInfoContext } from '@/contexts/personContext';

// Components
import AddPersonWizard from '../components/AddPersonWizard/AddPersonWizard';
import ProgressBarWizard from '../components/ProgressBarWizard/ProgressBarWizard';

// Types
import { AddressType } from '../types/localization/localization';

// API & Services
import { fetchPersonCategories } from '../utils/api/people';
import { personService } from '../services';

const AddPerson = () => {
  const accountContext = React.useContext(AccountContext);
  const navigate = useNavigate();
  const { hash } = useLocation();
  const location = useLocation();
  const [hashValue, setHashValue] = useState(parseInt(hash.slice(5)));
  const [photos, setPhotos] = React.useState();
  const { setPersonInfo } = useContext(PersonInfoContext);
  const [personId, setPersonId] = React.useState<any>([]);

  useEffect(() => {
    setHashValue(parseInt(hash.slice(5)));
  }, [location.hash]);
  const { t } = useTranslation('dashboard');
  const {
    selected: { id: accountId },
  } = accountContext.state;
  const { pathname } = useLocation();

  useEffect(() => {
    setHashValue(parseInt(hash.slice(5)));

    if (pathname === '/contacts/people/new') {
      navigate('/contacts/people/new/#step1');
      if (window.location) {
        window.location.reload();
      }
    }
  }, [pathname]);

  const initialValues = {
    first_name: '',
    last_name: '',
    gender: '',
    dob: undefined as unknown as Date,
    category_id: null,
    note: '',
    contact_informations: [
      {
        info: '',
        type: 'Email',
        label: '',
        main: true,
      },
    ],
    accepts_marketing: false,
    data_consent: false,
    addresses: [
      {
        address: undefined as unknown as AddressType,
        name: '',
        street2: '',
      },
    ],
    tag_list: [],
  };

  const validationSchema = yup.object().shape({
    first_name: yup.string().required(t('toast.missingFirstName')),
    last_name: yup.string().required(t('toast.missingName')),
    gender: yup.string(),
    dob: yup.date(),
    note: yup.string(),
    accepts_marketing: yup.boolean(),
    contact_informations: yup.array().of(
      yup.object().shape({
        info: yup.string().test({
          name: 'emailValidation',
          message: `${t('errors.email')}`,
          test: function (value) {
            if (this.parent.type === 'Email') {
              return yup.string().email().isValidSync(value);
            }
            return true;
          },
        }),
      }),
    ),
  });

  function onSubmit(fields: any, { setStatus, setSubmitting }: FormikHelpers<Values>) {
    setStatus();
    if (photos) {
      fields.picture = photos;
    }
    createPerson(accountId, fields, setSubmitting);
  }

  function onSuccess(data: any, setSubmitting: any, addresses: any) {
    setPersonId(data.id);
    data.addresses = addresses;
    setPersonInfo(data);
    setSubmitting(false);
    navigate('/contacts/people/new/#step7');
  }

  function createPerson(accountId: number, fields: any, setSubmitting) {
    let shallowFieldsCopy = { ...fields };
    if (shallowFieldsCopy.data_consent === true) {
      shallowFieldsCopy.data_consent = 'accepted';
    } else {
      shallowFieldsCopy.data_consent = 'rejected';
    }
    personService.create(accountId, shallowFieldsCopy, onSuccess, setSubmitting);
  }

  return (
    <>
      <ProgressBarWizard hashValue={hashValue} percentage={14.29} totalSteps={7} />
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        {({
          setFieldValue,
          values,
          errors,
          touched,
          isSubmitting,
          submitForm,
        }: FormikProps<typeof initialValues>) => {
          const fetchCategories = async () => {
            const response = await fetchPersonCategories(accountId).then(response => [
              ...response.data.map((category: any) => ({
                value: category.id,
                label: category.name,
              })),
            ]);
            return response;
          };

          return (
            <>
              <Form className="flex flex-col gap-5 h-[80vh] ">
                <AddPersonWizard
                  setFieldValue={setFieldValue}
                  fetchCategories={fetchCategories}
                  submitForm={submitForm}
                  photos={photos}
                  setPhotos={setPhotos}
                  errors={errors}
                  touched={touched}
                  isSubmitting={isSubmitting}
                  hashValue={hashValue}
                  values={values}
                  personId={personId}
                  accountId={accountId}
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default AddPerson;
