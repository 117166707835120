import { useThemeConfig } from '@/contexts/configContext';
import { ThemeType } from '@/types';
import { classNames } from '@/utils/utils';
import React from 'react';
import BottomNavbar from './BottomNavbar';

// import Footer from './.Footer'
import Header from './Header';

type LayoutProps = {
  children: React.ReactNode;
  noHeader?: boolean;
  noFooter?: boolean;
};

const Layout = ({ children, noHeader = false, noFooter = false }: LayoutProps) => {
  const theme = useThemeConfig();
  return (
    <>
      {!noHeader && <Header />}
      <main className={classNames('pb-[64px] relative', noFooter ? 'pb-0' : '') + ' '}>
        {children}
        <div className="h-16">&nbsp;</div>
      </main>
      {!noFooter && theme === ThemeType.toucan && <BottomNavbar />}
    </>
  );
};

export default Layout;
