import React from 'react';

import { useLocation } from 'react-router-dom';
import CustomerHeader from '../components/CustomerHeader/CustomerHeader';
import Layout from '../components/layout/Layout';
import LoginHookSkeleton from '../components/Skeletons/LoginHookSkeleton';
import { UserContext } from '../contexts/userContext';
import { getAuthorizeHref } from '../oauthConfig';

import useCookie from './useCookie';

const LoginHook = () => {
  const [origin, updateOrigin] = useCookie('origin');
  const userContext = React.useContext(UserContext);
  const { loggedIn } = userContext.state;

  const { pathname } = useLocation();

  React.useEffect(() => {
    const signIfNotLoggedIn = async () => {
      const jsonUser = await localStorage.getItem('@user');
      const user = JSON.parse(jsonUser || '{}');
      if (!user.loggedIn && !loggedIn) {
        window.open(getAuthorizeHref(), '_self');
      }
    };
    if (!origin) {
      updateOrigin(pathname, 1);
    }
    signIfNotLoggedIn();
  }, []);
  return (
    <div>
      <Layout>
        <CustomerHeader canHaveTitle={false} />
        <div className="pt-10 p-5 flex flex-col gap-6">
          <LoginHookSkeleton />
        </div>
      </Layout>
    </div>
  );
};

export { LoginHook };
