import { NodeEnvsType } from '@/types';
import Appsignal from '@appsignal/javascript';

const getAppSignalKey = () : string => {
  if(import.meta.env.VITE_NODE_ENVS !== NodeEnvsType.development) {
    return import.meta.env.VITE_NODE_ENVS === NodeEnvsType.production ? '9ed5284f-5547-4131-908c-9e04786122b6' : 'd261f89d-be1e-44f6-9dde-98f01c688163';
  }

  return '';
};

export default new Appsignal({
  key: getAppSignalKey(),
});
