import React from 'react';

import { AuthorizationState, UserPayloadAction, UserActionTypes, UserType } from '../types';

import { getUserData, storeUserData } from '../utils/data';

const initialState: AuthorizationState = {
  loggedIn: false,
  accessToken: '',
  tokenExpiryDate: '',
  user: {} as UserType,
};

const UserContext = React.createContext<{
  state: AuthorizationState;
  dispatch: React.Dispatch<UserPayloadAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

const reducer = (state: AuthorizationState, action: UserPayloadAction) => {
  switch (action.type) {
    case UserActionTypes.LoggedIn: {
      return {
        ...state,
        loggedIn: action.payload,
      };
    }
    case UserActionTypes.AccessToken: {
      return {
        ...state,
        accessToken: action.payload,
      };
    }
    case UserActionTypes.ExpiryDate: {
      const date = new Date();
      date.setSeconds(date.getSeconds() + action.payload);
      return {
        ...state,
        tokenExpiryDate: date.toISOString(),
      };
    }
    case UserActionTypes.SetUser: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case UserActionTypes.Hydrate:
      return action.payload;
    case UserActionTypes.Clear:
      return initialState;
    default: {
      return { ...state };
    }
  }
};

const UserProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    async function rehydrate() {
      const storedState = await getUserData();
      if (storedState) {
        dispatch({ type: UserActionTypes.Hydrate, payload: storedState });
      }
    }

    rehydrate();
  }, []);

  React.useEffect(() => {
    storeUserData(state);
  }, [state]);

  return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
