import React from 'react';
import { AccountContext } from '../contexts/accountContext';
import { UserContext } from '../contexts/userContext';
import {
  AccountActionTypes,
  ConfigActionTypes,
  UserActionTypes,
} from '../types';
import { LogisticModeType } from '../types/config/config';
import { updateUser, fetchUser, getCurrentAccount } from './api/user';
import { objectToFormdata } from './form';
import { ConfigContext } from '@/contexts/configContext';

export const refreshUser = () => {
  const userContext = React.useContext(UserContext);
  const accountContext = React.useContext(AccountContext);
  const configContext = React.useContext(ConfigContext);

  const refreshUser = async (account_id: number) => {
    const { currentAccount, default_module} = await getCurrentAccount(account_id);
    const updatedUser = await updateUser(
      userContext.state.user.id,
      objectToFormdata({
        current_account_id: account_id,
      }),
    );
    const user = await fetchUser();

    if (user && currentAccount) {
      userContext.dispatch({
        type: UserActionTypes.SetUser,
        payload: user,
      });
      accountContext.dispatch({
        type: AccountActionTypes.SetAccounts,
        payload: user.accounts
      });

      // Curent Account
      configContext.dispatch({
        type: ConfigActionTypes.SetMode,
        payload: default_module,
      });

      accountContext.dispatch({
        type: AccountActionTypes.SetAccount,
        payload: {
          ...currentAccount,
          mode:default_module,
          transportView: user.current_account.profile,
          logisticView: LogisticModeType.incoming,
          color: accountContext.state.accounts.find(a => a.id === user.current_account.id)
            ?.color,
        },
      });
    }
  };

  return refreshUser;
};
