// Utils
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import i18n from '@/localization/index';
import en from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import { TIME_INTERVAL } from '@/constants/time';
import { getInitialsFromName, gidToClassType, gidToId } from '@/utils/utils';

// Styles
import '../../../src/style/index.scss';
import 'react-datepicker/dist/react-datepicker.css';

// Components
import Button from '../Buttons/Button';
import Property from '../Property/Property';
import AddressForm from '../AddressForm/AddressForm';
import AsyncSelect from '../AsyncSelect';
import IconHolder from '../IconHolder/IconHolder';
import Autocomplete from '../Autocomplete/Autocomplete';
import TextArea from '../TextArea';
import RecurringPlanSelect from '../RecurringPlanSelect/RecurringPlanSelect';
import ToggleEventAttendance from '../ToggleEventAttendance/ToggleEventAttendance';
import TextInput from '../TextInput';

// Icons & Images
import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { faBagShopping } from '@fortawesome/pro-solid-svg-icons';
// Contexts
import { UserContext } from '@/contexts/userContext';
import { HeaderContext } from '@/contexts/headerContext';
import { useRefreshUser } from '@/hooks/useRefreshUser';

// Types
import { SubjectEnum, Option } from '@/types';

// API
import { fetchSurveys } from '@/utils/api/forms';
import { fetchOrderTemplates } from '@/utils/api/orderTemplates';
import { fetchSubject } from '@/utils/api/subjects';

// Hooks
import { useSlugAccount } from '@/hooks/useAccounts';
import { fetchOrganization } from '@/utils/api/organizations';
import { fetchHousehold } from '@/utils/api/households';

// Types & Interfaces
type CustomStepProps = {
  accountId?: number;
  errors?: any;
  fetchCategories?: any;
  goToStep?: any;
  hashValue?: any;
  isSubmitting?: any;
  nextStep?: any;
  previousStep?: any;
  setFieldValue?: any;
  touched?: any;
  values?: any;
  infos?: any;
  eventInformation?: any;
  personInformation?: any;
  setRepetition?: any;
  repetition?: boolean;
  selectedAccount?: object;
};

interface FormikTypes {
  order_template_id: number;
  person_ids: any;
  title: string | number | readonly string[] | undefined;
  add_new_address: any;
  subject: boolean | undefined;
  due_at: Date | null | undefined;
  start_at: Date | null | undefined;
  recurring_end_at: any;
  end_at: any;
  addresses: {
    info: string;
    type: string;
  }[];
  manual_address: boolean;
  address_from_id: number;
  value: any;
  contact_informations: any;
}

const locale = {
  fr: fr,
  en: en,
};

const AddEventWizard = ({
  values,
  hashValue,
  errors,
  touched,
  isSubmitting,
  setFieldValue,
  fetchCategories,
  accountId,
  infos,
  personInformation,
  eventInformation,
  setRepetition,
  repetition,
  selectedAccount,
}: CustomStepProps) => {
  const navigate = useNavigate();
  // Protection against invalid reloads
  // FIXME: use validation schema?
  useEffect(() => {
    if (values?.title === '' && hashValue >= 2) {
      navigate('/events/new/#step1');
    }
  }, []);

  return (
    <StepWizard isLazyMount={true} initialStep={hashValue} className="pb-6 p-6 h-full">
      <Step1
        hashValue={hashValue}
        errors={errors}
        touched={touched}
        accountId={accountId}
        infos={infos}
        setFieldValue={setFieldValue}
        personInformation={personInformation}
      />
      <Step2
        hashValue={hashValue}
        errors={errors}
        touched={touched}
        accountId={accountId}
        infos={infos}
        setFieldValue={setFieldValue}
        personInformation={personInformation}
        setRepetition={setRepetition}
        repetition={repetition}
      />
      <Step3
        hashValue={hashValue}
        errors={errors}
        isSubmitting={isSubmitting}
        accountId={accountId}
        setFieldValue={setFieldValue}
        personInformation={personInformation}
      />
      <Step4
        accountId={accountId}
        errors={errors}
        fetchCategories={fetchCategories}
        hashValue={hashValue}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
        touched={touched}
        personInformation={personInformation}
        selectedAccount={selectedAccount}
      />
      <Step5
        hashValue={hashValue}
        errors={errors}
        touched={touched}
        accountId={0}
        setFieldValue={setFieldValue}
      />
      <Step6 hashValue={hashValue} errors={errors} eventInformation={eventInformation} />
    </StepWizard>
  );
};

// Welcome slide & start wizard button
const Step1 = ({
  nextStep,
  hashValue,
  goToStep,
  accountId,
  errors,
  touched,
  setFieldValue,
}: CustomStepProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');
  const headerContext = React.useContext(HeaderContext);
  const { values } = useFormikContext<FormikTypes>();
  const { accountContacts } = useRefreshUser();
  const [nextStepAttempted, setNextStepAttempted] = useState(false);
  const {
    user: { current_account: user },
  } = React.useContext(UserContext).state;

  React.useEffect(() => {
    if (hashValue == 1) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: false,
          canEdit: false,
          canGoBack: true,
        },
      });
    }
  }, [hashValue]);

  function goToNextStep() {
    setNextStepAttempted(true);
    if (!errors?.title) {
      nextStep();
      navigate('/events/new/#step2');
    }
  }

  useEffect(() => {
    const setPersonIds = persons => setFieldValue('person_ids', persons);

    const handleCollaborations = (collaborations, user) => {
      const mainCollaboration =
        collaborations.find(collab => collab.main === true) || collaborations[0];
      const persons = [];
      if (user && user.person) {
        persons.push({ ...user.person, checked: true });
      }
      persons.push({ ...mainCollaboration, checked: true });
      setPersonIds(persons);
    };

    const fetchData = data => handleCollaborations(data?.collaborations || [], user);

    if (values?.subject?.gid?.includes('Organization'))
      fetchOrganization(accountId, gidToId(values.subject.gid)).then(fetchData);
    else if (values?.subject?.gid?.includes('Household'))
      fetchHousehold(accountId, gidToId(values.subject.gid)).then(fetchData);
    else {
      const persons = [{ ...values.subject, checked: true }];
      if (user && user.person) {
        persons.push({ ...user.person, checked: true });
      }
      setPersonIds(persons);
    }
  }, [accountId, hashValue, user, values.subject]);

  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);

  return (
    <div className="flex items-center justify-between flex-col pt-5 h-full pb-20">
      <div className="w-full gap-6 flex flex-col">
        <Property
          haveGap
          title={t('events.properties.eventSubject')}
          content={
            <Autocomplete
              fetchCallback={accountContacts}
              autoClear
              autoFocus={false}
              setSelected={subject => setFieldValue('subject', subject)}
              specifiedAccountId={accountId}
              placeholder={values?.subject?.name}
              isMultiItems={false}
              blackPlaceholder={true}
            />
          }
        />
        <Property
          haveGap
          title={t('events.properties.eventTitle')}
          content={
            <TextInput
              name="title"
              type="text"
              touched={touched?.title}
              nextStepAttempted={nextStepAttempted}
            />
          }
        />
        <Property
          haveGap
          hint={t('events.properties.descriptionHint')}
          title={t('events.properties.description')}
          content={
            <div className="relative">
              <TextArea type="text" name="description" />
            </div>
          }
        />
      </div>
      <div className="w-full translate-y-44 gap-3 pb-6 flex flex-col">
        <Button
          onClick={goToNextStep}
          text={t('common.buttons.next')}
          variant="primary"
          size="large"
        />
        <Button
          onClick={() => navigate('/')}
          type="button"
          text={t('common.buttons.cancel')}
          variant="secondaryOrange"
          size="large"
        />
      </div>
    </div>
  );
};

const Step2 = ({
  nextStep,
  hashValue,
  goToStep,
  accountId,
  errors,
  touched,
  setFieldValue,
  setRepetition,
  repetition,
}: CustomStepProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');
  const headerContext = React.useContext(HeaderContext);
  const { values } = useFormikContext<FormikTypes>();
  const [nextStepAttempted, setNextStepAttempted] = useState(false);
  const [repetitionError, setRepetitionError] = useState(false);
  const {
    user: { current_account: user },
  } = React.useContext(UserContext).state;

  React.useEffect(() => {
    if (hashValue == 2) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: false,
          canEdit: false,
          canGoBack: true,
        },
      });
    }
  }, [hashValue]);

  useEffect(() => {
    if (values.recurring_plan.value == 'not_recurring') {
      setRepetition(false);
    } else {
      setRepetition(true);
    }
  }, [values]);

  function goToNextStep() {
    setNextStepAttempted(true);
    if (!errors?.recurring_end_at) {
      if (repetition) {
        setRepetitionError(true);
        if (values?.recurring_end_at) {
          nextStep();
          navigate('/events/new/#step3');
        }
      } else {
        nextStep();
        navigate('/events/new/#step3');
      }
    }
  }

  useEffect(() => {
    goToStep(hashValue);

    if (hashValue == 2) {
      const uniqueIds: any[] = [];
      const filteredPersonIds = values?.person_ids?.filter(person => {
        if (!uniqueIds.includes(person.id)) {
          uniqueIds.push(person.id);
          return true;
        }
        return false;
      });
      setFieldValue('person_ids', filteredPersonIds);
    }
  }, [hashValue]);

  useEffect(() => {
    const filteredPersonIds = values?.person_ids.filter(person => person?.gid);
    setFieldValue('person_ids', filteredPersonIds || []);
  }, [hashValue]);

  return (
    <div className="flex items-center justify-between flex-col pt-5 h-full pb-20">
      <div className="w-full gap-6 flex flex-col">
        <Property
          haveGap
          title={t('events.properties.startAt')}
          content={
            <div className="flex">
              <DatePicker
                selected={values.start_at}
                name="start_at"
                onChange={(date: Date) => {
                  setFieldValue('start_at', date);
                  setFieldValue('end_at', new Date(new Date(date).setHours(date.getHours() + 1)));
                  setFieldValue('due_at', date);
                }}
                timeIntervals={TIME_INTERVAL}
                showTimeSelect
                dateFormat={t('date.dateAndTimeFormat')}
                locale={locale[i18n.language as 'fr' | 'en']}
                className="w-full px-4 py-3 focus:outline-none focus:ring-0 focus:text-primaryColor focus:border-primaryColor border border-strokeColor rounded-lg placeholder-gray-400 text-bodyTextColor"
                onFocus={e => (e.target.readOnly = true)}
              />
            </div>
          }
        />
        <Property
          haveGap
          title={t('events.properties.endAt')}
          content={
            <div className="flex">
              <DatePicker
                name="end_at"
                selected={values.end_at}
                onChange={(date: Date) => setFieldValue('end_at', date)}
                minDate={values.start_at}
                timeIntervals={TIME_INTERVAL}
                showTimeSelect
                dateFormat={t('date.dateAndTimeFormat')}
                locale={locale[i18n.language as 'fr' | 'en']}
                className="w-full px-4 py-3 focus:outline-none focus:ring-0 focus:text-primaryColor focus:border-primaryColor border border-strokeColor rounded-lg placeholder-gray-400 text-bodyTextColor"
                onFocus={e => (e.target.readOnly = true)}
              />
            </div>
          }
        />
        <Property
          haveGap
          title={t('common.due')}
          content={
            <div className="flex">
              <DatePicker
                name="due_at"
                isClearable
                placeholderText={t('common.noDue')}
                selected={values.due_at}
                onChange={(date: Date) => setFieldValue('due_at', date)}
                minDate={values.start_at}
                timeIntervals={TIME_INTERVAL}
                showTimeSelect
                dateFormat={t('date.dateAndTimeFormat')}
                locale={locale[i18n.language as 'fr' | 'en']}
                className="w-full px-4 py-3 focus:outline-none focus:ring-0 focus:text-primaryColor focus:border-primaryColor border border-strokeColor rounded-lg placeholder-gray-400 text-bodyTextColor"
                onFocus={e => (e.target.readOnly = true)}
              />
            </div>
          }
        />
        <RecurringPlanSelect
          setFieldValue={setFieldValue}
          repetitionError={repetitionError}
          setRepetitionError={setRepetitionError}
          isFormik={true}
        />
      </div>
      <div className="w-full translate-y-48 gap-3 pb-6 flex flex-col">
        <Button
          onClick={goToNextStep}
          text={t('common.buttons.next')}
          variant="primary"
          size="large"
        />
        <button
          type="submit"
          className="rounded-lg  border border-orange-500  flex justify-center items-center gap-1 h3 z-20 w-full h-[56px] bg-white text-orange-500">
          {t('subjects.organizationWizard.finish.button')}
        </button>
      </div>
    </div>
  );
};

const Step3 = ({
  hashValue,
  goToStep,
  setFieldValue,
  accountId,
  nextStep,
  personInformation,
}: CustomStepProps) => {
  const { t } = useTranslation('dashboard');
  const headerContext = React.useContext(HeaderContext);
  const { values } = useFormikContext<FormikTypes>();
  const { accountContacts } = useRefreshUser();
  const navigate = useNavigate();
  const {
    user: { current_account: user },
  } = React.useContext(UserContext).state;

  React.useEffect(() => {
    if (hashValue == 3) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: false,
          canEdit: false,
          canGoBack: true,
        },
      });
    }
  }, []);

  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);

  // Select All
  function handleSelectAll(e) {
    if (e.target.checked) {
      setFieldValue(
        'person_ids',
        values.person_ids.map(person => {
          return { ...person, checked: true };
        }),
      );
    } else {
      setFieldValue(
        'person_ids',
        values.person_ids.map(person => {
          return { ...person, checked: false };
        }),
      );
    }
  }

  function goToNextStep() {
    nextStep();
    navigate('/events/new/#step4');
  }

  return (
    <div className="flex flex-col items-center justify-between w-full h-full">
      <div className="w-full gap-6 flex flex-col">
        <Property
          haveGap
          content={
            <Autocomplete
              fetchCallback={accountContacts}
              autoClear
              autoFocus={false}
              setSelected={participant => {
                if (
                  (values?.person_ids.filter(person => participant.id == person.id)).length == 0
                ) {
                  setFieldValue('person_ids', [
                    ...values.person_ids,
                    { ...participant, checked: true },
                  ]);
                }
              }}
              specifiedAccountId={accountId}
              displayValue={false}
              placeholder={t('events.properties.participant.placeHolder')}
            />
          }
        />
        <div className="flex flex-col ">
          {/* Select All
          <div className="flex items-center gap-2 px-2 py-1 rounded-lg">
            <input
              type="checkbox"
              onChange={handleSelectAll}
              className="mr-2 custom-checkbox"
              checked={
                values?.person_ids.filter(person => person.checked).length ==
                values?.person_ids.length
              }
            />
            <span>{t('events.properties.participant.selectAll')}</span>
          </div> */}
          <div className="flex  flex-col">
            {values?.person_ids?.map(participant =>
              participant.checked ? (
                <div key={participant?.id} className="flex items-center gap-2 px-2 py-1 rounded-lg">
                  <input
                    type="checkbox"
                    checked={participant.checked}
                    onChange={e => {
                      if (e.target.checked == false) {
                        setFieldValue(
                          'person_ids',
                          values?.person_ids.filter(person => participant.id != person.id),
                        );
                      } else {
                        setFieldValue(
                          'person_ids',
                          values.person_ids.map(person => {
                            if (person.id == participant.id)
                              return { ...person, checked: !person.checked };
                            else return person;
                          }),
                        );
                      }
                    }}
                    className="mr-2 custom-checkbox"
                  />
                  {participant?.picture_url ? (
                    <img
                      className="inline-block h-8 w-8 rounded-full object-contain border border-lightStrokeColor"
                      src={participant.picture_url}
                      alt=""
                    />
                  ) : (
                    <div>
                      <span className="inline-flex items-center justify-center  h-8 w-8 rounded-full bg-gray-300 border border-lightStrokeColor">
                        <span className="font-medium text-sm text-bodyTextColor">
                          {participant?.name && getInitialsFromName(participant?.name)}
                        </span>
                      </span>
                    </div>
                  )}
                  <div>
                    <span>{participant?.name}</span>
                    {participant?.class_type ? (
                      <p className="text-sm text-gray-500 flex flex-row">
                        {t(`subjects.properties.${participant?.class_type.toLowerCase()}`)}
                        {participant?.category?.name && (
                          <span className="text-sm text-gray-500 mt-auto">
                            , {participant.category.name}
                          </span>
                        )}
                      </p>
                    ) : (
                      <p className="text-sm text-gray-500">
                        {t(
                          `subjects.properties.${gidToClassType(participant?.gid)?.toLowerCase()}`,
                        )}
                        {personInformation?.category?.name && (
                          <span className="text-sm text-gray-500 mt-auto">
                            , {personInformation.category.name}
                          </span>
                        )}
                      </p>
                    )}
                  </div>
                </div>
              ) : null,
            )}
          </div>
        </div>
      </div>
      <div className="w-full pb-5 translate-y-28 ">
        <Button
          type="button"
          onClick={goToNextStep}
          text={t('common.buttons.next')}
          variant="primary"
          size="large"
          className={'text-white bg-primaryColor w-full'}
        />
        <div className="mt-3">
          <Button
            type="submit"
            text={t('subjects.organizationWizard.finish.button')}
            variant="secondaryOrange"
            size="large"
            className={'text-white bg-primaryColor w-full pt-10'}
          />
        </div>
      </div>
    </div>
  );
};

const Step4 = ({
  hashValue,
  goToStep,
  nextStep,
  setFieldValue,
  accountId,
  selectedAccount,
}: CustomStepProps) => {
  const { values } = useFormikContext<FormikTypes>();
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');
  let subjectAccountSame = false;

  const headerContext = React.useContext(HeaderContext);
  const [showDefaultAddress, setShowDefaultAddress] = useState(true);
  const [showAddress, setShowAddress] = useState(false);
  const [hideAddress, setHideAddress] = useState(true);
  const [objectInformation, setObjectInformation] = useState();
  const [selectedAddress, setSelectedAddress] = useState('');
  const [accountAddress, setAccountAddress] = useState();

  const handleAddressSelection = (placeName: string, id: any) => {
    setSelectedAddress(placeName);
    setFieldValue('address_from_id', id);
  };

  if (selectedAccount?.name == values?.subject?.name) {
    subjectAccountSame = true;
  } else {
    useEffect(() => {
      fetchSubject(accountId, selectedAccount?.default_subject?.gid).then(data => {
        setAccountAddress(data);
      });
    }, [accountId, hashValue]);
  }

  React.useEffect(() => {
    if (hashValue == 4) {
      fetchSubject(accountId, values?.subject.gid).then(data => {
        setObjectInformation(data);
        if (data && data.addresses && data.addresses.length > 0) {
          let mainAddress = data.addresses.find(address => address.main);
          if (!mainAddress) {
            mainAddress = data.addresses[0];
          }
          if (values?.manual_address == false) {
            if (mainAddress && !selectedAddress) {
              handleAddressSelection(mainAddress.place_name, mainAddress.id);
              setHideAddress(false);
            }
          } else {
            for (let i = 0; i < data.addresses.length; i++) {
              if (values.address_from_id == data.addresses[i].id) {
                handleAddressSelection(data?.addresses[i]?.place_name, values?.address_from_id);
                setHideAddress(false);
              } else if (values.address_from_id == null) {
                handleAddressSelection(
                  t('events.properties.event_location.address.noAddress'),
                  null,
                );
                setHideAddress(false);
              }
            }
          }
        }
      });
    }

    if (hashValue == 4) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: false,
          canEdit: false,
          canGoBack: true,
        },
      });
    }
  }, [accountId, hashValue]);

  function goToNextStep() {
    nextStep();
    navigate('/events/new/#step5');
  }

  // Code here will run after *every* render
  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);

  const toggleMode = (toggle: boolean) => {
    setShowDefaultAddress(toggle);
  };

  return (
    <div className="flex flex-col items-center justify-between w-full h-full ">
      <div className="flex justify-center flex-col w-full h-full mt-16">
        <div className="flex flex-col  gap-6 justify-center w-full ">
          <div className="flex justify-center mt-auto gap-6 flex-col text-center w-full">
            <IconHolder
              header={t('events.properties.event_location.title')}
              subTitle={t('events.properties.event_location.subTitle')}
            />
          </div>
        </div>
        <div className="py-9">
          <ToggleEventAttendance
            setFieldValue={setFieldValue}
            toggleMode={toggleMode}
            isFormik={true}
          />
        </div>
      </div>
      {showDefaultAddress && (
        <div className="w-full gap-2 flex flex-col gap-2">
          <div className="mb-8">
            <div className="flex flex-col w-full gap-1 mb-4">
              <h3> {t('events.properties.event_location.placeTitle')}</h3>
              <p className="text-gray-500">{t('events.properties.event_location.placeSubtitle')}</p>
            </div>
            {objectInformation &&
            objectInformation.addresses &&
            objectInformation.addresses.length > 0 ? (
              <>
                {objectInformation.addresses.map((address, index) => (
                  <label key={index} className="flex flex-row text-gray-600 mt-2 w-full">
                    <div className="h-5 w-5 mr-2">
                      <Field
                        type="radio"
                        className="mr-2 h-5  w-5"
                        style={{ color: '#F87315' }}
                        name={`address_from_id`}
                        checked={selectedAddress === address.place_name}
                        onChange={() => {
                          setFieldValue('manual_address', true);
                          setFieldValue('attendance_mode', 'offline');
                          handleAddressSelection(address.place_name, address.id);
                          setHideAddress(false);
                        }}
                        value={address?.place_name}
                      />
                    </div>
                    <div>{address?.place_name}</div>
                  </label>
                ))}
                {!subjectAccountSame && (
                  <>
                    {accountAddress?.addresses.map((address, index) => (
                      <label key={index} className="flex flex-row text-gray-600 mt-2 w-full">
                        <div className="h-5 w-5 mr-2">
                          <Field
                            type="radio"
                            className="mr-2 h-5  w-5"
                            style={{ color: '#F87315' }}
                            name={`address_from_id`}
                            checked={selectedAddress === address.place_name}
                            onChange={() => {
                              setFieldValue('manual_address', true);
                              setFieldValue('attendance_mode', 'offline');
                              handleAddressSelection(address.place_name, address.id);
                              setHideAddress(false);
                            }}
                            value={address?.place_name}
                          />
                        </div>
                        <div>{address?.place_name}</div>
                      </label>
                    ))}
                  </>
                )}
                <label className="flex flex-row text-gray-600 mt-2">
                  <div className="h-5 w-5 mr-2">
                    <Field
                      type="radio"
                      className="mr-2 h-5 w-5"
                      name={`address_from_id`}
                      checked={
                        selectedAddress === t('events.properties.event_location.address.noAddress')
                      }
                      onChange={() => {
                        setFieldValue('manual_address', true);
                        handleAddressSelection(
                          t('events.properties.event_location.address.noAddress'),
                          null,
                        );
                        setFieldValue('attendance_mode', 'mixed');
                        setHideAddress(true);
                      }}
                      value={t('events.properties.event_location.address.noAddress')}
                    />
                  </div>
                  <div>{t('events.properties.event_location.address.noAddress')}</div>
                </label>
              </>
            ) : (
              <div className="mx-auto mt-2">
                {t('events.properties.event_location.address.noAddress')}
              </div>
            )}
          </div>
          {hideAddress && false && (
            <div className="flex flex-col gap-5">
              <p className=""> {t('events.properties.event_location.address.title')}</p>
              {showAddress == false && (
                <button
                  type="button"
                  onClick={() => setShowAddress(true)}
                  className="text-orange-500 mr-auto flex flex-row">
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="mr-2 mt-0.5"
                    style={{ color: '#F87315', fontSize: '21px' }}
                  />
                  <h3>{t('events.properties.event_location.address.add')}</h3>
                </button>
              )}
              {showAddress && (
                <FieldArray name="addresses">
                  {({ remove }) => (
                    <>
                      {values.addresses.length > 0 &&
                        values.addresses.map((address, index) => (
                          <AddressForm
                            key={index} // Don't forget to add the key for dynamic lists.
                            remove={() => remove(index)}
                            index={index}
                            setFieldValue={setFieldValue}
                            keepDelete={false}
                          />
                        ))}
                    </>
                  )}
                </FieldArray>
              )}
              <label className="flex flex-row text-gray-600">
                <Field type="checkbox" className="mr-2 h-5 w-5" name="add_new_address" />
                <div> {t('events.properties.event_location.address.checkboxAddAdress')}</div>
              </label>
            </div>
          )}
        </div>
      )}
      <div className="w-full gap-3  pb-6 translate-y-24">
        <Button
          type="button"
          onClick={goToNextStep}
          text={t('common.buttons.next')}
          variant="primary"
          size="large"
        />
        <button
          type="submit"
          className="rounded-lg  border border-orange-500 mt-3 flex justify-center items-center gap-1 h3 z-20 w-full h-[56px] bg-white text-orange-500">
          {t('subjects.organizationWizard.finish.button')}
        </button>
      </div>
    </div>
  );
};

const Step5 = ({ hashValue, goToStep, setFieldValue }: CustomStepProps) => {
  const { t } = useTranslation('dashboard');
  const headerContext = React.useContext(HeaderContext);
  const params = useParams();
  const { values } = useFormikContext<FormikTypes>();
  const { getAccountId } = useSlugAccount();
  const { pathname }: { pathname: string; state: any } = useLocation();
  const accountId = getAccountId(pathname, params.accountId);
  const [showProperty, setShowProperty] = useState(false);
  const [hasActivityForms, setHasActivityForms] = useState(false);
  const [hasParticipationForms, setHasParticipationForms] = useState(false);
  const [hasOrderTemplates, setHasOrderTemplates] = useState(false);

  const handleToggle = () => {
    setShowProperty(!showProperty);
  };

  React.useEffect(() => {
    if (hashValue == 5) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: false,
          canEdit: false,
          canGoBack: true,
        },
      });
    }
  }, []);

  const getActivityForms = async () => {
    const subjectType = gidToClassType(values?.subject?.gid);
    const response = await fetchSurveys(accountId, 'survey', subjectType).then(response =>
      response.map((form: Option) => ({
        value: form.id,
        label: form.name,
      })),
    );
    return response;
  };

  const getParticipationForms = async () => {
    const response = await fetchSurveys(accountId, 'participation').then(response => [
      ...response.map((form: any) => ({
        value: form.id,
        label: form.name,
      })),
    ]);

    return response;
  };

  const getOrderTemplates = async () => {
    const response = await fetchOrderTemplates(accountId).then(response =>
      response.map((option: Option) => ({
        value: option.id,
        label: option.name,
      })),
    );

    return response;
  };

  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);

  useEffect(() => {
    getActivityForms().then(forms => {
      if (forms && forms.length > 0) {
        setHasActivityForms(true);
      }
    });

    getParticipationForms().then(forms => {
      if (forms && forms.length > 0) {
        setHasParticipationForms(true);
      }
    });

    getOrderTemplates().then(forms => {
      if (forms && forms.length > 0) {
        setHasOrderTemplates(true);
      }
    });
  }, [accountId]);

  return (
    <div className="flex flex-col h-full gap-5 justify-between w-full">
      <div className="w-full gap-6 flex flex-col">
        {hasOrderTemplates && (
          <Property
            haveGap
            title={t('events.properties.distributionMode')}
            content={
              <AsyncSelect
                type="select"
                name="order_template_id"
                placeholder={t('events.properties.modelPlaceHolder')}
                setFieldValue={setFieldValue}
                loadOptions={getOrderTemplates}
                isClearable
                defaultOptions
                deepDataObject
              />
            }
            icon={
              <FontAwesomeIcon
                className="-mt-1 mb-1"
                icon={faBagShopping}
                size="xl"
                style={{
                  color: '#1E3050',
                  fontSize: '22px',
                }}
              />
            }
          />
        )}
        {/* Waiting for API
        <Property
          haveGap
          title={t('events.properties.distributionMode')}
          content={
            <Select
              classNamePrefix="select-content"
              className="w-full select-component placeholder-gray-400"
              onChange={option => setFieldValue('distribution', option)}
              isClearable
              placeholder=""
              name="distribution"
            />
          }
          icon={
            <FontAwesomeIcon
              className="-mt-1 mb-1"
              icon={faBagShopping}
              size="xl"
              style={{
                color: '#1E3050',
                fontSize: '22px',
              }}
            />
          }
        /> */}
        {hasActivityForms && (
          <Property
            haveGap
            title={t('events.properties.survey')}
            content={
              <AsyncSelect
                type="select"
                name="survey_id"
                placeholder={t('events.properties.formPlaceHolder')}
                setFieldValue={setFieldValue}
                loadOptions={getActivityForms}
                isClearable
                defaultOptions
                deepDataObject
              />
            }
            icon={
              <FontAwesomeIcon
                className="-mt-1 mb-1"
                icon={faClipboardListCheck}
                size="xl"
                style={{
                  color: '#1E3050',
                  fontSize: '22px',
                }}
              />
            }
          />
        )}
        {hasParticipationForms && (
          <Property
            haveGap
            title={t('events.properties.participationSurvey')}
            content={
              <AsyncSelect
                type="select"
                name="participation_survey_id"
                placeholder={t('events.properties.formPlaceHolder')}
                setFieldValue={setFieldValue}
                loadOptions={getParticipationForms}
                isClearable
                defaultOptions
                deepDataObject
              />
            }
            icon={
              <FontAwesomeIcon
                className="-mt-1 mb-1"
                icon={faClipboardListCheck}
                size="xl"
                style={{
                  color: '#1E3050',
                  fontSize: '22px',
                }}
              />
            }
          />
        )}
        <div>
          <Property
            haveGap
            title={t('events.properties.participationPrice')}
            content={
              <input
                name="participation_price"
                className="w-full px-4 py-3  focus:outline-none focus:ring-0 focus:text-primaryColor focus:border-primaryColor border border-strokeColor rounded-lg placeholder-gray-400 text-bodyTextColor"
                type="number"
                min={0}
                onChange={event => setFieldValue('participation_price', event.target.value)}
              />
            }
          />
        </div>
        {/* Waiting for API
        <Property
          haveGap
          title={t('events.properties.visibility')}
          content={
            <Select
              classNamePrefix="select-content"
              className="w-full select-component placeholder-gray-400"
              isClearable
              placeholder=""
              name="visibility"
            />
          }
        /> */}
      </div>
      <div className="w-full gap-3 pb-6 translate-y-24">
        <Button type="submit" text={t('common.buttons.next')} variant="primary" size="large" />
        <button
          type="submit"
          className="rounded-lg  border border-orange-500 mt-3 flex justify-center items-center gap-1 h3 z-20 w-full h-[56px] bg-white text-orange-500">
          {t('subjects.organizationWizard.finish.button')}
        </button>
      </div>
    </div>
  );
};

const Step6 = ({ hashValue, eventInformation }: CustomStepProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');
  const headerContext = React.useContext(HeaderContext);

  function goToNextStep() {
    navigate(`/events/${eventInformation?.id}`);
  }

  React.useEffect(() => {
    if (hashValue == 6) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: true,
          canEdit: false,
          canGoBack: false,
          closeUrl: `/events/${eventInformation?.id}`,
        },
      });
    }
  }, []);

  return (
    <div className="flex flex-col gap-5 h-full justify-between h-full pt-10">
      <div className="flex justify-center flex-col w-full h-full">
        <div className="flex flex-col  gap-5 justify-center w-full">
          <div className="flex justify-center mt-auto gap-5 flex-col text-center w-full">
            <IconHolder
              header={t('events.properties.eventCreated')}
              subTitle={t('events.properties.eventCreatedSubtitle')}
            />
          </div>
        </div>
      </div>
      <div className="w-full pb-6 translate-y-24">
        <div className="w-full">
          <Button
            type="button"
            onClick={goToNextStep}
            text={t('events.properties.seeEvent')}
            variant="primary"
            size="large"
          />
        </div>
      </div>
    </div>
  );
};

export default AddEventWizard;
