import { LinkType } from '../types/urls/link';

export const getHashParams = (url?: string) => {
  return (url || window.location.search.substring(1))
    .split('&')
    .reduce(function (initial: { [key: string]: any }, item) {
      if (item) {
        const parts = item.split('=');
        initial[parts[0]] = decodeURIComponent(parts[1]);
      }
      return initial;
    }, {});
};

export const removeHashParamsFromUrl = () => {
  window.history.pushState('', document.title, window.location.pathname);
};

export const parse_link_header = (header: string | undefined | null) => {
  if (header === undefined || header === null) {
    return {};
  }
  if (header.length === 0) {
    throw new Error('headers must not be of zero length');
  }

  const parts = header.split(',');
  const links: LinkType = {} as LinkType;
  for (let i = 0; i < parts.length; i++) {
    const section = parts[i].split(';');
    if (section.length !== 2) {
      throw new Error("section could not be split on ';'");
    }
    const url = section[0].replace(/<(.*)>/, '$1').trim();
    const urlParams = getHashParams(url.split('?')[1]);
    const name = section[1].replace(/rel="(.*)"/, '$1').trim();
    links[name as keyof LinkType] = { url, ...(urlParams as any) };
  }
  return links;
};
