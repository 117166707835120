import React from 'react';

import { FiltersType, HeadersProperties } from '@/types';

interface FiltersContextStateType {
  filters: FiltersType;
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
  pagination: HeadersProperties;
  setPagination: React.Dispatch<React.SetStateAction<HeadersProperties>>;
  removeFilter: (key: string) => void;
  resetAndFetch: () => void;
  nextPage: () => void;
}

export const FiltersContext = React.createContext<FiltersContextStateType>({
  filters: {} as FiltersType,
  setFilters: () => null,
  pagination: {} as HeadersProperties,
  setPagination: () => null,
  removeFilter: () => null,
  resetAndFetch: () => null,
  nextPage: () => null,
});

export const FiltersContextProvider = ({ children }: { children: React.ReactNode }) => {
  const initialPagination = {
    page: 1,
    per_page: 10,
    total: 0,
    links: {},
  };

  const [filters, setFilters] = React.useState<FiltersType>({} as FiltersType);

  const [pagination, setPagination] = React.useState<HeadersProperties>(initialPagination);

  const removeFilter = (key: string) => {
    setFilters({ ...filters, [key]: null } as FiltersType);
  };

  const resetAndFetch = () => {
    setFilters({} as FiltersType);
  };

  const nextPage = () => {
    setPagination({ ...pagination, page: pagination.page + 1 });
  };

  return (
    <FiltersContext.Provider
      value={{
        filters,
        setFilters,
        pagination,
        setPagination,
        removeFilter,
        resetAndFetch,
        nextPage,
      }}>
      {children}
    </FiltersContext.Provider>
  );
};
