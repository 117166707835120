// Utils
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useFormikContext, FieldArray } from 'formik';
import { useNavigate } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import { toast } from 'react-toastify';

// Styles
import '../../../src/style/index.scss';
import 'react-datepicker/dist/react-datepicker.css';

// Context
import { HeaderContext } from '@/contexts/headerContext';

// API
import { fetchTags } from '@/utils/api/tags';

// Components
import AddressForm from '../../components/AddressForm/AddressForm';
import AsyncSelect from '../../components/AsyncSelect';
import Button from '../../components/Buttons/Button';
import ContactInformation from '../../components/ContactInformation/ContactInformation';
import GenderSelect from '../../components/GenderSelect/GenderSelect';
import IconHolder from '../../components/IconHolder/IconHolder';
import ImageHandler from '../../components/ImageHandler';
import Property from '../../components/Property/Property';
import TextArea from '../../components/TextArea';
import TextInput from '../../components/TextInput';
import HallowButton from '../HallowButton/HallowButton';
import CheckboxDatabaseEmail from '../CheckboxDatabaseEmail/CheckboxDatabaseEmail';
import TagSelect from '../TagSelect/TagSelect';

// Icons & Images
import { ReactComponent as Smiley } from '@/assets/icons/smiley-face.svg';
import { ReactComponent as PersonFile } from '@/assets/icons/person-file.svg';
import { ReactComponent as Diagram } from '@/assets/icons/diagram.svg';
import { ReactComponent as PhoneBook } from '@/assets/icons/phone-book.svg';
import { ReactComponent as Location } from '@/assets/icons/location-pin.svg';
import { ReactComponent as ProfilePictureFrame } from '@/assets/icons/profile-picture-frame.svg';
import { ReactComponent as AddIcon } from '@/assets/icons/add-circle.svg';

// Types & Interfaces
type CustomStepProps = {
  nextStep?: any;
  hashValue?: any;
  goToStep?: any;
  setFieldValue?: any;
  fetchCategories?: any;
  personDisplayName?: string;
  photos?: any;
  setPhotos?: any;
  errors?: any;
  isSubmitting?: any;
  touched?: any;
  values?: any;
  personId?: any;
  submitForm?: any;
  accountId?: number;
};

interface FormikTypes {
  addresses: {
    info: string;
    type: string;
  }[];
  value: any;
  contact_informations: any;
}

const AddPersonWizard = ({
  hashValue,
  errors,
  touched,
  isSubmitting,
  photos,
  setFieldValue,
  fetchCategories,
  setPhotos,
  values,
  personId,
  submitForm,
  accountId,
}: CustomStepProps) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const handleStepChange = (step: number) => {
    setCurrentStep(step);
  };

  useEffect(() => {
    if ((values?.first_name && values?.last_name) === '' && hashValue >= 2) {
      navigate('/contacts/people/new/#step1');
    }
  }, []);

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, [hashValue]);

  return (
    <StepWizard
      onStepChange={() => handleStepChange}
      isLazyMount={true}
      initialStep={hashValue}
      className="pb-6 p-6 h-full">
      <Step1 hashValue={hashValue} errors={errors} />
      <Step2 hashValue={hashValue} errors={errors} touched={touched} />
      <Step3
        hashValue={hashValue}
        errors={errors}
        setFieldValue={setFieldValue}
        fetchCategories={fetchCategories}
        submitForm={submitForm}
        accountId={accountId}
      />
      <Step4 hashValue={hashValue} errors={errors} touched={touched} />
      <Step5
        hashValue={hashValue}
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
      />
      <Step6
        hashValue={hashValue}
        errors={errors}
        photos={photos}
        setPhotos={setPhotos}
        isSubmitting={isSubmitting}
      />
      <Step7
        hashValue={hashValue}
        personId={personId}
        photos={photos}
        errors={errors}
        personDisplayName={
          values?.first_name.charAt(0).toUpperCase() +
          values?.first_name.slice(1) +
          ' ' +
          (values?.last_name.charAt(0).toUpperCase() + values?.last_name.slice(1))
        }
      />
    </StepWizard>
  );
};

const Step1 = ({ nextStep, hashValue, goToStep }: CustomStepProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');
  const headerContext = React.useContext(HeaderContext);

  React.useEffect(() => {
    if (hashValue == 1) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: false,
          canEdit: false,
          canGoBack: true,
        },
      });
    }
  }, []);

  function goToNextStep() {
    nextStep();
    navigate('/contacts/people/new/#step2');
  }

  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);

  return (
    <div className="flex items-center justify-between flex-col pt-20 h-full ">
      <div className="flex justify-center flex-col w-full h-full">
        <div className="flex flex-col  gap-6 justify-center w-full">
          <div className="flex justify-center mt-auto gap-6 flex-col text-center w-full">
            <IconHolder
              svgIcon={<Smiley />}
              header={t('subjects.addPersonForm.beforeBegin')}
              subTitle={t('subjects.addPersonForm.beforeBeginTitle')}
            />
          </div>
        </div>
      </div>
      <div className="w-full translate-y-32 flex flex-col gap-3 pb-6">
        <Button
          type="button"
          onClick={goToNextStep}
          text={t('subjects.addPersonForm.buttonStart')}
          variant="primary"
          size="large"
        />
        <Button
          type="button"
          link="/contacts/people"
          text={t('common.buttons.cancel')}
          variant="secondaryOrange"
          size="large"
        />
      </div>
    </div>
  );
};

const Step2 = ({ nextStep, hashValue, goToStep, errors, touched }: CustomStepProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');
  const [nextStepAttempted, setNextStepAttempted] = useState(false);
  const headerContext = React.useContext(HeaderContext);

  React.useEffect(() => {
    if (hashValue == 2) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: false,
          canEdit: false,
          canGoBack: true,
        },
      });
    }
  }, []);

  function goToNextStep() {
    setNextStepAttempted(true);
    if (errors?.first_name || errors?.last_name) {
      console.log(' there are errors');
    } else {
      nextStep();
      navigate('/contacts/people/new/#step3');
    }
  }

  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);
  return (
    <div className="flex flex-col items-center justify-between w-full h-full">
      <div className="flex flex-col gap-6 h-full mb-5">
        <IconHolder
          svgIcon={<PersonFile />}
          header={t('subjects.addPersonForm.personInfo')}
          subTitle={t('subjects.addPersonForm.personInfoTitle')}
        />
      </div>
      <div className="w-full gap-6 flex flex-col">
        <Property
          title={t('common.firstname')}
          bold={true}
          required={true}
          content={
            <TextInput
              touched={touched?.first_name}
              nextStepAttempted={nextStepAttempted}
              type="text"
              name="first_name"
              placeholder={t('common.firstname')}
            />
          }
        />
        <Property
          title={t('common.lastname')}
          bold={true}
          required={true}
          content={
            <TextInput
              touched={touched?.last_name}
              nextStepAttempted={nextStepAttempted}
              type="text"
              name="last_name"
              placeholder={t('common.lastname')}
            />
          }
        />
        <Property
          haveGap
          bold={true}
          title={t('subjects.addPersonForm.gender.genderTitle')}
          content={<GenderSelect name="gender" />}
        />
        <Property
          title={t('subjects.properties.dob')}
          haveGap
          bold={true}
          content={
            <div className="flex relative">
              <TextInput
                id="date"
                type="date"
                name="dob"
                isDob={true}
                placeholder={t('placeHolders.date')}
                containerClasses="w-full"
              />
            </div>
          }
        />
      </div>
      <div className="w-full pb-6  translate-y-28">
        <Button
          type="button"
          onClick={goToNextStep}
          text={t('common.buttons.next')}
          variant="primary"
          size="large"
        />
      </div>
    </div>
  );
};

const Step3 = ({
  nextStep,
  hashValue,
  goToStep,
  setFieldValue,
  fetchCategories,
  touched,
  errors,
  accountId,
}: CustomStepProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');
  const [nextStepAttempted, setNextStepAttempted] = useState(false);
  const { values } = useFormikContext<FormikTypes>();
  function goToNextStep() {
    setNextStepAttempted(true);
    if (!errors.category_id) {
      nextStep();
      navigate('/contacts/people/new/#step4');
    }
  }

  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);

  return (
    <div className="flex flex-col items-center justify-between w-full h-full">
      <div className="flex flex-col h-full mb-5 ">
        <IconHolder
          svgIcon={<Diagram />}
          header={t('subjects.addPersonForm.contactInfo')}
          subTitle={t('subjects.addPersonForm.contactInfoTitle')}
        />
      </div>
      <div className="w-full flex flex-col gap-6">
        <Property
          title={t('parcels.properties.category')}
          bold={true}
          content={
            <AsyncSelect
              type="select"
              touched={touched?.category_id}
              name="category_id"
              placeholder={t('placeHolders.vehicleCategoriesSelect')}
              setFieldValue={setFieldValue}
              loadOptions={fetchCategories}
              isClearable
              defaultOptions
              nextStepAttempted={nextStepAttempted}
            />
          }
        />
        <div className="flex flex-col">
          <Property
            haveGap
            bold={true}
            title={t('vehicles.properties.description')}
            content={
              <div className="relative">
                <TextArea type="text" name="note" />
              </div>
            }
          />
          <div className="flex flex-col gap-3 text-gray-600 mt-1">
            {t('subjects.addPersonForm.infoDescription')}
          </div>
        </div>
        <TagSelect
          fetchTags={fetchTags}
          setFieldValue={setFieldValue}
          values={values}
          accountId={accountId}
        />
        <CheckboxDatabaseEmail />
      </div>
      <div className="w-full  pb-6 translate-y-24 gap-3 flex flex-col">
        <Button
          type="button"
          onClick={goToNextStep}
          text={t('common.buttons.next')}
          variant="primary"
          size="large"
        />
        <Button
          type="submit"
          text={t('subjects.addPersonForm.finish')}
          variant="secondaryOrange"
          size="large"
        />
      </div>
    </div>
  );
};

const Step4 = ({ nextStep, hashValue, goToStep, errors, touched }: CustomStepProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');
  const { values } = useFormikContext<FormikTypes>();
  const [nextStepAttempted, setNextStepAttempted] = useState(false);
  const headerContext = React.useContext(HeaderContext);

  React.useEffect(() => {
    if (hashValue == 4) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: false,
          canEdit: false,
          canGoBack: true,
        },
      });
    }
  }, []);

  function goToNextStep() {
    setNextStepAttempted(true);
    if (!errors.contact_informations) {
      nextStep();
      navigate('/contacts/people/new/#step5');
    }
  }

  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);

  return (
    <div className="flex flex-col h-full gap-6 justify-between w-full">
      <IconHolder
        svgIcon={<PhoneBook />}
        header={t('subjects.addPersonForm.wayToContact')}
        subTitle={t('subjects.addPersonForm.wayToContactTitle')}
      />
      <div className="flex flex-col w-full">
        <FieldArray name="contact_informations">
          {({ push, remove }) => (
            <>
              {values.contact_informations.length > 0 &&
                values.contact_informations.map((contact_information: any, index: number) => (
                  <ContactInformation
                    key={index}
                    remove={() => remove(index)}
                    push={() => push(index)}
                    index={index}
                    item={contact_information}
                    touched={touched?.contact_informations}
                    nextStepAttempted={nextStepAttempted}
                  />
                ))}
              <div className="flex justify-start text-orange-500 mt-2 ">
                <button
                  type="button"
                  className="my-auto flex flex-row"
                  onClick={() => push({ info: '', type: 'Email', main: false })}>
                  <div className="flex justify-center items-center font-semibold mr-2">
                    <AddIcon style={{ stroke: '#F97316' }} />
                  </div>
                  <div className="mt-0.5">{t('subjects.addPersonForm.addContact')}</div>
                </button>
              </div>
            </>
          )}
        </FieldArray>
      </div>
      <div className="w-full translate-y-32 flex flex-col gap-3 pb-6">
        <div className="w-full">
          <Button
            type="button"
            onClick={goToNextStep}
            text={t('common.buttons.next')}
            variant="primary"
            size="large"
          />
        </div>
        <Button
          type="submit"
          text={t('subjects.addPersonForm.finish')}
          variant="secondaryOrange"
          size="large"
        />
      </div>
    </div>
  );
};

const Step5 = ({ hashValue, goToStep, setFieldValue }: CustomStepProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');
  const { values } = useFormikContext<FormikTypes>();
  const headerContext = React.useContext(HeaderContext);

  React.useEffect(() => {
    if (hashValue == 5) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: false,
          canEdit: false,
          canGoBack: true,
        },
      });
    }
  }, []);

  function goToNextStep() {
    navigate('/contacts/people/new/#step6');
  }

  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);

  return (
    <div className="flex flex-col items-center justify-between w-full h-full">
      <div className="flex flex-col gap-6 h-full mb-5">
        <IconHolder
          svgIcon={<Location />}
          header={t('subjects.addPersonForm.address')}
          subTitle={t('subjects.addPersonForm.addressTitle')}
        />
      </div>
      <div className="flex flex-col w-full">
        <FieldArray name="addresses">
          {({ push, remove }) => (
            <>
              {values.addresses.length > 0 &&
                values.addresses.map((address, index) => (
                  <AddressForm
                    key={index} // Don't forget to add the key for dynamic lists.
                    remove={() => remove(index)}
                    index={index}
                    setFieldValue={setFieldValue}
                  />
                ))}
              <div className="flex justify-start text-orange-500 mt-2 ">
                <button
                  type="button"
                  className="my-auto flex flex-row"
                  onClick={() => push({ info: '', type: 'Email' })}>
                  <div className="flex justify-center items-center font-semibold mr-2">
                    <AddIcon style={{ stroke: '#F97316' }} />
                  </div>
                  <div className="mt-0.5">{t('subjects.addPersonForm.addAddress')}</div>
                </button>
              </div>
            </>
          )}
        </FieldArray>
      </div>
      <div className="w-full  pb-6 translate-y-24 gap-3 flex flex-col">
        <div className="w-full">
          <Button
            type="button"
            onClick={goToNextStep}
            text={t('common.buttons.next')}
            variant="primary"
            size="large"
          />
        </div>
        <Button
          type="submit"
          text={t('subjects.addPersonForm.finish')}
          variant="secondaryOrange"
          size="large"
        />
      </div>
    </div>
  );
};

const Step6 = ({ hashValue, goToStep, photos, setPhotos, isSubmitting }: CustomStepProps) => {
  const { t } = useTranslation('dashboard');
  const headerContext = React.useContext(HeaderContext);

  React.useEffect(() => {
    if (hashValue == 6) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: false,
          canEdit: false,
          canGoBack: true,
        },
      });
    }
  }, []);

  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);

  return (
    <div className="flex flex-col items-center justify-between w-full h-full">
      <div className="flex flex-col gap-6 h-full">
        <IconHolder
          svgIcon={<ProfilePictureFrame />}
          header={t('vehicles.add.photo')}
          subTitle={t('subjects.addPersonForm.identificationTitle')}
        />
      </div>
      <div className="w-full flex flex-col gap-6">
        <Property
          title={''}
          content={<ImageHandler height={300} photos={photos} setPhotos={setPhotos} />}
        />
      </div>
      <div className="w-full translate-y-32 flex flex-col gap-3 pb-6">
        <Button
          type="submit"
          text={t('subjects.addPersonForm.finish')}
          variant="primary"
          size="large"
        />
      </div>
    </div>
  );
};

const Step7 = ({ hashValue, goToStep, personDisplayName, photos, personId }: CustomStepProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');

  function goToNextStep() {
    navigate(`/contacts/people/${personId}/summary`);
    toast.success(t(`toast.personAddSuccess`));
  }

  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);

  const [preview, setPreview] = React.useState<string>();
  React.useEffect(() => {
    if (!photos) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(photos);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [photos]);

  const headerContext = React.useContext(HeaderContext);

  React.useEffect(() => {
    if (hashValue == 7) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: true,
          canEdit: false,
          canGoBack: false,
          closeUrl: `/contacts/people/${personId}/summary`,
        },
      });
    }
  }, []);

  return (
    <div className="flex items-center justify-between flex-col pt-20 h-full ">
      <div className="flex justify-center flex-col w-full h-full">
        <div className="flex flex-col  gap-5 justify-center w-full">
          <div className="flex justify-center mt-auto gap-5 flex-col text-center w-full">
            <IconHolder
              svgIcon={
                preview && (
                  <img src={preview} className="object-cover w-32 h-32 mx-auto rounded-full" />
                )
              }
              header={
                personDisplayName && (
                  <div className="font-semibold">
                    {personDisplayName?.charAt(0).toUpperCase() + personDisplayName?.slice(1)}
                    {t('subjects.organizationWizard.success.title')}
                  </div>
                )
              }
              subTitle={t('subjects.addPersonForm.addSuccessSubTitle1')}
            />
            <HallowButton
              title={t('subjects.addPersonForm.buttonHousehold')}
              onClick={() => navigate('/contacts/households/new/#step1')}
              type="button"
            />
            <HallowButton
              title={t('subjects.addPersonForm.buttonOrganization')}
              onClick={() => navigate('/contacts/organizations/new/#step1')}
              type="button"
            />
          </div>
        </div>
      </div>
      <div className="w-full translate-y-32 flex flex-col gap-6 pb-6 ">
        <Button
          type="button"
          onClick={goToNextStep}
          text={t('subjects.addPersonForm.seePerson')}
          variant="primary"
          size="large"
        />
      </div>
    </div>
  );
};

export default AddPersonWizard;
