import { AxiosResponse } from 'axios';
import { SubjectType } from '../../types/subject/subject';
import api from './api';

export const fetchSubject = async (
  account_id: number,
  subjectGid: string,
): Promise<SubjectType> => {
  if (!account_id || !subjectGid) throw Error('No account_id or subjectGid');
  const rawResponse = await api.get(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/subjects/${encodeURIComponent(
      subjectGid,
    )}.json`,
  );
  return rawResponse.data;
};

export const fetchCachedSubject = async (account_id: number, subjectGid: string) => {
  const rawResponse = caches
    .match(
      `${import.meta.env.VITE_API}/${account_id}/api/v1/subjects/${encodeURIComponent(
        subjectGid,
      )}.json`,
    )
    .then(response => {
      if (!response) throw Error('No data');
      return response.json().then(data => data);
    });
  return rawResponse;
};

export const createSubject = async (
  account_id: number,
  body: object,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  const rawResponse = await api.post(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/subjects.json`,
    body,
  );
  return rawResponse;
};

export const fetchCollaborations = async (
  account_id: number,
  gid: string,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  const rawResponse = await api.get(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/subjects/${encodeURIComponent(
      gid,
    )}/collaborations.json`,
  );
  return rawResponse;
};

export const createCollaboration = async (
  account_id: number,
  gid: string,
  body: object,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  const rawResponse = await api.post(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/subjects/${encodeURIComponent(
      gid,
    )}/collaborations.json`,
    body,
  );
  return rawResponse;
};

export const editCollaboration = async (
  account_id: number,
  gid: string,
  collaborationId: number,
  body: object,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  if (!gid) throw Error('No subject gid');
  if (!collaborationId) throw Error('No collaboration id');

  const rawResponse = await api.put(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/subjects/${encodeURIComponent(
      gid,
    )}/collaborations/${collaborationId}.json`,
    body,
  );

  return rawResponse;
};

export const deleteCollaboration = async (
  account_id: number,
  gid: string,
  collaborationId: number,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  if (!gid) throw Error('No subject gid');
  if (!collaborationId) throw Error('No collaboration id');

  const rawResponse = await api.delete(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/subjects/${encodeURIComponent(
      gid,
    )}/collaborations/${collaborationId}.json`,
  );
  return rawResponse;
};

export const fetchContactCategories = async (
  account_id: number,
  selectedFetchFunc: (account_id: number) => Promise<AxiosResponse<any, any>>,
) => {
  const response = await selectedFetchFunc(account_id).then(response => [
    ...response.data.map((category: any) => ({ value: category.id, label: category.name })),
  ]);
  return response;
};
