export default {
  welcome: 'Bienvenue',
  back: 'Retour',
  edit: 'Modifier',
  add: 'Ajouter',
  noMatch: {
    title: 'Aucun résultat',
    text: 'Oups, il semble que vous essayez d’accéder à une page qui n’existe pas.',
    button: 'Page d’accueil',
    noAccounts: 'Aucun compte ne vous a été assigné. Veuillez contacter votre administrateur.',
    noAccount: 'Ce compte a été supprimé. Veuillez contacter votre administrateur.',
  },
  toast: {
    offline: 'Vous êtes hors ligne',
    online: 'Vous êtes en ligne',
    error: 'Une erreur est survenue',
    vehicleDeleted: 'Le véhicule a bien été supprimé',
    tripDeleted: 'Le trajet a bien été supprimé',
    parcelDeleted: 'Le colis a bien été supprimé',
    parcelAdressDeleted: 'L’adresse a bien été supprimée',
    eventDeleted: 'L’événement a bien été supprimé',
    noteDeleted: 'La note a bien été supprimé',
    organizationDeleted: 'L’organisation a bien été supprimée',
    personDeleted: 'La personne a bien été supprimée',
    householdDeleted: 'Le ménage a bien été supprimé',
    connectionDeleted: 'La connexion a bien été supprimée',
    commentDeleted: 'Le commentaire a bien été supprimé',
    productDeleted: 'Le produit a bien été supprimé',
    attendeeDeleted: 'Le participant a été enlevé',
    collaboratorDeleted: 'Le collaborateur a été enlevé',
  },
  sw: {
    appReadyOffline: 'L’application est prête pour l’utilisation hors ligne',
    newContentAvailable:
      'Une nouvelle version est disponible, cliquez sur Actualiser pour la mettre à jour',
  },
  buttons: {
    delete: 'Supprimer',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    logout: 'Déconnexion',
    close: 'Fermer',
    reload: 'Actualiser',
  },
  modal: {
    delete: {
      vehicle: {
        title: 'Supprimer le véhicule',
        content:
          'Vous vous apprêtez à supprimer le véhicule. Cette action est irréversible. Voulez-vous continuer ?',
      },
      trip: {
        title: 'Supprimer le trajet',
        content:
          'Vous vous apprêtez à supprimer le trajet. Cette action est irréversible. Voulez-vous continuer ?',
      },
      tripAssociate: {
        title: 'Désassocier',
        content: 'Voulez-vous vraiment désassocier ce trajet ?',
      },
      parcel: {
        title: 'Supprimer le colis',
        content:
          'Vous vous apprêtez à supprimer le colis. Cette action est irréversible. Voulez-vous continuer ?',
      },
      parcelAssociate: {
        title: 'Désassocier',
        content: 'Voulez-vous vraiment désassocier cet envoi ?',
      },
      parcelAdress: {
        title: "Supprimer l'adress du colis",
        content:
          "Vous vous apprêtez à supprimer l'adresse du colis. Cette action est irréversible. Voulez-vous continuer ?",
      },
      event: {
        title: "Supprimer l'événement",
        content:
          "Vous vous apprêtez à supprimer l'événement. Cette action est irréversible. Voulez-vous continuer ?",
      },
      note: {
        title: 'Supprimer la note',
        content:
          'Vous vous apprêtez à supprimer la note. Cette action est irréversible. Voulez-vous continuer ?',
      },
      organization: {
        title: "Supprimer l'organisation",
        content:
          "Vous vous apprêtez à supprimer l'organisation. Cette action est irréversible. Voulez-vous continuer ?",
      },
      person: {
        title: 'Supprimer la personne',
        content:
          'Vous vous apprêtez à supprimer la personne. Cette action est irréversible. Voulez-vous continuer ?',
      },
      connection: {
        title: 'Supprimer la connection',
        content:
          'Vous vous apprêtez à supprimer la connection. Cette action est irréversible. Voulez-vous continuer ?',
      },
      comment: {
        title: 'Supprimer le commentaire',
        content:
          'Vous vous apprêtez à supprimer le commentaire. Cette action est irréversible. Voulez-vous continuer ?',
      },
      product: {
        title: 'Supprimer le produit',
        content:
          'Vous vous apprêtez à supprimer le produit. Cette action est irréversible. Voulez-vous continuer ?',
      },
      household: {
        title: 'Supprimer le ménage',
        content:
          'Vous vous apprêtez à supprimer le ménage. Cette action est irréversible. Voulez-vous continuer ?',
      },
      attendee: {
        title: 'Supprimer un participant',
        content:
          'Vous vous apprêtez à supprimer le participant. Cette action est irréversible. Voulez-vous continuer ?',
      },
      collaborator: {
        title: 'Supprimer une collaboration',
        content:
          'Vous vous apprêtez à supprimer la collaboration. Cette action est irréversible. Voulez-vous continuer ?',
      },
    },
  },
  time: {
    ago: 'il y a',
    now: "À l'instant",
    timeAgo: 'Il y a un moment',
    days_one: 'Il y a {{count}} jour',
    days_other: 'Il y a {{count}} jours',
    hours_one: 'Il y a {{count}} heure',
    hours_other: 'Il y a {{count}} heures',
    minutes_one: 'Il y a {{count}} minute',
    minutes_other: 'Il y a {{count}} minutes',
  },
  errorBoundaries: {
    title: 'Oups, une erreur est survenue',
    description:
      'Nous nous excusons pour la gêne occasionnée. \nAfin de nous aider à améliorer notre service, vous pouvez prendre une capture d’écran et nous la remonter.',
    details: "Détails d'erreur",
    button: 'Retourner à la page d’accueil',
  },
};
