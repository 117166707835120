import React from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { ErrorBoundary as AppSignalErrorBoundary} from '@appsignal/react';
import appsignal from '@/utils/appsignal';
import { ErrorType, NodeEnvsType } from '@/types';
import FallbackComponent from '../FallbackComponent';


const ErrorBoundaryContainer = ({children} : { children : JSX.Element}) => {

  if(import.meta.env.VITE_NODE_ENVS === NodeEnvsType.development) {
    return <ReactErrorBoundary FallbackComponent={(error: ErrorType) => <FallbackComponent error={error} />}>{children}</ReactErrorBoundary>;
  }

  return (
    <AppSignalErrorBoundary
      instance={appsignal}
      fallback={(error: ErrorType) => <FallbackComponent error={error} />}
    >
      {children}
    </AppSignalErrorBoundary>
  )
};

export default ErrorBoundaryContainer;