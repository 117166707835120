import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import LogoutButton from '../Buttons/LogoutButton/LogoutButton';
import LangButton from '../LangButton/LangButton';
import AccountsSelect from '../AccountsSelect/AccountsSelect';

import { UrlType } from '../../types';

import TouconLogo from '../../assets/images/btn-login-icon-toucan-white.png';

import { useModeConfig } from '../../contexts/configContext';
import { UserContext } from '../../contexts/userContext';
import { AdminLinksType } from '../../types';

const Navbar = ({ opened, setOpened }: { opened: boolean; setOpened: () => void }) => {
  const { t } = useTranslation('dashboard');
  const mode = useModeConfig();
  const navigate = useNavigate();

  const urls = t(`actions.${mode}`, { returnObjects: true }) as Array<UrlType>;

  const userContext = React.useContext(UserContext);
  const { loggedIn, user } = userContext.state;
  const adminLinks = user?.current_account?.admin_links;

  const returnHome = () => {
    setOpened();
    navigate('/dashboard');
  };

  return (
    <nav
      className={`h-screen${opened ? ' slide-in bg-slate-400 bg-opacity-60' : ''}`}
      onClick={setOpened}>
      <div
        onClick={e => e.stopPropagation()}
        className={`nav max-w-full h-screen absolute right-0 top-0 bg-primaryColor z-50`}>
        <div className="max-height-chrome flex flex-col h-full p-5 pt-20 w-full justify-between">
          <LangButton />
          {/* {loggedIn && import.meta.env.VITE_THEME_CONFIG !== 'colinor' && (
            <SwitchMode callback={returnHome} />
          )} */}
          <div className="flex flex-col gap-3.5">
            {/* {loggedIn && mode === ModeType.transport && (
              <SwitchTransportMode callback={returnHome} />
            )} */}
            {user.accounts && <AccountsSelect callback={returnHome} />}
            {loggedIn && (
              <React.Fragment>
                {urls?.map(url => (
                  <Link
                    key={url.title}
                    onClick={() => setOpened()}
                    to={url.url}
                    state={url?.state?.reduce((o, key) => ({ ...o, [key]: true }), {})}
                    className="text-white text-xl font-bold">
                    {url.title}
                  </Link>
                ))}
              </React.Fragment>
            )}
          </div>
          <div>
            {adminLinks && Array.isArray(adminLinks) && adminLinks.map(({ title, href } : AdminLinksType, index) => (
              <a
                key={index}
                href={href}
                className="block text-white text-xl font-bold mb-3">
                {title}
              </a>
            ))}
            {adminLinks && !Array.isArray(adminLinks) && (
              <a
                href={adminLinks.href}
                className="block text-white text-xl font-bold mb-3">
                {adminLinks.title}
              </a>
            )}
            <div className="flex flex-col gap-3.5">{loggedIn && <LogoutButton />}</div>
            <p className="text-white text-center flex gap-1 mt-2">
              {t('poweredBy')}{' '}
              <a
                href="https://www.toucansolutions.ca/"
                target="_blank"
                rel="noopener noreferrer"
                className="underline underline-offset-2 flex gap-2">
                {t('toucan')}
                <img src={TouconLogo} alt="Toucan Solutions" className="w-[20px] h-[20px]" />
              </a>
            </p>
            <p className="text-white text-center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://gitlab.com/toucansolutions/pocket/-/commit/${
                  import.meta.env.VITE_VERSION
                }`}>
                v {import.meta.env.VITE_VERSION?.slice(0, 8) ?? '0.1.0'}
              </a>
            </p>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
