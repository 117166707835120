// Utils
import { getYearMonthDay } from '@/utils/time';
// API & Services
import { createPeople, updatePerson, fetchPeople } from '../utils/api/people';
import { createContactInformation } from '../utils/api/contact_informations';
import { addressService } from '../services';

function getById(accountId: number, id: number | string): Promise<any> {
  return fetchPeople(accountId, id);
}

function update(
  accountId: number,
  id: number | string,
  params: any,
  onSuccessCallback: any,
  setSubmitting: any,
): Promise<any> {
  updatePerson(accountId, id, sanitizePersonParams(params))
    .then(personResponse => {
      onSuccessCallback(personResponse.data, setSubmitting);
    })
    .catch(error => {
      console.log(error.message);
      // TODO: add onErrorCallback
    });
}

function create(accountId: number, params: any, onSuccessCallback: any, setSubmitting: any) {
  createPeople(accountId, sanitizePersonParams(params))
    .then(personResponse => {
      const dependantPersonItemActions = [];

      // Create contact_informations if any
      params.contact_informations.forEach(contact_information => {
        if (contact_information.info != '') {
          dependantPersonItemActions.push(
            createContactInformation(accountId, personResponse.data.gid, contact_information),
          );
        }
      });

      // Create addresses if any
      params.addresses.forEach(address => {
        if (address.address) {
          dependantPersonItemActions.push(
            addressService.create(accountId, personResponse.data.gid, address),
          );
        }
      });

      const dependantPersonItemResults = Promise.all(dependantPersonItemActions);
      dependantPersonItemResults.then(() => {
        onSuccessCallback(personResponse.data, setSubmitting, params.addresses);
      });
    })
    .catch(error => {
      console.log(error.message);
      // TODO: add onErrorCallback
    });
}

// Private
function sanitizePersonParams(params: any): any {
  const sanitizedPersonParams = Object.assign({}, params);
  sanitizedPersonParams['dob'] = params.dob ? getYearMonthDay(params.dob) : '';
  sanitizedPersonParams['name'] = params.first_name
    ? `${params.first_name} ${params.last_name}`
    : params.last_name;
  return sanitizedPersonParams;
}

export const personService = {
  getById,
  update,
  create,
};
