import { TransportModeType, LogisticModeType, ModeType } from '../config/config';
import { SubjectGidType } from '../subject/subject';

export interface AdminLinksType {
  title: string;
  href: string;
}

export interface AccountType {
  current_account: any;
  id: number;
  name: string;
  role: string;
  person_id: number;
  profile: string;
  color: string;
  transportView: TransportModeType;
  logisticView: LogisticModeType;
  authorized_modules: Array<AuthorizedModules>;
  people_count: number;
  organizations_count: number;
  households_count: number;
  jwt_token: string;
  admin_links: Array<AdminLinksType> | AdminLinksType;
  person: SubjectGidType;
  default_currency: string;
  mode: string;
  user_membership: {
    admin_links: Array<AdminLinksType> | AdminLinksType;
    authorized_modules: string[];
    default_module: ModeType;
    jwt_token: string;
    person: SubjectGidType;
    role: string;
  };
}

export enum AuthorizedModules {
  triage = 'triage',
  procurement = 'procurement',
  warehouses = 'warehouses',
  transports = 'transports',
  sharings = 'sharings',
  items = 'items',
  resources = 'resources',
  crm = 'crm',
  communication = 'communication',
  reports = 'reports',
  all_categories = 'all_categories',
}
