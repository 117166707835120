// API
import { createAddress, deleteAddress, editAddress } from '../utils/api/addresses';

function create(
  accountId: number,
  subject_gid: string,
  params: any,
  onSuccessCallback?: any,
  setSubmitting?: any,
): Promise<any> {
  createAddress(accountId, sanitizeAddressParams(params, subject_gid))
    .then(addressResponse => {
      onSuccessCallback(addressResponse.data, setSubmitting);
    })
    .catch(error => {
      console.log(error.message);
      // TODO: add onErrorCallback
    });
}
function update(
  addressId: number,
  account_id: number,
  subject_gid: string,
  params: any,
  onSuccessCallback?: any,
  setSubmitting?: any,
): Promise<any> {
  editAddress(addressId, account_id, sanitizeAddressParams(params, subject_gid))
    .then(addressResponse => {
      onSuccessCallback(addressResponse.data, setSubmitting);
    })
    .catch(error => {
      console.log(error.message);
      // TODO: add onErrorCallback
    });
}

function destroy(
  addressId: number,
  account_id: number,
  onSuccessCallback?: any,
  setSubmitting?: any,
): Promise<any> {
  deleteAddress(addressId, account_id)
    .then(addressResponse => {
      onSuccessCallback(addressResponse.data, setSubmitting);
    })
    .catch(error => {
      console.log(error.message);
      // TODO: add onErrorCallback
    });
}

function sanitizeAddressParams(params: any, addressable_gid: string): any {
  const addressParams = params.address;
  const sanitizedAddressParams = params;
  sanitizedAddressParams['addressable_gid'] = addressable_gid;
  if (addressParams) {
    sanitizedAddressParams['street1'] =
      (addressParams.place_type?.[0] === 'address'
        ? `${addressParams.address} ${addressParams.text}`
        : addressParams.properties?.address) ||
      addressParams.text ||
      addressParams.street1 ||
      '';
    sanitizedAddressParams['city'] =
      addressParams.context?.find((c: any) => c.id.startsWith('place'))?.text ||
      addressParams.city ||
      '';
    sanitizedAddressParams['zip'] =
      addressParams.context?.find((c: any) => c.id.startsWith('postcode'))?.text ||
      addressParams.zip ||
      '';
    sanitizedAddressParams['region_code'] =
      addressParams.context
        ?.find((c: any) => c.id.startsWith('region'))
        ?.short_code?.split('-')[1] ||
      addressParams.region_code ||
      '';
    sanitizedAddressParams['region'] =
      addressParams.context?.find((c: any) => c.id.startsWith('region'))?.text ||
      addressParams.region ||
      '';
    sanitizedAddressParams['country_code'] =
      addressParams.context
        ?.find((c: any) => c.id.startsWith('country'))
        ?.short_code?.toUpperCase() ||
      addressParams.country_code ||
      '';
    sanitizedAddressParams['country'] =
      addressParams.context?.find((c: any) => c.id.startsWith('country'))?.text ||
      addressParams.country ||
      '';
    sanitizedAddressParams['coordinates'] = addressParams.geometry?.coordinates;
  }
  return sanitizedAddressParams;
}

export const addressService = {
  create,
  update,
  destroy,
};
