// Utils
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { gidToId } from '@/utils/utils';

// Styles
import '../../../src/style/index.scss';
import 'react-datepicker/dist/react-datepicker.css';

// Components

import Button from '../Buttons/Button';
import IconHolder from '../IconHolder/IconHolder';

// Icons & Images
import { ReactComponent as DefaultPersonFrame } from '@/assets/icons/default-person-frame.svg';

// Contexts
import { HeaderContext } from '@/contexts/headerContext';

type CustomStepProps = {
  goToStep?: any;
  hashValue?: any;
  personInfo?: any;
  isHousehold?: boolean;
  associatedHousehold?: object;
  associatedOrganization?: object;
};

const AssociatePersonSuccess = ({
  hashValue,
  goToStep,
  personInfo,
  isHousehold,
  associatedHousehold,
  associatedOrganization,
}: CustomStepProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');
  const headerContext = React.useContext(HeaderContext);

  React.useEffect(() => {
    if (hashValue == 2) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          title: t('subjects.addHouseholdWizard.association'),
          hamburgerMenu: false,
          homePage: false,
          closeButton: false,
          canEdit: false,
          canGoBack: false,
        },
      });
    }
  }, []);

  // Code here will run after *every* render
  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);

  function successTitle() {
    let title;
    if (isHousehold) {
      title =
        t('subjects.associateWizard.household.title') +
        associatedHousehold?.name +
        t('subjects.associateWizard.household.personAssociate') +
        personInfo?.name;
    } else {
      title =
        t('subjects.associateWizard.organization.title') +
        associatedOrganization?.name +
        t('subjects.associateWizard.organization.personAssociate') +
        personInfo?.name;
    }

    return title;
  }

  return (
    <div className="flex items-center justify-between flex-col pt-20 h-full ">
      <div className="flex justify-center flex-col w-full h-full">
        <div className="flex flex-col gap-5 justify-center w-full">
          <div className="flex justify-center mt-auto gap-5 flex-col text-center w-full">
            <IconHolder svgIcon={<DefaultPersonFrame />} header={successTitle()} />
          </div>
        </div>
      </div>
      <div className="w-full translate-y-32 flex flex-col gap-3 pb-6">
        <Button
          type="button"
          onClick={
            isHousehold
              ? // use gid and get id from it to update
                () => navigate(`/contacts/households/${gidToId(associatedHousehold?.gid)}/summary`)
              : () =>
                  navigate(
                    `/contacts/organizations/${gidToId(associatedOrganization?.gid)}/summary`,
                  )
          }
          text={
            isHousehold
              ? t('subjects.associateWizard.household.see')
              : t('subjects.associateWizard.organization.see')
          }
          variant="primary"
          size="large"
        />
      </div>
    </div>
  );
};

export default AssociatePersonSuccess;
