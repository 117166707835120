import React from 'react';
import { AccountContext } from '../contexts/accountContext';
import { slugify } from '../utils/utils';

export const useAccountId = () => {
  const {
    state: {
      selected: { id },
    },
  } = React.useContext(AccountContext);

  return id;
};

export const useSelectedAccount = () => {
  const {
    state: {
      selected: { id: accountId, name: accountName },
    },
  } = React.useContext(AccountContext);

  return { accountId, accountName };
};

export const doIHaveAccessToAccount = () => {
  const {
    state: { accounts },
  } = React.useContext(AccountContext);

  const func = (accountId: number) => !!accounts.find(account => account.id === accountId);

  return func;
};

export const useAccount = () => {
  const {
    state: { accounts },
  } = React.useContext(AccountContext);

  const func = (accountId: number) => accounts.find(account => account.id === accountId);

  return func;
};

export const useSlugAccount = () => {
  const {
    state: { accounts, selected },
  } = React.useContext(AccountContext);

  const getAccountId = (pathname: string, accountId?: string) =>
    accounts.find(account => pathname.includes(slugify(account.name)))?.id ||
    (accountId && !isNaN(parseInt(accountId)) ? parseInt(accountId) : selected.id);

  const getAccountName = (pathname: string, accountId?: string) =>
    accounts.find(account => pathname.includes(slugify(account.name)))?.name ||
    (accountId && !isNaN(parseInt(accountId))
      ? accounts.find(account => account.id === parseInt(accountId))?.name
      : selected.name);

  return { getAccountId, getAccountName };
};
