// Utils
import React, { ReactNode, createContext, useState } from 'react';

// Types & Interfaces
type PersonInfoContextType = {
  personInfo: any;
  setPersonInfo: React.Dispatch<React.SetStateAction<any>>;
};
type PersonInfoProviderProps = {
  children: ReactNode;
};

const initialPersonInfo: PersonInfoContextType = {
  personInfo: undefined,
  setPersonInfo: () => {},
};

export const PersonInfoContext = createContext<PersonInfoContextType>(initialPersonInfo);

const PersonInfoProvider = ({ children }: PersonInfoProviderProps) => {
  const [personInfo, setPersonInfo] = useState<any>();

  return (
    <PersonInfoContext.Provider value={{ personInfo, setPersonInfo }}>
      {children}
    </PersonInfoContext.Provider>
  );
};

export default PersonInfoProvider;
