// Utils
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { classNames, subjectLink } from '../../utils/utils';
import { wrapLongText } from '@/utils/wrapper';

// Icons & Images
import ColinorColoredLogo from '../../assets/images/colinor-logo-couleur-sansphrase.svg';
import ColinorWhiteLogo from '../../assets/images/colinor-logo-blanc-sansphrase.svg';
import ColinorWhiteIcon from '../../assets/images/colinor-icon-blanc.svg';
import ColinorColoredIcon from '../../assets/images/colinor-icon-couleur.svg';
import ToucanColoredLogo from '../../assets/images/toucan-logo-couleur.png';
import ToucanWhiteLogo from '../../assets/images/toucan-logo-blanc.png';
import ToucanWhiteIcon from '../../assets/images/toucan-icon-blanc.png';
import ToucanColoredIcon from '../../assets/images/toucan-icon-couleur.png';
import { ReactComponent as QRCodeIcon } from '@/assets/icons/scan.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane } from '@fortawesome/free-solid-svg-icons';
import { faPen, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

// Components
import Navbar from '../Navbar/Navbar';
import FullScreenSearch from '../FullScreenSearch/FullScreenSearch';
import LangButton from '../LangButton/LangButton';
import FullScreenSearchGlobal from '../FullScreenSearchGlobal/FullScreenSearchGlobal';
import HamBurgerButton from './HamBurgerButton';

// Contexts
import { useModeConfig, useThemeConfig } from '../../contexts/configContext';
import { useOnlineStatus } from '../../contexts/offlineContext';
import { UserContext } from '../../contexts/userContext';
import { HeaderContext } from '@/contexts/headerContext';

// Types
import { SubjectType } from '../../types/subject/subject';
import { ModeType, ThemeType } from '../../types/config/config';
import { GlobalSearchRoutesType } from '@/types';

// API
import { fetchPeoplesByName } from '../../utils/api/people';
import { fetchOrganizationsByName } from '../../utils/api/organizations';

const Header = () => {
  const mode = useModeConfig();
  const headerContext = React.useContext(HeaderContext).state;
  let {
    title,
    inverted,
    canEdit,
    canGoBack,
    canSearch,
    dashboard,
    editPath,
    isEditAutorized,
    hamburgerMenu,
    homePage,
    closeButton,
    closeUrl,
  } = headerContext;
  const theme = useThemeConfig();
  const {
    loggedIn,
    user: { name: userName },
  } = React.useContext(UserContext).state;
  const { t } = useTranslation('dashboard');
  const { state, pathname }: any = useLocation();
  const navigate = useNavigate();
  const isOnline = useOnlineStatus();

  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const colinorLogo = inverted
    ? !title
      ? ColinorWhiteLogo
      : ColinorWhiteIcon
    : !title
    ? ColinorColoredLogo
    : ColinorColoredIcon;
  const toucanLogo = inverted
    ? !title
      ? ToucanWhiteLogo
      : ToucanWhiteIcon
    : !title
    ? ToucanColoredLogo
    : ToucanColoredIcon;

  const openMenu = () => {
    if (!menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    setMenuOpen(!menuOpen);
  };

  return (
    <React.Fragment>
      {!isOnline && (
        <div className="flex gap-3.5 justify-center items-center w-full text-white h-[40px] bg-slate-700">
          <FontAwesomeIcon icon={faPlane} size="lg" />
          {t('status.offline')}
        </div>
      )}
      <header className={classNames('w-full', inverted ? 'bg-primaryColor' : 'bg-transparent')}>
        <div className="flex sm:flex-row h-[56px] justify-between p-3.5 px-5 gap-3.5 items-center">
          <div className="flex items-center text-2xl">
            {!canGoBack ? (
              <>
                {homePage && (
                  <Link to="/" title="accueil">
                    <img
                      src={theme === ThemeType.colinor ? colinorLogo : toucanLogo}
                      alt="logo"
                      className="h-[24px]"
                    />
                  </Link>
                )}
              </>
            ) : (
              <a
                onClick={() => (state?.goDashboard ? navigate('/dashboard') : navigate(-1))}
                className="flex items-center p-1">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{ color: '#4b5462', fontSize: '23px' }}
                  className="mb-1"
                />
              </a>
            )}
          </div>

          {title && (
            <div className="flex-1">
              {dashboard ? (
                <h1 className={classNames('-ml-2', inverted ? 'text-white' : '')}>
                  <span className="font-normal">{title} </span>
                  {wrapLongText(userName?.split(' ')?.[0] || userName || '', canSearch ? 9 : 20)}
                </h1>
              ) : (
                <h1 className={classNames('text-center', inverted ? 'text-white' : '')}>{title}</h1>
              )}
            </div>
          )}
          <div className="flex items-center burger-container gap-3.5">
            {!loggedIn ? (
              <div>
                <LangButton type="button" />
              </div>
            ) : dashboard ? (
              <>
                {canSearch && mode !== ModeType.logistic && (
                  <Link to="/dashboard/scan">
                    <QRCodeIcon className="stroke-primaryColor" />
                  </Link>
                )}
                {canSearch && mode !== ModeType.logistic && (
                  <FullScreenSearch
                    inputClassName="rounded-lg px-2 py-3 w-full truncate"
                    placeholder={t('placeHolders.headerSearch')}
                    fetchCallback={[
                      { name: t('person.title'), callback: fetchPeoplesByName },
                      { name: t('organization.title'), callback: fetchOrganizationsByName },
                    ]}
                    callback={(result: SubjectType) =>
                      navigate(`/dashboard/${subjectLink(result.gid)}`)
                    }
                  />
                )}
                <HamBurgerButton openMenu={openMenu} menuOpen={menuOpen} inverted={inverted} />
              </>
            ) : canEdit && editPath && isEditAutorized ? (
              <button
                type="button"
                className="flex justify-center items-center"
                onClick={() => navigate(editPath)}>
                <FontAwesomeIcon
                  icon={faPen}
                  style={{ color: '#4b5462', fontSize: '23px' }}
                  className="mb-1"
                />
              </button>
            ) : (
              <>
                {hamburgerMenu && (
                  <HamBurgerButton openMenu={openMenu} menuOpen={menuOpen} inverted={inverted} />
                )}
                {closeButton && (
                  <Link to={`${closeUrl}`} title="toProfile">
                    <CloseIcon className="stroke-black" />
                  </Link>
                )}
              </>
            )}
          </div>
        </div>
        {loggedIn &&
          mode !== ModeType.transport &&
          (pathname === GlobalSearchRoutesType.dashboard ||
            pathname === GlobalSearchRoutesType.events ||
            pathname === GlobalSearchRoutesType.notes) && <FullScreenSearchGlobal />}
        <Navbar opened={menuOpen} setOpened={openMenu} />
      </header>
    </React.Fragment>
  );
};

export default Header;
