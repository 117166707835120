type ProgressBarProps = {
  hashValue: number;
  percentage: number;
  totalSteps: number;
};

const ProgressBarWizard = ({ hashValue, percentage, totalSteps }: ProgressBarProps) => {
  return (
    <div>
      <div className="w-full bg-rose-100 h-2.5 dark:bg-rose-100">
        {hashValue >= totalSteps ? (
          <div
            className="bg-orange-500 h-2.5 "
            style={{ width: `${hashValue * percentage}%` }}></div>
        ) : (
          <div
            className="bg-orange-500 h-2.5 rounded-r-lg"
            style={{ width: `${hashValue * percentage}%` }}></div> // FIXME:  no hardcoded value. to be discussed: number_of_steps / 100
        )}
      </div>
    </div>
  );
};

export default ProgressBarWizard;
