import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en';
import fr from './fr';

export const defaultNS = 'common';
export const resources = {
  fr,
  en,
} as const;

const I18N_LANGUAGE = '@toucan/lang';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: localStorage.getItem(I18N_LANGUAGE) || 'fr',

    ns: ['common'],
    defaultNS,
    resources,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      order: ['localStorage', 'navigator'],
      lookupQuerystring: 'lng',
      lookupLocalStorage: I18N_LANGUAGE,
      caches: ['localStorage'],
    },
  });

export default i18n;
