import { AxiosResponse } from 'axios';
import api from './api';

export const fetchAddress = async (account_id: number, address_id: number) => {
  if (!address_id) throw Error('No address id');
  const rawResponse = await api.get(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/addresses/${address_id}.json`,
  );
  return rawResponse.data;
};

export const fetchCachedAddress = async (account_id: number, address_id: number) => {
  const rawResponse = caches
    .match(`${import.meta.env.VITE_API}/${account_id}/api/v1/addresses/${address_id}.json`)
    .then(response => {
      if (!response) throw Error('No data');
      return response.json().then(data => data);
    });
  return rawResponse;
};


export const createAddress = async (
  account_id: number,
  body: object,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  const rawResponse = await api.post(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/addresses.json`,
    body,
  );
  return rawResponse;
};

export const editAddress = async (
  addressId: number,
  account_id: number,
  body: object,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  if (!addressId) throw Error('No address id');
  const rawResponse = await api.patch(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/addresses/${addressId}.json`,
    body,
  );
  return rawResponse;
};

export const deleteAddress = async (
  addressId: number,
  account_id: number,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  if (!addressId) throw Error('No address id');
  const rawResponse = await api.delete(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/addresses/${addressId}.json`,
  );
  return rawResponse;
};
