import { useContext } from 'react'
import { UserContext } from '@/contexts/userContext';
import { useNavigate } from 'react-router-dom';
import { UserActionTypes } from '@/types';
import useCookie from './useCookie';


export const useLogout = () => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [origin, updateOrigin] = useCookie('origin');

  const logoutHandler = (pathName : string) => {
    userContext.dispatch({ type: UserActionTypes.Clear });
    updateOrigin('', -1);
    navigate(origin || pathName);
  }

  return {
    logoutHandler
  }
};
