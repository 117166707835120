import { AxiosResponse } from 'axios';
import api from './api';
import { getParamString } from '../utils';
import { parse_link_header } from '../hashUtils';
import { objectToFormdata } from '../..//utils/form';

export const fetchPeoplesByName = async (account_id: number, query: string) => {
  if (!query) return [];
  const rawResponse = await api.get(
    `${
      import.meta.env.VITE_API
    }/${account_id}/api/v1/people.json?q[first_name_or_last_name_or_import_id_cont]=${query}`,
  );
  return rawResponse.data;
};

export const fetchPeoples = async ({
  account_id,
  page = 1,
  limit = 5,
  query,
  category,
}: {
  account_id: number;
  page?: number;
  limit?: number;
  query?: string;
  category?: string;
}) => {
  const params = {
    ...(query ? { 'q[first_name_or_last_name_or_import_id_cont]': query } : {}),
    ...(category ? { 'q[category_id_eq]': category } : {}),
  };
  const paramString = getParamString(params);

  const rawResponse = await api
    .get(
      `${import.meta.env.VITE_API}/${account_id}/api/v1/people.json?page=${page}&per_page=${limit}${
        paramString.length ? '&' : ''
      }${paramString}`,
    )
    .then(response => {
      const properties = {
        links: parse_link_header(response.headers['link']),
        total: parseInt(response.headers['x-total'] || '0'),
        page: parseInt(response.headers['x-page'] || '1') || page,
        per_page: parseInt(response.headers['x-per-page'] || '0'),
      };
      return { properties: properties, data: response.data };
    });
  return rawResponse;
};

export const fetchCachedPeoples = async (account_id: number, query: string) => {
  const rawResponse = caches
    .match(
      `${
        import.meta.env.VITE_API
      }/${account_id}/api/v1/people.json?q[first_name_or_last_name_or_import_id_cont]=${query}`,
    )
    .then(response => {
      if (!response) throw Error('No data');
      return response.json().then(data => data);
    });
  return rawResponse;
};

export const fetchPeople = async (account_id: number, id: string | number) => {
  if (!id) throw Error('No people id');
  const rawResponse = await api.get(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/people/${id}.json`,
  );
  return rawResponse.data;
};

export const createPeople = async (
  account_id: number,
  body: object,
): Promise<AxiosResponse<any, any>> => {
  const newBody = objectToFormdata(body);

  if (!account_id) throw Error('No account id');
  const rawResponse = await api.post(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/people.json`,
    newBody,
  );
  return rawResponse;
};

export const updatePerson = async (
  account_id: number,
  personId: number | string,
  body: object,
): Promise<AxiosResponse<any, any>> => {
  const newBody = objectToFormdata(body);

  if (!account_id) throw Error('No account id');
  if (!personId) throw Error('No account id');
  const rawResponse = await api.put(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/people/${personId}.json`,
    newBody,
  );
  return rawResponse;
};

export const editPeople = async (
  account_id: number,
  peopleId: number | string,
  body: object,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  const rawResponse = await api.put(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/people/${peopleId}.json`,
    body,
  );
  return rawResponse;
};

export const deletePerson = async (
  account_id: number,
  personId: number,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  if (!personId) throw Error('No person id');
  const rawResponse = await api.delete(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/people/${personId}.json`,
  );
  return rawResponse;
};

export const fetchPersonCategories = async (account_id: number) => {
  if (!account_id) throw Error('No account id');
  const rawResponse = await api.get(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/person_categories.json`,
  );
  return rawResponse;
};
