import React from 'react';
import { useRefreshUser } from '@/hooks/useRefreshUser';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useModeConfig } from '@/contexts/configContext';
import { LogisticModeType, ModeType, SubjectType } from '@/types';
import { subjectLink } from '@/utils/utils';
import {
  fetchExpeditionsBySupply,
  fetchPreparationsBySupply,
  fetchReceptionsBySupply,
} from '@/utils/api/warehouses';
import FullScreenSearch from '../FullScreenSearch/FullScreenSearch';

const FullScreenSearchGlobal = () => {
  const { t } = useTranslation('dashboard');
  const mode = useModeConfig();
  const navigate = useNavigate();
  const { accountContacts } = useRefreshUser();

  return (
    <div className="p-5">
      {mode === ModeType.collaboration && (
        <FullScreenSearch
          theme="fullwidth"
          fetchCallback={accountContacts}
          callback={(result: SubjectType) =>
            navigate(`/contacts/${subjectLink(result.gid)}/summary`)
          }
          placeholder={t('placeHolders.queryContact')}
        />
      )}
      {mode === ModeType.logistic && (
        <FullScreenSearch
          theme="fullwidth"
          fetchCallback={[
            {
              name: t('receptions.title'),
              callback: fetchReceptionsBySupply,
              type: LogisticModeType.incoming,
              prepared: false,
            },
            {
              name: t('preparations.title'),
              callback: fetchPreparationsBySupply,
              type: LogisticModeType.outgoing,
              prepared: false,
            },
            {
              name: t('expeditions.title'),
              callback: fetchExpeditionsBySupply,
              type: LogisticModeType.outgoing,
              prepared: true,
            },
          ]}
          placeholder={t('placeHolders.queryWarehouse')}
        />
      )}
    </div>
  );
};

export default FullScreenSearchGlobal;
