import { useField } from 'formik';

import { FormFieldsType } from '@/types';
import { classNames } from '@/utils/utils';

const TextArea = ({ containerClasses, rows, ...props }: FormFieldsType & { rows?: number }) => {
  const [field, meta] = useField(props);

  return (
    <div
      className={classNames(
        'flex flex-col items-start text-sm',
        containerClasses ? containerClasses : '',
      )}>
      <div className="relative w-full">
        <textarea
          className="w-full px-3 py-2 bg-white focus:outline-none focus:ring-0 focus:text-primaryColor focus:border-primaryColor border border-strokeColor rounded-lg placeholder-gray-400 text-bodyTextColor"
          rows={rows || 6}
          {...field}
          {...props}
          required={false}
        />
      </div>
      {meta.error ? (
        typeof meta.error === 'string' ? (
          <div className="text-red-500 ml-1 text-xs">{meta.error}</div>
        ) : (
          Object.values(meta.error).map((error: any, i) => (
            <div key={i} className="text-red-500 ml-1 text-xs">
              {error}
            </div>
          ))
        )
      ) : null}
    </div>
  );
};
export default TextArea;
