const scopes = ['read'];

export const getAuthorizeHref = (origin?: string): string => {
  const authEndpoint = import.meta.env.VITE_OAUTH_ENDPOINT;
  const clientId = import.meta.env.VITE_TOUCAN_CLIENT_ID;
  const redirectUri = origin || window.location.origin;
  return `${authEndpoint}/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
    '%20',
  )}&response_type=code`;
};
