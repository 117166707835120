import { AxiosResponse } from 'axios';
import api from './api';

export const fetchUser = async () => {
  const rawResponse = await api.get(`${import.meta.env.VITE_API}/api/v1/me`);
  return rawResponse.data;
};

export const updateUser = async (
  userId: number,
  body: object,
): Promise<AxiosResponse<any, any>> => {
  if (!userId) throw Error('No user id');
  const rawResponse = await api.put(
    `${import.meta.env.VITE_API}/api/v1/users/${userId}.json`,
    body,
  );
  return rawResponse;
};

export const getCurrentAccount = async (accountId: number) => {
  const rawResponse = await api.get(`${import.meta.env.VITE_API}/api/v1/accounts/${accountId}.json`);
  const { data: currentAccount } = rawResponse;
  const { user_membership: { default_module }} = currentAccount;

  return {
    currentAccount,
    default_module
  }
};