import { AccountType, AccountState } from '..';
import { LogisticModeType, TransportModeType } from '../config/config';

export enum AccountActionTypes {
  Hydrate = 'HYDRATE',
  Clear = 'CLEAR',
  SetAccount = 'SET_ACCOUNT',
  SetAccounts = 'SET_ACCOUNTS',
  SetTransportView = 'SET_TRANSPORT_VIEW',
  SetLogisticView = 'SET_LOGISTIC_VIEW',
}

export type AccountPayloadAction =
  | {
      type: AccountActionTypes.Hydrate;
      payload: AccountState;
    }
  | {
      type: AccountActionTypes.Clear;
    }
  | {
      type: AccountActionTypes.SetAccount;
      payload: AccountType;
    }
  | {
      type: AccountActionTypes.SetAccounts;
      payload: Array<AccountType>;
    }
  | {
      type: AccountActionTypes.SetTransportView;
      payload: TransportModeType;
    }
  | {
      type: AccountActionTypes.SetLogisticView;
      payload: LogisticModeType;
    };
