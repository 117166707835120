// Utils
import { classNames } from '@/utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Icons & Images
import { faAsterisk } from '@fortawesome/pro-regular-svg-icons';

const Property = ({
  title,
  content,
  hint,
  haveGap,
  bold,
  whiteTitle,
  additionalClasses,
  icon,
  required,
}: {
  title?: string;
  content: JSX.Element;
  hint?: string;
  bold?: boolean;
  haveGap?: boolean;
  whiteTitle?: boolean;
  additionalClasses?: string;
  icon?: any;
  required?: boolean;
}) => (
  <div className={`${additionalClasses ? additionalClasses : ''}${haveGap ? '' : 'mt-3'}`}>
    {title && (
      <div
        className={classNames(whiteTitle ? 'text-white' : 'text-titleTextColor', bold ? 'h3' : '')}>
        {icon ? (
          <div className="flex flex-row gap-2">
            {icon} {title}
          </div>
        ) : (
          <div>
            {required ? (
              <div className="flex flex-row">
                {title}
                <div className="-mt-2">
                  <FontAwesomeIcon
                    icon={faAsterisk}
                    size="xl"
                    style={{ color: '#E50F10', fontSize: '10px' }}
                    className="ml-1  "
                  />
                </div>
              </div>
            ) : (
              <>{title}</>
            )}
          </div>
        )}
      </div>
    )}
    <div className="mt-1">{content}</div>
    {hint && <p className="text-gray-500 mt-2">{hint}</p>}
  </div>
);

export default Property;
