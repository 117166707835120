import React, { PropsWithChildren } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';

const OnlineStatusContext = React.createContext(true);

export const OnlineStatusProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('common');
  const [onlineStatus, setOnlineStatus] = React.useState<boolean>(true);

  const notifyOffline = () => {
    toast.clearWaitingQueue();
    setOnlineStatus(false);
    toast.warn(t('toast.offline'), {
      position: 'top-center',
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const notifyOnline = () => {
    toast.clearWaitingQueue();
    setOnlineStatus(true);
    toast.success(t('toast.online'), {
      position: 'top-center',
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  React.useEffect(() => {
    window.addEventListener('offline', notifyOffline);
    window.addEventListener('online', notifyOnline);

    return () => {
      window.removeEventListener('offline', notifyOffline);
      window.removeEventListener('online', notifyOnline);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>{children}</OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => {
  const store = React.useContext(OnlineStatusContext);
  return store;
};
