import { SubjectType, SubjectContextState as SubjectState, SubjectAddressType } from '..';

export enum SubjectActionTypes {
  SetSubjects = 'SET_SUBJECTS',
  SetSubject = 'SET_SUBJECT',
  SetSearchHistory = 'SET_SEARCH_HISTORY',
  SetSearchHistorySubjectAddress = 'SET_SEARCH_HISTORY_SUBJECT_ADDRESS',
  RemoveSubject = 'REMOVE_SUBJECT',
  Hydrate = 'HYDRATE',
  Clear = 'CLEAR',
  SetAccount = 'SET_ACCOUNT',
}

export type SubjectPayloadAction =
  | {
      type: SubjectActionTypes.Hydrate;
      payload: SubjectState;
    }
  | {
      type: SubjectActionTypes.SetSubjects;
      payload: SubjectType[];
    }
  | {
      type: SubjectActionTypes.SetSearchHistory;
      payload: SubjectType;
    }
  | {
      type: SubjectActionTypes.SetSubject;
      payload: SubjectType;
    }
  | {
      type: SubjectActionTypes.SetSearchHistorySubjectAddress;
      payload: SubjectAddressType;
    }
  | {
      type: SubjectActionTypes.RemoveSubject;
      payload: SubjectState;
    }
  | {
      type: SubjectActionTypes.Clear;
    }
  | {
      type: SubjectActionTypes.SetAccount;
      payload: SubjectType;
    };
