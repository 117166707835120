// Utils
import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

// Styles
import '../../src/style/index.scss';
import 'react-datepicker/dist/react-datepicker.css';

// Contexts
import { AccountContext } from '../contexts/accountContext';
import { PersonInfoContext } from '@/contexts/personContext';

// Components
import AddHouseholdWizard from '../components/AddHouseholdWizard/AddHouseholdWizard';
import ProgressBarWizard from '../components/ProgressBarWizard/ProgressBarWizard';

// Services
import { householdService } from '../services';

// Types
import { AddressType } from '../types/localization/localization';

// API
import { fetchPeople } from '@/utils/api/people';
import { fetchHouseholdCategories } from '@/utils/api/households';

const AddHousehold = () => {
  const accountContext = React.useContext(AccountContext);
  const navigate = useNavigate();
  const { hash } = useLocation();
  const location = useLocation();
  const [hashValue, setHashValue] = useState(parseInt(hash.slice(5))); // FIXME: Ouch
  const { personInfo } = useContext(PersonInfoContext);
  const [personContactAddress, setPersonContactAddress] = useState();
  const [associatedHousehold, setAssociatedHousehold] = useState();
  const [isCreateHousehold, setIsCreateHousehold] = useState<boolean>(false);

  useEffect(() => {
    setHashValue(parseInt(hash.slice(5)));
  }, [location.hash]);
  const { t } = useTranslation('dashboard');
  const {
    selected: { id: accountId },
  } = accountContext.state;
  const { pathname } = useLocation();

  useEffect(() => {
    setHashValue(parseInt(hash.slice(5))); // FIXME: Ouch

    if (pathname === '/contacts/households/new') {
      navigate('/contacts/households/new/#step1');
      if (window.location) {
        window.location.reload();
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (personInfo) {
      fetchPeople(accountId, personInfo.id).then(data => {
        setPersonContactAddress(data);
      });
    }
  }, []);

  const fetchCategories = async () => {
    const response = await fetchHouseholdCategories(accountId).then(response => [
      ...response.data.map((category: any) => ({
        value: category.id,
        label: category.name,
      })),
    ]);
    return response;
  };

  const initialValues = {
    name: '',
    category_id: -1,
    main: true,
    collaborations: [],
    accepts_marketing: false,
    data_consent: false,
    contact_informations:
      personContactAddress &&
      personContactAddress.contact_informations &&
      personContactAddress.contact_informations.length > 0
        ? personContactAddress.contact_informations.map((contactInfo: any) => ({
            ...contactInfo,
          }))
        : [
            {
              info: '',
              type: 'Email',
              label: '',
              main: true,
            },
          ],
    addresses: personInfo
      ? personInfo.addresses
      : [
          {
            address: undefined as unknown as AddressType,
            name: '',
            street2: '',
          },
        ],
    tag_list: [],
  };
  const validationSchema = yup.object().shape({
    name: personInfo
      ? isCreateHousehold
        ? yup.string().required(t('toast.householdMissing'))
        : yup.string().optional()
      : yup.string().required(t('toast.householdMissing')),
    category_id: personInfo
      ? isCreateHousehold
        ? yup.number().required(t('toast.missingCategory')).positive(t('toast.missingCategory'))
        : yup.number().optional()
      : yup.number().required(t('toast.missingCategory')).positive(t('toast.missingCategory')),
    contact_informations: yup.array().of(
      yup.object().shape({
        info: yup.string().test({
          name: 'emailValidation',
          message: `${t('errors.email')}`,
          test: function (value) {
            if (this.parent.type === 'Email') {
              return yup.string().email().isValidSync(value);
            }
            return true;
          },
        }),
      }),
    ),
    collaborations: yup.array().of(
      yup.object().shape({
        name: yup.string(),
        title: yup.string(),
        main: yup.boolean(),
      }),
    ),
  });

  function onSubmit(fields: any, { setStatus, setSubmitting }: FormikHelpers<Values>) {
    setStatus();
    if (personInfo) {
      if (isCreateHousehold) {
        let householdCopy = {
          category_id: fields.category_id,
          name: fields.name,
          main: fields.main,
          title: fields.title,
        };
        createaAndAssociate(accountId, personInfo?.gid, householdCopy, setSubmitting);
      } else {
        associatePerson(accountId, personInfo?.gid, fields, setSubmitting);
      }
    } else {
      createHousehold(accountId, fields, setSubmitting);
    }
  }

  function onSuccess(data: any, setSubmitting: any) {
    setSubmitting(false);
    if (personInfo) {
      setAssociatedHousehold(data);
      navigate(`/contacts/households/new/#step2`);
    } else {
      navigate(`/contacts/households/${data.id}/summary`);
    }
  }

  function createHousehold(accountId: number, fields: any, setSubmitting: any) {
    let shallowFieldsCopy = { ...fields };
    if (shallowFieldsCopy.data_consent === true) {
      shallowFieldsCopy.data_consent = 'accepted';
    } else {
      shallowFieldsCopy.data_consent = 'rejected';
    }
    householdService.create(accountId, shallowFieldsCopy, onSuccess, setSubmitting);
    toast.success(t(`toast.householdAddSuccess`));
  }

  function associatePerson(accountId: number, subjectGid: any, fields: any, setSubmitting: any) {
    householdService.associate(accountId, subjectGid, fields, onSuccess, setSubmitting);
  }

  function createaAndAssociate(
    accountId: number,
    subjectGid: any,
    fields: any,
    setSubmitting: any,
  ) {
    householdService.createAndAssociate(accountId, subjectGid, fields, onSuccess, setSubmitting);
  }

  return (
    <>
      <ProgressBarWizard
        hashValue={hashValue}
        percentage={personInfo ? 50 : 16.66}
        totalSteps={personInfo ? 2 : 6}
      />
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        {({
          setFieldValue,
          values,
          errors,
          touched,
          isSubmitting,
        }: FormikProps<typeof initialValues>) => {
          return (
            <Form className="flex flex-col gap-5 h-[80vh] ">
              <AddHouseholdWizard
                setFieldValue={setFieldValue}
                fetchCategories={fetchCategories}
                errors={errors}
                touched={touched}
                isSubmitting={isSubmitting}
                hashValue={hashValue}
                values={values}
                accountId={accountId}
                associatedHousehold={associatedHousehold}
                setIsCreateHousehold={setIsCreateHousehold}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddHousehold;
