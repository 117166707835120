import React from 'react';

const FullScreenSkeleton = () => (
  <div className="mt-4 px-4 animate-pulse space-y-6 w-full">
    {Array(2)
      .fill(0)
      .map((_, i) => (
        <div key={i}>
          <div className="h-3 bg-gray-200 rounded-full w-48 mb-4"></div>
          {Array(4)
            .fill(0)
            .map((_, i: number) => (
              <div key={i} className="h-2 bg-gray-200 rounded-full w-full mb-4"></div>
            ))}
        </div>
      ))}
  </div>
);

export default FullScreenSkeleton;
