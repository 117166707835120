import React from 'react';

const LoginHookSkeleton = () => (
  <React.Fragment>
    <div className="animate-pulse">
      <div className="h-2.5 bg-gray-300 rounded-full w-48 mb-4"></div>
      <div className="h-2 bg-gray-200 rounded-full max-w-[360px] mb-2.5"></div>
      <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
      <div className="h-2 bg-gray-200 rounded-full max-w-[330px] mb-2.5"></div>
      <div className="h-2 bg-gray-200 rounded-full max-w-[300px] mb-2.5"></div>
      <div className="h-2 bg-gray-200 rounded-full max-w-[360px]"></div>
      <span className="sr-only">Loading...</span>
    </div>
    <div className="flex flex-col gap-6">
      {Array(4)
        .fill(0)
        .map((_, i) => (
          <div key={i} className="space-y-2.5 animate-pulse max-w-lg">
            <div className="flex items-center space-x-2 w-full">
              <div className="h-2.5 bg-gray-200 rounded-full w-32"></div>
              <div className="h-2.5 bg-gray-300 rounded-full w-24"></div>
              <div className="h-2.5 bg-gray-300 rounded-full w-full"></div>
            </div>
            <div className="flex items-center w-full space-x-2 max-w-[480px]">
              <div className="h-2.5 bg-gray-200 rounded-full w-full"></div>
              <div className="h-2.5 bg-gray-300 rounded-full w-full"></div>
              <div className="h-2.5 bg-gray-300 rounded-full w-24"></div>
            </div>
            <div className="flex items-center w-full space-x-2 max-w-[400px]">
              <div className="h-2.5 bg-gray-300 rounded-full w-full"></div>
              <div className="h-2.5 bg-gray-200 rounded-full w-80"></div>
              <div className="h-2.5 bg-gray-300 rounded-full w-full"></div>
            </div>
            <div className="flex items-center w-full space-x-2 max-w-[480px]">
              <div className="h-2.5 bg-gray-200 rounded-full w-full"></div>
              <div className="h-2.5 bg-gray-300 rounded-full w-full"></div>
              <div className="h-2.5 bg-gray-300 rounded-full w-24"></div>
            </div>
            <div className="flex items-center w-full space-x-2 max-w-[440px]">
              <div className="h-2.5 bg-gray-300 rounded-full w-32"></div>
              <div className="h-2.5 bg-gray-300 rounded-full w-24"></div>
              <div className="h-2.5 bg-gray-200 rounded-full w-full"></div>
            </div>
            <div className="flex items-center w-full space-x-2 max-w-[360px]">
              <div className="h-2.5 bg-gray-300 rounded-full w-full"></div>
              <div className="h-2.5 bg-gray-200 rounded-full w-80"></div>
              <div className="h-2.5 bg-gray-300 rounded-full w-full"></div>
            </div>
            <span className="sr-only">Loading...</span>
          </div>
        ))}
    </div>
  </React.Fragment>
);
export default LoginHookSkeleton;
