import { parse_link_header } from "../hashUtils";
import api from "./api";

export const fetchTags = async (account_id: number, page = 1, limit = 50) => {
    const rawResponse = await api
      .get(
        `${
          import.meta.env.VITE_API
        }/${account_id}/api/v1/tags.json?page=${page}&per_page=${limit}`,
      )
      .then(response => {
        const properties = {
          links: parse_link_header(response.headers['link']),
          total: parseInt(response.headers['x-total'] || '0'),
          page: parseInt(response.headers['x-page'] || '1') || page,
          per_page: parseInt(response.headers['x-per-page'] || '0'),
        };
        return { properties: properties, data: response.data };
      });
    return rawResponse;
  };