// API
import { createHousehold } from '../utils/api/households';
import { createCollaboration } from '../utils/api/subjects';
import { createPeople } from '../utils/api/people';
import { createContactInformation } from '@/utils/api/contact_informations';
import { addressService } from './address.service';

function create(accountId: number, params: any, onSuccessCallback: any, setSubmitting: any) {
  const collaborations = params.collaborations;
  createHousehold(accountId, sanitizeHouseholdParams(params))
    .then(householdResponse => {
      const dependantHouseholdRelationActions = [];
      if (collaborations.length === 0) {
        onSuccessCallback(householdResponse.data, setSubmitting);
      } else {
        // Collaborations with collaborator_gid set (no person to be created)
        const withPersonCollaborations = collaborations.filter((row: any) => row.collaborator_gid);
        withPersonCollaborations.forEach(collaboration => {
          dependantHouseholdRelationActions.push(
            createCollaboration(
              accountId,
              householdResponse.data.gid,
              sanitizeCollaborationParams(collaboration),
            ),
          );
        });

        // Collaborations without collaborator_gid set (person to be created)
        const withoutPersonCollaborations = collaborations.filter(
          (row: any) => !row.collaborator_gid,
        );
        withoutPersonCollaborations.forEach(collaboration => {
          // 1. Create a new person
          dependantHouseholdRelationActions.push(
            createNewPersonCollaboration(
              accountId,
              householdResponse.data.gid,
              sanitizeNewPersonParams(collaboration),
              sanitizeCollaborationParams(collaboration),
            ),
          );
        });
      }
      // Create contact_informations if any
      params.contact_informations.forEach(contact_information => {
        if (contact_information.info != '') {
          dependantHouseholdRelationActions.push(
            createContactInformation(accountId, householdResponse.data.gid, contact_information),
          );
        }
      });

      // Create addresses if any
      params.addresses.forEach(address => {
        if (address.address) {
          dependantHouseholdRelationActions.push(
            addressService.create(accountId, householdResponse.data.gid, address),
          );
        }
      });

      const dependantHouseholdRelationResults = Promise.all(dependantHouseholdRelationActions);
      dependantHouseholdRelationResults.then(() => {
        onSuccessCallback(householdResponse.data, setSubmitting);
      });
    })
    .catch(error => {
      console.log(error.message);
      // TODO: add onErrorCallback
    });
}

// Private

// const newCollaboration = {
//   collaborator_gid: addingPerson ? null : values.person_gid,
//   collaborator_name: newPersonName,
//   main: values.person_main,
//   title: values.person_title,
//   new_person_first_name: values.new_person_first_name,
//   new_person_last_name: values.new_person_last_name,
//   new_person_gender: values.new_person_gender,
//   new_person_dob: values.new_person_dob,
// };
function createNewPersonCollaboration(
  accountId: number,
  householdGid: string,
  newPersonParams: any,
  newCollaborationParams: any,
): any {
  createPeople(accountId, newPersonParams)
    .then(personResponse => {
      const collaborationParams = newCollaborationParams;
      collaborationParams['collaborator_gid'] = personResponse.data.gid;
      createCollaboration(accountId, householdGid, collaborationParams);
    })
    .catch(error => {
      console.log(error.message);
      // TODO: add onErrorCallback
    });
}

function associate(
  accountId: number,
  subjectGid: string,
  fields: any,
  onSuccess: any,
  setSubmitting: any,
): any {
  createCollaboration(accountId, subjectGid, fields)
    .then(response => {
      onSuccess(response?.data, setSubmitting);
    })
    .catch(error => {
      console.log(error.message);
      // TODO: add onErrorCallback
    });
}

function createAndAssociate(
  accountId: number,
  subjectGid: string,
  fields: any,
  onSuccess: any,
  setSubmitting: any,
): any {
  createHousehold(accountId, fields).then(householdResponse => {
    const householdInformation = {
      collaborator_gid: householdResponse.data.gid,
      ...fields,
    };
    createCollaboration(accountId, subjectGid, householdInformation)
      .then(response => {
        onSuccess(response?.data, setSubmitting);
      })
      .catch(error => {
        console.log(error.message);
        // TODO: add onErrorCallback
      });
  });
}

function sanitizeCollaborationParams(params: any): any {
  const sanitizedCollaborationParams = Object.assign({}, params);
  // Remove new person related fields
  delete sanitizedCollaborationParams['collaborator_name'];
  delete sanitizedCollaborationParams['new_person_first_name'];
  delete sanitizedCollaborationParams['new_person_last_name'];
  delete sanitizedCollaborationParams['new_person_gender'];
  delete sanitizedCollaborationParams['new_person_dob'];
  return sanitizedCollaborationParams;
}

function sanitizeNewPersonParams(params: any): any {
  const sanitizedNewPersonParams = Object.assign({}, params);
  sanitizedNewPersonParams['first_name'] = sanitizedNewPersonParams['new_person_first_name'];
  sanitizedNewPersonParams['last_name'] = sanitizedNewPersonParams['new_person_last_name'];
  sanitizedNewPersonParams['gender'] = params.new_person_gender
    ? sanitizedNewPersonParams['new_person_gender']
    : 'unknown';
  sanitizedNewPersonParams['dob'] = params.new_person_dob
    ? sanitizedNewPersonParams['new_person_dob']
    : '';

  // Remove redundant fields
  delete sanitizedNewPersonParams['new_person_first_name'];
  delete sanitizedNewPersonParams['new_person_last_name'];
  delete sanitizedNewPersonParams['new_person_gender'];
  delete sanitizedNewPersonParams['new_person_dob'];
  delete sanitizedNewPersonParams['collaborator_gid'];
  delete sanitizedNewPersonParams['collaborator_name'];
  delete sanitizedNewPersonParams['title'];
  delete sanitizedNewPersonParams['main'];
  return sanitizedNewPersonParams;
}

function sanitizeHouseholdParams(params: any): any {
  const sanitizedHouseholdParams = params;
  return sanitizedHouseholdParams;
}

export const householdService = {
  create,
  associate,
  createAndAssociate,
};
