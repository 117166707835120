import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../../contexts/userContext';
import useCookie from '../../../hooks/useCookie';
import { UserActionTypes } from '../../../types';

const LogoutButton = () => {
  const { t } = useTranslation('common');
  const [_, updateOrigin] = useCookie('origin');
  const userContext = React.useContext(UserContext);
  const navigate = useNavigate();

  const logout = () => {
    userContext.dispatch({ type: UserActionTypes.Clear });
    updateOrigin('', -1);
    navigate('/');
  };

  return (
    <div>
      <button
        className="h2 text-white"
        onClick={logout}>
        {t('buttons.logout')}
      </button>
    </div>
  );
};

export default LogoutButton;
