// Utils
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import { toast } from 'react-toastify';

// Styles
import '../../../src/style/index.scss';
import 'react-datepicker/dist/react-datepicker.css';

// Components
import Button from '../Buttons/Button';
import Property from '../Property/Property';
import TextInput from '../TextInput';
import ContactInformation from '../ContactInformation/ContactInformation';
import AddressForm from '../AddressForm/AddressForm';
import HallowButton from '../HallowButton/HallowButton';
import AsyncSelect from '../AsyncSelect';
import CheckboxDatabaseEmail from '../CheckboxDatabaseEmail/CheckboxDatabaseEmail';
import TagSelect from '../TagSelect/TagSelect';

// Icons & Images
import { ReactComponent as PersonFile } from '@/assets/icons/person-file.svg';
import { ReactComponent as Smiley } from '@/assets/icons/smiley-face.svg';
import IconHolder from '../IconHolder/IconHolder';
import { ReactComponent as PhoneBook } from '@/assets/icons/phone-book.svg';
import { ReactComponent as Location } from '@/assets/icons/location-pin.svg';
import { ReactComponent as ProfilePictureFrame } from '@/assets/icons/profile-picture-frame.svg';
import { ReactComponent as AddIcon } from '@/assets/icons/add-circle.svg';

// API
import ImageHandler from '../ImageHandler';
import React from 'react';
import { HeaderContext } from '@/contexts/headerContext';
import { fetchTags } from '@/utils/api/tags';

// Contexts
import { PersonInfoContext } from '@/contexts/personContext';
import AssociatePerson from '../AssociatePerson/AssociatePerson';
import AssociatePersonSuccess from '../AssociatePersonSuccess/AssociatePersonSuccess';

// Types & Interfaces
type CustomStepProps = {
  accountId?: number;
  errors?: any;
  fetchCategories?: any;
  goToStep?: any;
  hashValue?: any;
  isSubmitting?: any;
  nextStep?: any;
  previousStep?: any;
  setFieldValue?: any;
  touched?: any;
  values?: any;
  photos?: any;
  setPhotos?: any;
  organizationInfo?: any;
  setOrganizationInfo?: any;
  associatedOrganization?: object;
  setIsCreateOrganization?: Function;
};

interface FormikTypes {
  tag_list: any;
  addresses: {
    info: string;
    type: string;
  }[];
  value: any;
  contact_informations: any;
}

const AddOrganizationWizard = ({
  values,
  hashValue,
  errors,
  touched,
  isSubmitting,
  setFieldValue,
  fetchCategories,
  accountId,
  photos,
  setPhotos,
  organizationInfo,
  associatedOrganization,
  setIsCreateOrganization,
}: CustomStepProps) => {
  const navigate = useNavigate();
  const { personInfo } = useContext(PersonInfoContext);

  // Protection against invalid reloads
  // FIXME: use validation schema?
  useEffect(() => {
    if (values?.name === '' && hashValue >= 2) {
      navigate('/contacts/organizations/new/#step1');
    }
  }, []);

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, [hashValue]);

  return (
    <>
      {personInfo ? (
        <>
          <StepWizard isLazyMount={true} initialStep={hashValue} className="pb-6 p-6 h-full">
            <AssociatePerson
              accountId={accountId}
              errors={errors}
              fetchCategories={fetchCategories}
              hashValue={hashValue}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              touched={touched}
              personInfo={personInfo}
              isHousehold={false}
              setIsCreateOrganization={setIsCreateOrganization}
            />
            <AssociatePersonSuccess
              hashValue={hashValue}
              personInfo={personInfo}
              isHousehold={false}
              associatedOrganization={associatedOrganization}
            />
          </StepWizard>
        </>
      ) : (
        <StepWizard isLazyMount={true} initialStep={hashValue} className="pb-6 p-6 h-full">
          <Step1 hashValue={hashValue} errors={errors} accountId={accountId} />
          <Step2
            accountId={accountId}
            errors={errors}
            fetchCategories={fetchCategories}
            hashValue={hashValue}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            touched={touched}
          />
          <Step3 hashValue={hashValue} errors={errors} touched={touched} accountId={0} />
          <Step4
            hashValue={hashValue}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
          <Step5
            hashValue={hashValue}
            errors={errors}
            photos={photos}
            setPhotos={setPhotos}
            isSubmitting={isSubmitting}
            organizationInfo={organizationInfo}
          />
          <Step6
            hashValue={hashValue}
            photos={photos}
            errors={errors}
            organizationInfo={organizationInfo}
          />
        </StepWizard>
      )}
    </>
  );
};

// Welcome slide & start wizard button
const Step1 = ({ nextStep, hashValue, goToStep }: CustomStepProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');
  const headerContext = React.useContext(HeaderContext);

  React.useEffect(() => {
    if (hashValue == 1) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: false,
          canEdit: false,
          canGoBack: true,
        },
      });
    }
  }, []);

  function goToNextStep() {
    nextStep();
    navigate('/contacts/organizations/new/#step2');
  }

  // Code here will run after *every* render
  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);

  return (
    <div className="flex items-center justify-between flex-col pt-20 h-full ">
      <div className="flex justify-center flex-col w-full h-full">
        <div className="flex flex-col gap-5 justify-center w-full">
          <div className="flex justify-center mt-auto gap-5 flex-col text-center w-full">
            <IconHolder
              svgIcon={<Smiley />}
              header={t('subjects.organizationWizard.welcome.title')}
              subTitle={t('subjects.organizationWizard.welcome.subtitle')}
            />
          </div>
        </div>
      </div>
      <div className="w-full translate-y-24 gap-3 flex flex-col">
        <Button
          onClick={goToNextStep}
          text={t('subjects.organizationWizard.welcome.actions.next')}
          variant="primary"
          size="large"
        />
        <Button
          link="/contacts/organizations"
          type="button"
          text={t('common.buttons.cancel')}
          variant="secondaryOrange"
          size="large"
        />
      </div>
    </div>
  );
};

const Step2 = ({
  hashValue,
  goToStep,
  errors,
  touched,
  nextStep,
  setFieldValue,
  accountId,
  fetchCategories,
}: CustomStepProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');
  const [nextStepAttempted, setNextStepAttempted] = useState(false);
  const headerContext = React.useContext(HeaderContext);
  const { values } = useFormikContext<FormikTypes>();

  React.useEffect(() => {
    if (hashValue == 2) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: false,
          canEdit: false,
          canGoBack: true,
        },
      });
    }
  }, []);

  function goToNextStep() {
    setNextStepAttempted(true);
    if (!errors?.name && !errors.category_id) {
      nextStep();
      navigate('/contacts/organizations/new/#step3');
    }
  }

  // Code here will run after *every* render
  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);

  return (
    <div className="flex flex-col items-center justify-between w-full h-full">
      <div className="flex flex-col mb-5 ">
        <IconHolder
          svgIcon={<PersonFile />}
          header={t('subjects.organizationWizard.organization.title')}
          subTitle={t('subjects.organizationWizard.organization.subtitle')}
        />
      </div>
      <div className="w-full gap-5 flex flex-col">
        <Property
          title={t('subjects.organizationWizard.organization.fields.name.label')}
          haveGap
          bold
          required={true}
          content={
            <TextInput
              touched={touched?.name}
              nextStepAttempted={nextStepAttempted}
              type="text"
              name="name"
              placeholder={t('subjects.organizationWizard.organization.fields.name.label')}
            />
          }
        />
        <Property
          title={t('subjects.householdWizard.household.fields.category_id.label')}
          haveGap
          bold
          required={true}
          content={
            <AsyncSelect
              type="select"
              name="category_id"
              placeholder={t('subjects.householdWizard.household.fields.category_id.placeholder')}
              setFieldValue={setFieldValue}
              loadOptions={fetchCategories}
              isClearable
              defaultOptions
              nextStepAttempted={nextStepAttempted}
            />
          }
        />
        <TagSelect
          fetchTags={fetchTags}
          setFieldValue={setFieldValue}
          values={values}
          accountId={accountId}
        />
        <CheckboxDatabaseEmail />
      </div>
      <div className="w-full pb-6 translate-y-28">
        <Button
          onClick={goToNextStep}
          text={t('common.buttons.next')}
          variant="primary"
          size="large"
        />
      </div>
    </div>
  );
};

const Step3 = ({ nextStep, hashValue, goToStep, errors, touched }: CustomStepProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');
  const { values } = useFormikContext<FormikTypes>();
  const [nextStepAttempted, setNextStepAttempted] = useState(false);
  const headerContext = React.useContext(HeaderContext);

  React.useEffect(() => {
    if (hashValue == 3) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: false,
          canEdit: false,
          canGoBack: true,
        },
      });
    }
  }, []);

  function goToNextStep() {
    setNextStepAttempted(true);
    if (!errors.contact_informations) {
      nextStep();
      navigate('/contacts/organizations/new/#step4');
    }
  }

  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);

  return (
    <div className="flex flex-col h-full gap-5 justify-between w-full">
      <IconHolder
        svgIcon={<PhoneBook />}
        header={t('subjects.organizationWizard.contact_details.title')}
        subTitle={t('subjects.organizationWizard.contact_details.subtitle')}
      />
      <div className="flex flex-col w-full">
        <FieldArray name="contact_informations">
          {({ push, remove }) => (
            <>
              {values.contact_informations.length > 0 &&
                values.contact_informations.map((contact_information: any, index: number) => (
                  <ContactInformation
                    key={index}
                    remove={() => remove(index)}
                    push={() => push(index)}
                    index={index}
                    item={contact_information}
                    touched={touched?.contact_informations}
                    nextStepAttempted={nextStepAttempted}
                  />
                ))}
              <div className="flex justify-start text-orange-500 mt-2 ">
                <button
                  type="button"
                  className="my-auto flex flex-row"
                  onClick={() => push({ info: '', type: 'Email', main: false })}>
                  <div className="flex justify-center items-center font-semibold mr-2">
                    <AddIcon style={{ stroke: '#F97316' }} />
                  </div>
                  <div className="mt-0.5">
                    {t('subjects.organizationWizard.contact_details.button.add')}
                  </div>
                </button>
              </div>
            </>
          )}
        </FieldArray>
      </div>
      <div className="w-full translate-y-32 flex flex-col gap-3 pb-6">
        <Button
          onClick={goToNextStep}
          text={t('common.buttons.next')}
          variant="primary"
          size="large"
        />
        <Button
          type="submit"
          text={t('subjects.addPersonForm.finish')}
          variant="secondaryOrange"
          size="large"
        />
      </div>
    </div>
  );
};

const Step4 = ({ hashValue, goToStep, setFieldValue }: CustomStepProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');
  const { values } = useFormikContext<FormikTypes>();
  const headerContext = React.useContext(HeaderContext);

  React.useEffect(() => {
    if (hashValue == 4) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: false,
          canEdit: false,
          canGoBack: true,
        },
      });
    }
  }, []);

  function goToNextStep() {
    navigate('/contacts/organizations/new/#step5');
  }

  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);

  return (
    <div className="flex flex-col items-center justify-between w-full h-full">
      <div className="flex flex-col gap-5 h-full mb-5">
        <IconHolder
          svgIcon={<Location />}
          header={t('subjects.organizationWizard.address_details.title')}
          subTitle={t('subjects.organizationWizard.address_details.subtitle')}
        />
      </div>
      <div className="flex flex-col w-full">
        <FieldArray name="addresses">
          {({ push, remove }) => (
            <>
              {values.addresses.length > 0 &&
                values.addresses.map((address, index) => (
                  <AddressForm
                    key={index} // Don't forget to add the key for dynamic lists.
                    remove={() => remove(index)}
                    index={index}
                    setFieldValue={setFieldValue}
                  />
                ))}
              <div className="flex justify-start text-orange-500 mt-2 ">
                <button
                  type="button"
                  className="my-auto flex flex-row"
                  onClick={() => push({ info: '', type: 'Email' })}>
                  <div className="flex justify-center items-center font-semibold mr-2">
                    <AddIcon style={{ stroke: '#F97316' }} />
                  </div>
                  <div className="mt-0.5">
                    {t('subjects.organizationWizard.address_details.button.add')}
                  </div>
                </button>
              </div>
            </>
          )}
        </FieldArray>
      </div>

      <div className="w-full  pb-10 translate-y-24">
        <Button
          onClick={goToNextStep}
          text={t('common.buttons.next')}
          variant="primary"
          size="large"
        />
        <button
          type="submit"
          className="rounded-lg  border border-orange-500 mt-5 flex justify-center items-center gap-1 h3 z-20 w-full h-[56px] bg-white text-orange-500">
          {t('subjects.organizationWizard.finish.button')}
        </button>
      </div>
    </div>
  );
};

const Step5 = ({ hashValue, goToStep, photos, setPhotos, isSubmitting }: CustomStepProps) => {
  const { t } = useTranslation('dashboard');
  const headerContext = React.useContext(HeaderContext);

  React.useEffect(() => {
    if (hashValue == 5) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: false,
          canEdit: false,
          canGoBack: true,
        },
      });
    }
  }, []);

  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);

  return (
    <div className="flex flex-col items-center justify-between w-full h-full">
      <div className="flex flex-col gap-5 h-full">
        <IconHolder
          svgIcon={<ProfilePictureFrame />}
          header={t('subjects.organizationWizard.add_logo.title')}
          subTitle={t('subjects.organizationWizard.add_logo.subtitle')}
        />
      </div>
      <div className="w-full flex flex-col gap-5">
        <Property
          title={''}
          content={<ImageHandler height={300} photos={photos} setPhotos={setPhotos} />}
        />
      </div>
      <div className="w-full pb-5 translate-y-20 ">
        <Button
          type="submit"
          text={t('common.buttons.next')}
          variant="primary"
          size="large"
          disabled={isSubmitting}
          className={`${
            isSubmitting
              ? 'text-disabledText bg-disabledBg w-full'
              : 'text-white bg-primaryColor w-full'
          }`}
        />
        <button
          type="submit"
          className="rounded-lg  border border-orange-500 mt-5  flex justify-center items-center gap-1 h3 z-20 w-full h-[56px] bg-white text-orange-500">
          {t('subjects.organizationWizard.finish.button')}
        </button>
      </div>
    </div>
  );
};

// Final step: View list of household members with following actions:
// - add a new household member (back to previous step)
// - finalize the creation of the household and collaborations and navigate to the household page
const Step6 = ({ hashValue, photos, organizationInfo }: CustomStepProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');

  function goToNextStep() {
    navigate(`/contacts/organizations/${organizationInfo?.id}/summary`);
    toast.success(t(`toast.organizationAddSuccess`));
  }

  const [preview, setPreview] = React.useState<string>();
  React.useEffect(() => {
    if (!photos) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(photos);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [photos]);

  const headerContext = React.useContext(HeaderContext);

  React.useEffect(() => {
    if (hashValue == 6) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          hamburgerMenu: false,
          homePage: false,
          closeButton: true,
          canEdit: false,
          canGoBack: false,
          closeUrl: `/contacts/organizations/${organizationInfo?.id}/summary`,
        },
      });
    }
  }, []);
  return (
    <div className="flex flex-col gap-5 h-full justify-between h-full pt-10">
      <div className="flex justify-center flex-col w-full h-full">
        <div className="flex flex-col  gap-5 justify-center w-full">
          <div className="flex justify-center mt-auto gap-5 flex-col text-center w-full">
            <IconHolder
              svgIcon={
                preview && (
                  <img src={preview} className="object-cover w-32 h-32 mx-auto rounded-full" />
                )
              }
              header={
                organizationInfo && (
                  <div className="font-semibold">
                    {organizationInfo?.name.charAt(0).toUpperCase() +
                      organizationInfo?.name.slice(1)}
                    {t('subjects.organizationWizard.success.title')}
                  </div>
                )
              }
              subTitle={t('subjects.organizationWizard.success.subtitle')}
            />
            <HallowButton
              title={t('subjects.organizationWizard.success.button.associate')}
              link={`/contacts/organizations/${organizationInfo?.id}/summary`}
            />
          </div>
        </div>
      </div>
      <div className="w-full  translate-y-24">
        <div className="w-full">
          <Button
            onClick={goToNextStep}
            text={t('subjects.organizationWizard.success.button.see_organization')}
            variant="primary"
            size="large"
          />
        </div>
      </div>
    </div>
  );
};

export default AddOrganizationWizard;
