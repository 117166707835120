import { AxiosResponse } from 'axios';
import { parse_link_header } from '../hashUtils';
import { getYearMonthDay } from '../time';
import { getParamString } from '../utils';
import api from './api';

export const fetchEvents = async ({
  account_id,
  page = 1,
  limit = 5,
  startAt,
  endAt,
  mine,
  query,
  completed,
  subjectGid,
}: {
  account_id?: number;
  page?: number;
  limit?: number;
  startAt?: Date | string;
  endAt?: Date | string;
  mine?: boolean;
  query?: string;
  completed?: boolean;
  subjectGid?: string;
}) => {
  const params = {
    'q[intent_eq]': 'event',
    ...(startAt
      ? { 'q[start_at_gteq]': typeof startAt === 'string' ? startAt : getYearMonthDay(startAt) }
      : {}),
    ...(endAt
      ? { 'q[end_at_lteq]': typeof endAt === 'string' ? endAt : getYearMonthDay(endAt) }
      : {}),
    ...(mine ? { mine_only: true } : {}),
    ...(completed ? { 'q[completed_at_null]': 'true' } : {}),
    ...(subjectGid ? { 'q[commentable_gid_eq]': subjectGid } : {}),
    ...(query
      ? {
          'q[title_or_commentable_of_Organization_type_name_or_commentable_of_Person_type_name_cont]':
            query,
        }
      : {}),
  };

  const paramString = getParamString(params);

  const rawResponse = await api
    .get(
      `${import.meta.env.VITE_API}${
        account_id ? `/${account_id}` : ''
      }/api/v1/events.json?page=${page}&per_page=${limit}&${paramString}`,
    )
    .then(response => {
      const properties = {
        links: parse_link_header(response.headers['link']),
        total: parseInt(response.headers['x-total'] || '0'),
        page: parseInt(response.headers['x-page'] || '1') || page,
        per_page: parseInt(response.headers['x-per-page'] || '0'),
      };

      return { properties: properties, data: response.data };
    });
  return rawResponse;
};

export const fetchAllEvents = async ({
  account_id,
  page = 1,
  limit = 5,
  startAt,
  endAt,
  mine,
  query,
  completed,
  subjectGid,
}: {
  account_id?: number;
  page?: number;
  limit?: number;
  startAt?: Date | string;
  endAt?: Date | string;
  mine?: boolean;
  query?: string;
  completed?: boolean;
  subjectGid?: string;
}) => {
  const params = {
    'q[intent_eq]': 'event',
    ...(startAt
      ? { 'q[start_at_gteq]': typeof startAt === 'string' ? startAt : getYearMonthDay(startAt) }
      : {}),
    // ...(endAt
    //   ? { 'q[end_at_lteq]': typeof endAt === 'string' ? endAt : getYearMonthDay(endAt) }
    //   : {}),
    // ...(mine ? { mine_only: true } : {}),
    // ...(completed ? { 'q[completed_at_null]': 'true' } : {}),
    ...(subjectGid ? { 'q[commentable_gid_eq]': subjectGid } : {}),
    // ...(query
    //   ? {
    //       'q[title_or_commentable_of_Organization_type_name_or_commentable_of_Person_type_name_cont]':
    //         query,
    //     }
    //   : {}),
  };
  const paramString = getParamString(params);

  //"/{account_id}/api/v1/events.json?page=1&q[intent_eq]=event&q[commentable_gid_eq]={contact_gid}&q[start_at_gteq]={today}"

  //https://app-staging.toucanhq.com/1/api/v1/events.json?page=1&per_page=10&q[intent_eq]=event&q[start_at_gteq]=2023-09-20&mine_only=true
  const rawResponse = await api
    .get(
      `${import.meta.env.VITE_API}${
        account_id ? `/${account_id}` : ''
      }/api/v1/events.json?page=${page}&per_page=${limit}&${paramString}`,
    )
    .then(response => {
      const properties = {
        links: parse_link_header(response.headers['link']),
        total: parseInt(response.headers['x-total'] || '0'),
        page: parseInt(response.headers['x-page'] || '1') || page,
        per_page: parseInt(response.headers['x-per-page'] || '0'),
      };
      return { properties: properties, data: response.data };
    });
  return rawResponse;
};

export const fetchProfileEvents = async ({
  account_id,
  page = 1,
  limit = 5,
  startAt,
  endAt,
  mine,
  query,
  completed,
  subjectGid,
}: {
  account_id?: number;
  page?: number;
  limit?: number;
  startAt?: Date | string;
  endAt?: Date | string;
  mine?: boolean;
  query?: string;
  completed?: boolean;
  subjectGid?: string;
}) => {
  const params = {
    'q[intent_eq]': 'event',
    ...(startAt
      ? { 'q[start_at_gteq]': typeof startAt === 'string' ? startAt : getYearMonthDay(startAt) }
      : {}),
    ...(completed ? { 'q[completed_at_null]': 'true' } : {}),
    ...(subjectGid ? { 'q[commentable_gid_eq]': subjectGid } : {}),
  };
  const paramString = getParamString(params);

  const rawResponse = await api
    .get(
      `${import.meta.env.VITE_API}${
        account_id ? `/${account_id}` : ''
      }/api/v1/events.json?page=${page}&per_page=${limit}&${paramString}`,
    )
    .then(response => {
      const properties = {
        links: parse_link_header(response.headers['link']),
        total: parseInt(response.headers['x-total'] || '0'),
        page: parseInt(response.headers['x-page'] || '1') || page,
        per_page: parseInt(response.headers['x-per-page'] || '0'),
      };
      return { properties: properties, data: response.data };
    });
  return rawResponse;
};

export const fetchEvent = async (account_id: number, eventId: string) => {
  if (!account_id) throw Error('No account id');
  if (!eventId) throw Error('No address id');
  const rawResponse = await api.get(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/events/${eventId}.json`,
  );
  return rawResponse.data;
};

export const createEvent = async (
  account_id: number,
  body: object,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  const rawResponse = await api.post(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/events.json`,
    body,
  );
  return rawResponse;
};

export const editEvent = async (
  account_id: number,
  eventId: number,
  body: object,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  if (!eventId) throw Error('No event id');
  const rawResponse = await api.put(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/events/${eventId}.json`,
    body,
  );
  return rawResponse;
};

export const deleteEvent = async (
  account_id: number,
  eventId: number,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  if (!eventId) throw Error('No event id');
  const rawResponse = await api.delete(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/events/${eventId}.json`,
  );
  return rawResponse;
};
