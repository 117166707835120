// Utils
import { useTranslation } from 'react-i18next';
import { useField, Field } from 'formik';
// Types
import { FormFieldsType } from '@/types';

type MainSelectProps = FormFieldsType & {
  isAlternateString?: boolean;
};

const MainSelect = ({ isAlternateString = false, ...props }: MainSelectProps) => {
  const { t } = useTranslation('dashboard');
  const [field] = useField(props);

  return (
    <Field
      className="w-full focus:border-orange-400 select-component placeholder-gray-400 border border-gray-400 rounded-md h-12 pl-3"
      as="select"
      {...field}
      {...props}>
      <option value="true">
        {isAlternateString ? (
          <> {t('subjects.properties.edit.status.active')}</>
        ) : (
          <> {t('subjects.addPersonForm.primary')}</>
        )}
      </option>
      <option value="false">
        {isAlternateString ? (
          <>{t('subjects.properties.edit.status.deactive')}</>
        ) : (
          <> {t('subjects.addPersonForm.secondary')}</>
        )}
      </option>
    </Field>
  );
};
export default MainSelect;
