export default {
  title: 'Contacts',
  welcome: 'Welcome',
  hello: 'Hello,',
  poweredBy: 'Powered by',
  toucan: 'Toucan Solutions',
  actions: {
    transport: [
      {
        title: 'Home',
        icon: '',
        url: '/dashboard',
      },
      {
        title: 'Parcels',
        myTitle: 'My parcels',
        icon: 'order',
        url: 'orders',
      },
      {
        title: 'Vehicles',
        myTitle: 'My vehicles',
        icon: 'camion',
        url: 'vehicules',
      },
      {
        title: 'Trips',
        myTitle: 'My trips',
        icon: 'pushpin',
        url: 'trips',
        state: ['account_only'],
      },
    ],
    collaboration: [
      {
        title: 'Home',
        icon: '',
        url: '/dashboard',
      },
      {
        title: 'Events',
        icon: '',
        url: '/events',
      },
      {
        title: 'Scan',
        icon: '',
        url: 'scan',
      },
    ],
    logistic: [
      {
        title: 'Home',
        icon: '',
        url: '/dashboard',
      },
      {
        title: 'Ingoing',
        icon: '',
        url: 'receptions',
      },
      {
        title: 'Preparing',
        icon: '',
        url: 'preparations',
      },
      {
        title: 'Outgoing',
        icon: '',
        url: 'expeditions',
      },
      {
        title: 'Scan',
        icon: '',
        url: 'scan',
      },
    ],
  },
  urls: {
    parcels: 'Active parcels',
    trips: 'My trips',
    vehicles: 'My vehicles',
    addVehicle: 'Add a vehicle',
    editVehicle: 'Edit a vehicle',
    addTrip: 'Add a trip',
    addEvent: 'New event',
    addNote: 'Add a note',
    editEvent: 'Edit an event',
    editNote: 'Edit a note',
    editTrip: 'Edit a trip',
    addParcel: 'Add a parcel',
    editParcel: 'Edit a parcel',
    editOrder: 'Edit an order',
    addSubject: 'Add a subject/organization',
    addPeople: 'Add a person',
    addOrganization: 'Add an organization',
    addHousehold: 'Add a household',
    addHousehold2: 'New household',
    editPeople: 'Edit a person',
    editOrganization: 'Edit an organization',
    editHousehold: 'Edit a household',
    addAppro: 'New incoming',
    addDistri: 'New order',
  },
  dashboard: {
    actions: {
      transport: [
        {
          title: 'Add a trip',
          icon: 'camion',
          url: 'trips/add',
        },
        {
          title: 'Add a parcel',
          icon: 'order',
          url: 'order/add',
          state: '{"firstStep":"true"}',
        },
      ],
      collaboration: [
        {
          title: 'New person',
          icon: 'addPerson',
          url: 'subjects/add?person',
        },
        {
          title: 'New organization',
          icon: 'addOrganization',
          url: 'subjects/add?organization',
        },
      ],
      logistic: [
        {
          title: 'Add a new order',
          icon: 'add',
          url: 'order/add',
          state: '{"resetNewParcel": "true"}',
        },
      ],
    },
    nextTrips: 'Next trips',
    todayTrips: "Today's trips",
    incoming: 'Incoming',
    prepare: 'Prepare',
    outgoing: 'Outgoing',
    noResult: {
      incoming: 'No recent incoming order',
      prepare: 'No recent prepared order',
      outgoing: 'No recent shipped order',
    },
    upcomingIncoming: 'Upcoming incoming',
    upcomingOutgoing: 'Upcoming outgoing',
    waitingParcels: 'Waiting parcels',
    calendar: 'Calendar',
    tasks: 'My tasks',
    todayCalendar: "Today's calendar",
    todayEvents: "Today's events ({{count}})",
    notes: 'Notes ({{count}})',
    max_date: 'Departure on: {{date}}',
    buttons: {
      allTrips: 'All trips',
      myTrips: 'All my trips',
      allParcels: 'All parcels',
      allIncoming: 'All incoming',
      allOutgoing: 'All outgoing',
      seeAll: 'See all',
      seeMore: 'See more',
    },
    modes: {
      collaboration: 'Contacts',
      logistic: 'Orders',
      transport: 'Transport',
    },
    transportMode: 'Profile: {{mode}}',
    transportModes: {
      parcel: 'Standard profile',
      transport: 'Trip manager',
      manager: 'Carrier',
    },
    logisticModes: {
      incoming: 'Incoming',
      outgoing: 'Outgoing',
    },
    transportModeDisplay: 'Display: {{mode}}',
  },
  parcels: {
    properties: {
      title: 'Parcel',
      confirmTitle: 'Confirm',
      details: 'Details',
      parcelDetails: 'Parcel details',
      recipient: 'Recipient',
      recipientDestination: 'Recipient',
      origin: 'Origin',
      originSender: 'Origin (sender)',
      organizationPlaceholder: 'Search an organization or a person',
      contactPlaceholder: 'Search a person',
      status: 'Status',
      dateFrom: 'Date added',
      dateTo: 'Planned departure',
      dateWantTo: 'Desired date',
      description: 'Description',
      descriptionPlaceholder: `Optional: indicate here all the information that you consider relevant to communicate to the other users of the platform: Does your shipment have particularities that deserve to be reported (dimensions, weight, type of item, dangerousness)? Do you have any transport constraints (temperature, delivery time)? Do the addresses of origin or destination have any particularities (opening hours, delivery access, floor, bell or code, etc.)?`,
      reference: 'Reference',
      invoiceReference: 'Reference',
      invoiceNumber: 'Invoice or order number',
      weight: 'Weight',
      total_weight: 'Total weight (kg)',
      dimensions: 'Dimensions',
      deliveryNote: 'Delivery note',
      storage: 'Storage temperature',
      tags: 'Tags',
      calculatedDistance: 'Calculated distance: {{distance}} km',
      vehicle: 'Vehicle',
      noTrips: 'No trip associated',
      carrier: 'Carrier',
      contact: 'Pickup contact',
      type: 'Type',
      note: 'Note',
      direction: 'Direction',
      contextKind: {
        distribution: 'Sale',
        purchase: 'Purchase',
        donation: 'Donation',
      },
      parcelType: {
        distribution: 'I want to send',
        purchase: 'I want to receive',
      },
      kindType: {
        purchase: 'Purchase',
        donation: 'Donnation',
      },
      distribution: 'Sender',
      purchase: 'Recipient',
      unit: 'Inventory unit',
      product: 'Product',
      products: 'Products ({{count}})',
      participants: 'Participant(s)',
      category: 'Category',
      images: 'Images',
      image: 'Image',
      coments: 'Comments',
      confirmedQuantity: 'Confirmed quantity',
      foundParcels_one: 'Parcel found',
      foundParcels_other: 'Parcels found',
      linkedTrips: 'Linked trips ({{count}})',
      steps: {
        order: 'Order',
        direction: 'Direction',
        distribution: 'Sender',
        purchase: 'Recipient',
        itinerary: 'Itinerary',
        productDetails: 'Products',
        parcelDetails: 'Details',
      },
      iAm: 'I am',
      avgValue: 'Average monetary value (/kg)',
      subcategories: 'Subcategories',
      checkContactInformationMessage:
        'Check that the contact information below is correct, if not change it.',
      searchRecipientMessage: 'Search for the recipient in your contacts or create a new one',
      searchSenderMessage: 'Search for the sender in your contacts or create a new one',
    },
    buttons: {
      add: 'Add a parcel',
      addAddress: 'Add an address',
      order: 'Add an order',
      view: 'View',
      edit: 'Edit',
      editContact: 'Edit contact',
      duplicate: 'Duplicate',
      showWaitingParcels: 'Show waiting parcels only',
      parcelsFrom: 'Parcels from',
      seeParcel: 'See parcel',
    },
    noParcelsFound: 'No parcels found',
    changeFilter: 'Change your filters or add a new parcel',
    contactOrigin: 'Contact the outgoinger',
    contactDriver: 'Contact the driver',
    noParcels: 'No parcels',
    actions: {
      addtoTrip: 'Associate to a trip',
      addParcelToTrip: 'Add this parcel to the trip',
      tripLinked: 'Trip linked',
      cannotFindParcel: 'Cannot find the parcel',
      edit: 'Edit parcel',
      removeTrip: 'Remove trip',
    },
    add: {
      recipient: 'Recipient',
      contact: 'Profile',
      originContact: 'Origin contact',
      recipientContact: 'Recipient contact',
      description: 'Description and important informations',
      addCategory: 'Add a category',
      addVariant: 'Add a product',
      variant: 'Product',
      departure: 'Desired pick-up date',
      publish: 'Publish parcel',
      addressLabel: 'Address label',
      apptSuite: 'Appt/suite',
      quantity: 'Quantity',
      subjectType: {
        Organization: 'Organization',
        Person: 'Person',
      },
      name: 'Name',
    },
  },
  orders: {
    properties: {
      orderName: 'Name:',
      etaDate: 'Due date:',
      supplierName: 'Supplier:',
      clientName: 'Client:',
      status: {
        allocated: 'Allocated',
        canceled: 'Cancelled',
        completed: 'Completed',
        confirmed: 'Confirmed',
        consolidated: 'Grouped',
        in_progress: 'In process',
        paid: 'Paid',
        pending: 'Waiting',
        delivered: 'Delivered',
        fulfilled: 'Shipped',
        partial: 'Partially shipped',
        partially_delivered: 'Partially delivered',
        partially_prepared: 'Partially prepared',
        prepared: 'Prepared',
        restocked: 'Cancelled',
        authorized: 'Authorized',
        partially_paid: 'Partially paid',
        partially_refunded: 'Partially refunded',
        refunded: 'Refunded',
        voided: 'Cancelled',
        accepted: 'Confirmed',
        dispatched: 'Shipped',
        expired: 'Expired',
        received: 'Received',
        rejected: 'Refused',
        sent: 'Sent',
        waiting: 'Pending',
        ready_for_pickup: 'Ready for collection',
        in_transit: 'In transit',
      },
    },
    noOrders: 'No orders',
    noDistribution: 'No distribution order found',
    noPurchase: 'No purchase order',
    changeFilterDistribution: 'Change your filters or add a new distribution',
    changeFilterPurchase: 'Change your filters or add a new purchase',
    contact: 'Contact person',
    buttons: {
      edit: 'Edit order',
      addDistribution: 'Add a distribution',
      addPurchase: 'Add a purchase',
    },
  },
  products: {
    properties: {
      productName: 'Product name',
      quantity: 'Unit quantity',
      unit: 'Unit',
      weight: 'Unit weight',
      unitWeight: 'Weight unit',
      price: 'Unit price',
      value: 'Unit Value',
      currency: 'Currency',
      variantName: 'Variant name',
      fraction: 'Fractionnable',
      fractionQuantity: 'Fraction quantity',
      fractionUnit: 'Fraction unit',
      bestBefore: 'Best before',
      expiresAt: 'Expires at',
      invoiceDate: 'Invoice date',
    },
    errors: {
      quantity: 'You must enter a quantity',
      unit: 'You must select a unit',
      weight: 'You must enter a weight',
      unitWeight: 'You must enter a weight unit',
      price: 'You must enter a price',
      variantName: 'You must enter a variant name',
    },
    add: {
      createProduct: 'Create a product',
      createVariant: 'Create a variant',
    },
  },
  trips: {
    properties: {
      title: 'Trip',
      tripDetails: 'Trip details',
      parcels: 'Parcels',
      noParcel: 'No parcel',
      vehicleType: 'Vehicle type',
      departure: 'Departure',
      destination: 'Destination',
      vehicleDescription: 'Vehicle description',
      tripDescription: 'Trip description',
      descriptionPlaceholder:
        'Include any relevant information: are your dates and times flexible, do you plan to make any stops, do you have any constraints to mention?',
      fulfillment_status: {
        pending: 'Pending',
        partially_prepared: 'Partially prepared',
        prepared: 'Prepared',
        ready_for_pickup: 'Ready for pickup',
        in_transit: 'In transit',
        fulfilled: 'Fulfilled',
        partial: 'Partial',
        restocked: 'Restocked',
        delivered: 'Delivered',
        partially_delivered: 'Partially delivered',
      },
      parcelState: {
        collect: 'collect',
        collectAll: 'collect all',
        collected: 'collected',
        deliver: 'deliver',
        deliverAll: 'deliver all',
        delivered: 'delivered',
        deliveredAll: 'all delivered',
        incoming: 'incoming',
        incomingAll: 'incoming all',
        preparing: 'preparing',
        preparingAll: 'preparing all',
        prepared: 'prepared',
        outgoing: 'outgoing',
        outgoingAll: 'outgoing all',
        received: 'received',
        shipped: 'shipped',
      },
      collected: 'Collected',
      originAccount: 'Origin account',
      resources: 'Resources',
      foundTrips_one: 'Trip found',
      foundTrips_other: 'Trips found',
      tripDetail: 'Trip details',
      linkedParcels: 'Linked parcels ({{count}})',
    },
    buttons: {
      add: 'Add a trip',
      view: 'View',
      edit: 'Edit',
      duplicate: 'Duplicate',
      saveTrip: 'Publish trip',
      editTrip: 'Edit trip',
      orderByLastBefore: 'Order by last before',
      myTripsOnly: 'My trips only',
      tripsFrom: 'Trips from',
      addReturnTrip: 'Add a return trip',
      tripWaypoints: '{{waypoints}} cities',
      seeTrip: 'See trip',
    },
    noTrips: 'No trips',
    noTripsFound: 'No trips found',
    changeFilter: 'Change your filters or add a new trip',
    actions: {
      addParcel: 'Add a parcel',
      cannotFindTrip: 'Cannot find the trip',
      removeParcel_one: 'Remove parcel',
      removeParcel_other: 'Remove parcels',
    },
    add: {
      title: 'Trip settings',
      origin: 'Origin',
      destination: 'Destination',
      category: 'Trip category',
      departure: 'Departure planned',
      return: 'Return planned',
      descriptionPlaceholder: 'Description',
      addressPlaceholder: 'Address, city, zip code...',
      vehicleRadio: 'Would you like to select a vehicle for this trip?',
      vehicle: 'Vehicle',
      markerHint: 'You can drag the marker for a better location',
    },
  },
  vehicles: {
    properties: {
      dateFrom: 'Date added',
      description: 'Description',
      storage: 'Storage temperature',
      storageType: {
        ambient: 'Ambient',
        cool: 'Cool',
        ambient_cool: 'Mix (ambient and cool)',
        frozen: 'Frozen',
      },
      vehicleType: 'Vehicle type',
      type: 'Type',
      brand: 'Brand',
      foundVehicles_one: 'Vehicle',
      foundVehicles_other: 'Vehicles',
    },
    buttons: {
      add: 'Add a vehicle',
      view: 'View',
      edit: 'Edit',
      duplicate: 'Duplicate',
    },
    noVehicles: 'No vehicles',
    noVehiclesFound: 'No vehicles found',
    actions: {
      cannotFindVehicle: 'Cannot find the vehicle',
    },
    add: {
      title: 'Vehicle settings',
      name: 'Name',
      category: 'Category',
      photos: 'Photos',
      photo: 'Photo',
      descriptionPlaceholder: 'Description',
      namePlaceholder: 'Name of the vehicle',
    },
  },
  subjects: {
    properties: {
      addresses: 'Address(es)',
      picture: 'Picture/Logo',
      tags: 'Tags',
      category: 'Category',
      code: 'Code',
      principal: 'Principal contact',
      inheritedSource: 'Source: ',
      description: 'Description',
      created: 'Created',
      updated: 'Updated',
      createdOnBy: 'Created on {{createdOn}} by {{createdBy}}',
      updatedOnBy: 'Updated on {{updatedOn}} by {{updatedBy}}',
      gender: 'Gender',
      genders: {
        man: 'Male',
        woman: 'Female',
        other: 'Other',
        unknown: 'Unknown',
      },
      dob: 'Date of birth',
      dobYears: ' years old',
      contactInformation: 'Contact information(s)',
      contactMethods: 'Contact method(s)',
      contactInformations: {
        placeholder: 'Contact method',
        PhoneNumber: 'Phone number',
        CellNumber: 'Cell number',
        Email: 'Email',
        Website: 'Website',
      },
      relationType: {
        main: 'Main',
        secondary: 'Secondary',
      },
      connection: '{{connection}} of {{subject}}',
      typeOfConnection: 'Type of connection',
      choosePerson: 'Choose a person',
      person: 'Person',
      collaborators: 'Collaborators',
      collaboratorsN: 'Collaborators ({{count}})',
      compositions: 'Composition',
      compositionsN: 'Composition ({{count}})',
      collaborations: 'Collaborations',
      collaborationsN: 'Collaborations ({{count}})',
      collaborationsList: 'List of collaborations ({{count}})',
      collaborationProfilePage: {
        addOrganization: 'Add an organization to ',
        addHousehold: 'Add a household to ',
        addPerson: 'Add a person to ',
      },
      noCollaborators: 'No recent collaborators',
      organizationName: "Organization's name",
      householdName: "Household's name",
      peopleName: 'People name',
      contacts: 'Contacts',
      household: 'Household',
      households: 'household(s)',
      Household: 'households',
      people: 'People',
      peoples: 'people',
      Person: 'people',
      organization: 'Organization',
      organizations: 'organization(s)',
      Organization: 'organizations',
      forms: 'Form(s)',
      addSurvey: 'Add an individual form',
      unfilledForm: 'unfilled form',
      formsN: 'Forms ({{count}})',
      noForms: 'No forms available',
      role: 'Role',
      householdComposition: 'Household composition',
      edit: {
        description: 'Description',
        status: {
          title: 'Status',
          active: 'Active',
          deactive: 'Deactive',
        },
        reference: 'External reference',
        code: 'Code',
        codeHint: 'This random code is generated by the platform. You can modify this code.',
        tags: 'Tags',
        checkbox: {
          consentDataBase: 'Consent to appear in the database.',
          consentDataBaseInformation:
            'The person has given consent for the collection and use of their personal information.',
          consentEmail: 'Consent to receive electronic communications.',
          consentEmailInformation:
            'The person has given consent to receive electronic communications.',
        },
      },
    },
    addPersonForm: {
      beforeBegin: 'Before you begin',
      beforeBeginTitle:
        'You are about to add a person to your contact database. Make sure you fill in all the required information.',
      personInfo: 'Personal Information',
      newPerson: 'New Person',
      personInfoTitle: 'Provide some information about the person you are adding.',
      name: 'First Name',
      lastName: 'Last Name',
      gender: {
        genderTitle: 'Gender',
        select: 'Not answered',
        man: 'Male',
        woman: 'Female',
        other: 'Other',
        confidential: 'Prefer not to respond',
      },
      dob: 'Date of Birth',
      contactInfo: 'Contact Information',
      contactInfoTitle: 'Provide some information about the person to add.',
      category: 'Category',
      description: 'Description',
      infoDescription: 'Specify any relevant information you wish to share with other users.',
      marketingConsent: 'Consents to receive email communications.',
      databaseConsent: 'Consents to be included in the database.',
      databaseConsentInformation:
        'The person has given consent for the collection and use of their personal information.',
      wayToContact: 'Contact Method',
      wayToContactTitle: "Provide the person's contact information.",
      email: 'Email',
      emailExample: 'example@gmail.com',
      addEmail: 'Add an email',
      cellPhone: 'Cell Phone',
      addCellPhone: 'Add a cell phone',
      phone: 'Phone',
      addPhone: 'Add a phone',
      webSite: 'Website',
      addWebSite: 'Add a website',
      fax: 'Fax',
      addFax: 'Add a fax',
      primary: 'Primary',
      secondary: 'Secondary',
      tertiary: 'Tertiary',
      address: 'Postal Address(es)',
      addressTitle: 'Enter one or more addresses for the contact.',
      addressInput: 'Address',
      addressLabel: 'Address Label',
      addressLabelExample: 'Excepteur sint occaecat',
      addressDetail: 'Address Details',
      addAddress: 'Add an address',
      addressDescription: 'Additional information',
      addressDescriptionExample: 'Apartment, suite, etc.',
      addressExample: '327 Rielle, Montreal, QC',
      identification: 'Identification',
      identificationTitle:
        'Add a photo identifying the person. It will make it easier for you to find them later.',
      addPhoto: 'Add a photo',
      addSuccess: ' has been successfully added to your Contacts!',
      addSuccessTitle:
        'Congratulations, you have created a contact in Toucan. You can now associate them with a household or an organization.',
      addHousehold: 'Add a household',
      addOrganization: 'Add an organization',
      addSuccessSubTitle1: "Don't have any collaborations to add? Finish filling out ",
      addSuccessSubTitle2: "'s profile by completing the forms.",
      buttonHousehold: 'Associate with a household',
      buttonOrganization: 'Associate with an organization',
      buttonStart: 'Start',
      buttonFinish: 'Create the person',
      buttonForm: 'Fill out a form',
      finish: 'Finish',
      apartment: 'Apartment number',
      city: 'City',
      postal: 'Postal code',
      country: 'Country',
      addManual: 'Add an address manually',
      contactLabel: 'Home, Work...',
      addContact: 'Add a contact method',
      apartmentNumber: 'Apartment number',
      seePerson: 'See the person',
      newAddressInput: 'New address',
    },
    actions: {
      cannotFindPerson: 'Cannot find the person',
      cannotFindOrganization: 'Cannot find the organization',
      cannotFindHousehold: 'Cannot find the household',
      addNewPerson: 'New person',
      addNewOrganization: 'New organization',
      addNewConnection: 'New connection',
      connections: 'Connections',
      existingPerson: 'Existing person',
      existingOrganization: 'Existing organization',
      orCreateNewPerson: 'or create a new person',
      orCreateNewOrganization: 'or create a new organization',
    },

    organizationWizard: {
      welcome: {
        title: 'Before you begin',
        subtitle:
          'You are about to create an organization in your contacts database. Make sure to fill in the required information.',
        actions: {
          next: 'Start',
        },
      },
      organization: {
        title: 'Organization Information',
        subtitle: 'Enter the information about the organization to add.',
        fields: {
          name: {
            label: 'Organization Name',
            placeholder: 'Toucan Solutions',
            hint: 'You can use the last name of the main contact (e.g., Tremblay).',
          },
          category_id: {
            label: 'Category',
            placeholder: 'Choose a category',
          },
          note: {
            label: 'Description',
            hint: 'Provide any information you consider relevant to communicate to other users.',
          },
          accepts_marketing: {
            label: 'Consent to receive communications via email.',
            hint: 'Indicate any information that you deem relevant to share with other users.',
          },
        },
      },
      contact_details: {
        title: 'Contact Details',
        subtitle: 'Enter the contact details of the organization',
        button: {
          add: 'Add a contact method',
        },
      },
      address_details: {
        title: 'Postal Address(es)',
        subtitle: 'Enter one or more addresses for the contact.',
        button: {
          add: 'Add an address',
        },
      },
      add_logo: {
        title: 'Logo',
        subtitle:
          'Add a logo or an image of the organization. It will be easier for you to find it later.',
      },
      success: {
        title: ' has been successfully added to your contacts!',
        subtitle:
          'Congratulations! You have just added an organization to your contacts. You can now associate it with people.',
        button: {
          associate: 'Associate a Collaborator',
          see_organization: 'View the Organization',
        },
      },
      finish: {
        button: 'Finish',
      },
    },
    householdWizard: {
      welcome: {
        title: 'Before you start',
        subtitle:
          "You're about to create a household in your contact database. Make sure you fill in all the required information.",
        actions: {
          next: 'Start',
        },
      },
      household: {
        title: 'Household details',
        subtitle: 'Enter the details of the household to be added.',
        fields: {
          name: {
            label: 'Household name',
            placeholder: 'Smith Household',
            hint: 'You can use the last name of the main contact (ex. Smith Household).',
          },
          category_id: {
            label: 'Category',
            placeholder: 'Choose a category',
          },
          note: {
            label: 'Description',
            hint: 'Indicate any information you feel should be communicated to other users.',
          },
        },
      },
      contactInformation: {
        title: 'Contact Method',
        subtitle: "Provide the household's contact information.",
      },
      address: {
        title: 'Postal Address(es)',
        subtitle: 'Enter one or more addresses for the contact.',
      },
      addCollaboration: {
        title: 'Link a person to the household',
        titleShort: 'Link a person',
        subtitle: 'Find an existing contact or create a new one.',
        fields: {
          person_gid: {
            placeholder: 'Search by name...',
          },
          person_title: {
            label: 'Role',
            placeholder: 'Ex: Parent, child, roomate...',
            hint: "Define the person's role in the household.",
          },
          person_main: {
            hint: 'In general, there can only be one main contact per household.',
          },
        },
        actions: {
          next: 'Link',
          search: 'Search',
          create: 'Creation',
        },
      },
      listCollaborations: {
        title: 'Associate a person to the household',
        subtitle:
          "Congratulations, you've just associated the {{householdName}} household and {{collaboratorName}}. You can now associate other people with the household or finalize the household creation.",
        actions: {
          back: 'Link another person',
          next: 'Create the {{householdName}} household',
          unlink: 'Do you want to dissociate {{collaboratorName}} from this household?',
        },
      },
    },
    addHouseholdWizard: {
      buttons: {
        begin: 'Begin',
        next: 'Next',
        associate: 'Associate a person with the household',
        see: 'See the household',
        create: 'Create the household',
      },
      beforeBegin: 'Before you begin',
      beforeBeginTitle:
        'You are about to create a household in the Toucan contact database. Make sure to fill in the requested information.',
      information: 'Household Information',
      informationSubTitle: 'Complete this information before creating the household.',
      name: 'Household Name',
      namePlaceholder: 'Smith',
      nameSubTitle: 'You can use the last name of the main contact (e.g., Smith).',
      descriptionText: 'Provide any information you deem relevant to communicate to other users.',
      household: 'The household',
      householdSuccess: 'has been successfully created!',
      householdSubText: 'Congratulations, you have just created a household.',
      associate: 'Associate a person with the household',
      associateSubTitle: 'Search for an existing contact or create a new one.',
      role: 'Role',
      rolePlaceholder: 'e.g., Daughter, Mother...',
      roleSubtitle: 'Define the person`s role in the household.',
      principal: 'Main Contact',
      principalSubTitle: 'There can be only one main contact per household.',
      contact: 'Contact Information and Address',
      contactSubTitle:
        'Contact information and postal address are inherited from the principal contact.',
      email: 'Email',
      cellPhone: 'Cell Phone',
      website: 'Website',
      phone: 'Phone',
      address: 'Address',
      success: 'Successful Association!',
      association: 'Association',
      successCongratulations: 'Congratulations, you have just associated',
      successWith: 'with',
      successHousehold: 'household',
      successWithoutPerson: 'Congratulations, you have just created ',
    },
    associateWizard: {
      associate: 'Associate',
      finish: 'Finish',
      household: {
        associateTitle: 'Associate a household with a person',
        associateSubtitle: 'Search for an existing contact or create a new one.',
        name: 'Household Name',
        category: 'Category',
        role: 'Role',
        roleSubtitle: "Define the person's role in the household.",
        title: 'The Household ',
        personAssociate: ' has been successfully associated with the person ',
        see: 'See the household',
      },
      organization: {
        associateTitle: 'Associate an organization with a person',
        associateSubtitle: 'Search for an existing contact or create a new one.',
        name: 'Organization Name',
        category: 'Category',
        role: 'Role',
        roleSubtitle: "Define the person's role in the organization.",
        title: 'The Organization ',
        personAssociate: ' has been successfully associated with the person ',
        see: 'See the organization',
      },
    },
  },
  paginate: 'Results {{current}} to {{on}} of {{total}}',
  errors: {
    nA: 'N/A',
    email: 'Email is invalid',
    emailRequired: 'Email is required',
    phoneNumber: 'Phone number is invalid',
  },
  placeHolders: {
    tripSelect: 'Select a trip',
    parcelSelect: 'Select a parcel',
    addParcelSelect: 'Add a parcel',
    vehicleCategoriesSelect: 'Select a category',
    vehicleSelect: 'Select a vehicle',
    emptyVehicleSelect: 'No vehicle',
    select: 'Select an option',
    query: 'Search...',
    queryContact: 'Search by contact name or code ...',
    queryWarehouse: 'Search by reference ...',
    search: 'Search',
    noResults: 'No results',
    noContactInformation: 'No contact information found',
    noAddressInformation: 'No address information found',
    headerSearch: 'Search a person or an organization...',
    subjectSearch: 'Person or organization',
    yourMessage: 'Your message...',
    searchLength: 'Search input must be at least {{length}} characters',
    inputThreeChars: 'Input at least 3 characters to search',
    categoryName: 'Nom de la catégorie',
    category: 'Category',
    value: 'Valeur',
    description: 'Add here any information you think is relevant',
    website: 'www.example.com',
    email: 'email@example.com',
    date: 'MM/DD/YYYY',
    phoneNumber: '555-555-5555',
  },
  genericNoResult: {
    defaultTitle: 'No result found',
    defaultSubtitle: 'Change your filters and try again',
    defaultLabel: 'Return back to Dashboard',
    peoples: {
      title: 'No people found',
      subtitle: 'Change your filters or add a people',
      label: 'Add a people.',
    },
    households: {
      title: 'No household found',
      subtitle: 'Change your filters or add a household',
      label: 'Add a household.',
    },
    organizations: {
      title: 'No organization found',
      subtitle: 'Change your filters or add a organization',
      label: 'Add a organization.',
    },
    events: {
      title: 'No events found',
      subtitle: 'Change your filters or add an event',
      label: 'Add an event.',
    },
    notes: {
      title: 'No notes found',
      subtitle: 'Change your filters or add a note',
      label: 'Add a note.',
    },
    trips: {
      title: 'No trips found',
      subtitle: 'Change your filters or add a trip',
      label: 'Add a trip.',
    },
    orders: {
      title: 'No orders found',
      subtitle: 'Change your filters or add an order',
      label: 'Add an order.',
    },
  },
  loading: 'Loading...',
  save: 'Save',
  toast: {
    error: 'An error occurred',
    tokenExpired: 'Logged out due to inactivity. Please login again',
    vehiculeAddSuccess: 'The vehicle has been added',
    vehiculeEditSuccess: 'The vehicle has been edited',
    tripAddSuccess: 'The trip has been added',
    tripEditSuccess: 'The trip has been edited',
    parcelAddSuccess: 'The parcel has been added',
    parcelEditSuccess: 'The parcel has been edited',
    orderEditSuccess: 'The order has been edited',
    addressAddSuccess: 'The address has been added',
    addressEditSuccess: 'The address has been edited',
    subjectAddSuccess: 'The subject has been added',
    personAddSuccess: 'The person has been added',
    personEditSuccess: 'The person has been edited',
    eventAddSuccess: 'The event has been added',
    eventEditSuccess: 'The event has been edited',
    noteAddSuccess: 'The note has been added',
    noteEditSuccess: 'The note has been edited',
    attendeeAddSuccess: 'The attendee has been added',
    attendeeEditSuccess: 'The attendee has been edited',
    collaboratorAddSuccess: 'The collaboration has been added',
    organizationMissing: 'You must select an organization name',
    organizationEditSuccess: 'The organization has been edited',
    organizationAddSuccess: 'The organization has been added',
    householdMissing: 'You must select a household name',
    householdEditSuccess: 'The household has been edited',
    householdAddSuccess: 'The household has been added',
    contactEditSuccess: 'The contact has been edited',
    contactAddSuccess: 'The contact has been added',
    categoryAddSuccess: 'The category has been added',
    productAddSuccess: 'The product has been added',
    connectionAddSuccess: 'The connection has been added',
    missingOrigin: 'You must select an origin',
    missingDestination: 'You must select a destination',
    missingVehicle: 'You must select a vehicle',
    missingVehicleCategory: 'You must select a category',
    missingAddress: 'You must select an address',
    missingContact: 'You must select a contact',
    missingRecipient: 'You must select a recipient',
    missingContactAddress: 'You must select an address',
    missingVariant: 'You must select a product',
    missingName: 'You must select a last name',
    missingFirstName: 'You must select a first name',
    missingTypeOrName: 'You must select a type or a name',
    missingField: 'You must fill all the fields',
    missingEventTitle: 'You must add a title',
    missingEventSubject: 'You must add an object',
    missingEventDate: 'You must add a date',
    missingEventParticipants: 'You must add at least one participant',
    missingContactInformationField: 'You must fill all the contact informations fields',
    missingStorageType: 'You must select a storage type',
    missingDate: 'You must select a date',
    missingReturnDate: 'You must select a return date',
    missingCategory: 'You must select a category',
    missingAttendee: 'You must select an attendee',
    missingStatus: 'You must select a status',
    existingAttendee: 'The attendee is already in use. Please choose a different attendee.',
    missingCollaborator: 'You must select a collaborator',
    existingCollaborator:
      'The collaborator is already in use. Please choose a different collaborator.',
    noVehiclesLinked: "You don't have any vehicle linked to your account",
    supplierGidRequired: 'You must select a supplier',
    imageAdded: 'Image added',
    imageRemoved: 'Image removed',
    formSuccess: 'Form saved',
    sameDatesError: 'The end of the repetition should be after the event’s end time',
  },
  common: {
    yes: 'Yes',
    no: 'No',
    add: 'Add',
    address: 'Address',
    date: 'Date',
    email: 'Email',
    phoneNumber: 'Phone',
    readMore: 'Read more',
    only: 'Only',
    account: 'Account',
    available: 'Available',
    unavailable: 'Unavailable',
    creation: 'Creation',
    at: 'at',
    me: 'Me',
    name: 'Name',
    firstname: 'Firstname',
    lastname: 'Lastname',
    deleteQuestion: 'Are you sure you would like to delete?',
    noAuthorized: 'You may not have the necessary permissions to access this page',
    buttons: {
      cancel: 'Cancel',
      save: 'Save',
      delete: 'Delete',
      confirm: 'Confirm',
      add: 'Add',
      edit: 'Edit',
      close: 'Close',
      advancedFilters: 'Advanced filters',
      apply: 'Apply',
      addPicture: 'Add a picture',
      next: 'Next',
      associate: 'Associate',
      dissociate: 'Dissociate',
      validate: 'Validate',
      filters: 'Filters',
    },
    title: {
      comments: 'Comments',
      default: 'Title',
    },
    stepCountTitle: 'Step {{step}}: {{title}}',
    filterByDate: 'Filter by date',
    startDate: 'Start date',
    endDate: 'End date',
    due: 'Reminder',
    noDue: 'No Reminder',
    calendar: {
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      today: 'Today',
      nextWeek: 'Next week',
      nextMonth: 'Next month',
      nextYear: 'Next year',
      previousWeek: 'Previous week',
      previousMonth: 'Previous month',
      previousYear: 'Previous year',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      day: 'Day',
      dayView: 'Day view',
      weekView: 'Week view',
      monthView: 'Month view',
      yearView: 'Year view',
      listView: 'List view',
    },
    errorMessages: {
      required: 'This field is required',
      name: 'Name is invalid',
      descriptionTooLong: 'Description is too long',
      noAddress: 'No address found',
      quantity: 'Quantity is invalid',
      weight: 'Weight is invalid',
      refRequired: 'Reference is required',
    },
    languages: { title: 'Language', french: 'French', english: 'English' },
  },
  status: {
    offline: 'Offline mode',
  },
  date: {
    hour: 'Time',
    capitalFormat: 'MM-JJ-AAAA',
    dateFormat: 'MM/dd/yyyy',
    dateOnlyFormat: 'MMMM d y',
    timeOnlyFormat: 'hh:mm a',
    dateAndTimeFormat: "MMM d y 'at' hh:mm a",
  },
  person: {
    title: 'People',
    buttons: {
      add: 'Add a person',
      addContact: 'Add a contact',
      create: 'Create a person',
    },
    collaborationList: 'Peoples ({{count}})',
  },
  organization: {
    title: 'Organizations',
    buttons: {
      add: 'Add an organization',
      create: 'Create an organization',
    },
    collaborationList: 'Organizations ({{count}})',
  },
  household: {
    title: 'Households',
    buttons: {
      add: 'Add an household',
      create: 'Create a household',
    },
    collaborationList: 'Households ({{count}})',
  },
  events: {
    properties: {
      title: 'Event',
      titlePlural: 'Events',
      eventTitle: 'Event title',
      events: 'Activities ({{count}})',
      description: 'Event Description',
      descriptionHint: 'Provide all the information that you consider relevant about this event.',
      startAt: 'Start date',
      endAt: 'End date',
      reminder: 'Reminder',
      participants: 'Participants',
      keyWords: 'Search by key words',
      eventName: 'Event name',
      form: 'Form',
      details: 'Details',
      participationMonetized: 'Participation',
      participations: 'Participants ({{count}})',
      participationsList: 'Participants list ({{count}})',
      comments: 'Discussion',
      visibility: 'Visibility',
      survey: 'Activity survey',
      participationSurvey: 'Participation survey',
      eventSubject: 'Object',
      participationPrice: 'Financial participation',
      monetized: 'Price',
      repetition: 'Repetition',
      endOfRepetition: 'End of repetition',
      recurring_plan: {
        every_day: {
          value: 'every_day',
          label: 'Every day',
        },
        every_month: {
          value: 'every_month',
          label: 'Every month',
        },
        every_week: {
          value: 'every_week',
          label: 'Every week',
        },
        every_biweek: {
          value: 'every_biweek',
          label: 'Every 2 weeks',
        },
        every_weekday: {
          value: 'every_weekday',
          label: 'Every week day (Mon-Fri)',
        },
        every_year: {
          value: 'every_year',
          label: 'Every year',
        },
        not_recurring: {
          value: 'not_recurring',
          label: 'Not recurring',
        },
        error: 'The end of the repetition should be after the event’s end time',
      },
      event_location: {
        title: 'In-person or Virtual?',
        subTitle: 'Specify whether the event takes place via in person or virtual.',
        location: 'In Person',
        virtual: 'Virtual',
        placeTitle: 'Event Location',
        placeSubtitle: 'By default, the event location is the same as the object.',
        address: {
          title: 'Is this event taking place at a different address?',
          add: 'Add an address',
          checkboxAddAdress: 'Add this address to this event',
          noAddress: 'No address for this event',
        },
      },
      resources: 'Resources',
      distributionMode: 'Distribution mode',
      formPlaceHolder: 'Choose a form',
      distributionPlaceholder: 'Choose a distribution',
      resourcePlaceholder: 'Choose a resource',
      modelPlaceHolder: 'Choose a model',
      participant: {
        placeHolder: 'Search by name',
        selectAll: 'Select all',
        add: 'Add participants',
      },
      eventCreated: 'Event created!',
      eventCreatedSubtitle: 'Congratulations, your event has been created.',
      seeEvent: 'See the event',
      confirmModal: {
        title: 'Confirm the distribution of ',
        description:
          'If you confirm the distribution, the system will automatically trigger an order. Please note, once confirmed, you will not be able to modify it anymore.',
      },
    },
    buttons: {
      add: 'Add an event',
      edit: 'Edit event',
    },
    noRecentEvents: 'No events',
    notes: 'Notes',
    createEvent: 'Create event',
    filterAccounts: 'Select accounts to display',
    allEvent: 'Display all accounts',
    currentEvent: 'Display {{account}} only ',
    actions: {
      goToday: 'Go to today',
      cannotFindEvent: 'Cannot find the event',
    },
  },
  notes: {
    noRecentNotes: 'No recent notes',
    default: 'Notes',
    singularDefault: 'Note',
    buttons: {
      add: 'Add a note',
      edit: 'Edit note',
    },
    properties: {
      noteTitle: 'Note title',
    },
  },
  comments: {
    title: 'Comments',
    actions: {
      outgoingMessage: 'Envoyer le message',
    },
  },
  warehouse: {
    properties: {
      program: 'Program',
      programPlaceholder: 'Select a program',
      from: 'Origin',
      fromPlaceholder: 'Select an origin',
      ref: 'Reference',
      refDate: 'Reference date',
      tags: 'Tags',
      note: 'Note',
      commandLines: 'Order lines',
      supply: 'Supply',
      distribution: 'Distribution',
      supplier: 'Supplier',
      noPrograms: 'No program found',
      noFrom: 'No organization found',
      noTags: 'No tags found',
      supplierProduct: 'Supplier product',
      supplierPlaceholder: 'Select a supplier',
      client: 'Client',
      clientPlaceholder: 'Select a client',
      howReceive: 'How do you want to receive it?',
      associatedVariants: 'Associated variants',
      supplierVariant: 'Supplier variant',
      resultsFind: 'Results found ({{count}})',
      clearFilters: 'Clear filters',
    },
    incoming: 'to be received',
    preparing: 'to be prepared',
    outgoing: 'to be shipped',
    noIncoming: 'No incoming',
    noOutgoing: 'No outgoing',
    pleaseContact: 'Please contact {{name}}',
    buttons: {
      withoutDate: 'Without date',
    },
  },
  filters: {
    title: 'Filters',
  },
  contacts: {
    noRecentContacts: 'No recent contacts',
  },
  receptions: {
    title: 'Receptions',
    buttons: {
      add: 'Add an reception',
    },
  },
  preparations: {
    title: 'Preparations',
    buttons: {
      add: 'Add an preparation',
    },
  },
  expeditions: {
    title: 'Expeditions',
    buttons: {
      add: 'Add an expedition',
    },
  },
  attendees: {
    title: 'Attendees',
    buttons: {
      add: 'Add an attendee',
      edit: 'Edit an attendee',
    },
    properties: {
      title: 'New attendee',
      response: 'Response to the event',
      presence: 'Attendance at the event',
      information: 'Information about attendance',
      add_distribution: 'Add a distribution',
      model_distribution: 'Distribution model',
      payment: {
        title: 'Payment',
        no_payment: 'No payment has been made.',
        payment_exists: 'A partial or full payment has been made.',
        payment_amount: 'Financial participation',
      },
      status: {
        title: 'Status',
        unknown: {
          value: 'unknown',
          label: 'Presence unknown',
        },
        attended: {
          value: 'attended',
          label: 'Present',
        },
        absent: {
          value: 'absent',
          label: 'Absent',
        },
        not_attending: {
          value: 'not_attending',
          label: 'Not participating',
        },
        attending: {
          value: 'attending',
          label: 'Will attend',
        },
        tentatively_attending: {
          value: 'tentatively_attending',
          label: 'Might participate',
        },
        will_attend: {
          value: 'attend',
          label: 'Will attend',
        },
        might_attend: {
          value: 'tentatively_attending',
          label: 'Might participate',
        },
        no_participation: {
          value: 'tentatively_attending',
          label: 'Not participating',
        },
      },
      paymentStatus: {
        title: 'Payment Status',
        pending: {
          value: 'pending',
          label: 'Pending',
        },
        settled: {
          value: 'settled',
          label: 'Settled',
        },
        canceled: {
          value: 'canceled',
          label: 'Canceled',
        },
      },
      noAttendees: 'No recent attendees',
    },
  },
  collaborations: {
    title: 'Collaborations',
    buttons: {
      add: 'Associate a person',
    },
    properties: {
      title: 'New collaboration',
      titlePerson: 'Associate a person',
      role: {
        title: 'Role of the person',
        placeholder: 'e.g.: Parent, child, roommate',
      },
      priority: {
        title: 'Priority',
        placeholder: 'Select priority',
        principal: {
          value: 'principal',
          label: 'Principal',
        },
        secondary: {
          value: 'secondaire',
          label: 'Secondary',
        },
      },
    },
  },
};
