// Utils
import { useTranslation } from 'react-i18next';

// Icons & Images
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-regular-svg-icons';

// Components
import Autocomplete from '../Autocomplete/Autocomplete';
import Property from '../Property/Property';

// Types & Interfaces
type TagSelectProps = {
  fetchTags: any;
  setFieldValue: Function;
  values: Object;
  accountId?: number;
};

const TagSelect = ({ fetchTags, setFieldValue, values, accountId }: TagSelectProps) => {
  const { t } = useTranslation('dashboard');

  return (
    <div className="flex flex-col">
      <Property
        haveGap
        title={t('subjects.properties.edit.tags')}
        bold
        content={
          <Autocomplete
            fetchCallback={fetchTags}
            autoClear
            autoFocus={false}
            setSelected={e => {
              if (!values?.tag_list.find(tag => tag?.name === e?.name)) {
                setFieldValue('tag_list', [...values?.tag_list, e?.name]);
              }
            }}
            specifiedAccountId={accountId}
            displayValue={false}
          />
        }
      />
      <div className="flex gap-2 flex-wrap mt-5">
        {values?.tag_list?.map(tag => (
          <div key={tag} className="flex items-center gap-2 px-2 py-1 rounded-lg bg-gray-200">
            <span>{tag}</span>
            <button
              type="button"
              onClick={() => {
                const updatedTagList = values?.tag_list.filter(
                  (selectedTag: any) => selectedTag !== tag,
                );
                setFieldValue('tag_list', updatedTagList);
              }}
              className="text-gray-600">
              <FontAwesomeIcon
                icon={faX}
                size="xl"
                className="mb-0.5"
                style={{ color: '#101928', fontSize: '12px' }}
              />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TagSelect;
