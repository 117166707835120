// Utils
import { useTranslation } from 'react-i18next';

type CompactNoResultsProps = {
  label?: string;
};

const CompactNoResults = ({ label }: CompactNoResultsProps) => {
  const { t } = useTranslation('dashboard');

  return (
    <p className="text-center text-back pb-2">
      <span>{label || t('placeHolders.noResults')}</span>
    </p>
  );
};

export default CompactNoResults;
