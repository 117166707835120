// Utils
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Styles
import '../../../src/style/index.scss';
import 'react-datepicker/dist/react-datepicker.css';

// Components
import AsyncSelect from '../AsyncSelect';
import Button from '../Buttons/Button';
import MainSelect from '../MainSelect/MainSelect';
import Property from '../Property/Property';
import TextInput from '../TextInput';
import IconHolder from '../IconHolder/IconHolder';
import SearchOrCreateButton from '../SeachOrCreateButton/SearchOrCreateButton';

// Icons & Images
import { ReactComponent as DefaultPersonFrame } from '@/assets/icons/default-person-frame.svg';

// Contexts
import { HeaderContext } from '@/contexts/headerContext';
import { fetchHouseholdsByName } from '@/utils/api/households';
import { fetchOrganizationsByName } from '@/utils/api/organizations';

// Types & Interfaces
type CustomStepProps = {
  accountId?: number;
  errors?: any;
  fetchCategories?: any;
  goToStep?: any;
  hashValue?: any;
  isSubmitting?: any;
  nextStep?: any;
  previousStep?: any;
  setFieldValue?: any;
  touched?: any;
  values?: any;
  newCollaborations?: any;
  submitForm?: any;
  isHousehold?: boolean;
  setIsCreateHousehold?: Function;
  setIsCreateOrganization?: Function;
};

const AssociatePerson = ({
  hashValue,
  goToStep,
  setFieldValue,
  accountId,
  fetchCategories,
  isHousehold,
  setIsCreateHousehold,
  setIsCreateOrganization,
  touched,
}: CustomStepProps) => {
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();
  const [searchShown, setSearchShown] = useState(false);
  const headerContext = React.useContext(HeaderContext);

  React.useEffect(() => {
    if (hashValue == 1) {
      headerContext.dispatch({
        type: 'SET_HEADER_PROPS',
        payload: {
          title: t('subjects.addHouseholdWizard.association'),
          hamburgerMenu: false,
          homePage: false,
          closeButton: false,
          canEdit: false,
          canGoBack: true,
        },
      });
    }
  }, []);

  const fetchHousehold = (query: string) =>
    fetchHouseholdsByName(accountId, query).then(response =>
      response.map((person: any) => ({
        value: person.gid,
        label: person.name,
      })),
    );
  const fetchOrganization = (query: string) =>
    fetchOrganizationsByName(accountId, query).then(response =>
      response.map((person: any) => ({
        value: person.gid,
        label: person.name,
      })),
    );

  const toggleMode = (toggle: boolean) => {
    setSearchShown(!toggle);
  };

  // Code here will run after *every* render
  useEffect(() => {
    goToStep(hashValue);
  }, [hashValue]);

  return (
    <div className="flex flex-col items-center justify-between h-full">
      <div className="flex flex-col gap-5 h-full mb-5">
        <IconHolder
          svgIcon={<DefaultPersonFrame />}
          header={
            isHousehold
              ? t('subjects.associateWizard.household.associateTitle')
              : t('subjects.associateWizard.organization.associateTitle')
          }
          subTitle={
            isHousehold
              ? t('subjects.associateWizard.household.associateSubtitle')
              : t('subjects.associateWizard.organization.associateSubtitle')
          }
        />
      </div>
      <div className="w-full gap-5 flex flex-col">
        <SearchOrCreateButton
          toggleSearch={toggleMode}
          searchShown={searchShown}
          searchFunction={() => {
            if (isHousehold) {
              setIsCreateHousehold && setIsCreateHousehold(false);
            } else {
              setIsCreateOrganization && setIsCreateOrganization(false);
            }
          }}
          createFunction={() => {
            if (isHousehold) {
              setIsCreateHousehold && setIsCreateHousehold(true);
            } else {
              setIsCreateOrganization && setIsCreateOrganization(true);
            }
          }}
        />
        {!searchShown ? (
          <div className="w-full gap-5 flex flex-col">
            <Property
              haveGap
              bold
              title={t('placeHolders.search')}
              content={
                <AsyncSelect
                  type="select"
                  name="collaborator_gid"
                  placeholder={t(
                    'subjects.householdWizard.addCollaboration.fields.person_gid.placeholder',
                  )}
                  setFieldValue={setFieldValue}
                  afterSelect={option => {
                    setFieldValue('collaborator_gid', option?.value);
                  }}
                  loadOptions={isHousehold ? fetchHousehold : fetchOrganization}
                  isClearable
                  defaultOptions
                />
              }
            />
          </div>
        ) : (
          <div className="w-full gap-2 flex flex-col">
            <Property
              title={
                isHousehold
                  ? t('subjects.associateWizard.household.name')
                  : t('subjects.associateWizard.organization.name')
              }
              bold={true}
              required={true}
              content={<TextInput type="text" name="name" touched={touched?.name} />}
            />
            <Property
              title={
                isHousehold
                  ? t('subjects.associateWizard.household.category')
                  : t('subjects.associateWizard.organization.category')
              }
              haveGap
              bold
              required={true}
              content={
                <AsyncSelect
                  type="select"
                  name="category_id"
                  placeholder={t(
                    'subjects.householdWizard.household.fields.category_id.placeholder',
                  )}
                  setFieldValue={setFieldValue}
                  loadOptions={fetchCategories}
                  isClearable
                  defaultOptions
                />
              }
            />
          </div>
        )}
        <Property
          title={
            isHousehold
              ? t('subjects.associateWizard.household.role')
              : t('subjects.associateWizard.organization.role')
          }
          hint={
            isHousehold
              ? t('subjects.associateWizard.household.roleSubtitle')
              : t('subjects.associateWizard.organization.roleSubtitle')
          }
          haveGap
          bold
          content={
            <TextInput
              type="text"
              name="title"
              placeholder={t(
                'subjects.householdWizard.addCollaboration.fields.person_title.placeholder',
              )}
            />
          }
        />
        <Property haveGap bold content={<MainSelect name="main" />} />
      </div>
      <div className="w-full flex flex-col pb-10 gap-3 translate-y-24">
        <div>
          <Button
            type="submit"
            text={t('subjects.associateWizard.associate')}
            variant="primary"
            size="large"
          />
        </div>
        <div>
          <Button
            type="button"
            onClick={() => navigate('/')}
            text={t('common.buttons.cancel')}
            variant="secondaryOrange"
            size="large"
          />
        </div>
      </div>
    </div>
  );
};

export default AssociatePerson;
