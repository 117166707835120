// Utils
import { useTranslation } from 'react-i18next';
import i18n from '../../localization';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';

// Components
import AsyncSelect from '../../components/AsyncSelect';
import Property from '../../components/Property/Property';
import TextInput from '../../components/TextInput';

// API
import axios from 'axios';

// Types & Interfaces
interface FormikTypes {
  addresses: {
    info: string;
    type: string;
    place_name: string;
  }[];
}

type AddressFormProps = {
  index: number;
  remove?: any;
  setFieldValue?: any;
  keepDelete?: boolean;
  personInfo?: any;
};

const AddressForm = ({
  index,
  remove,
  setFieldValue,
  keepDelete = true,
  personInfo,
}: AddressFormProps) => {
  const { t } = useTranslation('dashboard');
  const { values } = useFormikContext<FormikTypes>();

  const fetchAddress = async (query: string) =>
    axios
      .get(
        encodeURI(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${
            import.meta.env.VITE_MAPBOX_TOKEN
          }&autocomplete=true&country=CA&language=${i18n.language}`,
        ),
      )
      .then(res =>
        res.data.features.map((feature: any) => ({
          ...feature,
          value: feature.id,
          label: feature.place_name,
        })),
      );

  useEffect(() => {
    if (personInfo) {
      setFieldValue(`addresses.${index}.address`, values.addresses[index].address);
    }
  }, [personInfo, index, setFieldValue, values]);

  return (
    <div key={index} className="flex flex-col h-sreen gap-5 justify-between w-full">
      <div className="flex flex-col gap-6">
        <Property
          haveGap={true}
          title={t('subjects.addPersonForm.addressInput')}
          bold={true}
          content={
            <Property
              content={
                <AsyncSelect
                  type="select"
                  name={`addresses.${index}.address`}
                  placeholder={t('trips.add.addressPlaceholder')}
                  setFieldValue={setFieldValue}
                  loadOptions={fetchAddress}
                  isClearable
                  hideDropdownIndicator
                  minLength={3}
                  noOptionsMessage={() => t('common.errorMessages.noAddress')}
                  deepDataObject
                  values={values.addresses[index].place_name}
                />
              }
            />
          }
        />
        <Property
          haveGap={true}
          bold={true}
          title={t('subjects.addPersonForm.addressDescription')}
          content={
            <div className="relative">
              <TextInput
                type="text"
                name={`addresses.${index}.street2`}
                placeholder={t('subjects.addPersonForm.addressDescriptionExample')}
              />
            </div>
          }
        />
        <Property
          haveGap={true}
          title={t('subjects.addPersonForm.addressLabel')}
          bold={true}
          content={
            <TextInput
              type="text"
              name={`addresses.${index}.name`}
              placeholder={t('subjects.addPersonForm.contactLabel')}
            />
          }
        />
      </div>
      {keepDelete && (
        <div className="w-2/10 m-auto pl-3 text-orange-500">
          <button type="button" className="secondary" onClick={() => remove(index)}>
            {t('common.buttons.delete')}
          </button>
        </div>
      )}
    </div>
  );
};

export default AddressForm;
