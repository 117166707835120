import React from 'react';

import { classNames } from '@/utils/utils';

import { ReactComponent as RightArrowIcon } from '@/assets/icons/arrow-right.svg';
import { Link } from 'react-router-dom';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string | any;
  onClick?: () => void;
  link?: string;
  size?: 'small' | 'medium' | 'large' | 'square' | 'largeSquare';
  variant?:
    | 'primary'
    | 'primaryInverted'
    | 'secondary'
    | 'secondaryOrange'
    | 'tertiary'
    | 'success'
    | 'secondaryColor'
    | 'info'
    | 'error';
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  arrowIcon?: boolean;
  capitalize?: boolean;
  shallowDisabled?: boolean;
  loading?: boolean;
}

const ButtonContent = ({
  text,
  size,
  variant,
  link,
  icon,
  arrowIcon,
  capitalize,
  shallowDisabled,
  loading,
  rightIcon,
  ...props
}: ButtonProps) => {
  return (
    <button
      {...props}
      disabled={props.disabled || shallowDisabled || loading}
      className={classNames(
        'rounded-lg flex justify-center items-center gap-1 h3 z-20',
        size === 'square' ? 'aspect-square' : '',
        size === 'largeSquare' ? 'w-[56px] h-[56px] shrink-0' : '',
        size === 'small' ? 'w-[40px] h-[40px]' : '',
        size === 'medium' ? 'w-[184px] h-[56px]' : '',
        size === 'large' ? 'w-full h-[56px]' : '',
        variant === 'primary' && !props.disabled ? 'bg-primaryColor text-white' : '',
        variant === 'primaryInverted' && !props.disabled ? 'bg-white text-primaryColor' : '',
        variant === 'secondary' && !props.disabled ? 'border border-primaryColor' : '',
        variant === 'secondaryOrange' && !props.disabled
          ? 'border border-primaryColor text-orange-500'
          : '',
        variant === 'tertiary' && !props.disabled
          ? '!bg-primaryColor text-white border border-white'
          : '',
        variant === 'success' && !props.disabled ? 'bg-success text-white' : '',
        variant === 'secondaryColor' && !props.disabled ? 'bg-secondaryColor text-white' : '',
        variant === 'info' && !props.disabled ? 'bg-strokeColor text-titleTextColor' : '',
        variant === 'error' && !props.disabled ? 'bg-redInfo text-white' : '',
        props.disabled ? 'text-disabledText bg-disabledBg' : '',
        capitalize ? 'capitalize' : '',
      )}
      type={props.type || 'button'}>
      {loading ? (
        <svg
          className="animate-spin h-5 w-5 text-slate-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24">
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      ) : (
        <React.Fragment>
          {icon && icon}
          {text}{' '}
          {link && !props.disabled && arrowIcon && (
            <RightArrowIcon
              className={variant === 'primary' ? 'stroke-white' : 'stroke-primaryColor'}
            />
          )}
          {rightIcon && rightIcon}
        </React.Fragment>
      )}
    </button>
  );
};

const Button = ({
  link,
  state,
  ...props
}: ButtonProps & {
  state?: any;
}) => {
  if (link) {
    return (
      <Link
        to={link}
        className={classNames(props.size !== 'largeSquare' ? 'w-full' : '')}
        state={state || {}}>
        <ButtonContent link={link} {...props} />
      </Link>
    );
  }
  return <ButtonContent {...props} />;
};

export default Button;
