import { useCallback, useEffect, useState } from 'react';
import { LogisticModeType } from '../../types/config/config';
import { parse_link_header } from '../hashUtils';
import { getYearMonthDay } from '../time';
import { getParamString } from '../utils';
import api from './api';
import { useSelectedAccount } from '@/hooks/useAccounts';

export const fetchWarehouses = async ({
  account_id,
  page = 1,
  limit = 20,
  type,
  addressId,
  addressFromId,
  startDate,
  endDate,
  withDate,
  originSearch,
  destinationSearch,
  reference,
  program,
  middleman,
  prepared,
  supplier,
  buyer,
}: {
  account_id: number;
  type: LogisticModeType;
  page?: number;
  limit?: number;
  addressId?: number;
  addressFromId?: number;
  startDate?: Date;
  endDate?: Date;
  withDate?: boolean;
  originSearch?: string;
  destinationSearch?: string;
  reference?: string;
  program?: string;
  middleman?: string;
  prepared?: boolean;
  supplier?: string;
  buyer?: string;
}) => {
  const params = {
    ...(addressId ? { 'q[item_inventories_address_id_eq]': addressId } : {}),
    ...(addressFromId ? { 'q[item_inventories_address_from_id_eq]': addressFromId } : {}),
    ...(startDate ? { 'q[item_inventories_location_at_gteq]': getYearMonthDay(startDate) } : {}),
    ...(endDate ? { 'q[item_inventories_location_at_lteq]': getYearMonthDay(endDate) } : {}),
    ...(withDate ? { 'q[item_inventories_location_at_not_null]': '1' } : {}),
    ...(reference
      ? { 'q[reference_or_item_inventories_original_source_reference_cont]': reference }
      : {}),
    ...(program ? { 'q[program_id_eq]': program } : {}),
    ...(middleman ? { 'q[middleman_id_eq]': middleman } : {}),
    ...(originSearch ? { near: originSearch } : {}),
    ...(destinationSearch ? { near: destinationSearch } : {}),
    ...(prepared ? { prepared } : {}),
    ...(supplier
      ? {
          'q[seller_of_Organization_type_name_or_seller_of_Person_type_name_cont]': supplier,
        }
      : {}),
    ...(buyer
      ? {
          'q[buyer_of_Organization_type_name_or_buyer_of_Person_type_name_cont]': buyer,
        }
      : {}),
  };
  const paramString = getParamString(params);

  const rawResponse = await api
    .get(
      `${
        import.meta.env.VITE_API
      }/${account_id}/api/v1/warehouses/${type}_orders.json?page=${page}&per_page=${limit}${
        paramString.length ? '&' : ''
      }${paramString}`,
    )
    .then(response => {
      const properties = {
        links: parse_link_header(response.headers['link']),
        total: parseInt(response.headers['x-total'] || '0'),
        page: parseInt(response.headers['x-page'] || '1') || page,
        per_page: parseInt(response.headers['x-per-page'] || '0'),
      };
      return { properties: properties, data: response.data };
    });
  return rawResponse;
};

export const fetchWarehousePrograms = async (account_id: number, page = 1, limit = 50) => {
  const rawResponse = await api
    .get(
      `${
        import.meta.env.VITE_API
      }/${account_id}/api/v1/programs.json?page=${page}&per_page=${limit}`,
    )
    .then(response => {
      const properties = {
        links: parse_link_header(response.headers['link']),
        total: parseInt(response.headers['x-total'] || '0'),
        page: parseInt(response.headers['x-page'] || '1') || page,
        per_page: parseInt(response.headers['x-per-page'] || '0'),
      };
      return { properties: properties, data: response.data };
    });
  return rawResponse;
};

export const editWarehouseItem = async (account_id: number, item_id: number, body: any) => {
  if (!account_id) throw Error('No account id');
  if (!item_id) throw Error('No inventory id');
  const rawResponse = await api.put(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/warehouses/incoming_items/${item_id}.json`,
    body,
  );
  return rawResponse;
};

export const fetchReceptionsBySupply = async (account_id: number, query: string) => {
  if (!query) throw Error('No query');
  const rawResponse = await api.get(
    `${
      import.meta.env.VITE_API
    }/${account_id}/api/v1/warehouses/incoming_orders.json?page=1&per_page=10&q[reference_or_item_inventories_original_source_reference_cont]=${query}`,
  );
  return rawResponse.data;
};

export const fetchPreparationsBySupply = async (account_id: number, query: string) => {
  if (!query) throw Error('No query');
  const rawResponse = await api.get(
    `${
      import.meta.env.VITE_API
    }/${account_id}/api/v1/warehouses/outgoing_orders.json?page=1&per_page=10&q[reference_or_item_inventories_original_source_reference_cont]=${query}&prepared=false`,
  );
  return rawResponse.data;
};

export const fetchExpeditionsBySupply = async (account_id: number, query: string) => {
  if (!query) throw Error('No query');
  const rawResponse = await api.get(
    `${
      import.meta.env.VITE_API
    }/${account_id}/api/v1/warehouses/outgoing_orders.json?page=1&per_page=10&q[reference_or_item_inventories_original_source_reference_cont]=${query}&prepared=true`,
  );
  return rawResponse.data;
};

export const findWarehouseTotalItems = () => {
  const { accountId } = useSelectedAccount();
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const fetchWarehouseTotalItems = async () => {
      try {
        const response1 = await fetchWarehouses({
          account_id: accountId,
          type: LogisticModeType.incoming,
          prepared: undefined,
          limit: 1,
        });
        const data1 = response1.properties.total;
        const response2 = await fetchWarehouses({
          account_id: accountId,
          type: LogisticModeType.outgoing,
          prepared: false,
          limit: 1,
        });
        const data2 = response2.properties.total;
        const response3 = await fetchWarehouses({
          account_id: accountId,
          type: LogisticModeType.outgoing,
          prepared: true,
          limit: 1,
        });
        const data3 = response3.properties.total;
        setTotal(data1 + data2 + data3);
      } catch (error) {
        throw new Error('Something went wrong!!');
      }
    };

    fetchWarehouseTotalItems();
  }, [accountId]);

  return total;
};

export const findIncomingTotalItems = () => {
  const { accountId } = useSelectedAccount();
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchWarehouses({
          account_id: accountId,
          type: LogisticModeType.incoming,
          prepared: true,
          limit: 1,
        });

        setTotal(response.properties.total);
      } catch (error) {
        throw new Error('Something went wrong!!');
      }
    })();
  }, [accountId]);

  return total;
};

export const findOutgoingTotalItems = () => {
  const { accountId } = useSelectedAccount();
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchWarehouses({
          account_id: accountId,
          type: LogisticModeType.outgoing,
          prepared: true,
          limit: 1,
        });

        setTotal(response.properties.total);
      } catch (error) {
        throw new Error('Something went wrong!!');
      }
    })();
  }, [accountId]);

  return total;
};

export const findPreparingTotalItems = () => {
  const { accountId } = useSelectedAccount();
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchWarehouses({
          account_id: accountId,
          type: LogisticModeType.outgoing,
          prepared: false,
          limit: 1,
        });

        setTotal(response.properties.total);
      } catch (error) {
        throw new Error('Something went wrong!!');
      }
    })();
  }, [accountId]);

  return total;
};
