// Utils
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import CompactNoResults from '../NoResult/CompactNoResults';
import FormModal from '../Modal/FormModal';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';

const SubjectList = ({
  AddEditComponent,
  title,
  item,
  children,
  subject,
  refetchValues,
}: {
  AddEditComponent?: any;
  title?: string;
  item?: any;
  children: any;
  subject?: any;
  refetchValues?: any;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation('dashboard');
  return (
    <div className="pt-0">
      <div className="flex flex-col mb-2">
        {title && (
          <div className="flex items-center justify-between">
            <h3 className="text-titleTextColor">{title}</h3>
          </div>
        )}
        {AddEditComponent && item && (
          <>
            <button
              type="button"
              className="ml-auto -mt-5 flex flex-row gap-2 "
              onClick={() => setIsOpen(true)}>
              <FontAwesomeIcon icon={faCirclePlus} style={{ color: '#F87315', fontSize: '21px' }} />
              <span className="text-orange-500 font-semibold"> {t('common.buttons.add')}</span>
            </button>
            <FormModal open={isOpen} setOpen={setIsOpen} isOverFlow={false}>
              <AddEditComponent
                item={item}
                setIsOpen={setIsOpen}
                subject={subject}
                refetchValues={refetchValues}
              />
            </FormModal>
          </>
        )}
      </div>
      {children.length === 0 && (
        <div className="flex items-start">
          <CompactNoResults />
        </div>
      )}
      {children && <ul>{children}</ul>}
    </div>
  );
};

export default SubjectList;
