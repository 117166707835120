// Utils
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

// Components
import ContactInformationTypeSelect from '../ContactInformationTypeSelect/ContactInformationTypeSelect';
import MainSelect from '../MainSelect/MainSelect';
import Property from '../Property/Property';
import TextInput from '../TextInput';

type ContactInformationProps = {
  index?: number;
  remove?: any;
  push?: () => void;
  item?: any;
  touched?: any;
  nextStepAttempted?: boolean;
};

const ContactInformation = ({
  index,
  remove,
  touched,
  nextStepAttempted,
}: ContactInformationProps) => {
  const { t } = useTranslation('dashboard');
  const [changeType, setChangeType] = useState<string>('Email');

  return (
    <div key={index} className="flex flex-col h-sreen gap-5 justify-between w-full">
      <div className="flex flex-row w-full mb-6">
        <div className="w-full mr-3 flex flex-col ">
          <div className="flex-auto w-75 mt-4 ">
            {changeType == 'Email' ? (
              <TextInput
                setEmail="email"
                touched={touched?.contact_informations}
                nextStepAttempted={nextStepAttempted}
                name={`contact_informations.${index}.info`}
                placeholder={t(`subjects.properties.contactInformations.placeholder`)}
              />
            ) : (
              <TextInput
                setEmail="text"
                name={`contact_informations.${index}.info`}
                placeholder={t(`subjects.properties.contactInformations.placeholder`)}
              />
            )}
          </div>

          <div className="flex flex-row gap-2">
            <div className="w-full mt-4 h-12 ">
              <MainSelect name={`contact_informations.${index}.main`} />
            </div>
            <div className="w-full mt-1">
              <Property
                bold={true}
                content={
                  <ContactInformationTypeSelect
                    name={`contact_informations.${index}.type`}
                    setChangeType={setChangeType}
                  />
                }
              />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="w-full">
              <Property
                bold={true}
                content={
                  <TextInput
                    placeholder={t('subjects.addPersonForm.contactLabel')}
                    type="text"
                    name={`contact_informations.${index}.label`}
                  />
                }
              />
            </div>
          </div>
          <div className="w-2/10 m-auto pl-3 mt-6 text-orange-500">
            <button type="button" className="secondary" onClick={() => remove(index)}>
              {t('common.buttons.delete')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInformation;
