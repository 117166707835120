const Spinner = ({ color = 'bg-primaryColor' }: { color?: string }) => {
  return (
    <div
      className="fixed w-full h-screen flex items-center justify-center bg-white z-50"
      id="suspense-spinner">
      <div className="flex items-center justify-center space-x-2">
        <div className={`w-4 h-4 rounded-full animate-pulse ${color}`}></div>
        <div className={`w-4 h-4 rounded-full animate-pulse ${color}`}></div>
        <div className={`w-4 h-4 rounded-full animate-pulse ${color}`}></div>
      </div>
    </div>
  );
};

export default Spinner;
