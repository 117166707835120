import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const getElapsedTime = (time: Date) => {
  const { t } = useTranslation('common');
  const now = new Date();

  let timeDiff = now.getTime() - time.getTime();
  timeDiff = timeDiff / 1000;

  const days = Math.floor(timeDiff / 86400);
  timeDiff -= days * 86400;

  if (days > 1) {
    return t('time.days_other', { count: days });
  } else if (days === 1) {
    return t('time.days_one', { count: days });
  }
  const hours = Math.floor(timeDiff / 3600) % 24;
  timeDiff -= hours * 3600;

  if (hours > 1) {
    return t('time.hours_other', { count: hours });
  } else if (hours === 1) {
    return t('time.hours_one', { count: hours });
  }
  const minutes = Math.floor(timeDiff / 60) % 60;
  timeDiff -= minutes * 60;

  if (minutes > 1) {
    return t('time.minutes_other', { count: minutes });
  } else if (minutes === 1) {
    return t('time.minutes_one', { count: minutes });
  } else if (minutes === 0) {
    return t('time.now');
  }

  return t('time.timeAgo');
};

export const getHoursMinutes = (date: Date) =>
  `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;

export const getYearMonth = (date: Date) =>
  `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}`;

/**
 * Alias to formatDateHandler
 */
export const getYearMonthDay = (date: Date | string) => {
  if (!date) return '';
  const dateFormat = 'YYYY-MM-DD';
  const newDate = moment(date, dateFormat).format(dateFormat);
  return newDate;
};

export const isSameDay = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

/**
 * Compare two dates and return true if date1 is at least one day before date2
 * Precise to the minute
 * @param date1 first date to compare
 * @param date2 second date to compare
 * @returns true if date1 is at least one day before date2
 */
export const isBeforeMinute = (date1: Date, date2: Date) => {
  const millisecondsPerMinutes = 1000 * 60;
  const millisBetween = date2.getTime() - date1.getTime();
  const minutes = millisBetween / millisecondsPerMinutes;

  return Math.floor(minutes) > 0;
};

/**
 * Compare two dates and return true if date1 is at least one day before date2
 * Precise to the day
 * @param date1 first date to compare
 * @param date2 second date to compare
 * @returns true if date1 is at least one day before date2
 */
export const isBeforeDay = (date1: Date, date2: Date) => {
  const date1Copy = new Date(date1);
  date1Copy.setHours(0, 0, 0, 0);
  const date2Copy = new Date(date2);
  date2Copy.setHours(0, 0, 0, 0);
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const millisBetween = date2Copy.getTime() - date1Copy.getTime();
  const days = millisBetween / millisecondsPerDay;

  return Math.floor(days) > 0;
};

/**
 * Compare two dates and return true if date1 is at least one day after date2
 * @param date1 first date to compare
 * @param date2 second date to compare
 * @returns true if date1 is at least one day after date2
 */
export const isAfterDay = (date1: Date, date2: Date) => {
  const date1Copy = new Date(date1);
  date1Copy.setHours(0, 0, 0, 0);
  const date2Copy = new Date(date2);
  date2Copy.setHours(0, 0, 0, 0);
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const millisBetween = date2Copy.getTime() - date1Copy.getTime();
  const days = millisBetween / millisecondsPerDay;

  return Math.floor(days) < 0;
};

/**
 * Compare three dates and return if date2 is between date1 and date3.
 * Precise to the minute
 * @param date1 first date to compare
 * @param date2 second date to compare
 * @param date3 third date to compare
 * @returns true if date1 is at least one day before date2
 */
export const isBetween = (date1: Date, date2: Date, date3: Date) => {
  const millisecondsPerMinutes = 1000 * 60;
  const date1Minutes = date1.getTime() / millisecondsPerMinutes;
  const date2Minutes = date2.getTime() / millisecondsPerMinutes;
  const date3Minutes = date3.getTime() / millisecondsPerMinutes;

  return (
    Math.floor(date1Minutes) < Math.floor(date2Minutes) &&
    Math.floor(date2Minutes) < Math.floor(date3Minutes)
  );
};

export const countManyDays = (date1: Date, date2: Date) => {
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const millisBetween = date2.getTime() - date1.getTime();
  const days = millisBetween / millisecondsPerDay;

  return Math.floor(days);
};

export const getFirstDayOfWeek = (date: Date) =>
  new Date(
    new Date(date).setDate(
      new Date(date).getDate() + (new Date(date).getDay() == 0 ? -6 : 1) - new Date(date).getDay(),
    ),
  );

export const getLastDayOfWeek = (date: Date) =>
  new Date(
    new Date(date).setDate(
      new Date(date).getDate() + (new Date(date).getDay() == 0 ? 0 : 7) - new Date(date).getDay(),
    ),
  );

export const getCurrentWeek = (date: Date) =>
  Array(7)
    .fill(0)
    .map((_, i) => new Date(date.setDate(date.getDate() + (i > 0 ? 1 : 0))));

export const roundToNearestMinutes = (date: Date, minutes: number) => {
  const copy = new Date(date.getTime());
  const ms = minutes * 60 * 1000;
  const rounded = Math.round(copy.getTime() / ms) * ms;
  copy.setTime(rounded);
  return copy;
};

export const addDays = (date: Date, days: number) => {
  const copy = new Date(date.getTime());
  copy.setDate(copy.getDate() + days);
  return copy;
};

export const addHours = (date: Date, hours: number) => {
  const copy = new Date(date.getTime());
  copy.setHours(copy.getHours() + hours);
  return copy;
};

export const addMinutes = (date: Date, minutes: number) => {
  const copy = new Date(date.getTime());
  copy.setMinutes(copy.getMinutes() + minutes);
  return copy;
};

export const removeDays = (date: Date, days: number) => {
  const copy = new Date(date.getTime());
  copy.setDate(copy.getDate() - days);
  return copy;
};

export const removeHours = (date: Date, hours: number) => {
  const copy = new Date(date.getTime());
  copy.setHours(copy.getHours() - hours);
  return copy;
};

export const removeMinutes = (date: Date, minutes: number) => {
  const copy = new Date(date.getTime());
  copy.setMinutes(copy.getMinutes() - minutes);
  return copy;
};

export const formatDateHandler = (date: Date | string) => {
  if (!date) return '';
  const dateFormat = 'YYYY-MM-DD';
  const newDate = moment(date, dateFormat).format(dateFormat);
  return newDate;
};

export const isDateTimeAfter = (firstDate: Date, secondDate: Date) => {
  if (
    moment(firstDate).isSameOrAfter(secondDate, 'hour') &&
    (moment(firstDate).isAfter(secondDate, 'hour') ||
      (moment(firstDate).isSame(secondDate, 'hour') &&
        moment(firstDate).isAfter(secondDate, 'minute')))
  ) {
    return true;
  } else {
    return false;
  }
};
