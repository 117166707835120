import { AddressType } from '../localization/localization';
import { SubjectGidType, SubjectType } from '../subject/subject';

export enum EventIntent {
  event,
  note,
  task,
}

export type ParticipationType = {
  gid: string;
  attendee: {
    gid: string;
    name: string;
  };
};

export interface EventType {
  id: number;
  account_id: number;
  intent: EventIntent;
  gid: string;
  address: AddressType;
  subject_gid: string;
  title: string;
  due_at: string;
  start_at: string;
  end_at: string;
  completed: boolean;
  completed_at: string;
  participants: Array<SubjectType>;
  participations: Array<EventAttendee>;
  body: string;
  text_summary: string;
  has_survey: boolean;
  has_participation_survey: boolean;
  subject: SubjectGidType;
  monetized: boolean;
  participation_price: number;
  participation_price_currency: string;
  distribution: Object;
}
export interface NoteType {
  id: number;
  account_id: number;
  intent: EventIntent;
  gid: string;
  subject_gid: string;
  title: string;
  due_at: string;
  completed: boolean;
  completed_at: string;
  participants: Array<SubjectType>;
  participations: Array<EventAttendee>;
  body: string;
  text_summary: string;
  subject: SubjectGidType;
  monetized: boolean;
  participation_price: number;
  participation_price_currency: string;
}

export interface EventAttendee {
  id: number;
  monetized: boolean;
  status: string;
  created_at: string;
  updated_at: string;
  attendee: {
    class_type: string;
    email: string;
    gid: string;
    name: string;
    phone_number: string;
    picture_url: string;
  };
  price: number;
  price_currency: string;
  payment_status: string;
  distribution?: any;
}

export enum EventAttendeeStatusType {
  unknown = 'unknown',
  attended = 'attended',
  absent = 'absent',
  not_attending = 'not_attending',
  attending = 'attending',
  tentatively_attending = 'tentatively_attending',
}

export enum EventAttendeePaymentStatusType {
  pending = 'pending',
  settled = 'settled',
  canceled = 'canceled',
}

export enum EventRecurringPlanType {
  every_day = 'every_day',
  every_month = 'every_month',
  every_week = 'every_week',
  every_biweek = 'every_biweek',
  every_weekday = 'every_weekday',
  every_year = 'every_year',
  not_recurring = 'not_recurring',
}
