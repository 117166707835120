type IconHolderProps = {
  svgIcon?: any;
  header?: string;
  subTitle?: string;
};

const IconHolder = ({ svgIcon, header, subTitle }: IconHolderProps) => {
  return (
    <div className="flex flex-col gap-5 text-center">
      <div className="mx-auto">{svgIcon}</div>
      <div>
        <h1 className="mb-1 font-semibold">{header}</h1>
        <h3 className="text-slate-500 font-normal">{subTitle}</h3>
      </div>
    </div>
  );
};

export default IconHolder;
