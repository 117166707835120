import api from './api';

import { Option, SubjectEnum, SurveyType } from '@/types';

export const fetchForms = async (
  account_id: number,
  subjectGid: string,
): Promise<Array<SurveyType>> => {
  if (!account_id || !subjectGid) throw Error('No account_id or subjectGid');
  const rawResponse = await api.get(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/forms/surveys.json?gid=${encodeURIComponent(
      subjectGid,
    )}`,
  );
  return rawResponse.data;
};

export const fetchSurveys = async (
  account_id: number,
  type: 'survey' | 'participation',
  subjectType?: SubjectEnum | string,
): Promise<Array<Option>> => {
  if (!account_id || !type) throw Error('No account_id or type');

  const rawResponse = await api.get(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/forms/surveys/list_for_kind.json?kind=${
      type === 'participation'
        ? 'participation'
        : subjectType === 'Organization'
        ? 'comment_organization'
        : subjectType === 'Household'
        ? 'comment_household'
        : 'comment_person'
    }`,
  );

  return rawResponse.data;
};
