export default {
  title: 'Contacts',
  welcome: 'Bienvenue',
  hello: 'Bonjour,',
  poweredBy: 'Propulsé par',
  toucan: 'Toucan Solutions',
  actions: {
    transport: [
      {
        title: 'Accueil',
        icon: '',
        url: '/dashboard',
      },
      {
        title: 'Envois',
        myTitle: 'Mes envois',
        icon: 'order',
        url: 'orders',
      },
      {
        title: 'Véhicules',
        myTitle: 'Mes véhicules',
        icon: 'camion',
        url: 'vehicules',
      },
      {
        title: 'Trajets',
        myTitle: 'Mes trajets',
        icon: 'pushpin',
        url: 'trips',
        state: ['account_only'],
      },
    ],
    collaboration: [
      {
        title: 'Accueil',
        icon: '',
        url: '/dashboard',
      },
      {
        title: 'Événements',
        icon: '',
        url: '/events',
      },
      {
        title: 'Scan',
        icon: '',
        url: 'scan',
      },
    ],
    logistic: [
      {
        title: 'Accueil',
        icon: '',
        url: '/dashboard',
      },
      {
        title: 'Réception',
        icon: '',
        url: 'receptions',
      },
      {
        title: 'Préparation',
        icon: '',
        url: 'preparations',
      },
      {
        title: 'Expédition',
        icon: '',
        url: 'expeditions',
      },
      {
        title: 'Scan',
        icon: '',
        url: 'scan',
      },
    ],
  },
  urls: {
    parcels: 'order actifs',
    trips: 'Mes trajets',
    vehicles: 'Mes véhicules',
    addVehicle: 'Ajouter un véhicule',
    editVehicle: 'Modifier un véhicule',
    addTrip: 'Ajouter un trajet',
    addEvent: 'Nouvel événement',
    addNote: 'Ajouter une note',
    editEvent: 'Modifier un événement',
    editNote: 'Modifier une note',
    editTrip: 'Modifier un trajet',
    addParcel: 'Ajouter un envoi',
    editParcel: 'Modifier un envoi',
    editOrder: 'Modifier une commande',
    addSubject: 'Ajouter une personne/organisation',
    addPeople: 'Ajouter une personne',
    addOrganization: 'Ajouter une organisation',
    addHousehold: 'Ajouter un ménage',
    addHousehold2: 'Nouveau ménage',
    editPeople: 'Modifier une personne',
    editOrganization: 'Modifier une organisation',
    editHousehold: 'Modifier un ménage',
    addAppro: 'Ajouter un approvisionnement',
    addDistri: 'Nouvelle commande',
  },
  dashboard: {
    actions: {
      transport: [
        {
          title: 'Ajouter un trajet',
          icon: 'camion',
          url: 'trips/add',
        },
        {
          title: 'Ajouter un envoi',
          icon: 'order',
          url: 'order/add',
          state: '{"firstStep":"true"}',
        },
      ],

      collaboration: [
        {
          title: 'Nouvelle personne',
          icon: 'addPerson',
          url: 'subjects/add?person',
        },
        {
          title: 'Nouvelle organisation',
          icon: 'addOrganization',
          url: 'subjects/add?organization',
        },
      ],
      logistic: [
        {
          title: 'Ajouter une commande',
          icon: 'add',
          url: 'order/add',
          state: '{"resetNewParcel": "true"}',
        },
      ],
    },
    nextTrips: 'Prochains trajets',
    todayTrips: 'Trajets du jour',
    incoming: 'Réceptionner',
    prepare: 'Préparer',
    outgoing: 'Expédier',
    noResult: {
      incoming: 'Aucune commande entrante récente',
      prepare: 'Aucune commande préparée récente',
      outgoing: 'Aucune commande expédiée récente',
    },
    upcomingIncoming: 'Prochaines réceptions',
    upcomingOutgoing: 'Prochaines expéditions',
    waitingParcels: 'Envois en attente',
    calendar: 'Calendrier',
    tasks: 'Mes tâches',
    todayCalendar: 'Calendrier du jour',
    todayEvents: 'Événements du jour ({{count}})',
    notes: 'Notes ({{count}})',
    max_date: 'Départ max : {{date}}',
    buttons: {
      allTrips: 'Tous les trajets',
      myTrips: 'Tous mes trajets',
      allParcels: 'Tous les envois',
      allIncoming: 'Toutes les réceptions',
      allOutgoing: 'Toutes les expéditions',
      seeAll: 'Voir tout',
      seeMore: 'Voir plus',
    },
    modes: {
      collaboration: 'Contacts',
      logistic: 'Commandes',
      transport: 'Transport',
    },
    transportMode: 'Profil : {{mode}}',
    transportModes: {
      parcel: 'Profil standard',
      transport: 'Gestionnaire de trajets',
      manager: 'Transporteur',
    },
    logisticModes: {
      incoming: 'Réception',
      outgoing: 'Expédition',
    },
    transportModeDisplay: 'Affichage : {{mode}}',
  },
  parcels: {
    properties: {
      title: 'Envoi',
      confirmTitle: 'Confirmation',
      details: 'Détails',
      parcelDetails: "Détails d'envoi",
      recipient: 'Destination',
      recipientDestination: 'Destination (destinataire)',
      origin: 'Origine',
      originSender: 'Origine (expéditeur)',
      organizationPlaceholder: 'Rechercher une organisation ou une personne',
      contactPlaceholder: 'Rechercher une personne',
      status: 'Envoi',
      dateFrom: "Date d'ajout",
      dateTo: 'Départ prévu',
      dateWantTo: 'Départ souhaité',
      description: 'Description',
      descriptionPlaceholder: `Facultatif : indiquez ici toutes les informations que vous jugez pertinent de communiquer aux autres utilisateurs de la plateforme : Votre envoi a-t-il des particularités qui méritent d'être signalées (dimensions, poids, type d'article, dangerosité) ? Avez-vous des contraintes de transport (température, délai de livraison) ? Les adresses d'origine ou de destination ont-elles des particularités (horaires d'ouverture, accès livraison, étage, sonnette ou code, etc.) ?`,
      reference: 'Référence',
      invoiceReference: 'Référence',
      invoiceNumber: 'Numéro de commande ou facture',
      weight: 'Poids',
      total_weight: 'Poids total (kg)',
      dimensions: 'Dimensions',
      deliveryNote: 'Note au livreur',
      storage: 'Température',
      tags: 'Mots clés',
      calculatedDistance: "Distance calculée de l'expédition : {{distance}} km",
      vehicle: 'Véhicule',
      noTrips: 'Aucun trajet associé',
      carrier: 'Transporteur',
      contact: 'Contact de ramassage',
      type: 'Type',
      note: 'Note',
      direction: 'Direction',
      contextKind: {
        distribution: 'Vente',
        purchase: 'Achat',
        donation: 'Don',
      },
      parcelType: {
        distribution: 'Je veux envoyer',
        purchase: 'Je veux recevoir',
      },
      kindType: {
        purchase: 'Achat',
        donation: 'Don',
      },
      distribution: 'Expéditeur',
      purchase: 'Destinataire',
      unit: "Unité d'inventaire",
      product: 'Produit',
      products: 'Produits ({{count}})',
      participants: 'Participant(s)',
      category: 'Catégorie',
      images: 'Images',
      image: 'Image',
      comments: 'Commentaires',
      confirmedQuantity: 'Quantité confirmée',
      foundParcels_one: 'Envoi trouvé',
      foundParcels_other: 'Envois trouvés',
      linkedTrips: 'Trajets associés ({{count}})',
      steps: {
        order: 'Commande',
        direction: 'Direction',
        distribution: 'Expéditeur',
        purchase: 'Destinataire',
        itinerary: 'Itinéraire',
        productDetails: 'Produits',
        parcelDetails: 'Précisions',
      },
      iAm: 'Je suis',
      avgValue: 'Valeur monétaire moyenne (/kg)',
      subcategories: 'Sous-catégories',
      checkContactInformationMessage:
        'Vérifier que les coordonnées ci-dessous sont exactes, sinon modifiez-les.',
      searchRecipientMessage: 'Rechercher le destinataire dans vos contacts ou créez-en un nouveau',
      searchSenderMessage: 'Rechercher l’expéditeur dans vos contacts ou créez-en un nouveau',
    },
    buttons: {
      add: 'Ajouter un envoi',
      addAddress: 'Ajouter une adresse',
      order: 'Ajouter une commande',
      view: 'Consulter',
      edit: 'Modifier',
      editContact: 'Modifier le contact',
      duplicate: 'Dupliquer',
      showWaitingParcels: 'Afficher les envois en attente uniquement',
      parcelsFrom: 'Envois de',
      seeParcel: "Voir l'envoi",
    },
    contactOrigin: "Contacter l'expéditeur",
    contactDriver: 'Contacter le conducteur',
    noParcels: 'Aucun envoi',
    noParcelsFound: 'Aucun envoi trouvé',
    changeFilter: 'Changez vos filtres ou ajoutez un nouvel envoi',
    actions: {
      addtoTrip: 'Associer à un trajet',
      addParcelToTrip: 'Ajouter cet envoi au trajet',
      tripLinked: 'Trajet associé',
      cannotFindParcel: "Impossible de trouver l'envoi",
      edit: "Modifier l'envoi",
      removeTrip: 'Désassocier le trajet',
    },
    add: {
      recipient: 'Destinataire',
      contact: 'Profil',
      originContact: "Contact d'origine",
      recipientContact: 'Contact de destination',
      description: 'Description et informations importantes',
      addCategory: 'Ajouter une catégorie',
      addVariant: 'Ajouter un produit',
      variant: 'Produit',
      departure: 'Date de ramassage souhaitée',
      publish: "Publier l'envoi",
      addressLabel: "Nom de l'adresse",
      apptSuite: 'Appt/Suite',
      quantity: 'Quantité',
      subjectType: {
        Organization: 'Organisation',
        Person: 'Personne',
      },
      name: 'Nom',
    },
  },
  orders: {
    properties: {
      orderName: 'Nom:',
      etaDate: 'Date prévue:',
      supplierName: 'Fournisseur:',
      clientName: 'Client:',
      status: {
        allocated: 'Alloué',
        canceled: 'Annulé',
        completed: 'Complété',
        confirmed: 'Confirmé',
        consolidated: 'Groupé',
        in_progress: 'En traitement',
        paid: 'Payé',
        pending: 'En attente',
        delivered: 'Livré',
        fulfilled: 'Expédié',
        partial: 'Partiellement expédié',
        partially_delivered: 'Partiellement livré',
        partially_prepared: 'Partiellement préparé',
        prepared: 'Préparé',
        restocked: 'Annulé',
        authorized: 'Autorisé',
        partially_paid: 'Payé partiellement',
        partially_refunded: 'Remboursé partiellement',
        refunded: 'Remboursé',
        voided: 'Annulé',
        accepted: 'Confirmé',
        dispatched: 'Expédié',
        expired: 'Expiré',
        received: 'Réceptionné',
        rejected: 'Refusé',
        sent: 'Envoyé',
        waiting: 'En attente',
        ready_for_pickup: 'Prêt pour ramassage',
        in_transit: 'En transit',
      },
    },
    noOrders: 'Aucun envoi',
    noDistribution: 'Aucune commande de distribution trouvée',
    noPurchase: "Aucune commande d'approvisionnement",
    changeFilterDistribution: 'Changez vos filtres ou ajoutez une nouvelle distribution',
    changeFilterPurchase: 'Changez vos filtres ou ajoutez un nouvel approvisionnement',
    contact: 'Personne contact',
    buttons: {
      edit: 'Modifier la commande',
      addDistribution: 'Ajouter une distribution',
      addPurchase: 'Ajouter un approvisionnement',
    },
  },
  products: {
    properties: {
      productName: 'Nom du produit',
      quantity: 'Quantité unitaire',
      unit: 'Unité',
      weight: 'Poids unitaire',
      unitWeight: 'Unité de poids',
      price: 'Prix unitaire',
      value: 'Valeur unitaire',
      currency: 'Devise',
      variantName: 'Nom de variante',
      fraction: 'Fractionnable',
      fractionQuantity: 'Quantité fractionnée',
      fractionUnit: 'Unité de fractionnée',
      bestBefore: 'Meilleur avant',
      expiresAt: 'Péremption',
      invoiceDate: 'Date de référence',
    },
    errors: {
      quantity: 'Veuillez saisir une quantité',
      unit: 'Veuillez choisir une unité',
      weight: 'Veuillez saisir un poids',
      unitWeight: 'Veuillez saisir une unité de poids',
      price: 'Veuillez saisir un prix',
      variantName: 'Veuillez saisir un nom de variante',
    },
    add: {
      createProduct: 'Créer un produit',
      createVariant: 'Créer une variante',
    },
  },
  trips: {
    properties: {
      title: 'Trajet',
      tripDetails: 'Détails de trajet',
      parcels: 'Envois associés',
      noParcel: 'Aucun envoi associé',
      vehicleType: 'Type de véhicule',
      departure: 'Départ',
      destination: 'Destination',
      vehicleDescription: 'Description du véhicule',
      tripDescription: 'Description du trajet',
      descriptionPlaceholder:
        'Indiquer toute information pertinente : vos date et heure sont-elles flexibles, pensez-vous faire des arrêts, avez-vous des contraintes à mentionner ?',
      fulfillment_status: {
        pending: 'En attente',
        partially_prepared: 'Partiellement préparé',
        prepared: 'Préparé',
        ready_for_pickup: 'Prêt à être récupéré',
        in_transit: 'En transit',
        fulfilled: 'Fini',
        partial: 'Partiel',
        restocked: 'Réapprovisionné',
        delivered: 'Livré',
        partially_delivered: 'Partiellement livré',
      },
      parcelState: {
        collect: 'collecter',
        collectAll: 'collecter tous',
        collected: 'collecté',
        deliver: 'livrer',
        deliverAll: 'livrer tous',
        delivered: 'livré',
        deliveredAll: 'tous livrés',
        incoming: 'réceptionner',
        incomingAll: 'réceptionner tous',
        preparing: 'préparer',
        preparingAll: 'préparer tous',
        prepared: 'préparé',
        outgoing: 'expédier',
        outgoingAll: 'expédier tous',
        received: 'réceptionné',
        shipped: 'expédié',
      },
      collected: 'Collecté',
      originAccount: 'Compte source',
      resources: 'Ressources',
      foundTrips_one: 'Trajet trouvé',
      foundTrips_other: 'Trajets trouvés',
      tripDetail: 'Détail du trajet',
      linkedParcels: 'Envois associés ({{count}})',
    },
    buttons: {
      add: 'Ajouter un trajet',
      view: 'Consulter',
      edit: 'Modifier',
      duplicate: 'Dupliquer',
      saveTrip: 'Publier le trajet',
      editTrip: 'Modifier le trajet',
      orderByLastBefore: 'Trier par date de départ croissante',
      myTripsOnly: 'Mes trajets uniquement',
      tripsFrom: 'Trajets de',
      addReturnTrip: 'Ajouter un trajet retour',
      tripWaypoints: '{{waypoints}} villes intermédiaires',
      seeTrip: 'Voir le trajet',
    },
    noTrips: 'Aucun trajet',
    noTripsFound: 'Aucun trajet trouvé',
    changeFilter: 'Changez vos filtres ou ajoutez un nouveau trajet',
    actions: {
      addParcel: 'Associer un envoi',
      cannotFindTrip: 'Impossible de trouver le trajet',
      removeParcel_one: "Dissocier l'envoi",
      removeParcel_other: 'Dissocier les envois',
    },
    add: {
      title: 'Paramètres du trajet',
      origin: 'Origine',
      destination: 'Destination',
      category: 'Catégorie du trajet',
      departure: 'Départ prévu',
      return: 'Retour prévu',
      descriptionPlaceholder: 'Description du trajet',
      addressPlaceholder: 'Adresse, code postal, ville...',
      vehicleRadio: 'Voulez-vous indiquer un véhicule pour ce trajet ?',
      vehicle: 'Véhicule',
      markerHint: 'Vous pouvez déplacer le marqueur pour une meilleure localisation',
    },
  },
  vehicles: {
    properties: {
      dateFrom: "Date d'ajout",
      description: 'Description',
      storage: 'Température',
      storageType: {
        ambient: 'Sec',
        cool: 'Frais',
        ambient_cool: 'Mixte (sec et frais)',
        frozen: 'Congelé',
      },
      vehicleType: 'Type de véhicule',
      type: 'Type',
      brand: 'Marque',
      foundVehicles_one: 'Vehicule',
      foundVehicles_other: 'Vehicules',
    },
    buttons: {
      add: 'Ajouter un véhicule',
      view: 'Consulter',
      edit: 'Modifier',
      duplicate: 'Dupliquer',
    },
    noVehicles: 'Aucun véhicule',
    noVehiclesFound: 'Aucun véhicule trouvé',
    actions: {
      cannotFindVehicle: 'Impossible de trouver le véhicule',
    },
    add: {
      title: 'Paramètres du véhicule',
      name: 'Nom',
      category: 'Catégorie',
      photos: 'Image',
      photo: 'Photo',
      descriptionPlaceholder: 'Description du véhicule',
      namePlaceholder: 'Nom du véhicule',
    },
  },
  subjects: {
    properties: {
      addresses: 'Adresse(s)',
      picture: 'Image/Logo',
      tags: 'Mots clés',
      category: 'Catégorie',
      code: 'Code',
      principal: 'Contact principal',
      inheritedSource: 'Source: ',
      description: 'Description',
      created: 'Création',
      updated: 'Modification',
      createdOnBy: 'Créé par {{createdBy}} le {{createdOn}}',
      updatedOnBy: 'Modifié par {{updatedBy}} le {{updatedOn}}',
      gender: 'Genre',
      genders: {
        man: 'Homme',
        woman: 'Femme',
        other: 'Autre',
        unknown: 'Inconnu',
      },
      dob: 'Date de naissance',
      dobYears: ' ans',
      contactInformation: 'Information(s) de contact',
      contactMethods: 'Moyen(s) de contact',
      contactInformations: {
        placeholder: 'Moyen de contact',
        PhoneNumber: 'Téléphone',
        CellNumber: 'Cellulaire',
        Email: 'Courriel',
        Website: 'Site internet',
      },
      relationType: {
        main: 'Principal',
        secondary: 'Secondaire',
      },
      connection: '{{connection}} de {{subject}}',
      typeOfConnection: 'Type de relation',
      choosePerson: 'Choisir une personne',
      person: 'Personne',
      collaborators: 'Collaborateurs',
      collaboratorsN: 'Collaborateurs ({{count}})',
      compositions: 'Composition',
      compositionsN: 'Composition ({{count}})',
      collaborations: 'Collaborations',
      collaborationsN: 'Collaborations ({{count}})',
      collaborationsList: 'Liste des collaborations ({{count}})',
      collaborationProfilePage: {
        addOrganization: 'Ajouter une organisation à ',
        addHousehold: 'Ajouter un ménage à ',
        addPerson: 'Ajouter une personne à ',
      },
      noCollaborators: 'Pas de collaborateurs récents',
      organizationName: "Nom d'organisation",
      householdName: 'Nom de famille',
      peopleName: 'Nom de la personne',
      contacts: 'Contacts',
      household: 'Ménage',
      households: 'ménage(s)',
      Household: 'households',
      people: 'Personne',
      peoples: 'personne(s)',
      Person: 'people',
      organization: 'Organisation',
      organizations: 'organisation(s)',
      Organization: 'organizations',
      forms: 'Formulaire(s)',
      addSurvey: 'Ajouter un formulaire d’individu',
      unfilledForm: 'formulaire non rempli',
      formsN: 'Formulaires ({{count}})',
      noForms: 'Aucun formulaire disponible',
      role: 'Rôle',
      householdComposition: 'Composition du ménage',
      edit: {
        description: 'Description',
        status: {
          title: 'Statut',
          active: 'Actif',
          deactive: 'Désactivé',
        },
        reference: 'Références externes',
        code: 'Code',
        codeHint:
          'Ce code aléatoire aléatoire est créée par la plateforme. Vous pouvez modifier ce code.',
        tags: 'Tags',
        checkbox: {
          consentDataBase: 'Consent à figurer dans la base de données.',
          consentDataBaseInformation:
            'La personne a donné son consentement pour la collecte et  l’utilisation de ses renseignements personnels.',
          consentEmail: 'Consent à recevoir des communications électroniques.',
          consentEmailInformation:
            'La personne a donné son consentement pour revoir des communication éléctroniques. ',
        },
      },
    },
    addPersonForm: {
      beforeBegin: 'Avant de commencer',
      beforeBeginTitle:
        'Vous allez ajouter une personne dans votre base de contacts. Assurez-vous de bien remplir les informations demandées.',
      personInfo: 'Informations personnelles',
      newPerson: 'Nouvelle Personne',
      personInfoTitle: 'Renseignez les informations sur la personne à ajouter',
      name: 'Prénom',
      lastName: 'Nom',
      gender: {
        genderTitle: 'Genre',
        select: 'Non répondu',
        man: 'Homme',
        woman: 'Femme',
        other: 'Autre',
        confidential: 'Préfère ne pas répondre',
      },
      dob: 'Date de naissance',
      contactInfo: 'Informations du contact',
      contactInfoTitle: 'Renseignez quelques informations sur la personne à ajouter.',
      category: 'Catégorie',
      description: 'Description',
      infoDescription:
        'Indiquez toutes les informations que vous jugez pertinentes de communiquer aux autres utilisateurs.',
      marketingConsent: 'Consent à recevoir des communications par courriel.',
      databaseConsent: 'Consent à figurer dans la base de données.',
      databaseConsentInformation:
        'La personne a donné son consentement pour la collecte et  l’utilisation de ses renseignements personnels.',
      wayToContact: 'Moyen(s) de contact',
      wayToContactTitle: 'Renseignez les moyens de contact de la personne.',
      email: 'Courriel',
      emailExample: 'example@gmail.com',
      websiteExample: 'http://siteinternet.com',
      addEmail: 'Ajouter un courriel',
      cellPhone: 'Cellulaire',
      addCellPhone: 'Ajouter un cellulaire',
      phone: 'Téléphone',
      addPhone: 'Ajouter un téléphone',
      webSite: 'Site web',
      addWebSite: 'Ajouter un site web',
      fax: 'Fax',
      addFax: 'Ajouter un fax',
      primary: 'Principal',
      secondary: 'Secondaire',
      tertiary: 'Tertiaire',
      address: 'Adresse(s) postale(s)',
      addressTitle: 'Renseignez une ou plusieurs adresses pour le contact.',
      addressInput: 'Adresse',
      newAddressInput: 'Nouvelle adresse',
      addressLabel: 'Intitulé de l’adresse',
      addressLabelExample: 'Excepteur sint occaecat',
      addressDetail: 'Détails sur l’adresse',
      addressDescription: 'Détails sur l’adresse',
      addressDescriptionExample: 'Appartement, suite, etc.',
      addressExample: '327 Rielle, Montréal, QC',
      addAddress: 'Ajouter une adresse',
      identification: 'Identification',
      identificationTitle:
        'Ajoutez une photo identifiant la personne. Il sera plus facile pour vous de la trouver ensuite.',
      addPhoto: 'Ajouter une photo',
      addSuccess: ' a bien été ajouté à vos Contacts !',
      addSuccessSubTitle1:
        'Bravo, vous venez d’ajouter une personne à votre base de contacts. Vous pouvez maintenant l’associer à un ménage ou une organisation.',
      addHousehold: 'Ajouter un ménage',
      addOrganization: 'Ajouter une organisation',
      buttonHousehold: 'Associer à un ménage',
      buttonOrganization: 'Associer à une organisation',
      buttonStart: 'Commencer',
      buttonFinish: 'Créer la personne',
      buttonForm: 'Remplir un formulaire',
      finish: 'Terminer',
      apartment: 'Numéro d’appartement',
      city: 'Ville',
      postal: 'Code postal',
      country: 'Pays',
      addManual: 'Ajouter une adresse manuellement',
      contactLabel: 'Maison, Travail...',
      addContact: 'Ajouter un moyen de contact',
      apartmentNumber: "Numéro d'appartement",
      seePerson: 'Voir la personne',
    },

    actions: {
      cannotFindPerson: 'Impossible de trouver la personne',
      cannotFindOrganization: "Impossible de trouver l'organisation",
      cannotFindHousehold: 'Impossible de trouver le ménage',
      addNewPerson: 'Nouvelle personne',
      addNewOrganization: 'Nouvelle organisation',
      addNewConnection: 'Nouvelle connexion',
      connections: 'Connexions',
      existingPerson: 'Personne existante',
      existingOrganization: 'Organisation existante',
      orCreateNewPerson: 'ou créer une nouvelle personne',
      orCreateNewOrganization: 'ou créer une nouvelle organisation',
    },

    organizationWizard: {
      welcome: {
        title: 'Avant de commencer',
        subtitle:
          'Vous allez créer une organisation dans votre base de contacts. Assurez-vous de bien remplir les informations demandées.',
        actions: {
          next: 'Commencer',
        },
      },
      organization: {
        title: 'Informations sur l’organisation',
        subtitle: 'Renseignez les informations sur l’organisation à ajouter.',
        fields: {
          name: {
            label: 'Nom de l’organisation',
            placeholder: 'Toucan Solutions',
            hint: 'Vous pouvez utiliser le nom de famille du contact principal (ex. Tremblay).',
          },
          category_id: {
            label: 'Catégorie',
            placeholder: 'Choisissez une catégorie',
          },
          note: {
            label: 'Description',
            hint: 'Indiquez toutes les informations que vous jugez pertinentes de communiquer aux autres utilisateurs.',
          },
          accepts_marketing: {
            label: 'Consent à recevoir des communications par courriel.',
            hint: 'Indiquez toutes les informations que vous jugez pertinentes de communiquer aux autres utilisateurs.',
          },
        },
      },
      contact_details: {
        title: 'Moyen(s) de contact',
        subtitle: 'Renseignez les moyens de contact de l’organisation',
        button: {
          add: 'Ajouter un moyen de contact',
        },
      },
      address_details: {
        title: 'Adresse(s) postale(s)',
        subtitle: 'Renseignez une ou plusieurs adresses pour le contact.',
        button: {
          add: 'Ajouter une adresse',
        },
      },
      add_logo: {
        title: 'Logo',
        subtitle:
          'Ajoutez un logo ou une image de l’organisation. Il sera plus facile pour vous de la trouver ensuite.',
      },
      success: {
        title: ' a bien été ajouté(e) à vos contacts !',
        subtitle:
          'Bravo, vous venez d’ajouter une organisation à votre base de contacts. Vous pouvez maintenant l’associer à des personnes.',
        button: {
          associate: 'Associer un collaborateur',
          see_organization: 'Voir l’organisation',
        },
      },
      finish: {
        button: 'Terminer',
      },
    },
    householdWizard: {
      welcome: {
        title: 'Avant de commencer',
        subtitle:
          'Vous allez créer un ménage dans votre base de contacts. Assurez-vous de bien remplir les informations demandées.',
        actions: {
          next: 'Commencer',
        },
      },
      household: {
        title: 'Informations sur le ménage',
        subtitle: 'Renseignez les informations sur le ménage à ajouter.',
        fields: {
          name: {
            label: 'Nom du ménage',
            placeholder: 'Ménage Tremblay',
            hint: 'Vous pouvez utiliser le nom de famille du contact principal (ex. Ménage Tremblay).',
          },
          category_id: {
            label: 'Catégorie',
            placeholder: 'Choisissez une catégorie',
          },
          note: {
            label: 'Description',
            hint: 'Indiquez toutes les informations que vous jugez pertinentes de communiquer aux autres utilisateurs.',
          },
        },
      },
      contactInformation: {
        title: 'Moyen(s) de contact',
        subtitle: 'Renseignez les moyens de contact du ménage.',
      },
      address: {
        title: 'Adresse(s) postale(s)',
        subtitle: 'Renseignez une ou plusieurs adresses pour le contact.',
      },
      addCollaboration: {
        title: 'Associer une personne au ménage',
        subtitle: 'Recherchez un contact existant ou créez-en un nouveau.',
        fields: {
          person_gid: {
            placeholder: 'Chercher par nom...',
          },
          person_title: {
            label: 'Rôle',
            placeholder: 'ex: Parent, enfant, colocataire...',
            hint: 'Définissez le rôle de la personne dans le ménage.',
          },
          person_main: {
            hint: 'Il ne peut y avoir, en général, qu’un seul contact principal par ménage.',
          },
        },
        actions: {
          next: 'Associer',
          search: 'Recherche',
          create: 'Création',
        },
      },
      listCollaborations: {
        title: 'Associer une personne au ménage',
        subtitle:
          'Bravo, vous venez d’associer le ménage {{householdName}} et {{collaboratorName}}. Vous pouvez maintenant associer d’autres personnes au ménage ou finaliser la création du ménage.',
        actions: {
          back: 'Associer une autre personne',
          next: 'Créer le ménage {{householdName}}',
          unlink: 'Voulez-vous dissocier {{collaboratorName}} de ce ménage?',
        },
      },
    },
    addHouseholdWizard: {
      buttons: {
        begin: 'Commencer',
        next: 'Suivant',
        associate: 'Associer une personne au ménage',
        see: 'Voir le ménage',
        create: 'Créer le ménage',
      },
      beforeBegin: 'Avant de commencer',
      beforeBeginTitle:
        'Vous allez créer un ménage dans la base de contacts de Toucan. Assurez-vous de bien remplir les informations demandées.',
      information: 'Informations sur le ménage',
      informationSubTitle: 'Complétez ces informations avant de créer le ménage.',
      name: 'Nom du ménage',
      namePlaceholder: 'Tremblay',
      nameSubTitle: 'Vous pouvez utiliser le nom de famille du contact principal (ex. Tremblay).',
      descriptionText:
        'Indiquez toutes les informations que vous jugez pertinentes de communiquer aux autres utilisateurs.',
      household: 'Le ménage',
      householdSuccess: 'a bien été créé !',
      householdSubText: 'Bravo, vous venez de créer un ménage.',
      associate: 'Associer une personne au ménage',
      associateSubTitle: 'Rechercher un contact existant ou créez-en un nouveau.',
      role: 'Rôle',
      rolePlaceholder: 'ex: Fille, mère...',
      roleSubtitle: 'Définissez le rôle de la personne dans le ménage.',
      principal: 'Contact principal',
      principalSubTitle: 'Il ne peut y avoir qu’un seul contact principal par ménage.',
      contact: 'Moyens de contact et adresse',
      contactSubTitle:
        'Les moyens de contact et adresse postale sont hérités du contact principal.',
      email: 'Courriel',
      cellPhone: 'Cellulaire',
      website: 'Site internet',
      phone: 'Téléphone',
      address: 'Adresse',
      success: 'Association réussie!',
      association: 'Association',
      successCongratulations: 'Bravo, vous venez d’associer',
      successWith: ' et ',
      successHousehold: 'au ménage',
      successWithoutPerson: 'Bravo, vous venez de créer',
    },
    associateWizard: {
      associate: 'Associer',
      finish: 'Terminer',
      household: {
        associateTitle: 'Associer un ménage à une personne',
        associateSubtitle: 'Recherchez un contact existant ou créez-en un nouveau.',
        name: 'Nom du ménage',
        category: 'Catégorie',
        role: 'Rôle',
        roleSubtitle: 'Définissez le rôle de la personne dans le ménage.',
        title: 'Le ménage ',
        personAssociate: ' a bien été associé à la personne ',
        see: 'Voir le ménage',
      },
      organization: {
        associateTitle: 'Associer une organisation à une personne',
        associateSubtitle: 'Recherchez un contact existant ou créez-en un nouveau.',
        name: 'Nom de la  organisation',
        category: 'Catégorie',
        role: 'Rôle',
        roleSubtitle: 'Définissez le rôle de la personne dans la organisation.',
        title: 'La organisation ',
        personAssociate: ' a bien été associé à la personne ',
        see: 'Voir la organisation',
      },
    },
  },
  paginate: 'Resultats {{current}} à {{on}} sur {{total}}',
  errors: {
    nA: 'N/D',
    email: 'Courriel invalide',
    emailRequired: 'Courriel est requise',
    phoneNumber: 'Numéro de téléphone invalide',
  },
  placeHolders: {
    tripSelect: 'Choisissez un trajet',
    parcelSelect: 'Choisissez un envoi',
    addParcelSelect: 'Ajouter un envoi',
    vehicleCategoriesSelect: 'Choisissez une catégorie',
    vehicleSelect: 'Choisissez un véhicule',
    emptyVehicleSelect: 'Aucun véhicule',
    select: 'Choisissez une option',
    query: 'Rechercher...',
    queryContact: 'Rechercher par nom ou code de contact ...',
    queryWarehouse: 'Rechercher par référence ...',
    search: 'Rechercher',
    noResults: 'Aucun résultat',
    noContactInformation: 'Les Informations de contact ne sont pas trouvées',
    noAddressInformation: "Les informations d'adresse ne sont pas trouvées.",
    headerSearch: 'Rechercher une personne ou une organisation...',
    subjectSearch: 'Personne ou organisation',
    yourMessage: 'Votre message...',
    searchLength: 'La recherche doit contenir au moins {{length}} caractères',
    inputThreeChars: 'Saisissez au moins 3 caractères pour rechercher',
    categoryName: 'Nom de la catégorie',
    category: 'Catégorie',
    value: 'Valeur',
    description:
      'Indiquez toutes les informations que vous jugez pertinent de communiquer aux autres utilisateurs.',
    website: 'www.example.com',
    email: 'email@example.com',
    date: 'JJ/MM/AAAA',
    phoneNumber: '555-555-5555',
  },
  genericNoResult: {
    defaultTitle: 'Aucun résultat trouvé',
    defaultSubtitle: 'Changez vos filtres et réessayez',
    defaultLabel: 'Retourner au Dashboard',
    peoples: {
      title: 'Aucune personne trouvée',
      subtitle: 'Changez vos filtres ou ajouter une personne',
      label: 'Ajouter un personne',
    },
    households: {
      title: 'Aucun ménage trouvé',
      subtitle: 'Changez vos filtres ou ajouter un ménage',
      label: 'Ajouter un ménage',
    },
    organizations: {
      title: 'Aucune organisation trouvée',
      subtitle: 'Changez vos filtres ou ajoutez une organisation',
      label: 'Ajouter une organisation',
    },
    events: {
      title: 'Aucun événement trouvé',
      subtitle: 'Changez vos filtres ou ajoutez une événement',
      label: 'Ajouter un événement',
    },
    notes: {
      title: 'Aucune note trouvé',
      subtitle: 'Changez vos filtres ou ajoutez une note',
      label: 'Ajouter une note',
    },
    trips: {
      title: 'Aucun trajet trouvé',
      subtitle: 'Changez vos filtres ou ajoutez un trajet',
      label: 'Ajouter un trajet',
    },
    orders: {
      title: 'Aucun envoi trouvé',
      subtitle: 'Changez vos filtres ou ajoutez un envoi',
      label: 'Ajouter un envoi',
    },
  },
  loading: 'Chargement...',
  save: 'Sauvegarder',
  toast: {
    error: 'Une erreur est survenue',
    tokenExpired: "Déconnecté en raison d'inactivité. Veuillez vous reconnecter",
    vehiculeAddSuccess: 'Le véhicule a bien été ajouté',
    vehiculeEditSuccess: 'Le véhicule a bien été modifié',
    tripAddSuccess: 'Le trajet a bien été ajouté',
    tripEditSuccess: 'Le trajet a bien été modifié',
    parcelAddSuccess: "L'envoi a bien été ajouté",
    parcelEditSuccess: "L'envoi a bien été modifié",
    orderEditSuccess: 'La commande a bien été modifiée',
    addressAddSuccess: "L'adresse a bien été ajoutée",
    addressEditSuccess: "L'adresse a bien été modifiée",
    subjectAddSuccess: 'Le fournisseur a bien été ajouté',
    personAddSuccess: 'La personne a bien été ajoutée',
    eventAddSuccess: "L'événement a bien été ajouté",
    eventEditSuccess: "L'événement a bien été modifié",
    noteAddSuccess: 'La note a bien été ajouté',
    noteEditSuccess: 'La note a bien été modifié',
    attendeeAddSuccess: 'Le participant a été ajouté',
    attendeeEditSuccess: 'Le participant a été modifié',
    collaboratorAddSuccess: 'La collaboration a été ajoutée',
    personEditSuccess: 'La personne a bien été modifiée',
    organizationMissing: 'Veuillez entrer un nom d’organisation',
    organizationAddSuccess: "L'organisation a bien été ajoutée",
    organizationEditSuccess: "L'organisation a bien été modifiée",
    householdMissing: 'Veuillez entrer un nom de ménage',
    householdAddSuccess: 'Le ménage a bien été ajouté',
    householdEditSuccess: 'Le ménage a bien été modifié',
    contactAddSuccess: 'Le contact a bien été ajouté',
    contactEditSuccess: 'Le contact a bien été modifité',
    categoryAddSuccess: 'La catégorie a bien été ajoutée',
    productAddSuccess: 'Le produit a bien été ajouté',
    connectionAddSuccess: 'La connexion a bien été ajoutée',
    missingOrigin: 'Veuillez indiquer une origine',
    missingDestination: 'Veuillez indiquer la destination',
    missingVehicle: 'Veuillez choisir un véhicule',
    missingVehicleCategory: 'Veuillez choisir une catégorie',
    missingAddress: 'Veuillez entrer une adresse',
    missingContact: 'Veuillez choisir un contact',
    missingRecipient: 'Veuillez choisir une destinatation',
    missingContactAddress: 'Veuillez choisir une adresse',
    missingVariant: 'Veuillez choisir un produit',
    missingName: 'Veuillez entrer un nom',
    missingFirstName: 'Veuillez entrer un prénom',
    missingTypeOrName: 'Veuillez entrer un nom ou un type',
    missingField: 'Veuillez remplir tous les champ',
    missingEventTitle: 'Veuillez entrer un titre',
    missingEventSubject: 'Veuillez entrer un objet',
    missingEventDate: 'Veuillez entrer une date',
    missingEventParticipants: 'Veuillez ajouter au moins un participant',
    missingContactInformationField: 'Veuillez remplir tous les champs de contact',
    missingStorageType: 'Veuillez choisir une température de transport',
    missingDate: 'Veuillez entrer une date de départ',
    missingReturnDate: 'Veuillez entrer une date de retour',
    missingCategory: 'Veuillez entrer une catégorie',
    missingAttendee: 'Vous devez sélectionner un participant',
    missingStatus: 'Vous devez sélectionner un statut',
    existingAttendee: 'Le participant est déjà utilisé. Veuillez choisir un autre participant.',
    missingCollaborator: 'Vous devez sélectionner un collaborateur',
    existingCollaborator:
      'Le collaborateur est déjà utilisé. Veuillez choisir un autre collaborateur.',
    noVehiclesLinked: "Vous n'avez aucun véhicule associé à votre compte",
    supplierGidRequired: "Vous n'avez pas choisi de fournisseur",
    imageAdded: 'Image ajoutée',
    imageRemoved: 'Image supprimée',
    formSuccess: 'Formulaire enregistré',
    sameDatesError: 'La fin de la répétition doit être après l’heure de fin de l’événement',
  },
  common: {
    yes: 'Oui',
    no: 'Non',
    add: 'Ajouter un',
    address: 'Adresse',
    date: 'Date',
    email: 'Courriel',
    phoneNumber: 'Téléphone',
    readMore: 'Lire plus',
    only: 'Seulement',
    account: 'Compte',
    available: 'Disponible',
    unavailable: 'Non disponible',
    at: 'à',
    me: 'Moi',
    creation: 'Création',
    name: 'Nom',
    firstname: 'Prénom',
    lastname: 'Nom de famille',
    deleteQuestion: 'Êtes-vous sûr de vouloir supprimer ?',
    noAuthorized: 'Vous n’avez peut-être pas les droits requis pour y accéder',
    buttons: {
      cancel: 'Annuler',
      save: 'Enregistrer',
      delete: 'Supprimer',
      confirm: 'Confirmer',
      add: 'Ajouter',
      edit: 'Modifier',
      close: 'Fermer',
      advancedFilters: 'Filtres avancés',
      apply: 'Appliquer',
      addPicture: 'Ajouter une image',
      next: 'Suivant',
      associate: 'Associer',
      dissociate: 'Dissocier',
      validate: 'Valider',
      filters: 'Filtres',
    },
    title: {
      comments: 'Commentaires',
      default: 'Titre',
    },
    stepCountTitle: 'Étape {{step}} : {{title}}',
    filterByDate: 'Filtrer par date',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    due: 'Rappel',
    noDue: 'Pas de rappel',
    calendar: {
      days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      months: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
      today: "Aujourd'hui",
      nextWeek: 'Semaine prochaine',
      nextMonth: 'Mois prochain',
      nextYear: 'Année prochaine',
      previousWeek: 'Semaine précédente',
      previousMonth: 'Mois précédent',
      previousYear: 'Année précédente',
      week: 'Semaine',
      month: 'Mois',
      year: 'Année',
      day: 'Jour',
      dayView: 'Vue journalière',
      weekView: 'Vue hebdomadaire',
      monthView: 'Vue mensuelle',
      yearView: 'Vue annuelle',
      listView: 'Vue liste',
    },
    errorMessages: {
      required: 'Ce champ est requis',
      name: 'Le nom est requis',
      descriptionTooLong: 'La description est trop longue',
      noAddress: 'Aucune adresse trouvée',
      quantity: 'La quantité est requise',
      weight: 'Le poids est requis',
      refRequired: 'La référence est requise',
    },
    languages: { title: 'Langue', french: 'Français', english: 'Anglais' },
  },
  status: {
    offline: 'Mode hors ligne',
  },
  date: {
    hour: 'Heure',
    capitalFormat: 'JJ-MM-AAAA',
    dateFormat: 'dd/MM/yyyy',
    dateOnlyFormat: 'd MMMM y',
    timeOnlyFormat: "HH'h'mm",
    dateAndTimeFormat: "d MMM y à HH'h'mm",
  },
  person: {
    title: 'Personnes',
    buttons: {
      add: 'Ajouter une personne',
      addContact: 'Ajouter un contact',
      create: 'Créer une personne',
    },
    collaborationList: 'Personnes ({{count}})',
  },
  organization: {
    title: 'Organisations',
    buttons: {
      add: 'Ajouter une organisation',
      create: 'Créer une organisation',
    },
    collaborationList: 'Organisations ({{count}})',
  },
  household: {
    title: 'Ménages',
    buttons: {
      add: 'Ajouter un ménage',
      create: 'Créer un ménage',
    },
    collaborationList: 'Ménages ({{count}})',
  },
  events: {
    properties: {
      title: 'Événement',
      titlePlural: 'Événements',
      eventTitle: 'Nom de l’événement',
      events: 'Activités ({{count}})',
      description: 'Description de l’événement',
      descriptionHint:
        'Indiquez toutes les informations que vous jugez pertinentes sur cet événement.',
      startAt: 'Début',
      endAt: 'Fin',
      reminder: 'Rappel',
      participants: 'Participants',
      keyWords: 'Recherche par mots-clés',
      eventName: 'Nom événement',
      form: 'Formulaire',
      details: 'Détails',
      participationMonetized: 'Participation',
      participations: 'Participants ({{count}})',
      participationsList: 'Liste des participants ({{count}})',
      comments: 'Discussion',
      visibility: 'Visibilité',
      survey: "Formulaire d'activité",
      participationSurvey: 'Formulaire de participation',
      eventSubject: 'Objet',
      participationPrice: 'Participation financière',
      monetized: 'Prix',
      repetition: 'Répétition',
      endOfRepetition: 'Fin de la répétition',
      recurring_plan: {
        every_day: {
          value: 'every_day',
          label: 'Tous les jours',
        },
        every_month: {
          value: 'every_month',
          label: 'Tous les mois',
        },
        every_week: {
          value: 'every_week',
          label: 'Toutes les semaines',
        },
        every_biweek: {
          value: 'every_biweek',
          label: 'Toutes les 2 semaines',
        },
        every_weekday: {
          value: 'every_weekday',
          label: 'Tous les jours ouvrés (Lun-Ven)',
        },
        every_year: {
          value: 'every_year',
          label: 'Toutes les années',
        },
        not_recurring: {
          value: 'not_recurring',
          label: 'Non récurrent',
        },

        error: 'La fin de la répétition doit être après l’heure de fin de l’événement',
      },
      event_location: {
        title: 'Présentiel ou virtuel ?',
        subTitle: 'Précisez si l’événement se déroule en présentiel ou en virtuel',
        location: 'Présentiel',
        virtual: 'Virtuel',
        placeTitle: 'Lieu de l’événement',
        placeSubtitle: 'Par défaut, le lieu de l’événement est celui de l’objet',
        address: {
          title: 'L’événement se déroule à une autre adresse ?',
          add: 'Ajouter une adresse',
          checkboxAddAdress: 'Ajouter cette adresse à l’objet de l’événement',
          noAddress: 'Aucune adresse pour l’événement',
        },
      },
      resources: 'Ressources',
      distributionMode: 'Modèle de distribution',
      formPlaceHolder: 'Choisissez un formulaire',
      distributionPlaceholder: 'Choisissez une distribution',
      resourcePlaceholder: 'Choisissez une ressource',
      modelPlaceHolder: 'Choisissez un modèle',
      participant: {
        placeHolder: 'Chercher par nom...',
        selectAll: 'Sélectionner tout',
        add: 'Ajouter des participants',
      },
      eventCreated: 'Événement créé!',
      eventCreatedSubtitle: 'Bravo, votre événement a bien été créé.',
      seeEvent: "Voir l'événement",
      confirmModal: {
        title: 'Confirmer la distribution de ',
        description:
          'Si vous confirmez la distribution, le système déclenchera automatiquement une commande. Attention, une fois confirmée, vous ne pourrez plus la modifier.',
      },
    },

    buttons: {
      add: 'Ajouter un événement',
      edit: "Modifier l'événement",
    },
    noRecentEvents: 'Aucun événement',
    notes: 'Notes',
    createEvent: 'Créer un événement',
    filterAccounts: 'Selection des comptes à afficher',
    allEvent: 'Afficher tous les comptes',
    currentEvent: 'Afficher {{account}} uniquement',
    actions: {
      goToday: "Aller à aujourd'hui",
      cannotFindEvent: "Impossible de trouver l'événement",
    },
  },
  notes: {
    noRecentNotes: 'Aucune note récente',
    default: 'Notes',
    singularDefault: 'Note',
    buttons: {
      add: 'Ajouter une note',
      edit: 'Modifier une note',
    },
    properties: {
      noteTitle: 'Titre',
    },
  },
  comments: {
    title: 'Commentaires',
    actions: {
      sendMessage: 'Envoyer le message',
    },
  },
  warehouse: {
    properties: {
      program: 'Programme',
      programPlaceholder: 'Sélectionner un programme',
      from: 'Provenance',
      fromPlaceholder: 'Sélectionner la provenance',
      ref: 'Référence',
      refDate: 'Date de référence',
      tags: 'Tags',
      note: 'Note',
      commandLines: 'Lignes de commande',
      supply: 'Approvisionnement',
      distribution: 'Distribution',
      noPrograms: 'Aucun programme trouvé',
      supplier: 'Fournisseur',
      noFrom: 'Aucun organisation trouvée',
      noTags: 'Aucun tag trouvé',
      supplierProduct: 'Produit fournisseur',
      supplierPlaceholder: 'Sélectionner un fournisseur',
      client: 'Client',
      clientPlaceholder: 'Sélectionner un client',
      howReceive: 'Comment souhaitez-vous le réceptionner ?',
      associatedVariants: 'Variantes associées',
      supplierVariant: 'Variante fournisseur',
      resultsFind: 'Résultats trouvés ({{count}})',
      clearFilters: 'Vider les filtres',
    },
    incoming: 'à réceptionner',
    preparing: 'à préparer',
    outgoing: 'à expédier',
    noIncoming: 'Aucune réception',
    noOutgoing: 'Aucune expédition',
    pleaseContact: 'Veuillez contacter {{name}}',
    buttons: {
      withoutDate: 'Sans date',
    },
  },
  filters: {
    title: 'Filtres',
  },
  contacts: {
    noRecentContacts: 'Pas de contacts récents',
  },
  receptions: {
    title: 'Réceptions',
    buttons: {
      add: 'Ajouter une réception',
    },
  },
  preparations: {
    title: 'Préparations',
    buttons: {
      add: 'Ajouter une préparation',
    },
  },
  expeditions: {
    title: 'Expéditions',
    buttons: {
      add: 'Ajouter une expédition',
    },
  },
  attendees: {
    title: 'Participants',
    buttons: {
      add: 'Ajouter un participant',
      edit: 'Modifier un participant',
    },
    properties: {
      title: 'Nouveau participant',
      response: "Réponse à l'événement",
      presence: 'Présence à l’événement',
      information: 'Informations sur la présence',
      add_distribution: 'Ajouter une distribution',
      model_distribution: 'Modèle de distribution ',
      payment: {
        title: 'Paiement',
        no_payment: "Aucun paiement n'a été effectué.",
        payment_exists: 'Un paiement partiel ou total a été effectué.',
        payment_amount: 'Participation financière',
      },
      status: {
        title: 'Statut',
        unknown: {
          value: 'unknown',
          label: 'Présence inconnue',
        },
        attended: {
          value: 'attended',
          label: 'Présent(e)',
        },
        absent: {
          value: 'absent',
          label: 'Absent(e)',
        },
        not_attending: {
          value: 'not_attending',
          label: 'Ne participera pas',
        },
        attending: {
          value: 'attending',
          label: 'Participera',
        },
        tentatively_attending: {
          value: 'tentatively_attending',
          label: 'Participera peut-être',
        },
        will_attend: {
          value: 'attend',
          label: 'Participera',
        },
        might_attend: {
          value: 'tentatively_attending',
          label: 'Participera peut-être',
        },
        no_participation: {
          value: 'tentatively_attending',
          label: 'Ne participera pas',
        },
      },
      paymentStatus: {
        title: 'Statut du paiement',
        pending: {
          value: 'pending',
          label: 'Nouveau',
        },
        settled: {
          value: 'settled',
          label: 'Payé',
        },
        canceled: {
          value: 'canceled',
          label: 'Annulé',
        },
      },
      noAttendees: 'Pas de participants récents',
    },
  },
  collaborations: {
    title: 'Collaborations',
    buttons: {
      add: 'Associer une personne',
    },
    properties: {
      title: 'Nouvelle collaboration',
      titlePerson: 'Associer une personne',
      role: {
        title: 'Rôle de la personne',
        placeholder: 'ex: Parent, enfant, colocataire',
      },
      priority: {
        title: 'Priorité',
        placeholder: 'Sélectionner une priorité',
        principal: {
          value: 'principal',
          label: 'Principal',
        },
        secondary: {
          value: 'secondaire',
          label: 'Secondaire',
        },
      },
    },
  },
};
