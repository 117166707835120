import { AddressType, HeadersProperties, Option } from '@/types';
import React from 'react';
import { WarehouseFilterType, WarehouseItemType } from '../types/warehouse/warehouse';

interface WarehouseItemsContextStateType {
  warehouseItems: WarehouseItemType[];
  setWarehouseItems: React.Dispatch<React.SetStateAction<WarehouseItemType[]>>;
  setFilters: React.Dispatch<React.SetStateAction<WarehouseFilterType>>;
  filters: WarehouseFilterType;
  resetFilters: () => void;
  initialFilters: WarehouseFilterType;
}

export const WarehouseItemsContext = React.createContext<WarehouseItemsContextStateType>({
  warehouseItems: [],
  setWarehouseItems: () => null,
  filters: {} as WarehouseFilterType,
  setFilters: () => null,
  resetFilters: () => null,
  initialFilters: {} as WarehouseFilterType,
});

export const WarehouseItemsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const initialFilters = {
    ref: '',
    address: null as unknown as AddressType,
    date: null as unknown as Date,
    program: null as unknown as Option,
    from: null as unknown as Option,
    supplier: '',
    pagination: {
      page: 1,
      per_page: 10,
      total: 0,
    } as HeadersProperties,
  };
  const [warehouseItems, setWarehouseItems] = React.useState<Array<WarehouseItemType>>([]);
  const [filters, setFilters] = React.useState<WarehouseFilterType>(initialFilters);

  const resetFilters = () => setFilters(initialFilters);

  return (
    <WarehouseItemsContext.Provider
      value={{
        warehouseItems,
        setWarehouseItems,
        filters,
        setFilters,
        resetFilters,
        initialFilters,
      }}>
      {children}
    </WarehouseItemsContext.Provider>
  );
};
