// Utils
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// Icons & Images
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faVideo } from '@fortawesome/pro-regular-svg-icons';

// Types & Interfaces
type ToggleEventAttendanceProps = {
  setFieldValue?: any;
  toggleMode?: any;
  isFormik?: boolean;
  setState?: any;
  infos?: any;
};

const ToggleEventAttendance = ({
  setFieldValue,
  toggleMode,
  isFormik,
  setState,
  infos,
}: ToggleEventAttendanceProps) => {
  const { t } = useTranslation('dashboard');
  const [showInPerson, setShowInperson] = useState<any>(true);
  const [showRemote, setShowRemote] = useState<any>(null);

  useEffect(() => {
    if (isFormik == false) {
      if (infos?.attendance_mode == 'offline') {
        setShowInperson(true);
        setShowRemote(false);
        toggleMode(true);
      } else {
        setShowRemote(true);
        setShowInperson(false);
        toggleMode(false);
      }
    }
  }, [infos]);

  return (
    <div className="w-full gap-1 flex flex-row gap-2 z-1">
      <div className="flex flex-col w-full text-center z-1">
        <a
          onClick={() => {
            if (isFormik) {
              setFieldValue('attendance_mode', 'offline');
            } else {
              setState('offline');
            }
            setShowInperson(true);
            setShowRemote(false);
            toggleMode(true);
          }}
          className={`rounded-lg flex flex p-16  mb-2  justify-center items-center gap-1 h3 z-20 w-full h-[56px] ${
            showInPerson
              ? 'text-white border border-primaryColor bg-customOrangeBackground '
              : 'bg-white text-primaryColor border border gray-800 '
          }`}>
          <FontAwesomeIcon
            icon={faLocationDot}
            size="xl"
            style={{
              color: showInPerson ? '#EE651D' : '#9DA3AF',
              fontSize: '22px',
            }}
          />
        </a>
        <p className={` ${!showInPerson && 'text-gray-400'}`}>
          {t('events.properties.event_location.location')}
        </p>
      </div>
      <div className="flex flex-col w-full text-center z-1">
        <a
          onClick={() => {
            if (isFormik) {
              setFieldValue('attendance_mode', 'online');
            } else {
              setState('online');
            }
            setShowRemote(true);
            setShowInperson(false);
            toggleMode(false);
          }}
          className={`rounded-lg flex p-16 mb-2 justify-center items-center gap-1 h3 z-20 w-full h-[56px] ${
            showRemote
              ? ' bg-customOrangeBackground text-white border border-primaryColor'
              : 'bg-white text-primaryColor border border gray-800'
          }`}>
          <FontAwesomeIcon
            icon={faVideo}
            size="xl"
            style={{
              color: showRemote ? '#EE651D' : '#9DA3AF',
              fontSize: '22px',
            }}
          />
        </a>
        <p className={` ${!showRemote && 'text-gray-400'}`}>
          {t('events.properties.event_location.virtual')}
        </p>
      </div>
    </div>
  );
};

export default ToggleEventAttendance;
