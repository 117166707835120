import React from 'react';
import { useField } from 'formik';

import { FormFieldsType } from '@/types';
import { classNames } from '@/utils/utils';
import { formatDateHandler } from '@/utils/time';

import IconEyeOpen from '@/assets/icons/eye-open.svg';
import IconEyeClosed from '@/assets/icons/eye-closed.svg';
import IconError from '@/assets/icons/error.svg';

const TextInput = ({
  containerClasses,
  setFieldValue,
  customChange,
  touched,
  setEmail,
  nextStepAttempted,
  isDob,
  ...props
}: FormFieldsType & {
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  customChange?: (value: any) => void;
}) => {
  const [field, meta] = useField(props);
  const [type, setType] = React.useState<string>(props.type);
  const [toggled, setToggled] = React.useState<boolean>(false);

  const changeType = () => {
    setToggled(!toggled);
    setType(toggled ? 'password' : 'text');
  };
  function openCalendar(e: React.MouseEvent) {
    if (type == 'date' && e.target.type == 'date') {
      e?.target?.showPicker();
    }
  }

  return (
    <div
      className={classNames(
        'flex flex-col items-start text-sm',
        containerClasses ? containerClasses : '',
      )}>
      <div className="relative w-full">
        <input
          onClick={openCalendar}
          className={classNames(
            'w-full px-4 py-3 focus:outline-none focus:ring-0 focus:text-primaryColor focus:border-primaryColor border border-strokeColor rounded-lg placeholder-gray-400 text-bodyTextColor',
            meta.error ? 'border-error' : '',
          )}
          {...field}
          {...props}
          {...(type === 'number' && setFieldValue
            ? {
                onChange: e => setFieldValue?.(props.name, e.target.value),
              }
            : {})}
          {...(customChange
            ? {
                onChange: e => customChange(e.target.value),
              }
            : {})}
          value={field.value || ''}
          type={setEmail || type}
          autoComplete="off"
          max={isDob && type === 'date' ? formatDateHandler(new Date()) : undefined}
        />
        {props.type === 'password' && (
          <div className="absolute right-3 top-1/2 transorm -translate-y-1/2 flex items-center">
            <button onClick={changeType}>
              <img src={toggled ? IconEyeClosed : IconEyeOpen} />
            </button>
          </div>
        )}
      </div>
      {meta.error && (touched || nextStepAttempted) ? (
        typeof meta.error === 'string' ? (
          <div className="text-error ml-1 flex gap-1 items-center mt-1">
            <img src={IconError} />
            {meta.error}
          </div>
        ) : (
          Object.values(meta.error).map((error: any, i) => (
            <div key={i} className="text-error ml-1 flex gap-1 items-center mt-1">
              <img src={IconError} />
              {error}
            </div>
          ))
        )
      ) : null}
    </div>
  );
};
export default TextInput;
