import { HeaderProps } from '@/types';
import React from 'react';

type Action =
  | {
      type: 'SET_HEADER_TITLE';
      payload: string;
    }
  | {
      type: 'SET_HEADER_PROPS';
      payload: Partial<State>;
    };

type State = HeaderProps;

const initialState: State = {
  inverted: false,
  title: '',
  dashboard: false,
  canGoBack: false,
  canAdd: false,
  canEdit: false,
  canSearch: false,
  editPath: '',
  isEditAutorized: false,
  canHaveFooter: false,
  hamburgerMenu: true,
  homePage: true,
  closeButton: false,
  closeUrl: '',
};

const HeaderContext = React.createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_HEADER_TITLE': {
      return {
        ...state,
        title: action.payload,
      };
    }
    case 'SET_HEADER_PROPS': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const HeaderProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return <HeaderContext.Provider value={{ state, dispatch }}>{children}</HeaderContext.Provider>;
};

export { HeaderContext, HeaderProvider };
