// Utils
import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { TIME_INTERVAL } from '../constants/time';
import { roundToNearestMinutes } from '../utils/time';

// Styles
import '../../src/style/index.scss';
import 'react-datepicker/dist/react-datepicker.css';

// Contexts
import { AccountContext } from '../contexts/accountContext';
import { UserContext } from '../contexts/userContext';

// Components
import AddEventWizard from '@/components/AddEventWizard/AddEventWizard';
import ProgressBarWizard from '../components/ProgressBarWizard/ProgressBarWizard';

// Services
import { eventService } from '../services';

// Types
import { AddressType } from '../types/localization/localization';

// API
import { fetchPeople } from '../utils/api/people';

// Hooks
import { useSlugAccount } from '@/hooks/useAccounts';

const AddEvent = () => {
  const accountContext = React.useContext(AccountContext);
  const { t } = useTranslation('dashboard');
  const { pathname, state }: { pathname: string; state: any } = useLocation();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const location = useLocation();
  const [hashValue, setHashValue] = useState(parseInt(hash.slice(5))); // FIXME: Ouch
  const [personInformation, setPersonInformation] = useState();
  const [eventInformation, setEventInformation] = useState();
  const [repetition, setRepetition] = useState(false);
  const params = useParams();
  const { getAccountName } = useSlugAccount();
  const accountName = getAccountName(pathname, params.accountId);

  useEffect(() => {
    setHashValue(parseInt(hash.slice(5)));
  }, [location.hash]);

  const {
    selected: { id: accountId },
  } = accountContext.state;

  useEffect(() => {
    setHashValue(parseInt(hash.slice(5))); // FIXME: Ouch

    if (pathname === '/events/new') {
      navigate('/events/new/#step1');
      if (window.location) {
        window.location.reload();
      }
    }
  }, [pathname, hashValue]);

  const {
    user: { current_account: user },
  } = React.useContext(UserContext).state;

  const {
    state: { selected },
  } = React.useContext(AccountContext);

  useEffect(() => {
    if (user?.person?.id) {
      fetchPeople(accountId, user.person.id).then(data => {
        setPersonInformation(data);
      });
    }
  }, [user.person?.id, accountId, hashValue]);

  const initialValues = {
    intent: 'event',
    title: '',
    description: '',
    body: '',
    due_at: null,
    start_at: new Date(roundToNearestMinutes(new Date(), TIME_INTERVAL)),
    end_at: new Date(
      roundToNearestMinutes(
        new Date(new Date().setHours(new Date().getHours() + 1)),
        TIME_INTERVAL,
      ),
    ),
    person_ids: [],
    survey_id: null,
    participation_survey_id: null,
    activity_forms: null,
    distribution: null,
    participation_price: null,
    visibility: 'visible',
    participants: null,
    subject: {
      id: null,
      gid: state ? state?.gid : null,
      name: state ? state.name : null,
    },
    recurring_end_at: null,
    recurring_plan: {
      value: t('events.properties.recurring_plan.not_recurring.value'),
      label: t('events.properties.recurring_plan.not_recurring.label'),
    },
    attendance_mode: 'offline',
    address_from_id: null,
    addresses: [
      {
        address: undefined as unknown as AddressType,
        name: '',
        street2: '',
      },
    ],
    manual_address: false,
    add_new_address: false,
    resources: null,
    order_template_id: null,
  };

  const validationSchema = yup.object().shape({
    title: yup.string().required(t('toast.missingEventTitle')),
    recurring_end_at: repetition ? yup.date().required(t('toast.missingEventTitle')) : null,
  });

  function onSubmit(fields: any, { setStatus, setSubmitting }: FormikHelpers<Values>) {
    setStatus();
    fields.user = user;
    let shallowFieldsCopy = { ...fields };
    shallowFieldsCopy.person_ids = JSON.parse(JSON.stringify(shallowFieldsCopy.person_ids));
    shallowFieldsCopy.person_ids = shallowFieldsCopy.person_ids.filter(person => person.checked);
    shallowFieldsCopy.person_ids = shallowFieldsCopy.person_ids.map(person => {
      delete person['checked'];
      return person;
    });

    createEvent(accountId, shallowFieldsCopy, setSubmitting);
  }

  function onSuccess(data: any, setSubmitting: any) {
    setSubmitting(false);
    setEventInformation(data);
    navigate(`/events/new/#step6`);
  }

  function createEvent(accountId: number, fields: any, setSubmitting: any) {
    eventService.create(accountId, fields, onSuccess, setSubmitting);
  }
  return (
    <>
      <ProgressBarWizard hashValue={hashValue} percentage={16.66} totalSteps={6} />
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({
          setFieldValue,
          values,
          errors,
          touched,
          isSubmitting,
        }: FormikProps<typeof initialValues>) => {
          React.useEffect(() => {
            if (!state) {
              if (selected?.default_subject) {
                setFieldValue('subject.gid', selected.default_subject.gid);
                setFieldValue('subject.name', selected.default_subject.name);
              } else {
                fetchPeople(accountId, user?.person?.id).then(dataPerson => {
                  if (dataPerson) {
                    setFieldValue('subject.gid', dataPerson?.gid);
                    setFieldValue('subject.name', dataPerson?.name);
                  } else {
                    setFieldValue('subject.gid', undefined);
                    setFieldValue('subject.name', undefined);
                  }
                });
              }
            }
          }, []);
          return (
            <Form className="flex flex-col gap-5 h-[80vh] ">
              <AddEventWizard
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                isSubmitting={isSubmitting}
                hashValue={hashValue}
                values={values}
                accountId={accountId}
                personInformation={personInformation}
                eventInformation={eventInformation}
                setRepetition={setRepetition}
                repetition={repetition}
                selectedAccount={selected}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddEvent;
