import React from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as AddCircleIcon } from '@/assets/icons/add-circle.svg';
import { ReactComponent as EditIcon } from '@/assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from '@/assets/icons/trash.svg';
import { ReactComponent as AddPerson } from '@/assets/icons/add-person-silhouette.svg';

type ImageHandlerProps = {
  photos: any;
  existingPreview?: string;
  setPhotos: React.Dispatch<React.SetStateAction<any>>;
  height?: number;
};
const ImageHandler = ({ photos, setPhotos, existingPreview, height = 300 }: ImageHandlerProps) => {
  const { t } = useTranslation('dashboard');
  const [preview, setPreview] = React.useState<string>();

  const handlePhotos = (e: any) => setPhotos(e.target.files[0]);
  const resetPhotos = () => {
    setPreview(undefined);
    setPhotos(undefined);
  };

  React.useEffect(() => {
    if (!photos) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(photos);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [photos]);

  React.useEffect(() => {
    if (existingPreview) setPreview(existingPreview);
  }, [existingPreview]);

  return (
    <div className=" rounded-lg overflow-hidden" style={{ height }}>
      {preview ? (
        <div className="flex h-full justify-center relative">
          <img src={preview} className="object-cover w-full" />
          <label
            htmlFor="upload-photos"
            className="flex p-2 rounded-full items-center justify-center bg-white absolute border border-strokeColor right-16 top-2">
            <EditIcon className="stroke-primaryColor" />
            <input
              id="upload-photos"
              className="hidden"
              type="file"
              onChange={handlePhotos}
              accept="image/*"
            />
          </label>
          <button
            onClick={resetPhotos}
            className="flex p-2 rounded-full items-center justify-center bg-white absolute border border-strokeColor right-2 top-2">
            <TrashIcon className="stroke-error" />
          </button>
        </div>
      ) : (
        <label
          htmlFor="upload-photos"
          className="cursor-pointer flex w-full h-full justify-center items-center">
          {/* <AddCircleIcon className="stroke-bodyTextColor" />
          <p className="ml-2">{t('common.buttons.addPicture')}</p> */}
          <div className="flex flex-col">
            <AddPerson />
            <p className="text-orange-500 text-center">{t('subjects.addPersonForm.addPhoto')}</p>
          </div>

          <input
            id="upload-photos"
            className="hidden"
            type="file"
            onChange={handlePhotos}
            accept="image/*"
          />
        </label>
      )}
    </div>
  );
};

export default ImageHandler;
