import { objectToFormdata } from '@/utils/form';
import { addressService } from './address.service';
import { createEvent } from '@/utils/api/events';

function create(accountId: number, params: any, onSuccessCallback: any, setSubmitting: any) {
  const body = {
    intent: 'event',
    title: params.title,
    ...(params.due_at ? { due_at: params.due_at?.toISOString() } : { due_at: '' }),
    ...(params.start_at ? { start_at: params.start_at?.toISOString() } : {}),
    ...(params.end_at ? { end_at: params.end_at?.toISOString() } : {}),
    ...(params.survey_id ? { survey_id: params.survey_id.value } : {}),
    ...(params.participation_survey_id
      ? { participation_survey_id: params.participation_survey_id.value }
      : {}),
    body: params.description,
    subject_gid: params.subject?.gid || params.user.current_account.person.gid,
    attendee_gids: params.person_ids.map(p => p.gid),
    user_id: params.user.id,
    subject: params.subject,
    ...(params.participation_price != null
      ? { participation_price: params.participation_price }
      : {}),
    ...(params.recurring_plan?.value != null
      ? { recurring_plan: params.recurring_plan.value }
      : {}),
    ...(params.recurring_end_at
      ? { recurring_end_at: params.recurring_end_at?.toISOString() }
      : {}),
    attendance_mode: params.attendance_mode,
    address_from_id: params.address_from_id,
    order_template_id: params.order_template_id?.value,
  };

  const formData = objectToFormdata(body);

  createEvent(accountId, formData)
    .then(eventResponse => {
      const dependantEventItemActions: any[] = [];

      // Create addresses if any
      // addressable_gid being an event gid creates 500 error problem
      // params.addresses.forEach((address: { address: any }) => {
      //   if (address.address) {
      //     dependantEventItemActions.push(
      //       addressService.create(accountId, eventResponse.data.gid, address),
      //     );
      //   }
      // });

      if (params.add_new_address) {
        params.addresses.forEach((address: { address: any }) => {
          if (address.address) {
            dependantEventItemActions.push(
              addressService.create(accountId, params.subject.gid, address),
            );
          }
        });
      }

      const dependantEventItemResults = Promise.all(dependantEventItemActions);
      dependantEventItemResults.then(() => {
        onSuccessCallback(eventResponse.data, setSubmitting);
      });
    })
    .catch(error => {
      console.log(error.message);
      // TODO: add onErrorCallback
    });
}

export const eventService = {
  create,
};
