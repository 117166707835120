import {
  AccountState,
  AuthorizationState,
  ConfigState,
  ParcelContextState,
  UserType,
  SubjectContextState,
  ParcelType,
  SubjectType,
} from '../types';
import { ModeType, ThemeType } from '../types/config/config';

export const storeUserData = async (value: AuthorizationState) => {
  try {
    const jsonValue = JSON.stringify(value);
    await localStorage.setItem('@user', jsonValue);
  } catch (e) {
    // saving error
  }
};

export const getUserData = async (): Promise<AuthorizationState> => {
  try {
    const jsonValue = await localStorage.getItem('@user');
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    return {
      loggedIn: false,
      accessToken: '',
      tokenExpiryDate: '',
      user: {} as UserType,
    };
    // error reading value
  }
};

export const storeAccountData = async (value: AccountState) => {
  try {
    const jsonValue = JSON.stringify(value);
    await localStorage.setItem('@account', jsonValue);
  } catch (e) {
    // saving error
  }
};

export const getAccountData = async (): Promise<AccountState> => {
  try {
    const jsonValue = await localStorage.getItem('@account');
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    return {
      accounts: [],
      selected: {
        id: 0,
        name: '',
        role: '',
      },
    };
    // error reading value
  }
};

export const storeConfigData = async (value: ConfigState) => {
  try {
    const jsonValue = JSON.stringify(value);
    await localStorage.setItem('@mode', jsonValue);
  } catch (e) {
    // saving error
  }
};

export const getConfigData = async (): Promise<ConfigState> => {
  try {
    const jsonValue = await localStorage.getItem('@mode');
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    return {
      theme: import.meta.env.VITE_THEME as ThemeType,
      mode:
        import.meta.env.VITE_THEME_CONFIG === 'toucan'
          ? ModeType.collaboration
          : ModeType.transport,
    };
    // error reading value
  }
};

export const storeParcelData = async (value: ParcelContextState) => {
  try {
    const jsonValue = JSON.stringify(value);
    await localStorage.setItem('@parcel', jsonValue);
  } catch (e) {
    // saving error
  }
};

export const getParcelData = async (): Promise<ParcelContextState> => {
  try {
    const jsonValue = await localStorage.getItem('@parcel');
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    return {
      parcels: [],
      activeParcel: {} as ParcelType,
    };
    // error reading value
  }
};
export const storeSubjectData = async (value: SubjectContextState) => {
  try {
    const jsonValue = JSON.stringify(value);
    await localStorage.setItem('@subject', jsonValue);
  } catch (e) {
    // saving error
  }
};

export const getSubjectData = async (): Promise<SubjectContextState> => {
  try {
    const jsonValue = await localStorage.getItem('@subject');
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    return {
      subjects: [],
      activeSubject: {} as SubjectType,
    };
    // error reading value
  }
};
