import { useTranslation } from 'react-i18next';
import { ErrorType, NodeEnvsType } from '@/types';

type FallbackComponentProps = {
  error: ErrorType;
};

const FallbackComponent = ({ error }: FallbackComponentProps) => {
  const { t } = useTranslation('common');

  if (import.meta.env.VITE_NODE_ENVS === NodeEnvsType.development) {
    error = error?.error;
  }

  console.log('error message', error.message);
  console.log('error stack', error.stack);
  return (
    <main role="alert" className="p-5 md:p-10 flex flex-col gap-3.5 w-full">
      <h1>{t('errorBoundaries.title') || 'Error'}</h1>
      <p className="whitespace-pre-line">
        {t('errorBoundaries.description' || "There's an error here")}
      </p>
      <div>
        <button
          className="rounded-lg flex justify-center items-center gap-1 bg-primaryColor text-white py-2 px-3 font-semibold"
          onClick={() => (window.location.href = '/dashboard')}>
          {t('errorBoundaries.button' || 'Go back to dashboard')}
        </button>
      </div>
      <div className="mt-5 flex flex-col gap-2">
        <h2>{t('errorBoundaries.details' || 'Error details')}</h2>
        <div className="border border-lightStrokeColor bg-lightStrokeColor/10 rounded-xl p-3 overflow-scroll">
          <pre>{window.location.pathname}</pre>
          <pre className="text-error whitespace-pre-wrap">{error.message}</pre>
          <pre className="text-error whitespace-pre-wrap">{error.stack}</pre>
        </div>
      </div>
    </main>
  );
};

export default FallbackComponent;
