import { AuthorizationState, UserType } from '..';

export enum UserActionTypes {
  LoggedIn = 'SET_LOGGED_IN',
  AccessToken = 'SET_ACCESS_TOKEN',
  ExpiryDate = 'SET_TOKEN_EXPIRY_DATE',
  Hydrate = 'HYDRATE',
  Clear = 'CLEAR',
  SetUser = 'SET_USER',
}

export type UserPayloadAction =
  | {
      type: UserActionTypes.LoggedIn;
      payload: boolean;
    }
  | {
      type: UserActionTypes.AccessToken;
      payload: string;
    }
  | {
      type: UserActionTypes.ExpiryDate;
      payload: number;
    }
  | {
      type: UserActionTypes.Hydrate;
      payload: AuthorizationState;
    }
  | {
      type: UserActionTypes.Clear;
    }
  | {
      type: UserActionTypes.SetUser;
      payload: UserType;
    };
