//Utils
import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { slugify } from '@/utils/utils';
import { useTranslation } from 'react-i18next';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Routes
const ActiveParcels = lazy(() => import('@/routes/activeParcels'));
const ActiveSubject = lazy(() => import('@/routes/activeSubject'));
const ActiveTrips = lazy(() => import('@/routes/activeTrips'));
const ActiveVehicle = lazy(() => import('@/routes/activeVehicle'));
const ActiveVehicles = lazy(() => import('@/routes/activeVehicles'));
const ActiveWarehouseItems = lazy(() => import('@/routes/activeWarehouseItems'));
const AddEditEvent = lazy(() => import('@/routes/addEditEvent'));
const AddEditNote = lazy(() => import('@/routes/addEditNote'));
const AddParcel = lazy(() => import('@/routes/addParcel'));
const AddProduct = lazy(() => import('@/routes/addProduct'));
const AddSubject = lazy(() => import('@/routes/addSubject'));
const AddTrip = lazy(() => import('@/routes/addTrip'));
const AddVehicle = lazy(() => import('@/routes/addVehicle'));
const CollectParcel = lazy(() => import('@/routes/collectParcel'));
const Comments = lazy(() => import('@/routes/comments'));
const Contacts = lazy(() => import('@/routes/contacts'));
const Dashboard = lazy(() => import('@/routes/dashboard'));
const DashboardLayout = lazy(() => import('@/components/layout/dashboard'));
const EditCollectParcel = lazy(() => import('@/routes/editCollectParcel'));
const EditOrder = lazy(() => import('@/routes/editOrder'));
const EditParcel = lazy(() => import('@/routes/editParcel'));
const EditSubject = lazy(() => import('@/routes/editSubject'));
const EditTrip = lazy(() => import('@/routes/editTrip'));
const EditVehicle = lazy(() => import('@/routes/editVehicle'));
const Event = lazy(() => import('@/routes/event'));
const Events = lazy(() => import('@/routes/events'));
const FormPage = lazy(() => import('@/routes/form'));
const Home = lazy(() => import('@/routes/home'));
const NoMatch = lazy(() => import('@/routes/noMatch'));
const Note = lazy(() => import('@/routes/note'));
const Notes = lazy(() => import('@/routes/notes'));
const Order = lazy(() => import('@/routes/order'));
const Orders = lazy(() => import('@/routes/orders'));
const Parcel = lazy(() => import('@/routes/parcel'));
const Parcels = lazy(() => import('@/routes/parcels'));
const PersonAddEdit = lazy(() => import('@/components/Person/PersonAddEdit'));
const ReceiveParcel = lazy(() => import('@/routes/receiveParcel'));
const Scan = lazy(() => import('@/routes/scan'));
const Trip = lazy(() => import('@/routes/trip'));
const Trips = lazy(() => import('@/routes/trips'));
const UnconnectedRoutes = lazy(() => import('@/routes/unconnectedRoutes'));
const Vehicles = lazy(() => import('@/routes/vehicles'));
import AddHousehold from './routes/addHousehold';
import AddPerson from './routes/addPerson';
import AddOrganization from './routes/addOrganization';

//Components
const ReloadPrompt = lazy(() => import('@/components/ReloadPrompt/ReloadPrompt'));
const MyWarehouseItems = lazy(() => import('@/components/MyWarehouseItems/MyWarehouseItems'));
const ParcelEditContact = lazy(() => import('@/components/Parcel/ParcelEditContact'));

//Hooks
import { LoginHook } from '@/hooks/loginHook';

//Contexts
import { UserContext } from '@/contexts/userContext';
import { AccountContext } from '@/contexts/accountContext';
import { FiltersContextProvider } from './contexts/filtersContext';
import PersonInfoProvider from './contexts/personContext';
import { WarehouseItemsContextProvider } from './contexts/warehouseItemsContext';

//Types
import { SubjectEnum, LogisticModeType } from '@/types';
import AddEvent from './routes/addEvent';

function App() {
  const userContext = React.useContext(UserContext);
  const accountContext = React.useContext(AccountContext);
  const { loggedIn } = userContext.state;
  const { accounts } = accountContext.state;
  const { t } = useTranslation('common');

  const parcelsRoutes = () => (
    <React.Fragment>
      <Route path=":parcelId" element={<Parcel />} />
      <Route path="add" element={<AddParcel />} />
      <Route path="edit/contact" element={<ParcelEditContact />} />
      <Route path="edit/:parcelId" element={<EditParcel />} />
    </React.Fragment>
  );

  const ordersRoutes = () => (
    <React.Fragment>
      <Route path=":orderId" element={<Order />} />
      <Route path="add" element={<AddParcel />} />
      <Route path="edit/:orderId" element={<EditOrder />} />
      <Route path="edit-parcel" element={<EditCollectParcel />} />
      <Route path="receive-parcel" element={<ReceiveParcel />} />
    </React.Fragment>
  );

  const productsRoutes = () => (
    <React.Fragment>
      <Route path="add" element={<AddProduct />} />
    </React.Fragment>
  );

  const tripsRoutes = () => (
    <React.Fragment>
      <Route path=":tripId" element={<Trip />} />
      <Route path="edit/:tripId" element={<EditTrip />} />
      <Route path="add" element={<AddTrip />} />
      <Route path="collect-parcel" element={<CollectParcel />} />
      <Route path="comments" element={<Comments />} />
    </React.Fragment>
  );

  const vehiclesRoutes = () => (
    <React.Fragment>
      <Route path=":vehicleId" element={<ActiveVehicle />} />
      <Route path="edit/:vehicleId" element={<EditVehicle />} />
      <Route path="add" element={<AddVehicle />} />
    </React.Fragment>
  );

  const eventRoutes = (additionalRoutePath?: string) => (
    <Route path={`events`}>
      <Route
        index
        element={
          <FiltersContextProvider>
            <Events />
          </FiltersContextProvider>
        }
      />
      <Route path=":eventId" element={<Event />} />
      <Route path="add" element={<AddEditEvent />} />
      <Route path="new" element={<AddEvent />} />
      <Route path="edit/:eventId" element={<AddEditEvent edit type={SubjectEnum.Events} />} />
    </Route>
  );

  const noteRoutes = (additionalRoutePath?: string) => (
    <Route path={`notes`}>
      <Route
        index
        element={
          <FiltersContextProvider>
            <Notes />
          </FiltersContextProvider>
        }
      />
      <Route path=":eventId" element={<Note />} />
      <Route path="add" element={<AddEditNote />} />
      <Route path="edit/:eventId" element={<AddEditNote edit type={SubjectEnum.Notes} />} />
    </Route>
  );

  const peopleRoutes = (additionalRoutePath?: string) => (
    <Route path={`contacts/people`}>
      <Route path=":subjectId/:tabName" element={<ActiveSubject type={SubjectEnum.Person} />} />
      <Route element={<DashboardLayout />}>
        <Route
          path=":subjectId/events"
          element={
            <FiltersContextProvider>
              <Events />
            </FiltersContextProvider>
          }
        />
        <Route
          path=":subjectId/notes"
          element={
            <FiltersContextProvider>
              <Notes />
            </FiltersContextProvider>
          }
        />
      </Route>
      <Route path="edit/:id" element={<PersonAddEdit />} />
    </Route>
  );

  const organizationRoutes = (additionalRoutePath?: string) => (
    <Route path={`contacts/organizations`}>
      <Route
        path=":subjectId/:tabName"
        element={<ActiveSubject type={SubjectEnum.Organization} />}
      />
      <Route element={<DashboardLayout />}>
        <Route
          path=":subjectId/events"
          element={
            <FiltersContextProvider>
              <Events />
            </FiltersContextProvider>
          }
        />
        <Route
          path=":subjectId/notes"
          element={
            <FiltersContextProvider>
              <Notes />
            </FiltersContextProvider>
          }
        />
      </Route>
      <Route path="edit/:subjectId" element={<EditSubject type={SubjectEnum.Organization} />} />
    </Route>
  );

  const householdRoutes = (additionalRoutePath?: string) => (
    <Route path={`contacts/households`}>
      <Route path=":subjectId/:tabName" element={<ActiveSubject type={SubjectEnum.Household} />} />
      <Route element={<DashboardLayout />}>
        <Route
          path=":subjectId/events"
          element={
            <FiltersContextProvider>
              <Events />
            </FiltersContextProvider>
          }
        />
        <Route
          path=":subjectId/notes"
          element={
            <FiltersContextProvider>
              <Notes />
            </FiltersContextProvider>
          }
        />
      </Route>
      <Route path="edit/:subjectId" element={<EditSubject type={SubjectEnum.Household} />} />
    </Route>
  );

  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        theme="colored"
        transition={Slide}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ReloadPrompt />
      <Routes>
        <Route
          index
          element={
            <UnconnectedRoutes>
              <Home />
            </UnconnectedRoutes>
          }
        />
        {loggedIn && (
          <>
            <Route path="dashboard" element={<DashboardLayout />}>
              <Route
                index
                element={
                  <WarehouseItemsContextProvider>
                    <Dashboard />
                  </WarehouseItemsContextProvider>
                }
              />
              <Route path="order" element={<ActiveParcels />}>
                <Route index element={<Parcels />} />
                {parcelsRoutes()}
              </Route>
              <Route path="orders" element={<ActiveParcels />}>
                <Route index element={<Orders />} />
                {ordersRoutes()}
              </Route>
              <Route path="produits" element={<ActiveParcels />}>
                {productsRoutes()}
              </Route>
              <Route path="trips" element={<ActiveTrips />}>
                <Route index element={<Trips />} />
                {tripsRoutes()}
              </Route>
              <Route path="mes-vehicules" element={<ActiveVehicles />}>
                <Route index element={<Vehicles />} />
                {vehiclesRoutes()}
              </Route>
              <Route path="vehicules" element={<ActiveVehicles />}>
                <Route index element={<Vehicles />} />
                {vehiclesRoutes()}
              </Route>
              <Route path="subjects">
                <Route path="add" element={<AddSubject />} />
              </Route>
              <Route path="expeditions" element={<ActiveWarehouseItems />}>
                <Route
                  index
                  element={<MyWarehouseItems direction={LogisticModeType.outgoing} prepared />}
                />
              </Route>
              <Route path="preparations" element={<ActiveWarehouseItems />}>
                <Route
                  index
                  element={<MyWarehouseItems direction={LogisticModeType.preparing} />}
                />
              </Route>
              <Route path="receptions" element={<ActiveWarehouseItems />}>
                <Route index element={<MyWarehouseItems direction={LogisticModeType.incoming} />} />
              </Route>
              <Route path="scan" element={<Scan />} />
            </Route>

            <Route element={<DashboardLayout />}>
              <Route path="form/:formId" element={<FormPage />} />
              {eventRoutes()}
              {noteRoutes()}
              {accounts.map(account => (
                <Route key={account.id} path={slugify(account.name)}>
                  {eventRoutes()}
                </Route>
              ))}
              {accounts.map(account => (
                <Route key={account.id} path={slugify(account.name)}>
                  {noteRoutes()}
                </Route>
              ))}
              {eventRoutes(':accountId/')}
              {noteRoutes(':accountId/')}
            </Route>

            {/* <Route path="events" element={<DashboardLayout />}>
              <Route path="events/add/" element={<AddEvent />} />
            </Route> */}

            <Route path="contacts" element={<DashboardLayout />}>
              <Route path="households" element={<Contacts />} />
              <Route
                path="households/new/"
                element={
                  <PersonInfoProvider>
                    <AddHousehold />
                  </PersonInfoProvider>
                }
              />
              <Route path="people" element={<Contacts />} />
              <Route
                path="people/new/"
                element={
                  <PersonInfoProvider>
                    <AddPerson />
                  </PersonInfoProvider>
                }
              />
              <Route path="organizations" element={<Contacts />} />
              <Route
                path="organizations/new/"
                element={
                  <PersonInfoProvider>
                    <AddOrganization />
                  </PersonInfoProvider>
                }
              />
            </Route>

            {peopleRoutes()}
            {accounts.map(account => (
              <Route key={account.id} path={slugify(account.name)}>
                {peopleRoutes()}
              </Route>
            ))}
            {householdRoutes()}
            {householdRoutes(':accountId/')}
            {organizationRoutes()}
            {accounts.map(account => (
              <Route key={account.id} path={slugify(account.name)}>
                {organizationRoutes()}
              </Route>
            ))}
            {organizationRoutes(':accountId/')}
          </>
        )}
        {!loggedIn && <Route path="/dashboard/*" element={<LoginHook />} />}
        <Route
          path="/no-accounts"
          element={<NoMatch logo={true} message={t('noMatch.noAccounts')} />}
        />
        <Route
          path="/no-account"
          element={<NoMatch logo={true} message={t('noMatch.noAccount')} />}
        />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
