import { useState, useEffect, useCallback, useContext } from 'react';
import { getCurrentAccount } from '@/utils/api/user';
import { UserContext } from '@/contexts/userContext';
import { AccountActionTypes, SubjectEnum, useRefreshUserReturnType } from '@/types';
import { fetchPeoplesByName } from '@/utils/api/people';
import { fetchOrganizationsByName } from '@/utils/api/organizations';
import { fetchHouseholdsByName } from '@/utils/api/households';
import { useTranslation } from 'react-i18next';
import { AccountContext } from '@/contexts/accountContext';
import { selectedAccountContactsHandler } from '@/utils/utils';

export const useRefreshUser = (escapeRefresh = false) : useRefreshUserReturnType => {
  const { t } = useTranslation('dashboard');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const userContext = useContext(UserContext);
  const accountContext = useContext(AccountContext);
  const accountId = userContext.state.user.current_account.id;
  const selectedAccount = accountContext?.state?.selected;
  const { people_count, organizations_count, households_count} = selectedAccount;
  const peopleCount = selectedAccountContactsHandler(people_count);
  const organizationsCount =  selectedAccountContactsHandler(organizations_count);
  const householdsCount = selectedAccountContactsHandler(households_count);
  const accountContacts = householdsCount
  ? [
      { name: t('person.title'), callback: fetchPeoplesByName, type: SubjectEnum.Person},
      { name: t('organization.title'), callback: fetchOrganizationsByName, type: SubjectEnum.Organization },
      { name: t('household.title'), callback: fetchHouseholdsByName, type: SubjectEnum.Household },
    ]
  : [
      { name: t('person.title'), callback: fetchPeoplesByName, type: SubjectEnum.Person },
      { name: t('organization.title'), callback: fetchOrganizationsByName, type: SubjectEnum.Organization },
    ];

  const refreshUserHandler = useCallback(async () => {
    setIsLoading(true);
    try {
      const { currentAccount, default_module } = await getCurrentAccount(accountId);
      if(currentAccount) {
        accountContext.dispatch({
          type: AccountActionTypes.SetAccount,
          payload: {
            ...currentAccount,
            mode:default_module,
          },
        });

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      throw new Error('Something went wrong!!');
    }
  },[]);

  useEffect(() => {
    if(escapeRefresh) return;
    refreshUserHandler();
  },[]);

  return {
    isLoading,
    peopleCount,
    organizationsCount,
    householdsCount,
    accountContacts
  }
};
