// Utils
import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

// Icons & Images
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';

const FormModal = ({
  open,
  setOpen,
  children,
  isOverFlow = true,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  isOverFlow?: boolean;
}) => {
  const close = () => {
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="w-100">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className={`relative bg-white rounded-b-3xl flex flex-col ${
                  isOverFlow ? 'overflow-hidden' : ''
                } shadow-xl transform transition-all w-full`}>
                <div className="absolute right-0 top-0 p-4 z-50">
                  <button type="button" onClick={close}>
                    <CloseIcon className="stroke-bodyTextColor" />
                  </button>
                </div>
                <div className="p-5 flex flex-col gap-3.5 relative">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default FormModal;
