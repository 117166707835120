import React from 'react';
import { ModeType, ThemeType } from '../types/config/config';
import { ConfigActionTypes, ConfigPayloadAction } from '../types/payload/configAction';

import { ConfigState } from '../types/state/config';
import { getConfigData, storeConfigData } from '../utils/data';

const initialState: ConfigState = {
  theme: import.meta.env.VITE_THEME_CONFIG as ThemeType,
  mode: import.meta.env.VITE_THEME_CONFIG === 'toucan' ? ModeType.logistic : ModeType.transport,
};

const ConfigContext = React.createContext<{
  state: ConfigState;
  dispatch: React.Dispatch<ConfigPayloadAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

const reducer = (state: ConfigState, action: ConfigPayloadAction) => {
  switch (action.type) {
    case ConfigActionTypes.SetMode: {
      return {
        ...state,
        mode: action.payload,
      };
    }
    case ConfigActionTypes.Hydrate:
      return action.payload;
    case ConfigActionTypes.Clear:
      return initialState;
    default: {
      return { ...state };
    }
  }
};

const ConfigProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    async function rehydrate() {
      const storedState = await getConfigData();
      if (storedState) {
        dispatch({ type: ConfigActionTypes.Hydrate, payload: storedState });
      }
    }

    rehydrate();
  }, []);

  React.useEffect(() => {
    storeConfigData(state);
  }, [state]);

  return <ConfigContext.Provider value={{ state, dispatch }}>{children}</ConfigContext.Provider>;
};

const useThemeConfig = () => {
  const {
    state: { theme },
  } = React.useContext(ConfigContext);
  return theme;
};

const useModeConfig = () => {
  const {
    state: { mode },
  } = React.useContext(ConfigContext);
  return mode;
};

export { ConfigContext, ConfigProvider, useThemeConfig, useModeConfig };
