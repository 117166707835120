// Utils
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import FormModal from '../Modal/FormModal';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/pro-regular-svg-icons';

type ActionListItemProps = {
  EditComponent?: any;
  DeleteComponent?: any;
  icon?: any;
  item?: any;
  mainText: string;
  mutedText?: string;
  main?: boolean | string;
  editable?: boolean;
  refetchValues?: any;
  index?: any;
};

const ActionListItem = ({
  EditComponent,
  DeleteComponent,
  icon,
  item,
  mainText,
  mutedText,
  main,
  editable = true,
  refetchValues,
  index,
}: ActionListItemProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const isMain = main || main == 'true';
  const { t } = useTranslation('dashboard');

  return (
    <li className="flex w-full align-top overflow-hidden">
      {icon && (
        <div className="mr-2 text-gray-800 ">
          <FontAwesomeIcon
            icon={icon}
            style={{ color: isMain ? '#F87315' : '#101928', fontSize: '16px', width: '16px' }}
          />
        </div>
      )}
      <div className="flex w-full pb-3">
        <div className="w-5/6 overflow-hidden">
          <span
            className={
              isMain == true || isMain == 'true' ? 'font-semibold text-orange-500' : 'text-gray-800'
            }>
            {mainText}
          </span>
          <div className={'text-gray-800 text-xs'}>
            {isMain ? t('subjects.properties.principal') : null}
            {isMain && mutedText ? ', ' : null}
            {mutedText ? mutedText : null}
          </div>
        </div>
        <div className="w-1/6 text-right mt-1 mr-0. flex flex-row ml-auto">
          {editable && EditComponent && item && (
            <span className="align-top mr-4 ml-auto">
              <button type="button" className="text-gray-800 -mt-5" onClick={() => setIsOpen(true)}>
                <FontAwesomeIcon
                  icon={faPen}
                  size="xl"
                  style={{ color: '#101928', fontSize: '16px' }}
                  className="-mt-5"
                />
              </button>
            </span>
          )}
          {editable && DeleteComponent && item && (
            <span className="align-top mr-1">
              <button type="button" className="text-gray-500" onClick={() => setIsOpenDelete(true)}>
                <FontAwesomeIcon
                  icon={faTrash}
                  size="xl"
                  style={{ color: '#101928', fontSize: '16px' }}
                  className="-mt-5"
                />
              </button>
            </span>
          )}
        </div>
      </div>
      {editable && DeleteComponent && (
        <FormModal open={isOpenDelete} setOpen={setIsOpenDelete} isOverFlow={false}>
          <DeleteComponent
            item={item}
            setIsOpenDelete={setIsOpenDelete}
            refetchValues={refetchValues}
            index={index}
          />
        </FormModal>
      )}
      {editable && EditComponent && (
        <FormModal open={isOpen} setOpen={setIsOpen} isOverFlow={false}>
          <EditComponent
            item={item}
            title={mainText}
            setIsOpen={setIsOpen}
            refetchValues={refetchValues}
          />
        </FormModal>
      )}
    </li>
  );
};

export default ActionListItem;
