import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.scss';
import './localization';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { registerSW } from 'virtual:pwa-register';

import { UserProvider } from './contexts/userContext';
import { AccountProvider } from './contexts/accountContext';
import { ParcelProvider } from './contexts/parcelContext';
import { HeaderProvider } from './contexts/headerContext';
import { OnlineStatusProvider } from './contexts/offlineContext';
import { ConfigProvider } from './contexts/configContext';
import { SubjectProvider } from './contexts/subjectContext';
import Spinner from './components/Spinner/Spinner';
import ErrorBoundaryContainer from './components/ErrorBoundaryContainer/ErrorBoundaryContainer';


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
    <ErrorBoundaryContainer>
      <ConfigProvider>
        <OnlineStatusProvider>
          <HeaderProvider>
            <UserProvider>
              <AccountProvider>
                <SubjectProvider>
                  <ParcelProvider>
                    <BrowserRouter>
                    <Suspense fallback={<Spinner/>}>
                      <App />
                    </Suspense>
                    </BrowserRouter>
                  </ParcelProvider>
                </SubjectProvider>
              </AccountProvider>
            </UserProvider>
          </HeaderProvider>
        </OnlineStatusProvider>
      </ConfigProvider>
    </ErrorBoundaryContainer>
  // </React.StrictMode>
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

if (import.meta.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  registerSW();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
