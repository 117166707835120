import React from 'react';

import { useTranslation } from 'react-i18next';

import { classNames } from '../../utils/utils';

import { AccountContext } from '../../contexts/accountContext';
import { UserContext } from '../../contexts/userContext';

import { updateUser } from '../../utils/api/user';
import { objectToFormdata } from '../../utils/form';

import { Option } from '../../types/input/select';
import { AccountActionTypes } from '../../types';
import { LogisticModeType } from '../../types/config/config';

const NavButton = ({
  tab,
  setTab,
  active,
}: {
  tab: Option;
  setTab: (mode: Option) => void;
  active: boolean;
}) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      active
        ? 'border-white text-white'
        : 'border-transparent text-black/75 hover:text-black hover:border-gray-300',
      'whitespace-nowrap pb-4 px-1 border-b-4 font-medium text-sm focus:outline-none w-1/3',
    )}>
    {tab.name}
  </button>
);

const SwitchLogisticMode = ({ callback }: { callback?: () => void }) => {
  const { t } = useTranslation('dashboard');
  const {
    state: {
      selected: { id: accountId, logisticView },
    },
    dispatch,
  } = React.useContext(AccountContext);
  const {
    state: {
      user: { id: userId },
    },
  } = React.useContext(UserContext);

  const modes = t(`dashboard.logisticModes`, { returnObjects: true }) as {
    [name: string]: string;
  };

  const selectOptions = Object.keys(modes).map(key => ({
    id: key,
    name: modes[key],
  }));

  const [selectedMode, setSelectedMode] = React.useState<Option>(
    selectOptions.find(option => option.id === logisticView) || selectOptions[0],
  );

  React.useEffect(() => {
    setSelectedMode(selectOptions.find(option => option.id === logisticView) || selectOptions[0]);
  }, [logisticView]);

  const selectMode = (mode: Option) => {
    setSelectedMode(mode);
    dispatch({
      type: AccountActionTypes.SetLogisticView,
      payload: mode.id as LogisticModeType,
    });
    updateUser(
      userId,
      objectToFormdata({
        current_account_id: accountId,
        profile: mode.id,
      }),
    );
    if (callback) callback();
  };

  return (
    <div className="sm:pb-0 w-full">
      <div className="mt-3 sm:mt-4">
        <div className="">
          <nav className="flex justify-between">
            {selectOptions.map(mode => (
              <NavButton
                key={mode.id}
                tab={mode}
                setTab={selectMode}
                active={mode.id === selectedMode.id}
              />
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SwitchLogisticMode;
