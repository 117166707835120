import { AxiosResponse } from 'axios';
import api from './api';
import { parse_link_header } from '../hashUtils';
import { getParamString } from '../utils';

export const fetchHouseholdsByName = async (account_id: number, query: string) => {
  const rawResponse = await api.get(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/households.json ${
      query && `?q[name_or_import_id_cont]=${query}`
    }`,
  );
  return rawResponse.data;
};

export const fetchHouseholds = async ({
  account_id,
  page = 1,
  limit = 5,
  query,
  category,
}: {
  account_id: number;
  page?: number;
  limit?: number;
  query?: string;
  category?: string;
}) => {
  const params = {
    ...(query ? { 'q[name_or_import_id_cont]': query } : {}),
    ...(category ? { 'q[category_id_eq]': category } : {}),
  };
  const paramString = getParamString(params);

  const rawResponse = await api
    .get(
      `${
        import.meta.env.VITE_API
      }/${account_id}/api/v1/households.json?page=${page}&per_page=${limit}${
        paramString.length ? '&' : ''
      }${paramString}`,
    )
    .then(response => {
      const properties = {
        links: parse_link_header(response.headers['link']),
        total: parseInt(response.headers['x-total'] || '0'),
        page: parseInt(response.headers['x-page'] || '1') || page,
        per_page: parseInt(response.headers['x-per-page'] || '0'),
      };
      return { properties: properties, data: response.data };
    });
  return rawResponse;
};

export const fetchHousehold = async (account_id: number, id: string) => {
  if (!id) throw Error('No id');
  const rawResponse = await api.get(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/households/${id}.json`,
  );
  return rawResponse.data;
};

export const createHousehold = async (
  account_id: number,
  body: object,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  const rawResponse = await api.post(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/households.json`,
    body,
  );
  return rawResponse;
};

export const editHousehold = async (
  account_id: number,
  householdId: number,
  body: object,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  const rawResponse = await api.put(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/households/${householdId}.json`,
    body,
  );
  return rawResponse;
};

export const deleteHousehold = async (
  account_id: number,
  householdId: number,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id) throw Error('No account id');
  if (!householdId) throw Error('No household id');
  const rawResponse = await api.delete(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/households/${householdId}.json`,
  );
  return rawResponse;
};

export const fetchHouseholdCategories = async (account_id: number) => {
  if (!account_id) throw Error('No account id');
  const rawResponse = await api.get(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/household_categories.json`,
  );
  return rawResponse;
};
