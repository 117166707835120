import React from 'react';

const Highlighted = ({ text = '', highlight = '' }: { text: string; highlight: string }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(
    `(${highlight
      .replaceAll('(', '\\(')
      .replaceAll(')', '\\)')
      .replace(/e/gi, '[eèéêë]')
      .replace(/c/gi, '[cç]')
      .replace(/i/gi, '[iîï]')
      .replace(/u/gi, '[uùûü]')
      .replace(/y/gi, '[yÿ]')
      .replace(/a/gi, '[aàâä]')
      .replace(/o/gi, '[oôö]')})`,
    'gi',
  );
  const parts = text.split(regex);

  return (
    <span>
      {parts.filter(String).map((part, i) => {
        return regex.test(part) ? (
          <b className="font-black" key={i}>
            {part}
          </b>
        ) : (
          <span key={i}>{part}</span>
        );
      })}
    </span>
  );
};

export default Highlighted;
