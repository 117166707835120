export type FilterValuesType = {
  keyword: string;
  category: number;
};

export enum ContactTypesType {
  households = 'households',
  peoples = 'people',
  organizations = 'organizations',
}

export enum SubjectEnum {
  Organization = 'Organization',
  organization = 'organization',
  Person = 'Person',
  person = 'person',
  Household = 'Household',
  household = 'household',
  Events = 'Events',
  events = 'events',
  Notes = 'Notes',
  notes = 'notes',
}

export interface SubjectAddressType {
  addressable_gid?: string;
  city: string;
  coordinates: [number, number];
  country: string;
  country_code: string;
  default?: true;
  id?: number;
  latitude?: number;
  longitude?: number;
  name?: string;
  place_name?: string;
  region: string;
  region_code: string;
  street1: string;
  street2?: string;
  zip: string;
  category?: { id: number; name: string };
  main?: boolean;
}

export interface CollaborationType {
  category: { id: number; name: string };
  class_type: string;
  collaboration_id: number;
  email: string;
  gid: string;
  id: number;
  main: boolean;
  name: string;
  phone_number: string;
  picture_url: string;
  title: string;
}

export enum ContactInformationType {
  PhoneNumber = 'PhoneNumber',
  CellNumber = 'CellNumber',
  Email = 'Email',
  Website = 'Website',
  FaxNumber = 'FaxNumber',
}

export interface ContactInformationsType {
  related_to_subject: any;
  id: number;
  info: string;
  label?: string;
  type: ContactInformationType;
  main: boolean;
}

export interface HouseholdPeopleType {
  main: boolean;
  subject: CollaborationType;
  title?: string;
}

export enum GenderEnum {
  man = 'man',
  woman = 'woman',
  other = 'other',
  unknown = 'unknown',
}

export interface SubjectType {
  account: { id: number; name: string };
  addresses: Array<SubjectAddressType> | string;
  gid: string;
  id: number;
  code: string;
  created_at: string;
  updated_at: string;
  creator: { id: number; name: string; email: string };
  default_program: { id: number; name: string };
  default_middleman: { id: number; name: string };
  updator: { id: number; name: string; email: string };
  name: string;
  class_type: SubjectEnum;
  dob: string;
  gender: GenderEnum;
  email: string;
  middleman_id: number;
  program_id: number;
  phone_number: string;
  collaborations: Array<CollaborationType>;
  contact_informations: Array<ContactInformationsType>;
  category: { id: number; name: string };
  picture_url: string;
  tags: Array<{ name: string; color: string }>;
  note: string;
  supplier_items_count: number;
  address_information: Array<{
    address: any;
    addressDetail: string;
    label: string;
    apartment: '';
  }>;
  genderUpdate: string;
  description: string;
  databaseAdd: boolean;
  receiveEmail: boolean;
}

export interface ContactType {
  cell_number: string;
  email: string;
  gid: string;
  name: string;
  phone_number: string;
  picture_url: string;
}

export interface SubjectGidType {
  id?: number;
  gid: string;
  name: string;
  code?: string;
}

export type SubjectAccountContacts = {
  name: any;
  callback: (account_id: number, query: string) => Promise<any>;
  type: SubjectEnum;
};

export const initialValues: FilterValuesType = {
  keyword: '',
  category: 0,
};
