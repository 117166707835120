import React from 'react'

type HamBurgerButtonPropsType = {
  openMenu: () => void;
  menuOpen: boolean;
  inverted: boolean | undefined;
};

const HamBurgerButton = ({openMenu, menuOpen, inverted} : HamBurgerButtonPropsType) => {
  return (
    <button
      className={`burger top-4 right-5${menuOpen ? ' checked' : ''}${
        inverted ? ' inverted' : ''
      }`}
      type="button"
      onClick={openMenu}>
      <div></div>
    </button>
  )
};

export default HamBurgerButton;