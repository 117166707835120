import React, { useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';
import { AccountContext } from '../../contexts/accountContext';
import { AccountActionTypes, AccountType } from '../../types';
import { refreshUser } from '../../utils/user';
import { classNames } from '../../utils/utils';
import { LogisticModeType, TransportModeType } from '../../types/config/config';
import { fetchUser } from '@/utils/api/user';
import { useLogout } from '@/hooks/useLogout';

let isExisitingAccount : AccountType;

const AccountsSelect = ({ callback }: { callback?: () => void }) => {
  const accountContext = React.useContext(AccountContext);
  const refreshCurrentUser = refreshUser();
  const { selected, accounts } = accountContext.state;
  const { logoutHandler } = useLogout();

  useEffect(() => {
    const getUpdatedAccounts = async () => {
      const user = await fetchUser();
      isExisitingAccount = user.accounts.find((account : AccountType) => account.id === selected.id);

      if(Array.isArray(user.accounts) && user.accounts.length === 0) {
        logoutHandler('/no-accounts');
        return;
      }

      if(!isExisitingAccount) {
        logoutHandler('/no-account');
        return; 
      }
    };
    getUpdatedAccounts();
  },[selected]);

  const setSelected = (account: AccountType) => {
    accountContext.dispatch({
      type: AccountActionTypes.SetAccount,
      payload: {
        ...account,
        transportView: account.profile as TransportModeType,
        logisticView: LogisticModeType.incoming,
      },
    });
    refreshCurrentUser(account.id);
    if (callback) callback();
  };

  if (!selected || !accounts.length || accounts.length === 1) return <></>;

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          {/* <Listbox.Label className="block text-sm font-medium text-gray-700">Comptes</Listbox.Label> */}
          <div className="mt-1 relative">
            <Listbox.Button className="relative w-full rounded-md pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm">
              <span className="flex">
                <span className="block truncate text-white h2">{selected.name}</span>
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-white" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              // as={React.Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="absolute top-10 mb-1 w-full rounded-md bg-white shadow-lg bottom-full">
              <Listbox.Options className=" z-10 mt-1 bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {accounts
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(account => (
                    <Listbox.Option
                      key={account.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-primaryColor' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pr-2 truncate',
                        )
                      }
                      value={account}>
                      {({ active }) => (
                        <>
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                selected.id === account.id ? 'font-semibold' : 'font-normal',
                                'ml-3 block truncate flex gap-2 items-center',
                              )}>
                              <div
                                className={classNames(
                                  'rounded-full w-5 h-5 border flex items-center justify-center shrink-0',
                                  selected.id === account.id
                                    ? 'border-primaryColor'
                                    : 'border-strokeColor',
                                )}>
                                {selected.id === account.id && (
                                  <div className="rounded-full w-3 h-3 bg-primaryColor"></div>
                                )}
                              </div>
                              {account.name}
                            </span>
                          </div>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default AccountsSelect;
