// Utils
import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

// Styles
import '../../src/style/index.scss';
import 'react-datepicker/dist/react-datepicker.css';

// Contexts
import { AccountContext } from '../contexts/accountContext';
import { PersonInfoContext } from '@/contexts/personContext';

// Components
import AddOrganizationWizard from '@/components/AddOrganizationWizard/AddOrganizationWizard';
import ProgressBarWizard from '../components/ProgressBarWizard/ProgressBarWizard';

// Services
import { fetchOrganizationCategories } from '../utils/api/organizations';
import { organizationService } from '../services';

// Types
import { AddressType } from '../types/localization/localization';

const AddOrganization = () => {
  const accountContext = React.useContext(AccountContext);
  const navigate = useNavigate();
  const { hash } = useLocation();
  const location = useLocation();
  const [hashValue, setHashValue] = useState(parseInt(hash.slice(5))); // FIXME: Ouch
  const { personInfo } = useContext(PersonInfoContext);
  const [photos, setPhotos] = React.useState();
  const [organizationInfo, setOrganizationInfo] = React.useState<any>(null);
  const [associatedOrganization, setAssociatedOrganization] = useState();
  const [isCreateOrganization, setIsCreateOrganization] = useState<boolean>(false);

  useEffect(() => {
    setHashValue(parseInt(hash.slice(5)));
  }, [location.hash]);
  const { t } = useTranslation('dashboard');
  const {
    selected: { id: accountId },
  } = accountContext.state;
  const { pathname } = useLocation();

  useEffect(() => {
    setHashValue(parseInt(hash.slice(5))); // FIXME: Ouch

    if (pathname === '/contacts/organizations/new') {
      navigate('/contacts/organizations/new/#step1');
      if (window.location) {
        window.location.reload();
      }
    }
  }, [pathname]);

  const fetchCategories = async () => {
    const response = await fetchOrganizationCategories(accountId).then(response => [
      ...response.data.map((category: any) => ({
        value: category.id,
        label: category.name,
      })),
    ]);
    return response;
  };

  const initialValues = {
    name: '',
    category_id: -1,
    note: '',
    accepts_marketing: false,
    data_consent: false,
    contact_informations: [
      {
        info: '',
        type: 'Email',
        label: '',
        main: true,
      },
    ],
    addresses: [
      {
        address: undefined as unknown as AddressType,
        name: '',
        street2: '',
      },
    ],
    collaborator_gid: null,
    title: null,
    tag_list: [],
  };

  const validationSchema = yup.object().shape({
    name: personInfo
      ? isCreateOrganization
        ? yup.string().required(t('toast.organizationMissing'))
        : yup.string().optional()
      : yup.string().required(t('toast.organizationMissing')),
    category_id: personInfo
      ? isCreateOrganization
        ? yup.number().required(t('toast.missingCategory')).positive(t('toast.missingCategory'))
        : yup.number().optional()
      : yup.number().required(t('toast.missingCategory')).positive(t('toast.missingCategory')),
    contact_informations: yup.array().of(
      yup.object().shape({
        info: yup.string().test({
          name: 'emailValidation',
          message: `${t('errors.email')}`,
          test: function (value) {
            if (this.parent.type === 'Email') {
              return yup.string().email().isValidSync(value);
            }
            return true;
          },
        }),
      }),
    ),
  });

  function onSubmit(fields: any, { setStatus, setSubmitting }: FormikHelpers<Values>) {
    setStatus();
    if (photos) {
      fields.picture = photos;
    }
    if (personInfo) {
      if (isCreateOrganization) {
        let organizationCopy = {
          category_id: fields.category_id,
          name: fields.name,
          main: fields.main,
          title: fields.title,
        };
        createaAndAssociate(accountId, personInfo?.gid, organizationCopy, setSubmitting);
      } else {
        associatePerson(accountId, personInfo?.gid, fields, setSubmitting);
      }
    } else {
      createOrganization(accountId, fields, setSubmitting);
    }
  }

  function onSuccess(data: any, setSubmitting: any) {
    setOrganizationInfo(data);
    setSubmitting(false);

    if (personInfo) {
      setAssociatedOrganization(data);
      navigate(`/contacts/organizations/new/#step2`);
    } else {
      navigate('/contacts/organizations/new/#step6');
    }
  }

  function createOrganization(accountId: number, fields: any, setSubmitting: any) {
    let shallowFieldsCopy = { ...fields };
    if (shallowFieldsCopy.data_consent === true) {
      shallowFieldsCopy.data_consent = 'accepted';
    } else {
      shallowFieldsCopy.data_consent = 'rejected';
    }
    organizationService.create(accountId, shallowFieldsCopy, onSuccess, setSubmitting);
  }

  function associatePerson(accountId: number, subjectGid: any, fields: any, setSubmitting: any) {
    organizationService.associate(accountId, subjectGid, fields, onSuccess, setSubmitting);
  }

  function createaAndAssociate(
    accountId: number,
    subjectGid: any,
    fields: any,
    setSubmitting: any,
  ) {
    organizationService.createAndAssociate(accountId, subjectGid, fields, onSuccess, setSubmitting);
  }

  return (
    <>
      <ProgressBarWizard
        hashValue={hashValue}
        percentage={personInfo ? 50 : 16.66}
        totalSteps={personInfo ? 2 : 6}
      />
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        {({
          setFieldValue,
          values,
          errors,
          touched,
          isSubmitting,
        }: FormikProps<typeof initialValues>) => {
          return (
            <Form className="flex flex-col gap-5 h-[80vh] ">
              <AddOrganizationWizard
                setFieldValue={setFieldValue}
                fetchCategories={fetchCategories}
                photos={photos}
                setPhotos={setPhotos}
                errors={errors}
                touched={touched}
                isSubmitting={isSubmitting}
                hashValue={hashValue}
                values={values}
                accountId={accountId}
                organizationInfo={organizationInfo}
                associatedOrganization={associatedOrganization}
                setIsCreateOrganization={setIsCreateOrganization}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddOrganization;
