import { useTranslation } from 'react-i18next';
import { useField, Field } from 'formik';
// Types
import { FormFieldsType } from '@/types';
import { useEffect } from 'react';

const ContactInformationTypeSelect = ({ setChangeType, ...props }: FormFieldsType) => {
  const { t } = useTranslation('dashboard');
  const [field] = useField(props);
  {
    setChangeType && (
      <>
        {useEffect(() => {
          setChangeType(field.value);
        }, [field])}
      </>
    );
  }

  return (
    <Field
      className="w-full focus:border-orange-400 select-component placeholder-gray-400 border border-gray-400 rounded-md h-12 pl-3"
      as="select"
      {...field}
      {...props}>
      <option value="Email">{t('subjects.addPersonForm.email')}</option>
      <option value="CellNumber">{t('subjects.addPersonForm.cellPhone')}</option>
      <option value="PhoneNumber">{t('subjects.addPersonForm.phone')}</option>
      <option value="Website">{t('subjects.addPersonForm.webSite')}</option>
    </Field>
  );
};
export default ContactInformationTypeSelect;
