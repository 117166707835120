import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../Buttons/Button';

const LangButton = ({ type }: { type?: 'button' | null }) => {
  const { i18n } = useTranslation('common');

  const toggleLang = async () => {
    const lng = i18n.language === 'en' ? 'fr' : 'en';
    i18n.changeLanguage(lng);
    await localStorage.setItem('@toucan/lang', lng);
  };

  return type === 'button' ? (
    <Button
      onClick={toggleLang}
      className="text-white"
      text={i18n.language === 'en' ? 'FR' : 'EN'}
      size="small"
      variant={i18n.language === 'en' ? 'secondary' : 'primary'}
    />
  ) : (
    <button
      type="button"
      className="absolute -mt-16 uppercase text-white font-bold bg-white/10 p-2 rounded-lg border border-white"
      onClick={toggleLang}>
      {i18n.language === 'en' ? 'fr' : 'en'}
    </button>
  );
};

export default LangButton;
