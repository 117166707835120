import { AxiosResponse } from 'axios';
import api from './api';

export const fetchContactInformations = async (account_id: number, subjectGid: string) => {
  if (!account_id || !subjectGid) throw Error('No account_id or subjectGid');
  const rawResponse = await api.get(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/subjects/${encodeURIComponent(
      subjectGid,
    )}/contact_informations.json`,
  );
  return rawResponse.data;
};

export const createContactInformation = async (
  account_id: number,
  subjectGid: string,
  body: object,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id || !subjectGid) throw Error('No account_id or subjectGid');
  const rawResponse = await api.post(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/subjects/${encodeURIComponent(
      subjectGid,
    )}/contact_informations.json`,
    body,
  );
  return rawResponse;
};

export const editContactInformation = async (
  account_id: number,
  subjectGid: string,
  contactInformationId: number | string,
  body: object,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id || !subjectGid) throw Error('No account_id or subjectGid');
  const rawResponse = await api.put(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/subjects/${encodeURIComponent(
      subjectGid,
    )}/contact_informations/${contactInformationId}`,
    body,
  );
  return rawResponse;
};

export const deleteContactInformation = async (
  account_id: number,
  subjectGid: string,
  contactInformationId: number | string,
): Promise<AxiosResponse<any, any>> => {
  if (!account_id || !subjectGid) throw Error('No account_id or subjectGid');
  const rawResponse = await api.delete(
    `${import.meta.env.VITE_API}/${account_id}/api/v1/subjects/${encodeURIComponent(
      subjectGid,
    )}/contact_informations/${contactInformationId}`,
  );
  return rawResponse;
};
