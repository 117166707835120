// API
import { createOrganization } from '@/utils/api/organizations';
import { createContactInformation } from '@/utils/api/contact_informations';
import { addressService } from './address.service';
import { createCollaboration } from '@/utils/api/subjects';

function create(accountId: number, params: any, onSuccessCallback: any, setSubmitting: any) {
  createOrganization(accountId, sanitizeOrganizationParams(params))
    .then(organizationResponse => {
      const dependantOrganizationItemActions = [];

      // Create contact_informations if any
      params.contact_informations.forEach(contact_information => {
        if (contact_information.info != '') {
          dependantOrganizationItemActions.push(
            createContactInformation(accountId, organizationResponse.data.gid, contact_information),
          );
        }
      });

      // Create addresses if any
      params.addresses.forEach(address => {
        if (address.address) {
          dependantOrganizationItemActions.push(
            addressService.create(accountId, organizationResponse.data.gid, address),
          );
        }
      });
      const dependantOrganizationItemResults = Promise.all(dependantOrganizationItemActions);
      dependantOrganizationItemResults.then(() => {
        onSuccessCallback(organizationResponse.data, setSubmitting);
      });
    })
    .catch(error => {
      console.log(error.message);
      // TODO: add onErrorCallback
    });
}

function associate(
  accountId: number,
  subjectGid: string,
  fields: any,
  onSuccess: any,
  setSubmitting: any,
): any {
  createCollaboration(accountId, subjectGid, fields)
    .then(response => {
      onSuccess(response?.data, setSubmitting);
    })
    .catch(error => {
      console.log(error.message);
      // TODO: add onErrorCallback
    });
}

function createAndAssociate(
  accountId: number,
  subjectGid: string,
  fields: any,
  onSuccess: any,
  setSubmitting: any,
): any {
  createOrganization(accountId, fields).then(organizationResponse => {
    const organizationInformation = {
      collaborator_gid: organizationResponse.data.gid,
      ...fields,
    };
    createCollaboration(accountId, subjectGid, organizationInformation)
      .then(response => {
        onSuccess(response?.data, setSubmitting);
      })
      .catch(error => {
        console.log(error.message);
        // TODO: add onErrorCallback
      });
  });
}

function sanitizeOrganizationParams(params: any): any {
  const sanitizedOrganizationParams = Object.assign({}, params);
  sanitizedOrganizationParams['category_id'] = params.category_id.value;
  sanitizedOrganizationParams['tag_list'] = params.tag_list;
  return sanitizedOrganizationParams;
}

export const organizationService = {
  create,
  associate,
  createAndAssociate,
};
