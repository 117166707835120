import { ParcelType, ParcelContextState as ParcelState, NewParcelType } from '..';

export enum ParcelActionTypes {
  AddParcels = 'ADD_PARCELS',
  SetParcel = 'SET_PARCEL',
  RemoveParcel = 'REMOVE_PARCEL',
  Hydrate = 'HYDRATE',
  Clear = 'CLEAR',
  SetAccount = 'SET_ACCOUNT',
  UpdateNewParcel = 'UPDATE_NEW_PARCEL',
  ResetNewParcel = 'RESET_NEW_PARCEL',
}

export type ParcelPayloadAction =
  | {
      type: ParcelActionTypes.Hydrate;
      payload: ParcelState;
    }
  | {
      type: ParcelActionTypes.AddParcels;
      payload: ParcelType[];
    }
  | {
      type: ParcelActionTypes.SetParcel;
      payload: ParcelType;
    }
  | {
      type: ParcelActionTypes.RemoveParcel;
      payload: ParcelState;
    }
  | {
      type: ParcelActionTypes.Clear;
    }
  | {
      type: ParcelActionTypes.SetAccount;
      payload: ParcelType;
    }
  | {
      type: ParcelActionTypes.UpdateNewParcel;
      payload: NewParcelType;
    }
  | {
      type: ParcelActionTypes.ResetNewParcel;
    };
