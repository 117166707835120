// Icons & Images
import { ReactComponent as AddIcon } from '@/assets/icons/add-circle.svg';

// Types & Interfaces
type HallowButtonProps = {
  title?: string;
  link?: string;
  addIcon?: boolean;
  onClick?: any;
  keepDefaultIcon?: boolean;
  alternateIcon?: any;
  type?: 'button' | 'submit' | 'reset' | undefined;
};

const HallowButton = ({
  title,
  link,
  addIcon = true,
  onClick,
  keepDefaultIcon = true,
  alternateIcon,
  type,
}: HallowButtonProps) => {
  return (
    <button
      className="flex flex-col gap-5 text-center border border-orange-500 rounded-lg w-full mx-auto"
      onClick={onClick}
      type={type || 'button'}>
      <a href={link} className="flex justify-center mx-auto items-center px-1">
        {addIcon && keepDefaultIcon ? (
          <AddIcon style={{ stroke: '#F97316' }} className="mr-2 " />
        ) : (
          <div className="mr-2">{alternateIcon}</div>
        )}
        <h3 className="text-orange-500 py-4">{title}</h3>
      </a>
    </button>
  );
};

export default HallowButton;
