import React from 'react';

import {
  SubjectContextState as SubjectState,
  SubjectPayloadAction,
  SubjectActionTypes,
  SubjectType,
} from '../types';

import { getSubjectData, storeSubjectData } from '../utils/data';

const initialState: SubjectState = {
  subjects: [] as SubjectType[],
  searchHistory: [] as SubjectType[],
};

const SubjectContext = React.createContext<{
  state: SubjectState;
  dispatch: React.Dispatch<SubjectPayloadAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

const reducer = (state: SubjectState, action: SubjectPayloadAction) => {
  switch (action.type) {
    case SubjectActionTypes.SetSubjects: {
      return {
        ...state,
        subjects: [
          ...state.subjects,
          ...action.payload.filter(p => !state.subjects.find(subject => subject.id === p.id)),
        ],
      };
    }
    case SubjectActionTypes.SetSubject: {
      return {
        ...state,
        subjects: [
          ...state.subjects.filter(subject => subject.id !== action.payload.id),
          action.payload,
        ],
      };
    }
    case SubjectActionTypes.SetSearchHistory: {
      return {
        ...state,
        searchHistory: [
          ...state.searchHistory.filter(subject => subject.id !== action.payload.id),
          action.payload,
        ],
      };
    }
    case SubjectActionTypes.SetSearchHistorySubjectAddress: {
      return {
        ...state,
        searchHistory: [
          ...state.searchHistory.map(subject => {
            if (subject.gid === action.payload.addressable_gid) {
              subject.addresses = subject.addresses.map(address =>
                address.id === action.payload.id ? action.payload : address,
              );
            }
            return subject;
          }),
        ],
      };
    }
    case SubjectActionTypes.Hydrate:
      return action.payload;
    case SubjectActionTypes.Clear:
      return initialState;
    default: {
      return { ...state };
    }
  }
};

const SubjectProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    async function rehydrate() {
      const storedState = await getSubjectData();
      if (storedState) {
        dispatch({ type: SubjectActionTypes.Hydrate, payload: storedState });
      }
    }

    rehydrate();
  }, []);

  React.useEffect(() => {
    storeSubjectData(state);
  }, [state]);

  return <SubjectContext.Provider value={{ state, dispatch }}>{children}</SubjectContext.Provider>;
};

export { SubjectContext, SubjectProvider };
