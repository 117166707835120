export default {
  welcome: 'Welcome',
  back: 'Back',
  edit: 'Edit',
  add: 'Add',
  noMatch: {
    title: 'No match',
    text: 'Ooops, seems that you tried to access a page that does not exist.',
    button: 'Homepage',
    noAccounts: 'No account has been assigned to you. Please contact your administrator.',
    noAccount: 'This account has been deleted. Please contact your administrator.',
  },
  toast: {
    offline: 'You are offline',
    online: 'You are online',
    error: 'Something went wrong',
    vehicleDeleted: 'The vehicle has been deleted',
    tripDeleted: 'The trip has been deleted',
    parcelDeleted: 'The parcel has been deleted',
    parcelAdressDeleted: 'The adress has been deleted',
    eventDeleted: 'The event has been deleted',
    noteDeleted: 'The note has been deleted',
    organizationDeleted: 'The organization has been deleted',
    personDeleted: 'The person has been deleted',
    householdDeleted: 'The household has been deleted',
    connectionDeleted: 'The connection has been deleted',
    commentDeleted: 'The comment has been deleted',
    productDeleted: 'The product has been deleted',
    attendeeDeleted: 'The attendee has been removed',
    collaboratorDeleted: 'The collaborator has been removed',
  },
  sw: {
    appReadyOffline: 'App ready to work offline',
    newContentAvailable: 'New content available, click on reload button to update.',
  },
  buttons: {
    delete: 'Delete',
    cancel: 'Cancel',
    confirm: 'Confirm',
    logout: 'Logout',
    close: 'Close',
    reload: 'Reload',
  },
  modal: {
    delete: {
      vehicle: {
        title: 'Delete vehicle',
        content:
          'You are about to delete the vehicle. This action is irreversible. Do you want to continue?',
      },
      trip: {
        title: 'Delete trip',
        content:
          'You are about to delete the trip. This action is irreversible. Do you want to continue?',
      },
      tripAssociate: {
        title: 'Dissociate',
        content: 'Do you really want to dissociate this trip?',
      },
      parcel: {
        title: 'Delete parcel',
        content:
          'You are about to delete the parcel. This action is irreversible. Do you want to continue?',
      },
      parcelAssociate: {
        title: 'Dissociate',
        content: 'Do you really want to dissociate this parcel?',
      },
      parcelAdress: {
        title: 'Delete parcel adress',
        content:
          'You are about to delete the parcel adress. This action is irreversible. Do you want to continue?',
      },
      event: {
        title: 'Delete event',
        content:
          'You are about to delete the event. This action is irreversible. Do you want to continue?',
      },
      note: {
        title: 'Delete note',
        content:
          'You are about to delete the event. This action is irreversible. Do you want to continue?',
      },
      organization: {
        title: 'Delete organization',
        content:
          'You are about to delete the organization. This action is irreversible. Do you want to continue?',
      },
      person: {
        title: 'Delete person',
        content:
          'You are about to delete the person. This action is irreversible. Do you want to continue?',
      },
      connection: {
        title: 'Delete connection',
        content:
          'You are about to delete the connection. This action is irreversible. Do you want to continue?',
      },
      comment: {
        title: 'Delete comment',
        content:
          'You are about to delete the comment. This action is irreversible. Do you want to continue?',
      },
      product: {
        title: 'Delete product',
        content:
          'You are about to delete the product. This action is irreversible. Do you want to continue?',
      },
      household: {
        title: 'Delete household',
        content:
          'You are about to delete the household. This action is irreversible. Do you want to continue?',
      },
      attendee: {
        title: 'Delete attendee',
        content:
          'You are about to delete the attendee. This action is irreversible. Do you want to continue?',
      },
      collaborator: {
        title: 'Delete a collaboration',
        content:
          'You are about to delete the collaboration. This action is irreversible. Do you want to continue?',
      },
    },
  },
  time: {
    ago: 'ago',
    now: 'Just now',
    timeAgo: 'A moment ago',
    days_one: '{{count}} day ago',
    days_other: '{{count}} days ago',
    hours_one: '{{count}} hour ago',
    hours_other: '{{count}} hours ago',
    minutes_one: '{{count}} minute ago',
    minutes_other: '{{count}} minutes ago',
  },
  errorBoundaries: {
    title: 'Something went wrong',
    description:
      'An error has occurred. We are sorry for the inconvenience. If you want to help us improve, please send us a message with the error details.',
    details: 'Error details',
    button: 'Go back to homepage',
  },
};
